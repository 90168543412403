import Button from '../../../components/button/Button'
import { SvgClose } from '../../../components/svg/SvgClose'
import { SvgNavigation } from '../../../components/svg/SvgNavigation'
import { SvgPhone } from '../../../components/svg/SvgPhone'
import { WDButtonTransparentWithoutHeight } from '../../../components/ui/WDButton'
import {
  WDLabel,
  WDLabelPrimaryBold,
  WDSubFormLabelHeading,
} from '../../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { LeadInfoResponseInterface } from '../../createContactList/ICreateLead'

import {
  CardContentWrapper,
  ContactCenterSectionWrapper,
  ContactLeftSectionIconWrapper,
  ContactLeftSectionTextWrapper,
  ContactLeftSectionWrapper,
  ContentFormWrapper,
  ContentWrapper,
  FooterSectionEndWrapper,
  FooterSectionWrapper,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
  OverlayWrapper,
  Wrapper,
} from './styles'
import { useState, useEffect } from 'react'
import { TitleTextWrp } from '../styles'
import { LeadInfoProps } from '../overviewSection/IEditDetails'
import { DefaultAddressList } from '../overviewSection/LeadContactCard'
import GraphQLLoader from '../../../composites/loader/GraphQLLoader'
import { SvgEmail } from '../../../components/svg/SvgEmail'
import { formatPhoneNumber } from '../../../services/Utils'
import Label from '../../../components/label/Label'
interface PartyRelationshipProps {
  closeMemberDetails: () => void
  leadData?: LeadInfoResponseInterface
  leadDetails?: LeadInfoProps
  loadingLeadData: boolean
  isHead?: boolean
  type: string
}
export const DefaultContactList = {
  addressline1: '',
  addressline2: '',
  city: '',
  state: '',
  country: '',
  zipcode: '',
}

const ContactCard = ({
  closeMemberDetails,
  leadData,
  loadingLeadData,
  isHead,
  leadDetails,
  type,
}: PartyRelationshipProps) => {
  const [contact, setContact] = useState<number>(0)
  const [email, setEmail] = useState<string>('')
  const [addressList, setAddressList] = useState<Array<string | number>>([])

  const displayState = (state: string, postalCode: string) => {
    if (state && postalCode) {
      return `${state} - ${postalCode}`
    } else if (state && !postalCode) {
      return state
    } else if (!state && postalCode) {
      return postalCode
    } else {
      return ''
    }
  }
  useEffect(() => {
    if (isHead) {
      const { addressLine1, addressLine2, city, state, country, postalCode } =
        leadDetails?.addressList.find((address) => address.isPrimary) ??
        DefaultAddressList
      setAddressList(
        [
          addressLine1,
          addressLine2,
          city,
          displayState(state, postalCode.toString()),
          country,
        ].filter((address) => address !== '')
      )
      setContact(
        leadDetails?.phoneList.find((phone) => phone.isPrimary)?.phoneNo ?? 0
      )
      setEmail(
        leadDetails?.emailList.find((email) => email.isPrimary)?.email ?? 'NA'
      )
    } else {
      const { addressline1, addressline2, city, state, country, zipcode } =
        leadData?.addresslist.find((address) => address.isprimary) ??
        DefaultContactList
      setAddressList(
        [
          addressline1,
          addressline2,
          city,
          displayState(state, zipcode.toString()),
          country,
        ].filter((address) => address !== '')
      )
      setContact(
        leadData?.contactList?.find((phone) => phone.isprimary)?.phonenumber ??
          0
      )
      setEmail(
        leadDetails?.emailList.find((email) => email.isPrimary)?.email ?? 'NA'
      )
    }
  }, [isHead, leadData, leadDetails])
  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <HeaderWrapper>
            <WDSubFormLabelHeading>
              <Label>
                <Label>{'Contact Card'}</Label>
              </Label>
            </WDSubFormLabelHeading>
            <WDButtonTransparentWithoutHeight>
              <Button type="button" onClick={() => closeMemberDetails()}>
                <SvgClose fillColor={DEFAULT_COLORS.Icons.Primary} />
              </Button>
            </WDButtonTransparentWithoutHeight>
          </HeaderWrapper>
          <ContentWrapper>
            {loadingLeadData ? (
              <GraphQLLoader />
            ) : (
              <ContentFormWrapper>
                <CardContentWrapper>
                  <ContactLeftSectionWrapper>
                    <ContactLeftSectionIconWrapper>
                      <SvgEmail fillColor={DEFAULT_COLORS.Icons.Primary} />
                    </ContactLeftSectionIconWrapper>
                    <ContactLeftSectionTextWrapper>
                      <WDLabelPrimaryBold>{'E-mail'}</WDLabelPrimaryBold>
                    </ContactLeftSectionTextWrapper>
                  </ContactLeftSectionWrapper>
                  <ContactCenterSectionWrapper>
                    <WDLabel>
                      <TitleTextWrp>{email}</TitleTextWrp>
                    </WDLabel>
                  </ContactCenterSectionWrapper>
                </CardContentWrapper>
                <CardContentWrapper>
                  <ContactLeftSectionWrapper>
                    <ContactLeftSectionIconWrapper>
                      <SvgPhone fillColor={DEFAULT_COLORS.Icons.Primary} />
                    </ContactLeftSectionIconWrapper>
                    <ContactLeftSectionTextWrapper>
                      <WDLabelPrimaryBold>{'Phone'}</WDLabelPrimaryBold>
                    </ContactLeftSectionTextWrapper>
                  </ContactLeftSectionWrapper>
                  <ContactCenterSectionWrapper>
                    <WDLabel>
                      <TitleTextWrp>
                        {type == 'lead' && contact
                          ? contact
                            ? formatPhoneNumber(contact)
                            : 'NA'
                          : 'NA'}
                      </TitleTextWrp>
                    </WDLabel>
                  </ContactCenterSectionWrapper>
                </CardContentWrapper>
                <CardContentWrapper>
                  <ContactLeftSectionWrapper>
                    <ContactLeftSectionIconWrapper>
                      <SvgNavigation fillColor={DEFAULT_COLORS.Icons.Primary} />
                    </ContactLeftSectionIconWrapper>
                    <ContactLeftSectionTextWrapper>
                      <WDLabelPrimaryBold>{'Home'}</WDLabelPrimaryBold>
                    </ContactLeftSectionTextWrapper>
                  </ContactLeftSectionWrapper>
                  <ContactCenterSectionWrapper>
                    <WDLabel>
                      {addressList.length === 0 ||
                      addressList.every((address) => address === '') ? (
                        <TitleTextWrp>{'NA'}</TitleTextWrp>
                      ) : (
                        <TitleTextWrp>
                          {addressList.map((address, addressIndex) =>
                            address === ''
                              ? ''
                              : addressIndex !== addressList.length - 1
                              ? `${address}, `
                              : address
                          )}
                        </TitleTextWrp>
                      )}
                    </WDLabel>
                  </ContactCenterSectionWrapper>
                </CardContentWrapper>
              </ContentFormWrapper>
            )}
          </ContentWrapper>
          <FooterWrapper>
            <FooterSectionWrapper></FooterSectionWrapper>
            <FooterSectionEndWrapper></FooterSectionEndWrapper>
          </FooterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}
export default ContactCard
