import styled from 'styled-components'
import { SIZES } from '../../theme/Sizes'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${SIZES[1]};
  ${() => DEFAULT_COLORS.BoxShadow.StyledCardShadow};
  padding: ${SIZES[3]};
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  z-index: 1;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`

export const CreateWrapper = styled.div`
  display: ${(props: { display?: boolean }) =>
    props.display == false ? 'none' : 'flex'};
  flex-direction: column;
  gap: ${SIZES[1]};
  width: 100%;
`

export const ItemWrapper = styled.div`
  display: flex;
  gap: ${SIZES[3]};
  flex: 1;
  margin: ${(props: { margin: string }) => props.margin ?? 'auto'};
`
export const SectionWrapper = styled.div`
  display: ${(props: { display: string }) => props.display};
  gap: ${SIZES[3]};
  flex: 1;
  flex-direction: column;
`
export const Table = styled.div`
  display: table;
`
export const Tr = styled.div`
  display: table-row;
`
export const Th = styled.div`
  font-weight: bold;
  display: table-cell;
  width: ${(props: { width: string }) => props.width ?? 'auto'};
  padding: 3px;
  border-bottom: 1px solid #ccc;
`
export const Td = styled.div`
  display: table-cell;
  padding: 5px 3px;
`
export const Heading = styled.div`
  font-weight: ${(props: { fontWeight: string }) => props.fontWeight ?? 'bold'};
  margin-bottom: ${(props: { mb: string }) => props.mb ?? '0px'};
  margin-top: ${(props: { mt: string }) => props.mt ?? '0px'};
`

export const DataWrapper = styled.div`
display: flex;
width: 100%;
position: relative;
margin-top: ${(props: { mt: string }) => props.mt ?? '0px'};
flex: 1;
background: ${() => DEFAULT_COLORS.Background.Neutral};
border: 1px solid ${() => DEFAULT_COLORS.Background.PrimaryHover};
padding: ${SIZES[1]};
`
export const DataItem = styled.div`
position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  padding: 5px 5px;
  font-size: 12px;
  ;
  background-color: ${(props: { type?: string }) =>
    props.type == 'primary' ? 'rgb(206 241 208)' : 'rgb(223 237 255)'};
  border-radius: 3px;
  margin: 5px;
  input {
    display: none;
  }
  & label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;
    margin-left: 0.5rem;
  }
`


export const DataBadge = styled.div`
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
margin-right: 3px;
border: 1px solid #5dc563;
height: 13px;
width: 13px;
border-radius: 8px;
font-size: 9px;
line-height: 13px;
background: #fff;
position: absolute;
top: -5px;
left: -4px;
`;
export const DataItemClose = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  content: 'x';
  padding-left:5px;
`

export const ItemSubWrp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid rgb(239 239 239);
`

export const SelectBoxLarge = styled.div`
  min-width: 34rem;
`
export const CollapseWrapper = styled.div`
  display: ${(props: { display?: boolean }) =>
    props.display ?? 'flex'};
  width: 100%;
  gap: ${SIZES[1]};
  cursor: pointer;
  align-items: center;
`
export const CollapseHeaderWrapper = styled.div`
  display: flex;
  flex: 0 1 90%;
  width: 100%;
`
export const CollapseButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 10%;
  justify-content: flex-end;
  width: 100%;
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: ${SIZES[1]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  padding: ${SIZES[1]};
  border: 1px solid ${() => DEFAULT_COLORS.Background.Grey};
`
export const PermissionWrapper = styled.div`
  display: ${(props: { display?: boolean }) =>
    props.display ? 'flex' : 'none'};
  width: 100%;
  position: relative;
  flex: 1;
`


export const LeftWrapper = styled.div`
  width: 40%;
  float: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const TextBoxWrap = styled.div`
  display: block;
  width: ${(props: { width?: string }) => { return props.width ?? 'auto' }};
  input {
    min-width: 16rem;
    padding: 0.5rem 1rem !important;
  }
`
export const SearchBoxWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
export const SearchButtonWrap = styled.div`
  position: absolute;
  right: 5px;
`
export const AllButtonWrapper = styled.div`
  display: flex;
  input {
    margin-right: 0.35rem;
    margin-top: 1px;
  }
  label {
    display: block;
    width: 100%;
  }
`
export const ResponseWrap = styled.div`
  padding: 0.2rem 0.4rem;
  margin: 1rem 0;
  border-radius: 0.25rem;
  color: #000;
  position: absolute;
  right: 4.2rem;
  font-weight:bold;
  background-color: ${(props: { type: string }) =>
    props.type == 'success' ? '#a3e7a7' : 'rgb(255 151 151)'};
  font-size: 11px;
`
export const Divider = styled.div`
  display: ${(props: { display?: boolean }) =>
    props.display == false ? 'none' : 'block'};
  background-color: #aaa;
  height: 1px;
  margin: 4px 0px;
`
export const Note = styled.div`
  font-size: 0.8rem;
  color: #929292;
`
