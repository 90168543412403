import { useEffect, useMemo, useState } from 'react'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { Textbox } from '../../components/textbox/Textbox'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import {
  AddressInformationProps,
  EmailInformationInterface,
} from './IEmailInformation'
import {
  CreateWrapper,
  ItemWrapper,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
  CheckboxWrapper,
  EmailSectionWrapper,
  StyledButtonWrapper,
  ItemSpaeWrapper,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import {
  WDButton,
  WDButtonTransparent,
  WDButtonMediumPrimaryBorder,
} from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import {
  ButtonWrapper,
  ButtonWrapperRight,
  WDContactInfoWrapper,
} from '../../components/ui/WDWrapper'
import Checkbox from '../../components/checkbox/Checkbox'
import { ItemSubWrp } from '../individualInformation/styles'

const EmailInformation = ({
  isUpdate,
  updateData,
  leadInfoData,
  updateEmailData,
}: AddressInformationProps) => {
  const [isShippinInformation, setIsShippingInformationOpen] = useState(true)
  const [emailList, setEmailList] = useState<EmailInformationInterface[]>([
    {
      emailType: '',
      isPrimary: true,
      email: '',
      activeFlag: true,
    },
  ])

  const getEmailList = (index: number, fieldName: string, value: string) => {
    if (emailList.length > 1) {
      const filteredEmail: EmailInformationInterface[] = emailList.map(
        (email, i) => {
          if (email.emailType === value && fieldName === 'emailType') {
            return { ...email, emailType: '' }
          } else if (fieldName === 'isPrimary' && index !== i) {
            return { ...email, isPrimary: false }
          }
          return { ...email }
        }
      )
      return filteredEmail
    } else {
      return emailList
    }
  }

  const updateEmailList = (index: number, fieldName: string, value: string) => {
    const currentEmailList = getEmailList(index, fieldName, value).map(
      (email, i) => {
        if (index === i) {
          switch (fieldName) {
            case 'isPrimary':
              return { ...email, isPrimary: value === 'true' }
            case 'emailType':
              return { ...email, emailType: value }
            case 'email':
              return { ...email, email: value }
            default:
              return { ...email }
          }
        } else {
          return { ...email }
        }
      }
    )
    setEmailList(currentEmailList)
    updateData({ emailList: currentEmailList }, fieldName)
  }

  const addressTypeList = useMemo(
    () => [
      { value: 'Home', label: 'Home' },
      { value: 'Work', label: 'Work' },
      { value: 'Other', label: 'Other' },
    ],
    []
  )

  const [filteredAddressTypeList, setFilteredAddressTypeList] =
    useState(addressTypeList)

  const deleteEmail = (indexNumber: number) => {
    const updatedEmailList = emailList.map((email, index) => {
      if (index === indexNumber) {
        return { ...email, activeFlag: false }
      } else {
        return { ...email }
      }
    })
    setEmailList([...updatedEmailList])
    updateData({ emailList: updatedEmailList })
  }

  const addAnotherEmail = () => {
    if (emailList.length < 3) {
      const updatedEmailList = emailList
      updatedEmailList.push({
        emailType: '',
        isPrimary: false,
        email: '',
        activeFlag: true,
      })
      setEmailList([...updatedEmailList])
    }
  }


  useEffect(() => {
    if (leadInfoData?.emailList?.length) {
      setEmailList(leadInfoData.emailList)
    }
  }, [leadInfoData?.emailList])

  useEffect(() => {
    if (emailList.length) {
      setFilteredAddressTypeList(
        addressTypeList.filter(
          (address) =>
            !emailList
              .filter((email) => email.activeFlag)
              .map((email) => {
                return email.emailType
              })
              .includes(address.value)
        )
      )
    }
  }, [addressTypeList, emailList])

  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper
          onClick={() => setIsShippingInformationOpen(!isShippinInformation)}
        >
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">{'Emails'}</Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() =>
                  setIsShippingInformationOpen(!isShippinInformation)
                }
              >
                <Image
                  image={
                    isShippinInformation ? Icons.ChevronUp : Icons.ChevronDown
                  }
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isShippinInformation && (
          <>
            {emailList.map((email, index) => {
              if (email.activeFlag) {
                return (
                  <EmailSectionWrapper key={index}>
                    <ItemWrapper>
                      <ItemSubWrp>
                        <CreateWrapper>
                          <Label>{'Email Type'}</Label>
                          <SelectDropdown
                            className="w-100"
                            borderColor={DEFAULT_COLORS.Border.Neutral}
                            padding="0rem"
                            options={
                              email.emailType
                                ? addressTypeList
                                : filteredAddressTypeList
                            }
                            height="2.62rem"
                            placeholder={'Select'}
                            value={{
                              value: email.emailType,
                              label: email.emailType,
                            }}
                            onChange={(option) =>
                              updateEmailList(index, 'emailType', option.value)
                            }
                          />
                        </CreateWrapper>
                        <CreateWrapper>
                          <Label>{'Email Address'}</Label>
                          <Textbox
                            onChange={(value) =>
                              updateEmailList(index, 'email', value)
                            }
                            disabled={!email.emailType}
                            value={email.email}
                          />
                        </CreateWrapper>
                      </ItemSubWrp>
                    </ItemWrapper>
                    <ItemSpaeWrapper>
                      <ItemWrapper>
                        <CheckboxWrapper>
                          <Checkbox
                            onChange={(checked) =>
                              updateEmailList(
                                index,
                                'isPrimary',
                                checked ? 'true' : 'false'
                              )
                            }
                            checked={email.isPrimary}
                            disabled={email.isPrimary}
                          ></Checkbox>
                          <Label>{'Primary'}</Label>
                        </CheckboxWrapper>
                      </ItemWrapper>
                      {!email.isPrimary && (
                        <ItemWrapper>
                          <StyledButtonWrapper>
                            <Button
                              type="button"
                              bgColor={DEFAULT_COLORS.Background.DangerStrong}
                              disabled={email.isPrimary}
                              onClick={() => deleteEmail(index)}
                            >
                              {'Delete'}
                            </Button>
                          </StyledButtonWrapper>
                        </ItemWrapper>
                      )}
                    </ItemSpaeWrapper>
                  </EmailSectionWrapper>
                )
              }
            })}
            <ButtonWrapper>
              <ButtonWrapperRight>
                <WDButtonMediumPrimaryBorder>
                  <Button
                    type="button"
                    onClick={() => addAnotherEmail()}
                    disabled={!(emailList.length < 3)}
                  >
                    {'Add Another Email'}
                  </Button>
                </WDButtonMediumPrimaryBorder>
                {isUpdate && (
                  <WDButton>
                    <Button type="button" onClick={updateEmailData}>
                      {'Save'}
                    </Button>
                  </WDButton>
                )}
              </ButtonWrapperRight>
            </ButtonWrapper>
          </>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default EmailInformation
