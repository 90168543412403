import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: ${(props: { position: string }) => props.position ?? 'fixed'};
  top: 0;
  left: 0;
  bottom:0;
  right: 0;
  z-index: 999;
  background-color: rgb(255 255 255 / 75%);
  justify-content: center;
  align-items: center;
`
export const GraphQLSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
`
export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SIZES[12]};
  height: ${SIZES[12]};
  padding: ${SIZES[1]};
`
export const LoaderTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'SourceSansPro-SemiBold';
  font-size: 1.125rem;
  color: ${DEFAULT_COLORS.Text.Neutral};
`
export const LottieWrapperMedium = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SIZES[1]};
  width: 5rem;
  height: 15rem;
  margin: 0 auto;
`