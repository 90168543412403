import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgCancelSmall = ({ fillColor }: ISvg) => {
  return (
    <StyledDiv>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.40994 8L13.7099 3.71C13.8982 3.5217 14.004 3.2663 14.004 3C14.004 2.7337 13.8982 2.47831 13.7099 2.29C13.5216 2.1017 13.2662 1.99591 12.9999 1.99591C12.7336 1.99591 12.4782 2.1017 12.2899 2.29L7.99994 6.59L3.70994 2.29C3.52164 2.1017 3.26624 1.99591 2.99994 1.99591C2.73364 1.99591 2.47824 2.1017 2.28994 2.29C2.10164 2.47831 1.99585 2.7337 1.99585 3C1.99585 3.2663 2.10164 3.5217 2.28994 3.71L6.58994 8L2.28994 12.29C2.19621 12.383 2.12182 12.4936 2.07105 12.6154C2.02028 12.7373 1.99414 12.868 1.99414 13C1.99414 13.132 2.02028 13.2627 2.07105 13.3846C2.12182 13.5064 2.19621 13.617 2.28994 13.71C2.3829 13.8037 2.4935 13.8781 2.61536 13.9289C2.73722 13.9797 2.86793 14.0058 2.99994 14.0058C3.13195 14.0058 3.26266 13.9797 3.38452 13.9289C3.50638 13.8781 3.61698 13.8037 3.70994 13.71L7.99994 9.41L12.2899 13.71C12.3829 13.8037 12.4935 13.8781 12.6154 13.9289C12.7372 13.9797 12.8679 14.0058 12.9999 14.0058C13.132 14.0058 13.2627 13.9797 13.3845 13.9289C13.5064 13.8781 13.617 13.8037 13.7099 13.71C13.8037 13.617 13.8781 13.5064 13.9288 13.3846C13.9796 13.2627 14.0057 13.132 14.0057 13C14.0057 12.868 13.9796 12.7373 13.9288 12.6154C13.8781 12.4936 13.8037 12.383 13.7099 12.29L9.40994 8Z"
          fill={fillColor}
        />
      </svg>
    </StyledDiv>
  )
}
