import { vars } from '../../../assets/utils/CommonFunctions'
import Button from '../../../components/button/Button'
import { WDBorderButtonPrimary } from '../../../components/ui/WDButton'
import { AuthContext } from '../../../services/AuthContext'
import { LeadInfoProps } from '../../createContactList/ICreateLead'
import EstimatedNetWorth from '../financialDetails/EstimatedNetWorth'
import EstimatedTaxInfo from '../financialDetails/EstimatedTaxInfo'
import StrategicModal from '../financialDetails/StrategicModal'
import { BtnWrapper } from '../styles'
import './style.css'
import { useContext, useState } from 'react'

type FinancialDetailsnProps = {
  state?: LeadInfoProps
  updateData?: (fields: Partial<LeadInfoProps>) => void
  updateLeadInfoData?: (fields: Partial<LeadInfoProps>) => void
}

const FinancialDetails = ({
  state,
  updateLeadInfoData,
}: FinancialDetailsnProps) => {
  const [isStrategicModal, setIsStrategicModal] = useState(false)
  const { userAccessDetails } = useContext(AuthContext)
  const [estimatedTaxInfoModal, setEstimatedTaxInfoModal] = useState(false)
  const [estimatedNetWorthInfoModal, setEstimatedNetWorthInfoModal] =
    useState(false)

  const formatUSD = (value: string | undefined | number) => {
    if (!value) return value
    const amount = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    return Number(amount) < 0 ? '-$' + amount.replace('-', '') : '$' + amount
  }
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <>
      <div className="tab-content">
        <div className="tab-pane">
          <div className="main-wrapper">
            <div className="col-md-4">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title pull-left">
                    {'Strategic Allocation Model'}
                  </h3>
                  {userAccessDetails.role === vars.adminRole && <BtnWrapper>
                    <WDBorderButtonPrimary>
                      <Button
                        type="button"
                        onClick={() => {
                          setIsStrategicModal(true)
                        }}
                      >
                        {'Edit'}
                      </Button>
                    </WDBorderButtonPrimary>
                  </BtnWrapper>}
                </div>

                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <td>{'Objective'}</td>
                      <td>
                        {state?.investmentObjectives?.length
                          ? state?.investmentObjectives
                          : 'NA'}
                      </td>
                    </tr>

                    <tr>
                      <td>{'Time Horizon'}</td>
                      <td>
                        {state?.timeHorizon?.length ? state?.timeHorizon : 'NA'}
                      </td>
                    </tr>

                    <tr>
                      <td>{'Risk Tolerance'}</td>
                      <td>
                        {state?.riskTolerance?.length
                          ? state?.riskTolerance
                          : 'NA'}
                      </td>
                    </tr>
                    <tr>
                      <td>{'Experience Funds'}</td>
                      <td>
                        {state?.experienceFunds != undefined
                          ? state?.experienceFunds
                          : 'NA'}
                      </td>
                    </tr>
                    <tr>
                      <td>{'Experience Stocks'}</td>
                      <td>
                        {state?.experienceStocks != undefined
                          ? state?.experienceStocks
                          : 'NA'}
                      </td>
                    </tr>

                    <tr>
                      <td>{'Experience Partnerships'}</td>
                      <td>
                        {state?.experiencePartnerships != undefined
                          ? state?.experiencePartnerships
                          : 'NA'}
                      </td>
                    </tr>

                    <tr>
                      <td>{'Experience - Other'}</td>
                      <td>
                        {state?.experienceOthers != undefined
                          ? state?.experienceOthers
                          : 'NA'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div
                id="panel-contact-tax-information"
                className="panel panel-default"
              >
                <div className="panel-heading">
                  <h3 className="panel-title pull-left">
                    Estimated Tax Information
                  </h3>
                  {userAccessDetails.role === vars.adminRole && <BtnWrapper>
                    <WDBorderButtonPrimary>
                      <Button
                        type="button"
                        onClick={() => {
                          setEstimatedTaxInfoModal(true)
                        }}
                      >
                        {'Edit'}
                      </Button>
                    </WDBorderButtonPrimary>
                  </BtnWrapper>}
                </div>
                <table id="tax-detail" className="table table-hover ">
                  <tbody>
                    <tr>
                      <td>{'Adjusted Gross Income'}</td>
                      <td>
                        {state && state.adjustedgrossincome >=0
                          ? formatUSD(state?.adjustedgrossincome)??'NA'
                          : 'NA'}
                      </td>
                    </tr>
                    <tr>
                      <td>{'Estimated Taxes'}</td>
                      <td> {state && state?.estimatedtaxes>=0  ? formatUSD(state?.estimatedtaxes)??'NA' : 'NA'} </td>
                    </tr>

                    <tr>
                      <td>{'Confirmed by Tax Return'}</td>
                      <td> {state?.confirmedbytaxreturn ? 'Yes' : 'No'} </td>
                    </tr>

                    <tr>
                      <td>{'Tax Year'}</td>
                      <td> {state?.taxyear ?? 'NA'} </td>
                    </tr>

                    <tr>
                      <td>{'Estimated Tax Bracket'}</td>
                      <td>
                        {state?.estimatedtaxbracket
                          ? state?.estimatedtaxbracket + '%'
                          : 'NA'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div
                id="panel-contact-personal-profile-est_net_worth"
                className="panel panel-default"
              >
                <div className="panel-heading">
                  <div className="panel-title pull-left">
                    Estimated Net Worth
                  </div>
                  {userAccessDetails.role === vars.adminRole && <BtnWrapper>
                    <WDBorderButtonPrimary>
                      <Button
                        type="button"
                        onClick={() => {
                          setEstimatedNetWorthInfoModal(true)
                        }}
                      >
                        {'Edit'}
                      </Button>
                    </WDBorderButtonPrimary>
                  </BtnWrapper>}
                </div>

                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <td>
                       {' Est. Total Assets'}
                        <br />
                        <div style={{ marginTop: '7px', marginLeft: '10px' }}>
                          Est. Non-Liquid Assets
                          <span style={{ marginLeft: '30px' }}>
                            {`${USDollar.format(
                              state?.totalNonLiquidAssets ?? 0
                            )}`}
                          </span>
                          <br />
                          {'Est. Liquid Assets'}
                          <span style={{ marginLeft: '50px' }}>
                            {`${USDollar.format(
                              (state?.totalAssets ?? 0) -
                              (state?.totalNonLiquidAssets ?? 0)
                            )}`}
                          </span>
                        </div>
                      </td>
                      <td>{`${USDollar.format(state?.totalAssets ?? 0)}`} </td>
                    </tr>

                    <tr>
                      <td>{'Est. Liabilities'}</td>
                      <td>{`${USDollar.format(state?.liabilities ?? 0)}`} </td>
                    </tr>

                    <tr>
                      <td>{'Est. Net Worth (total - liabilities)'}</td>
                      <td>
                        {`${USDollar.format(
                          (state?.totalAssets ?? 0) - (state?.liabilities ?? 0)
                        )}`}
                      </td>
                    </tr>

                    <tr>
                      <td>{'Est. Liquid Net Worth (liquid - liabilities)'}</td>
                      <td>
                        {`${USDollar.format(
                          (state?.totalAssets ?? 0) -
                          (state?.totalNonLiquidAssets ?? 0) -
                          (state?.liabilities ?? 0)
                        )}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* stratagic modal */}
      </div>
      {isStrategicModal && (
        <StrategicModal
          setIsStrategicModal={setIsStrategicModal}
          leadDetails={state}
          updateLeadInfoData={updateLeadInfoData}
        />
      )}
      {estimatedTaxInfoModal && (
        <EstimatedTaxInfo
          setEstimatedTaxInfoModal={setEstimatedTaxInfoModal}
          leadDetails={state}
          updateLeadInfoData={updateLeadInfoData}
        />
      )}
      {estimatedNetWorthInfoModal && (
        <EstimatedNetWorth
          setEstimatedNetWorthInfoModal={setEstimatedNetWorthInfoModal}
          leadDetails={state}
          updateLeadInfoData={updateLeadInfoData}
        />
      )}
    </>
  )
}
export default FinancialDetails
