import { useContext } from 'react'
import { vars } from '../../../assets/utils/CommonFunctions'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { WDBorderButtonPrimary } from '../../../components/ui/WDButton'
import { AuthContext } from '../../../services/AuthContext'
import { ThemeContext } from '../../../store/ThemeContext'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { LeadInfoProps } from '../../createContactList/ICreateLead'
import { BtnWrapper, LeftUserProfile } from '../styles'
import EditContactDetails from './ContactDetails'
import {
  ContactDetailsTable,
  HeadingTextWrapper,
  ProfileHeadingWrapper,
} from './styles'

type LeadContactSectionProps = {
  contactDetails: LeadInfoProps

  updateLeadInfoData: (fields: Partial<LeadInfoProps>) => void
  showEditContact: boolean
  setShowEditContact: (showEditContact: boolean) => void
  inputErrors: string[]
  setInputErrors: (errors: string[]) => void
}

export const LeadContactSection = ({
  contactDetails,
  updateLeadInfoData,
  inputErrors,
  setInputErrors,
  showEditContact,
  setShowEditContact,
}: LeadContactSectionProps) => {
  const { userAccessDetails } = useContext(AuthContext)
  const { isDarkMode } = useContext(ThemeContext)
  const getLabel = (value: string) => {
    if (value && value !== 'Invalid Date') {
      return value
    } else {
      return '--'
    }
  }
  const formatDisplayDate = (date: Date | null) => {
    return date ? new Date(date)?.toISOString()?.split('T')[0] : ''
  }

  const individualFields = [
    { label: 'Status', value: getLabel(contactDetails.status) },
    { label: 'Rel Segment', value: contactDetails.accountTier },
    { label: 'Gender', value: contactDetails.gender },
    { label: 'Martial Status', value: contactDetails.martialStatus },
    {
      label: 'Marital Anniversary',
      value: formatDisplayDate(contactDetails.maritalAnniversary),
    },
    {
      label: 'Date Of Birth',
      value: formatDisplayDate(contactDetails.dateOfBirth),
    },
    {
      label: 'Interest & Hobbies',
      value: contactDetails.interestHobbies ?? '',
    },
    {
      label: 'Household Members',
      value: contactDetails.householdMembers ?? '',
    },
    { label: 'Pets', value: contactDetails.pets ?? '' },
    { label: 'Family Name', value: contactDetails.familyName ?? '' },
    {
      label: 'Client Termination Date',
      value: formatDisplayDate(contactDetails.clientTerminationDate),
    },
    { label: 'Source', value: getLabel(contactDetails.leadSource) },
    { label: 'Website Address', value: contactDetails.websiteURL ?? '' },
    { label: 'Added By', value: getLabel(contactDetails?.ownerName ?? '') },
  ]

  const nonIndividualFields = [
    { label: 'Status', value: getLabel(contactDetails.status) },
    { label: 'Source', value: getLabel(contactDetails.leadSource) },
    { label: 'Referred By', value: contactDetails.referredby ?? '' },
    {
      label: 'Tax Id Number',
      value: contactDetails.taxIdNumber?.toString() ?? '',
    },
    { label: 'Client Since', value: contactDetails.clientSince ?? '' },
    { label: 'Website Address', value: contactDetails.websiteURL ?? '' },
    { label: 'Added By', value: getLabel(contactDetails?.ownerName ?? '') },
  ]

  const otherContactFields = [
    { label: 'Status', value: getLabel(contactDetails.status) },
    { label: 'Source', value: getLabel(contactDetails.leadSource) },
    { label: 'Website Address', value: contactDetails.websiteURL ?? '' },
    { label: 'Added By', value: getLabel(contactDetails?.ownerName ?? '') },
  ]

  const clientFields = [
    { label: 'Status', value: getLabel(contactDetails.status) },
    { label: 'Rel Segment', value: contactDetails.accountTier },
    { label: 'Gender', value: contactDetails.gender },
    { label: 'Martial Status', value: contactDetails.martialStatus },
    {
      label: 'Marital Anniversary',
      value: formatDisplayDate(contactDetails.maritalAnniversary),
    },
    {
      label: 'Date Of Birth',
      value: formatDisplayDate(contactDetails.dateOfBirth),
    },
    {
      label: 'Interest & Hobbies',
      value: contactDetails.interestHobbies ?? '',
    },
    {
      label: 'Household Members',
      value: contactDetails.householdMembers ?? '',
    },
    { label: 'Pets', value: contactDetails.pets ?? '' },
    { label: 'Family Name', value: contactDetails.familyName ?? '' },
    {
      label: 'Client Termination Date',
      value: formatDisplayDate(contactDetails.clientTerminationDate),
    },
    { label: 'Source', value: getLabel(contactDetails.leadSource) },
    { label: 'Referred By', value: contactDetails.referredby ?? '' },
    {
      label: 'Tax Id Number',
      value: contactDetails.taxIdNumber?.toString() ?? '',
    },
    { label: 'Website Address', value: contactDetails.websiteURL ?? '' },
    { label: 'Client Since', value: contactDetails.clientSince ?? '' },
  ]
  return (
    <LeftUserProfile>
      <ProfileHeadingWrapper>
        <HeadingTextWrapper>
          <Label>{'Contact Details'}</Label>
        </HeadingTextWrapper>
        {userAccessDetails.role === vars.adminRole && (
          <BtnWrapper>
            <WDBorderButtonPrimary>
              <Button
                type="button"
                onClick={() => {
                  setShowEditContact(!showEditContact)
                }}
                color={
                  isDarkMode
                    ? DEFAULT_COLORS.Text.UserDark
                    : DEFAULT_COLORS.Text.Primary
                }
              >
                {'Edit'}
              </Button>
            </WDBorderButtonPrimary>
          </BtnWrapper>
        )}
      </ProfileHeadingWrapper>
      <ContactDetailsTable>
        <tbody>
          {contactDetails.type === 'Individual' &&
            contactDetails.status !== 'Client' &&
            individualFields.map((field) => (
              <tr key={field.label}>
                <td>{field.label}</td>
                <td>:</td>
                <td>{getLabel(field.value)}</td>
              </tr>
            ))}
          {(contactDetails.type === 'New Recruit' ||
            contactDetails.type === 'Personal Contact') &&
            otherContactFields.map((field) => (
              <tr key={field.label}>
                <td>{field.label}</td>
                <td>:</td>
                <td>{getLabel(field.value)}</td>
              </tr>
            ))}
          {contactDetails.type !== 'Individual' &&
            contactDetails.type !== 'New Recruit' &&
            contactDetails.type !== 'Personal Contact' &&
            contactDetails.status !== 'Client' &&
            nonIndividualFields.map((field) => (
              <tr key={field.label}>
                <td>{field.label}</td>
                <td>:</td>
                <td>{getLabel(field.value)}</td>
              </tr>
            ))}

          {contactDetails.status === 'Client' &&
            clientFields.map((field) => (
              <tr key={field.label}>
                <td>{field.label}</td>
                <td>:</td>
                <td>{getLabel(field.value)}</td>
              </tr>
            ))}
        </tbody>
      </ContactDetailsTable>
      {showEditContact ? (
        <EditContactDetails
          leadDetails={contactDetails}
          updateLeadInfoData={updateLeadInfoData}
          inputErrors={inputErrors}
          setInputErrors={setInputErrors}
          setShowEditContact={setShowEditContact}
        />
      ) : null}
    </LeftUserProfile>
  )
}
