import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgClock = ({ fillColor }: ISvg) => {
  return (
    <StyledDiv>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0655 12.4212L12.6667 11.6139V8.66536C12.6667 8.48855 12.5965 8.31898 12.4714 8.19396C12.3464 8.06894 12.1769 7.9987 12 7.9987C11.8232 7.9987 11.6537 8.06894 11.5286 8.19396C11.4036 8.31898 11.3334 8.48855 11.3334 8.66536V11.9987C11.3333 12.1157 11.3641 12.2307 11.4226 12.3321C11.4812 12.4335 11.5653 12.5177 11.6667 12.5762L13.3988 13.5762C13.4746 13.62 13.5584 13.6485 13.6452 13.6599C13.732 13.6714 13.8203 13.6656 13.9049 13.643C13.9895 13.6203 14.0688 13.5812 14.1383 13.5279C14.2078 13.4746 14.2661 13.4081 14.3099 13.3322C14.3537 13.2563 14.3821 13.1726 14.3935 13.0858C14.4049 12.9989 14.3991 12.9107 14.3764 12.8261C14.3537 12.7415 14.3146 12.6622 14.2612 12.5927C14.2079 12.5232 14.1414 12.465 14.0655 12.4212ZM12 5.33203C10.6815 5.33203 9.39257 5.72302 8.29624 6.45557C7.19991 7.18811 6.34543 8.2293 5.84085 9.44747C5.33626 10.6656 5.20424 12.0061 5.46148 13.2993C5.71871 14.5925 6.35365 15.7804 7.286 16.7127C8.21835 17.6451 9.40623 18.28 10.6994 18.5373C11.9926 18.7945 13.3331 18.6625 14.5513 18.1579C15.7694 17.6533 16.8106 16.7988 17.5432 15.7025C18.2757 14.6062 18.6667 13.3172 18.6667 11.9987C18.6647 10.2312 17.9617 8.53664 16.7119 7.28682C15.4621 6.03701 13.7675 5.334 12 5.33203ZM12 17.332C10.9452 17.332 9.91406 17.0192 9.037 16.4332C8.15994 15.8472 7.47635 15.0142 7.07269 14.0397C6.66902 13.0651 6.5634 11.9928 6.76919 10.9582C6.97498 9.92365 7.48293 8.97334 8.22881 8.22746C8.97469 7.48158 9.925 6.97363 10.9596 6.76784C11.9941 6.56206 13.0665 6.66767 14.041 7.07134C15.0156 7.47501 15.8485 8.15859 16.4345 9.03566C17.0206 9.91272 17.3334 10.9439 17.3334 11.9987C17.3318 13.4127 16.7693 14.7683 15.7695 15.7681C14.7696 16.768 13.414 17.3304 12 17.332Z"
          fill={fillColor}
        />
      </svg>
    </StyledDiv>
  )
}
