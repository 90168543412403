import { useCallback, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import Table from '../../../components/table/Table'
import { Wrapper } from './styles'
import { useParams } from 'react-router-dom'
import { GET_NOTE_VW_WEALTH_OPPORTUNITIES } from '../../../services/graphQl/CustomerGraphQl'
import NoDataFound from '../../../composites/noDataFound/NoDataFound'
import GraphQLLoader from '../../../composites/loader/GraphQLLoader'
import { LeadInfoProps } from '../../createContactList/ICreateLead'

const Opportunities = ({ leadDetails }: { leadDetails: LeadInfoProps }) => {
  const { contactId } = useParams()

  const [opportunityFunc, { data, loading }] = useLazyQuery(
    GET_NOTE_VW_WEALTH_OPPORTUNITIES,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const columnData = [
    {
      Header: 'OPPORTUNITY',
      accessor: 'name',
    },
    {
      Header: 'LEAD/PARTY',
      accessor: 'contactname',
    },
    {
      Header: 'ASSIGNED TO',
      accessor: 'assignedtoname',
    },
    {
      Header: 'STATUS',
      accessor: 'stage',
    },
    {
      Header: 'PROBABILITY(%)',
      accessor: 'probablity',
    },
    {
      Header: 'CLOSED DATE',
      accessor: 'closedate',
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
    },
  ]

  const fetchOpportunities = useCallback(() => {
    if (leadDetails?.id) {
      opportunityFunc({
        variables: { partyid: +leadDetails?.id ?? 0 },
      })
    } else {
      opportunityFunc({
        variables: { partyid: parseInt(contactId ?? '0') },
      })
    }
  }, [contactId, leadDetails?.id, opportunityFunc])

  useEffect(() => {
    fetchOpportunities()
  }, [fetchOpportunities])

  return (
    <Wrapper>
      {loading ? (
        <GraphQLLoader />
      ) : data?.opportunities_opportunities &&
        data?.opportunities_opportunities.length > 0 ? (
        <Table
          source="opportunities"
          height="20rem"
          tableColumns={columnData}
          allData={data?.opportunities_opportunities}
          leadPartyId={leadDetails?.id ?? 0}
        />
      ) : (
        !loading && <NoDataFound />
      )}
    </Wrapper>
  )
}
export default Opportunities
