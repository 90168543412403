import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const Wrapper = styled.div`
  width: 100%;
  height: 70vh;
  max-width: 100%;
  overflow-x: overlay;
  overflow-y: auto;
  padding-bottom: 0.25rem;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 0.25rem;
  }

  table {
    table-layout: flex;
    border-collapse: collapse;
    width: 100%;
    min-width: 150rem;
    flex-direction: column;
    border: none;
    text-align: left;

    thead {
      font-family: 'SFMono-Regular';
      font-style: normal;
      z-index: 1;
      tr {
        z-index: 1;
        display: table-row;
        align-items: center;
        box-shadow: ${() => DEFAULT_COLORS.BoxShadow.IronShadow};

        th {
          display: table-cell;
          flex-wrap: wrap;
          align-items: center;
          gap: 0.5rem;
          box-shadow: ${() => DEFAULT_COLORS.BoxShadow.IronShadow};
          padding: 0.8rem 0 0.8rem 0.8rem;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
    }

    tbody {
      font-family: 'SFMono-Regular';
      font-style: normal;

      tr {
        display: table-row;
        font-family: 'SFMono-Regular';
        &.description {
          border-bottom: 0.25rem solid
            ${() => DEFAULT_COLORS.Border.NeutralMedium};
        }
        &.selected {
          background-color: ${() => DEFAULT_COLORS.Background.GrayOpacity};
          border-bottom: 1px solid ${() => DEFAULT_COLORS.Border.Primary};
          td {
            color: ${DEFAULT_COLORS.Text.Primary};
            font-weight: 600;
          }
        }
        td {
          display: table-cell;
          flex-wrap: wrap;
          word-break: break-word;
          align-items: center;
          gap: 0.5rem;
          box-shadow: ${() => DEFAULT_COLORS.BoxShadow.IronShadow};
          font-size: 1rem;
          line-height: 1.25rem;
          padding: 0.5rem 0 0.5rem 0.5rem;
          &.Attribute {
            font-family: 'SFMono-Regular';
          }
          &#R {
            color: ${() => DEFAULT_COLORS.Text.DangerDark};
          }
          &#O {
            color: ${() => DEFAULT_COLORS.Text.DarkGreen};
          }
          &#C {
            color: ${() => DEFAULT_COLORS.Text.Primary};
            &.Status {
              color: ${() => DEFAULT_COLORS.Text.Neutral};
              font-weight: 700;
              font-size: 1rem;
              line-height: 1.25rem;
            }
            &#R {
              color: ${() => DEFAULT_COLORS.Text.DangerDark};
            }
            &#O {
              color: ${() => DEFAULT_COLORS.Text.DarkGreen};
            }
            &#C {
              color: ${DEFAULT_COLORS.Text.Primary};
            }
            &#Description {
              width: 3.125rem;
            }
          }
        }
      }

      tfoot {
        font-family: 'SFMono-Regular';
        font-style: normal;

        tr {
          display: table-row;
          align-items: center;

          td {
            display: table-cell;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
`

export const ThContentWrapper = styled.div`
  width: 100%;
`
export const StyledRectangle = styled.div`
  width: 1px !important;
  height: 1rem;
  background: ${() => DEFAULT_COLORS.Background.Iron} !important;
  border-radius: 1px;
`
export const TableWrapper = styled.table``
export const TableRow = styled.tr``
export const TableHeader = styled.thead`
  position: sticky;
  top: 0rem;
  margin: 0;
  background-color: ${() => DEFAULT_COLORS.Background.Neutral};
  tr {
    :nth-child(1) {
      img {
        display: none;
      }
    }
  }
`
export const TableFooter = styled.tfoot``
export const TableHeaderCell = styled.th`
  &:active {
    background-color: ${DEFAULT_COLORS.Background.NeutralStrong};
  }
`
export const TableBody = styled.tbody``
export const TableDataCell = styled.td`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  tr {
    td {
      width: 100%;
    }
  }
`

export const ContentInputWrapper = styled.div`
  display: flex;
  padding: 0rem 1rem;
  justify-content: flex-end;
  align-items: center;
  justify-content: ${(props: { justifycontent?: any }) =>
    props.justifycontent ? 'flex-end' : 'space-between'};
`

export const SortWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`
export const BtnWrapper = styled.span`
  button {
    padding: 0.125rem;
  }
`
export const LogoWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`

export const LinkWrapper = styled.div`
  text-decoration: none;
`
export const DetailsTableWrapper = styled.div`
  width: 100%;
  background-color: ${() => DEFAULT_COLORS.Background.Neutral};
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
  height: 27vh;
  overflow-y: scroll;
  thead {
    background-color: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  table {
    tbody {
      background-color: ${() => DEFAULT_COLORS.Background.Neutral};
      padding: 0;
      tr {
        padding: 0;
      }
    }
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;
`
export const CrmWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;
`
export const ActiveWrapper = styled.div`
  background: ${() => DEFAULT_COLORS.Background.Success};
  border-radius: 0.625rem;
  padding: ${SIZES[0]} ${SIZES[1]};
  width: fit-content;
  display: flex;
`
export const InActiveWrapper = styled.div`
  background: ${() => DEFAULT_COLORS.Background.NeutralStrong};
  border-radius: 0.625rem;
  padding: ${SIZES[0]} ${SIZES[1]};
  width: fit-content;
  display: flex;
`

export const UnorderedWrapper = styled.ul``
export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
`

export const SplitWrapper = styled.ul`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`
export const SecuritiesWrapper = styled.ul`
  background: ${() => DEFAULT_COLORS.Background.PrimaryHover};
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  padding: ${SIZES[0]};
  width: fit-content;
  display: flex;
`
