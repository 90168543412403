import styled from 'styled-components'

export const StyleWrapper = styled.div`${(props: { height?: string }) => {
    return (`
    height: 100%;
    .quill{
      height: 100%;
    }
    .ql-toolbar,
    .ql-container {
      background-color: #fff !important;
      border-radius: 0 !important;
    }
    .ql-container {
      height: ${props.height ?? 'auto'};
    }`)
}}
`
