import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`
export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  position: relative;
`
export const CrownWrapper = styled.div`
  position: absolute;
  bottom: 77%;
  right: -2px;
`
export const UserDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9eccfc2b;
  border: 1px solid ${DEFAULT_COLORS.Text.PrimaryStrong};
  padding: ${SIZES[1]};
  align-self: center;
  width: ${SIZES[6]};
  height: ${SIZES[6]};
  border-radius: ${SIZES[6]};
  cursor: pointer;
`
export const UserProfileWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  background-color: ${DEFAULT_COLORS.Background.Neutral};
  justify-content: space-between;
  flex: ${(props: { flex?: string }) => props.flex ?? '0'};
`
export const UserProfilePaddingWrapper = styled.div`
  display: flex;
  width: 100%;
  border: solid 2px blue;
  border: 1px solid ${DEFAULT_COLORS.Border.NeutralWeak};
  background: #fff;
  padding: 1rem 1rem 1rem 0;
  border-bottom: 5px solid #757575;
  justify-content: space-between;
`
export const CardOverview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`
export const TitleTextWrp = styled.div`
  vertical-align: middle;
  box-sizing: border-box;
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`
export const TitleTextWrapper = styled.div`
  vertical-align: middle;
  box-sizing: border-box;
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  align-self: center;
`
export const LeftUserProfile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 0.5rem;
  height: 80%;
  padding-bottom: 0.5rem;
`
export const ProfileNameWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
export const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.1rem;
  flex-direction: column;
`
export const ProfileDesignationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
export const QuantityWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
  align-items: center;
`
export const AccountsMainWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
`
export const AccountsSubWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  width: 100%;
`
export const AccountsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const AccountNameWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`
export const ContactDetailsWrapper = styled.div`
  display: flex;
  background-color: ${DEFAULT_COLORS.Background.Neutral};
  flex: ${(props: { flex: string }) => {
    return props.flex ?? '1'
  }};
  border: solid 2px blue;
  border: 1px solid ${DEFAULT_COLORS.Border.NeutralWeak};
  padding: 0.75rem;
  /* overflow: auto; */
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`
export const OverviewWrapper = styled.div`
  display: flex;
  flex: 0 1 28%;
  flex-direction: column;
  gap: 0.5rem;
`
export const ProfileTabWrapper = styled.div`
  display: flex;
  flex: 1;
  border: solid 2px blue;
  border: 1px solid ${DEFAULT_COLORS.Border.NeutralWeak};
  padding: ${(props: { padding?: string }) => props.padding ?? '1rem'};
  gap: 0.5rem;
  background-color: ${DEFAULT_COLORS.Background.Neutral};
  max-width: 100%;
`
export const TabContainer = styled.nav`
  display: flex-column;
  gap: 0.5rem;
`
export const WrapperOne = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`
export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${DEFAULT_COLORS.BoxShadow.BackgroundShadow};
  z-index: 999;
`
export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
export const CreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SIZES[1]};
  input {
    height: ${SIZES[5]} !important;
  }
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SIZES[3]};
`
export const TextBoxWrap = styled.div`
  display: block;
  input {
    height: ${SIZES[7]} !important;
  }
`

export const TabWrapper = styled.div`
  padding-bottom: 1rem;
`
export const CreateEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: ${SIZES[1]};
  input {
    height: ${SIZES[3]} !important;
  }
`
