import styled from 'styled-components'

export const CardWrapper = styled.div`

max-height:600px;
overflow: auto;
+.ql-editor{
    padding: 0px 0px;
}
`;
export const ListDate = styled.div`
position: sticky;
    display: table;
   
    background-color: rgb(255 255 255);
    margin: 0.4rem auto;
    border-radius: 3px;
    color: rgb(0, 0, 0);
    font-size: 0.8rem;
    top: 0.5rem;
    font-weight: 600;
`;
export const Card = styled.div`

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: rgb(250, 250, 250);
    padding: 0.5rem 1rem;
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 3px 0px;

`;

export const CardContent = styled.div`

display:${(props: { display: boolean }) => { return props.display ? 'block' : 'none' }};

`

export const Details = styled.div`

display:flex;
align-items:center;
justify-content: end;
`
export const Icon = styled.div`
margin-right: 5px
`
export const Owner = styled.div`
display: flex;
align-items:center;
    font-size: 0.9rem;
    color: #393939;
    
`
export const TitleWrapper = styled.div`
display:flex;
align-items:center;
justify-content: space-between;
`
export const Type = styled.div`
background-color:${(props: { color?: string }) => { return props.color }};
    display: inline-table;
    padding: 3px 7px;
    text-transform: capitalize;
    border-radius: 3px;
    margin-right: 1rem;
    font-weight: 600;
    font-size: 0.88rem;
    color:#fff;
`
export const Title = styled.div`
background-color:${(props: { color?: string }) => { return props.color }};
padding: 3px;
    -webkit-box-align: center;
    align-items: center;
    text-transform: capitalize;
    border-radius: 3px;
    margin-right: 1rem;
    font-weight: 100;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
export const DateTime = styled.div`
padding-top: 3px;
font-size: 0.9rem;
    margin-right: 1rem;
    DISPLAY: flex;
    align-items: center;
    margin-left: -5px;
color: ${(props: { color: string }) => { return props.color }}
`
export const Subject = styled.div`
padding: 5px 0;
font-size: 1.05rem;
font-weight: bold;
display:inline-table;
`

