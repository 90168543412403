import { BaseSyntheticEvent, DragEvent, useRef, useState } from 'react'
import {
  Wrapper,
  File,
  UploadWrapper,
  RowWrapper,
  SampleFile,
  InlineErrorWrapper,
  IconWrapper,
  InputWrapper,
  WDLabelSemiBold,
  WDLabelClient,
  WDLabelError,
  LabelWrapper,
} from './styles'

import { AttachDocumentProps } from './IAttachDocument'
import { SvgUploadFile } from './../../components/svg/SvgUploadFile'
import { WDButton } from '../ui/WDButton'

export const EXTENSIONS = [
  'pdf',
  'jpeg',
  'jpg',
  'doc',
  'docx',
  'xls',
  'txt',
  'csv',
  'xlsx',
  'png',
]

export const AttachDocument = ({
  uploadedFilesCallback,
  duplicateFile,
}: AttachDocumentProps) => {
  const [files, setFiles] = useState<File[]>([])
  const [error, setError] = useState('')
  const [isCSV, setIsCSV] = useState(true)

  const fileUpload = useRef<HTMLInputElement>(null)

  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    const isAcceptedFile = EXTENSIONS.includes(extension.toLowerCase())
    setIsCSV(isAcceptedFile)
    return isAcceptedFile
  }

  const handleDrop = (uploadedFile: FileList) => {
    const fileArray = Array.from(uploadedFile)
    const updatedFile = [...files, ...fileArray]
    setFiles(updatedFile)
    fileArray.forEach((file) => {
      if (getExtension(file)) {
        uploadedFilesCallback?.(fileArray)
      } else {
        setError('*Please upload valid document type')
      }
    })
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDropFile = (event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    handleDrop(event.dataTransfer.files)
  }

  const handleFiles = (event: BaseSyntheticEvent) => {
    handleDrop(event.target.files)
  }

  const deleteExistingFile = (event: BaseSyntheticEvent) => {
    const inputElement = event.target as HTMLInputElement
    inputElement.value = ''
  }

  const browseFile = () => {
    fileUpload.current?.click()
  }

  return (
    <Wrapper>
      <File>
        <UploadWrapper
          id="drop-area"
          onDragEnter={preventDefaults}
          onDragOver={preventDefaults}
          onDragLeave={preventDefaults}
          onDrop={handleDropFile}
        >
          <InputWrapper
            type="file"
            id="fileElement"
            ref={fileUpload}
            multiple
            hidden
            onChange={handleFiles}
            onClick={deleteExistingFile}
          />
          <RowWrapper>
            <WDLabelSemiBold>
              <label>{'Drag files here to upload or...'}</label>
            </WDLabelSemiBold>
            <WDButton>
              <button onClick={browseFile} type={'button'}>
                <SvgUploadFile />
                <LabelWrapper>{'Browse file'}</LabelWrapper>
              </button>
            </WDButton>
          </RowWrapper>
        </UploadWrapper>
        <SampleFile>
          <IconWrapper>
            <WDLabelClient>
              <label>
                {'Max file size is 5 MB. Supported file types are ' +
                  EXTENSIONS.join(', ')}
              </label>
            </WDLabelClient>
          </IconWrapper>
          {!isCSV && (
            <InlineErrorWrapper>
              <WDLabelError>{error}</WDLabelError>
            </InlineErrorWrapper>
          )}
          {duplicateFile && (
            <InlineErrorWrapper>
              <WDLabelError>
                {'*File with same name already exist.'}
              </WDLabelError>
            </InlineErrorWrapper>
          )}
        </SampleFile>
      </File>
    </Wrapper>
  )
}
