import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../theme/Sizes'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  flex: 1;
  width: 100%;
  min-width: 300px;
  position: relative;
  padding: 0.5rem 1rem;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
  &:hover {
    border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
    border-color: ${() => DEFAULT_COLORS.Border.NeutralDisabled};
    color: ${() => DEFAULT_COLORS.Icons.NeutralHover};
  }
  /* &:active {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    } */
  &:disabled {
    box-shadow: inset 0px 0px 0px 1px
      ${() => DEFAULT_COLORS.Border.NeutralDisabled};
    border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
    cursor: no-drop;
  }
  &:invalid {
    box-shadow: inset 0px 0px 0px 1px ${() => DEFAULT_COLORS.Border.Iron};
    border-color: ${() => DEFAULT_COLORS.Border.Iron};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 1px
      ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    border-color: ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    color: ${() => DEFAULT_COLORS.Text.Black};
  }
  &:required:valid {
    box-shadow: inset 0px 0px 0px 1px ${() => DEFAULT_COLORS.Border.DangerDark};
    border-color: ${() => DEFAULT_COLORS.Border.DangerDark};
    background-color: ${() => DEFAULT_COLORS.Background.Fantasy};
  }
  box-sizing: border-box;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  outline: none;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const MasterWrapper = styled.div`
  display: block;
  width: 100%;
`
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
`
export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
export const UserDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${() => DEFAULT_COLORS.Background.PrimaryStrong};
  padding: ${SIZES[1]};
  align-self: center;
  width: ${SIZES[6]};
  height: ${SIZES[6]};
  border-radius: ${SIZES[6]};
  cursor: pointer;
`
export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
export const PlusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: ${SIZES[0]};
  cursor: pointer;
  margin-left: ${SIZES[3]};
  fill: ${() => DEFAULT_COLORS.Background.GreyNeutral};
  button {
    border: none;
    background: none;
    /* color: #9b9b9b; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }

  &:hover {
    background-color: ${DEFAULT_COLORS.Background.Neutral}!important;
    fill: ${DEFAULT_COLORS.Background.Primary}!important;
    stroke: ${DEFAULT_COLORS.Background.Primary}!important;
    text-decoration: none !important;
  }
`
export const TextBoxWrapper = styled.div`
  position: absolute;
  left: 0;
  padding: 0.5rem;
`
export const UserActionWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 2.75rem;
  z-index: 999;
  background-color: ${DEFAULT_COLORS.Background.Neutral};
  color: ${DEFAULT_COLORS.Text.Neutral};
  box-shadow: 0px 2px 8px 1px ${DEFAULT_COLORS.BoxShadow.Shadow3};
  border-radius: 0.5rem;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  flex-direction: column;
  &:hover {
    background-color: ${DEFAULT_COLORS.Background.NeutralMedium};
  }
`
export const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
`
