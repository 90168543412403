export const formatPhoneNumber = (value: string | number | undefined) => {
  if (!value) return value
  const digits = value.toString().replace(/\D/g, '') // Remove non-digits globally
  if (digits.length <= 3) {
    return digits // No formatting for less than 3 digits
  } else if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}` // Area code with space
  } else {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}` // Full format
  }
}

export const getAvatar = (value: string) => {
  const text = value
  const first_name = text && text.split(' ')[0]
  const last_name = text && text.split(' ')[1]
  const subAvatar = first_name && first_name[0][0]
  const subAvatar1 = last_name && last_name[0][0]
  const name =
    subAvatar && subAvatar1 ? subAvatar + subAvatar1 : subAvatar ?? 'NA'
  return name
}

export const getFullNameAvatar = (value: string, value1: string) => {
  const text = value
  const text1 = value1
  const first_name = text && text.split(' ')
  const last_name = text1 && text1.split(' ')
  const subAvatar = first_name && first_name[0][0]
  const subAvatar1 = last_name && last_name[0][0]
  const name = subAvatar && subAvatar1 ? subAvatar + subAvatar1 : ''
  return name
}
