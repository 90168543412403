export const plusNavbarMenudata = [
  {
    id: 'Contact',
    name: 'Contact',
    isClicked: false,
  },
  {
    id: 'Event',
    name: 'Event',
    isClicked: false,
  },
  {
    id: 'Note',
    name: 'Note',
    isClicked: false,
  },
  {
    id: 'Task',
    name: 'Task',
    isClicked: false,
  },
  {
    id: 'Opportunity',
    name: 'Opportunity',
    isClicked: false,
  },
]

export const plusInteractionsMenudata = [
  {
    id: 'Event',
    name: 'Event',
    isClicked: false,
  },
  {
    id: 'Note',
    name: 'Note',
    isClicked: false,
  },
  {
    id: 'Task',
    name: 'Task',
    isClicked: false,
  },
]
