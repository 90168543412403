import React, { RefObject } from 'react'
import { CSVLink } from 'react-csv'
import Button from '../button/Button'
import { WDButtonTransparent } from '../ui/WDButton'
import { CSVWrapper } from './styles'
import { TableData } from '../table/ITable'

const ExportCSV = (props: {
  csvData: TableData[]
  fileName: string
  children: React.ReactNode
  headers?: {
    label: string
    key: string
  }[]
  onClickCallBackFn?: () => void
  reference?: RefObject<HTMLDivElement>
}) => {
  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()
    return month.concat('-').concat(day).concat('-').concat(year)
  }

  const csvReport = {
    data: props.csvData,
    filename: `${props.fileName}_` + formatDate(new Date()) + '.csv',
    header: props.headers,
  }

  return (
    <CSVWrapper ref={props.reference}>
      <WDButtonTransparent>
        <Button type="button" onClick={props.onClickCallBackFn}>
          {props.csvData && (
            <CSVLink headers={props.headers} {...csvReport}>
              {props.children}
            </CSVLink>
          )}
        </Button>
      </WDButtonTransparent>
    </CSVWrapper>
  )
}

export default ExportCSV
