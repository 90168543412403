import axios from 'axios'
import appConfig from '../../settings/appConfig'

const axiosProtectedInstance = axios.create({
  baseURL: appConfig.apiEndPoint,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default axiosProtectedInstance
