import { useState, useEffect } from 'react'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { Textbox } from '../../components/textbox/Textbox'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { AddressInformationProps } from './IContactDetails'
import {
  FormContainer,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
  CalendarWrap,
  SectionContainer,
  GridItemContainer,
  ContactDetailContainer,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import { WDContactInfoWrapper } from '../../components/ui/WDWrapper'
import { useLocation } from 'react-router-dom'
import { OptionProps } from '../../components/selectDropdown/ISelectDropdown'
import moment from 'moment'

const ContactDetails = ({
  updateData,
  leadInfoData,
}: AddressInformationProps) => {
  const [isShippinInformation, setIsbillingInformationOpen] = useState(true)
  const [contactType, setContactType] = useState<string>('')
  const [isClientProfile, setClientProfile] = useState<boolean>(false)
  const location = useLocation()

  useEffect(() => {
    if (leadInfoData.status === 'Client') {
      setClientProfile(true)
    } else if (leadInfoData.type) {
      setContactType(leadInfoData.type.replace(' ', ''))
    } else {
      setContactType(location?.pathname?.split('/')?.pop() ?? '')
    }
  }, [leadInfoData.status, leadInfoData.type, location?.pathname])

  const StatusList = [
    { value: 'Lead', label: 'Lead' },
    { value: 'Prospect', label: 'Prospect' },
    { value: 'Interested', label: 'Interested' },
  ]
  const SourceList = [
    { value: 'COI', label: 'COI' },
    { value: 'Referral', label: 'Referral' },
    { value: 'Seminar', label: 'Seminar' },
    { value: 'Others', label: 'Others' },
  ]
  const genderList = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'NonBinary', label: 'Non-Binary' },
    { value: 'Others', label: 'Others' },
    { value: 'UnKnown', label: 'UnKnown' },
  ]
  const martialStatusList = [
    { value: 'Divorced', label: 'Divorced' },
    { value: 'LifePartner', label: 'Life Partner' },
    { value: 'Married', label: 'Married' },
    { value: 'Single', label: 'Single' },
    { value: 'Unknown', label: 'Unknown' },
    { value: 'Separated', label: 'Separated' },
    { value: 'Widowed', label: 'Widowed' },
  ]
  const householdMembersList = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ]
  const clientCategoryList = [
    { value: 'Platinum', label: 'Platinum' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Silver', label: 'Silver' },
  ]
  const defaultStatusOption = StatusList.find(
    (option: OptionProps) => option.value === leadInfoData.status
  )
  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper
          onClick={() => setIsbillingInformationOpen(!isShippinInformation)}
        >
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">
                {contactType === 'Individual' ||
                contactType === 'NewRecruit' ||
                contactType === 'PersonalContact' ||
                isClientProfile
                  ? 'Contact Details'
                  : contactType === 'Business'
                  ? 'Business Details'
                  : 'Trust Details'}
              </Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() =>
                  setIsbillingInformationOpen(!isShippinInformation)
                }
              >
                <Image
                  image={
                    isShippinInformation ? Icons.ChevronUp : Icons.ChevronDown
                  }
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isShippinInformation && (
          <ContactDetailContainer>
            {!isClientProfile && (
              <>
                <FormContainer>
                  <Label>{'Status'}</Label>
                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    options={StatusList}
                    padding="0rem"
                    height="2.62rem"
                    placeholder={'Select'}
                    value={defaultStatusOption}
                    onChange={(option) => updateData({ status: option.value })}
                  />
                </FormContainer>
                {contactType !== 'Individual' &&
                  contactType !== 'NewRecruit' &&
                  contactType !== 'PersonalContact' && (
                    <FormContainer>
                      <Label>{'Tax ID'}</Label>

                      <Textbox
                        type="number"
                        onChange={(value) =>
                          updateData({ taxIdNumber: +value }, 'taxIdNumber')
                        }
                        value={
                          leadInfoData.taxIdNumber == 0
                            ? ''
                            : leadInfoData.taxIdNumber
                        }
                      />
                    </FormContainer>
                  )}
              </>
            )}

            {(contactType === 'NewRecruit' ||
              contactType === 'PersonalContact') && (
              <FormContainer>
                <Label>{'Source'}</Label>
                <SelectDropdown
                  className="w-100"
                  borderColor={DEFAULT_COLORS.Border.Neutral}
                  options={SourceList}
                  padding="0rem"
                  height="2.62rem"
                  placeholder={'Select'}
                  value={{
                    value: leadInfoData.leadSource,
                    label: leadInfoData.leadSource,
                  }}
                  onChange={(option) =>
                    updateData({ leadSource: option.value }, 'leadSource')
                  }
                />
              </FormContainer>
            )}
            {(contactType === 'Individual' || isClientProfile) && (
              <>
                <FormContainer>
                  <Label>{'Relationship Segment'}</Label>
                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    options={clientCategoryList}
                    padding="0rem"
                    height="2.62rem"
                    placeholder={'Select'}
                    value={{
                      value: leadInfoData.accountTier,
                      label: leadInfoData.accountTier,
                    }}
                    onChange={(option) =>
                      updateData({ accountTier: option.value }, 'accountTier')
                    }
                  />
                </FormContainer>
                <FormContainer>
                  <Label>{'Gender'}</Label>
                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    options={genderList}
                    padding="0rem"
                    height="2.62rem"
                    placeholder={'Select'}
                    value={{
                      value: leadInfoData.gender,
                      label: leadInfoData.gender,
                    }}
                    onChange={(option) =>
                      updateData({ gender: option.value }, 'gender')
                    }
                  />
                </FormContainer>
              </>
            )}
            {(isClientProfile ||
              (contactType !== 'NewRecruit' &&
                contactType !== 'PersonalContact')) && (
              <FormContainer>
                <Label>{'Source'}</Label>
                <SelectDropdown
                  className="w-100"
                  borderColor={DEFAULT_COLORS.Border.Neutral}
                  options={SourceList}
                  padding="0rem"
                  height="2.62rem"
                  placeholder={'Select'}
                  value={{
                    value: leadInfoData.leadSource,
                    label: leadInfoData.leadSource,
                  }}
                  onChange={(option) =>
                    updateData({ leadSource: option.value }, 'leadSource')
                  }
                />
              </FormContainer>
            )}
            {contactType !== 'Individual' &&
              contactType !== 'NewRecruit' &&
              contactType !== 'PersonalContact' && (
                <FormContainer>
                  <Label>{'Referred By'}</Label>
                  <Textbox
                    width="16.5rem"
                    onChange={(value) =>
                      updateData({ referredby: value }, 'referredby')
                    }
                    value={leadInfoData.referredby}
                  />
                </FormContainer>
              )}

            {(contactType === 'Individual' || isClientProfile) && (
              <>
                {!isClientProfile && (
                  <GridItemContainer>
                    <Label>{'Martial Status'}</Label>
                    <SelectDropdown
                      className="w-100"
                      borderColor={DEFAULT_COLORS.Border.Neutral}
                      options={martialStatusList}
                      padding="0rem"
                      height="2.62rem"
                      placeholder={'Select'}
                      value={{
                        value: leadInfoData.martialStatus,
                        label: leadInfoData.martialStatus,
                      }}
                      onChange={(option) =>
                        updateData(
                          { martialStatus: option.value },
                          'martialStatus'
                        )
                      }
                    />
                  </GridItemContainer>
                )}
                <GridItemContainer>
                  <Label>{'Marital Anniversary'}</Label>
                  <CalendarWrap>
                    {/* <CustomCalendar
                      placeholder="Select Date"
                      onChange={(value) =>
                        updateData(
                          { maritalAnniversary: value },
                          'maritalAnniversary'
                        )
                      }
                      maxDate={new Date()}
                      value={leadInfoData.maritalAnniversary ?? undefined}
                    /> */}
                    <input
                      aria-label="Date"
                      type="date"
                      className="date-time"
                      name="maritalAnniversary"
                      // value={
                      //   leadInfoData.maritalAnniversary
                      //     ? moment(leadInfoData.maritalAnniversary).format(
                      //         'YYYY-MM-DD'
                      //       )
                      //     : ''
                      // }
                      value={
                        leadInfoData.maritalAnniversary
                          ? leadInfoData.maritalAnniversary
                              ?.toString()
                              ?.substring(0, 10)
                          : ''
                      }
                      onChange={(value) => {
                        if (String(value.target.value).length === 10) {
                          const formattedDate = new Date(
                            value.target.value
                          ).toISOString()
                          updateData(
                            { maritalAnniversary: formattedDate },
                            'maritalAnniversary'
                          )
                        } else if (value.target.value === '') {
                          updateData(
                            { maritalAnniversary: value.target.value },
                            'maritalAnniversary'
                          )
                        }
                      }}
                    />
                  </CalendarWrap>
                </GridItemContainer>
                <GridItemContainer>
                  <Label>{'Client Termination Date'}</Label>
                  <CalendarWrap>
                    {/* <CustomCalendar
                      placeholder="Select Date"
                      onChange={(value) =>
                        updateData(
                          { clientTerminationDate: value },
                          'clientTerminationDate'
                        )
                      }
                      value={leadInfoData.clientTerminationDate ?? undefined}
                    /> */}
                    <input
                      placeholder="Select Date"
                      aria-label="Date"
                      type="date"
                      className="date-time"
                      name="clientTerminationDate"
                      // value={
                      //   leadInfoData.clientTerminationDate
                      //     ? moment(leadInfoData.clientTerminationDate).format(
                      //         'YYYY-MM-DD'
                      //       )
                      //     : ''
                      // }
                      value={
                        leadInfoData.clientTerminationDate
                          ? leadInfoData.clientTerminationDate
                              ?.toString()
                              ?.substring(0, 10)
                          : ''
                      }
                      onChange={(value) => {
                        if (String(value.target.value).length === 10) {
                          const formattedDate = new Date(
                            value.target.value
                          ).toISOString()
                          updateData(
                            { clientTerminationDate: formattedDate },
                            'clientTerminationDate'
                          )
                        } else if (value.target.value === '') {
                          updateData(
                            { clientTerminationDate: value.target.value },
                            'clientTerminationDate'
                          )
                        }
                      }}
                    />
                  </CalendarWrap>
                </GridItemContainer>
                {!isClientProfile && (
                  <GridItemContainer>
                    <Label>{'Date Of Birth'}</Label>
                    <CalendarWrap>
                      {/* <CustomCalendar
                        placeholder="Select Date"
                        onChange={(value) =>
                          updateData({ dateOfBirth: value }, 'dateOfBirth')
                        }
                        value={leadInfoData.dateOfBirth ?? undefined}
                        maxDate={new Date()}
                      /> */}
                      <input
                        placeholder="Select Date"
                        aria-label="Date"
                        type="date"
                        className="date-time"
                        name="dateOfBirth"
                        // value={
                        //   leadInfoData.dateOfBirth
                        //     ? moment(leadInfoData.dateOfBirth).format(
                        //         'YYYY-MM-DD'
                        //       )
                        //     : ''
                        // }
                        value={
                          leadInfoData.dateOfBirth
                            ? leadInfoData.dateOfBirth
                                ?.toString()
                                ?.substring(0, 10)
                            : ''
                        }
                        onChange={(value) => {
                          if (String(value.target.value).length === 10) {
                            const formattedDate = new Date(
                              value.target.value
                            ).toISOString()
                            updateData(
                              { dateOfBirth: formattedDate },
                              'dateOfBirth'
                            )
                          } else if (value.target.value === '') {
                            updateData(
                              { dateOfBirth: value.target.value },
                              'dateOfBirth'
                            )
                          }
                        }}
                      />
                    </CalendarWrap>
                  </GridItemContainer>
                )}
                <SectionContainer>
                  <Label>{'Interest & Hobbies'}</Label>
                  <Textbox
                    width="100%"
                    onChange={(value) =>
                      updateData({ interestHobbies: value }, 'interestHobbies')
                    }
                    value={leadInfoData.interestHobbies}
                  />
                </SectionContainer>
                <FormContainer>
                  <Label>{'No Of Household Members'}</Label>
                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    options={householdMembersList}
                    padding="0rem"
                    height="2.62rem"
                    placeholder={'Select'}
                    value={{
                      value: leadInfoData.householdMembers,
                      label: leadInfoData.householdMembers,
                    }}
                    onChange={(option) =>
                      updateData(
                        { householdMembers: option.value },
                        'householdMembers'
                      )
                    }
                  />
                </FormContainer>
                <FormContainer>
                  <Label>{'Family Name'}</Label>
                  <Textbox
                    width="100%"
                    onChange={(value) =>
                      updateData({ familyName: value }, 'familyName')
                    }
                    value={leadInfoData.familyName}
                  />
                </FormContainer>
              </>
            )}
            {(isClientProfile ||
              (contactType !== 'Individual' &&
                contactType !== 'NewRecruit' &&
                contactType !== 'PersonalContact')) && (
              <FormContainer>
                <Label>{'Client Since'}</Label>
                <Textbox
                  width="16.5rem"
                  onChange={(value) =>
                    updateData({ clientSince: value }, 'clientSince')
                  }
                  value={leadInfoData.clientSince}
                />
              </FormContainer>
            )}
            {(contactType === 'Individual' || isClientProfile) && (
              <SectionContainer>
                <Label>{'Pets'}</Label>
                <Textbox
                  width="100%"
                  onChange={(value) => updateData({ pets: value }, 'pets')}
                  value={leadInfoData.pets}
                />
              </SectionContainer>
            )}
          </ContactDetailContainer>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default ContactDetails
