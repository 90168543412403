export const leadColumnData = [
  {
    Header: 'Party/Lead',
    accessor: 'partyname',
  },
  {
    Header: 'Account Name',
    accessor: 'accountname',
  },
  {
    Header: 'Account Number',
    accessor: 'accountnumber',
  },
  {
    Header: 'Type',
    accessor: 'typedescription',
  },
  {
    Header: 'Sub Type',
    accessor: 'subtypedescription',
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Date',
    accessor: 'notedate',
  },
  {
    Header: 'Owner',
    accessor: 'ownername',
  },
  {
    Header: 'Rep Code',
    accessor: 'repcode',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
