import styled from 'styled-components'

export interface bgColorProps {
  bgColor: string
}

export const MainWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 0px;
  border: 1px solid #e8e8e8;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 12%);

`
export const DataWrapper = styled.div`
  max-height: 250px;
  width: auto;
  overflow-y: auto;
  width: max-content;
  input[type='checkbox']{
    margin-right:5px;
    
  }
  label{
    text-transform: auto;
    font-weight: normal;
    font-size:0.9rem;
  }
`

export const Arrow = styled.div`
width: 0px;
height: 0px;
border-style: solid;
border-width: 0px 7px 7px;
border-color: transparent transparent rgb(255 255 255);
position: absolute;
left: 0px;
top: -7px;
`
export const DataItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: start;
  padding: 5px 5px;
  font-size: 12px;
  background-color: rgb(223 237 255);
  border-radius: 3px;
  margin: 5px;
  input {
    display: none;
  }
  & label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;
    margin-left: 0.5rem;
  }
`

export const BtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px auto;
  gap: 4px;
`
