export const columnData = [
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Owner',
    accessor: 'ownername',
  },
  {
    Header: 'Party/Lead',
    accessor: 'partyname',
  },
  {
    Header: 'Type',
    accessor: 'typedescription',
  },
  {
    Header: 'Sub Type',
    accessor: 'subtypedescription',
  },
  {
    Header: 'Start Date',
    accessor: 'starttime',
  },
  {
    Header: 'End Date',
    accessor: 'endtime',
  },
]
