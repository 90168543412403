import { StyledIcon, IconProps } from './styles'

export const Icon = ({ icon, margin, width, height }: IconProps) => {
  return (
    <StyledIcon
      src={icon}
      alt="Icon"
      margin={margin}
      width={width}
      height={height}
    />
  )
}
