import { StyledButton } from './styles'
import { ButtonProps } from './IButton'

const Button = ({
  type,
  bgColor,
  color,
  height,
  children,
  borderColor,
  margin,
  padding,
  borderRadius,
  prefixedIcon,
  suffixedIcon,
  disabled,
  onClick,
  onMouseOver,
  onBlur,
  onFocus,
  hover,
  hasBorder = true,
  borderLessBgColor,
  hoverBgColor,
  hoverTextColor,
  activeBgColor,
  focusBrColor,
  justify,
  title,
}: ButtonProps) => {
  return (
    <StyledButton
      height={height}
      type={type}
      className="hover-button"
      bgColor={bgColor}
      onMouseOver={onMouseOver}
      color={color}
      borderColor={borderColor}
      padding={padding}
      margin={margin}
      borderRadius={borderRadius}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      hover={hover}
      hasBorder={hasBorder}
      borderLessBgColor={borderLessBgColor}
      hoverBgColor={hoverBgColor}
      hoverTextColor={hoverTextColor}
      activeBgColor={activeBgColor}
      focusBgColor={focusBrColor}
      justify={justify}
      title={title}
    >
      {prefixedIcon}
      {children}
      {suffixedIcon}
    </StyledButton>
  )
}

export default Button
