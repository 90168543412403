import { useState, useEffect, useContext } from 'react'
import Button from '../../../components/button/Button'
import { SvgEdit } from '../../../components/svg/SvgEdit'
import {
  WDButtonPrimaryOutlined,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import {
  WDLabelPrimaryTextMedium,
  WDLabelSmall,
} from '../../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { UserProfileWrapper } from '../styles'
import {
  ActionsWrapper,
  AlignStartWrapper,
  DetailsWrapper,
  FamilyDependantWrapper,
  FamilyHeadCard,
  FamilyHeadWrapper,
  FamilyMemberCard,
  Wrapper,
} from './styles'
import AddMember from '../relationshipActionsPopups/AddMember'
import EditRelationship from '../relationshipActionsPopups/EditRelationship'
import ChangeHead from '../relationshipActionsPopups/ChangeHead'
import { SvgContactCard } from '../../../components/svg/SvgContactCard'
import UpdateMember from '../relationshipActionsPopups/UpdateMember'
import ContactCard from '../relationshipActionsPopups/ContactCard'
import { SvgUserAdd } from '../../../components/svg/SvgUserAdd'
import {
  LeadGraphQLResponseInterface,
  LeadInfoProps,
} from '../../createContactList/ICreateLead'
import { useLazyQuery } from '@apollo/client'
import { GET_LEAD_PARTY_RELATIONSHIP } from '../../../services/graphQl/CustomerGraphQl'
import { GET_LEAD_DETAILS } from '../../../services/graphQl/LeadsGraphQL'
import GraphQLLoader from '../../../composites/loader/GraphQLLoader'
import { useNavigate } from 'react-router'
import { UserDetailsWrapper } from '../../leadProfile/styles'
import Label from '../../../components/label/Label'
import { getAvatar } from '../../../services/Utils'
import { vars } from '../../../assets/utils/CommonFunctions'
import { AuthContext } from '../../../services/AuthContext'

interface PartyRelationshipProps {
  leadDetails: LeadInfoProps
}

export interface RelationshipProps {
  id?: number
  relationshipfrom: string
  relationshipto?: string
  sourceid?: number
  relationshipid: number
  realtionshipname?: string
  relationshipfromname: string
  realtionshiptoname?: string
  isparty: boolean
}
export interface LeadPartyRelationshipProps {
  lead_partyrelationship: RelationshipProps[]
}

const LeadRelationship = ({ leadDetails }: PartyRelationshipProps) => {
  const { userAccessDetails } = useContext(AuthContext)
  const navigate = useNavigate()
  const [allRelationshipFromData, setAllRelationshipFromData] =
    useState<RelationshipProps[]>()
  const [allRelationshipToData, setAllRelationshipToData] =
    useState<RelationshipProps[]>()
  const [relationshipFromData, setRelationshipFromData] =
    useState<RelationshipProps>()
  const [relationshipToData, setRelationshipToData] =
    useState<RelationshipProps>()
  const [isHead, setIsHead] = useState(false)
  const [showAddMemberPopup, setShowAddMemberPopup] = useState<boolean>(false)
  const [showUpdateMemberPopup, setShowUpdateMemberPopup] =
    useState<boolean>(false)
  const [showContactCardMemberPopup, setShowContactCardMemberPopup] =
    useState<boolean>(false)
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false)
  const [showChangeHeadPopup, setShowChangeHeadPopup] = useState<boolean>(false)

  const [getLeadDetails, { data: leadData, loading: loadingLeadData }] =
    useLazyQuery<LeadGraphQLResponseInterface>(GET_LEAD_DETAILS)

  const [
    RelationshipData,
    { data: allRelationshipData, loading: loadingAllRelationshipData },
  ] = useLazyQuery<LeadPartyRelationshipProps>(GET_LEAD_PARTY_RELATIONSHIP)

  const showActionPopup = (type: string) => {
    switch (type) {
      case 'edit':
        setShowEditPopup(true)
        break
      case 'add member':
        setShowAddMemberPopup(true)
        break
      case 'update member':
        setShowAddMemberPopup(true)
        break
      case 'change head':
        setShowChangeHeadPopup(true)
        break
      // case 'delete family':
      //   setShowDeleteFamilyPopup(true)
      //   break
    }
  }

  const fetchRelationshipData = () => {
    RelationshipData({
      variables: {
        sourceId: leadDetails?.id ?? 0,
        sourceRelationshipto: leadDetails?.id?.toString() ?? '',
      },
      fetchPolicy: 'no-cache',
    })
  }
  useEffect(() => {
    if (leadDetails?.id) {
      fetchRelationshipData()
    }
  }, [leadDetails?.id])

  useEffect(() => {
    if (allRelationshipData) {
      setAllRelationshipFromData(
        allRelationshipData.lead_partyrelationship.filter(
          (relationship) =>
            Number(relationship.relationshipto) !== leadDetails?.id
        )
      )
      setAllRelationshipToData(
        allRelationshipData.lead_partyrelationship.filter(
          (relationship) =>
            Number(relationship.relationshipto) === leadDetails?.id
        )
      )
    }
  }, [allRelationshipData, leadDetails?.id])

  const updateMemberPopup = (member: RelationshipProps) => {
    const selectedDependent = allRelationshipFromData?.find(
      (relation) => relation.relationshipto === member.relationshipfrom
    ) as RelationshipProps
    setRelationshipFromData(selectedDependent)
    setRelationshipToData(member)
    setShowUpdateMemberPopup(true)
  }

  const showMemberDetails = (member: RelationshipProps) => {
    getLeadDetails({
      variables: { id: member?.relationshipfrom },
      fetchPolicy: 'no-cache',
    })
    setShowContactCardMemberPopup(true)
  }

  const showLeadDetails = () => {
    setIsHead(true)
    setShowContactCardMemberPopup(true)
  }

  const closeUpdateMemberPopup = () => {
    if (leadDetails?.id) {
      fetchRelationshipData()
    }
    setShowUpdateMemberPopup(false)
  }

  const closeMemberDetails = () => {
    setShowContactCardMemberPopup(false)
    setIsHead(false)
  }

  const routeToProfile = (member: RelationshipProps) => {
    const id = member.relationshipfrom
    if (member.isparty) {
      navigate('/Profile/' + id, {
        state: {
          clientId: id,
        },
      })
    } else {
      navigate(`/Leadprofile`, {
        state: {
          leadId: id,
        },
      })
    }
  }
  const getAvatarForProfile = () => {
    if (leadDetails.type === 'Business') {
      return (
        (leadDetails &&
          leadDetails.businessName &&
          leadDetails.businessName.charAt(0)) ??
        ''
      )
    } else if (leadDetails.type === 'Trust') {
      return (
        (leadDetails &&
          leadDetails.trustName &&
          leadDetails.trustName.charAt(0)) ??
        ''
      )
    } else if (leadDetails.leadStatus == 'Client' && leadDetails?.fullName) {
      const clientName: string[] = leadDetails?.fullName.split(' ')
      if (clientName?.length > 1) {
        const firstLetter = clientName?.shift()?.charAt(0) ?? ''
        const lastLetter = clientName?.pop()?.charAt(0) ?? ''
        return firstLetter + lastLetter
      } else if (clientName?.length == 1) {
        return clientName?.pop()?.charAt(0) ?? ''
      }
    } else {
      const firstName =
        (leadDetails &&
          leadDetails.firstName &&
          leadDetails.firstName.charAt(0)) ??
        ''
      const lastname =
        (leadDetails &&
          leadDetails.lastName &&
          leadDetails.lastName.charAt(0)) ??
        ''
      return firstName + lastname
    }
  }

  const getFullNameForProfile = () => {
    if (leadDetails.type === 'Business') {
      return (leadDetails && leadDetails.businessName) ?? ''
    } else if (leadDetails.type === 'Trust') {
      return (leadDetails && leadDetails.trustName) ?? ''
    } else if (leadDetails.leadStatus == 'Client' && leadDetails?.fullName) {
      return leadDetails?.fullName ?? ''
    } else {
      const firstName = (leadDetails?.firstName) ?? ''
      const lastname = ( leadDetails?.lastName) ?? ''
      return firstName + ' ' + lastname
    }
  }

  return (
    <UserProfileWrapper flex={'1'}>
      <Wrapper>
        {loadingAllRelationshipData ? (
          <GraphQLLoader />
        ) : (
          <>
            <FamilyHeadWrapper>
              <FamilyHeadCard>
                {leadDetails && (
                  <UserDetailsWrapper>
                    <Label
                      color={DEFAULT_COLORS.Text.PrimaryStrong}
                      fontWeight={600}
                      lineHeight="1rem"
                    >
                      {getAvatarForProfile()}
                    </Label>
                  </UserDetailsWrapper>
                )}
                <DetailsWrapper>
                  <AlignStartWrapper>
                    <WDLabelPrimaryTextMedium>
                      {getFullNameForProfile()}
                    </WDLabelPrimaryTextMedium>
                  </AlignStartWrapper>
                  <AlignStartWrapper>
                    <WDLabelSmall>{'Head'}</WDLabelSmall>
                  </AlignStartWrapper>
                </DetailsWrapper>
                <ActionsWrapper>
                  <WDButtonTransparent>
                    <Button type="button" onClick={showLeadDetails}>
                      <SvgContactCard />
                    </Button>
                  </WDButtonTransparent>
                </ActionsWrapper>
              </FamilyHeadCard>
              {allRelationshipToData
                ?.filter(
                  (data: RelationshipProps) =>
                    data.realtionshipname === 'Head' ||
                    data.realtionshipname === 'Husband' ||
                    data.realtionshipname === 'wife'
                )
                .map((member: RelationshipProps, index: number) => {
                  return (
                    <FamilyMemberCard key={index}>
                      <UserDetailsWrapper>
                        <Label
                          color={DEFAULT_COLORS.Text.PrimaryStrong}
                          fontWeight={600}
                          lineHeight="1rem"
                        >
                          {getAvatar(member.relationshipfromname)}
                        </Label>
                      </UserDetailsWrapper>
                      <DetailsWrapper>
                        <AlignStartWrapper
                          onClick={() => routeToProfile(member)}
                        >
                          <WDLabelPrimaryTextMedium>
                            {member.relationshipfromname}
                          </WDLabelPrimaryTextMedium>
                        </AlignStartWrapper>

                        <AlignStartWrapper>
                          <WDLabelSmall>{member.realtionshipname}</WDLabelSmall>
                        </AlignStartWrapper>
                      </DetailsWrapper>
                      <ActionsWrapper>
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            onClick={() => showMemberDetails(member)}
                          >
                            <SvgContactCard />
                          </Button>
                        </WDButtonTransparent>
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            onClick={() => updateMemberPopup(member)}
                          >
                            <SvgEdit fillColor={DEFAULT_COLORS.Icons.Primary} />
                          </Button>
                        </WDButtonTransparent>
                      </ActionsWrapper>
                    </FamilyMemberCard>
                  )
                })}
            </FamilyHeadWrapper>
            <FamilyDependantWrapper>
              {allRelationshipToData
                ?.filter(
                  (data: RelationshipProps) =>
                    data.realtionshipname !== 'Head' &&
                    data.realtionshipname !== 'Husband' &&
                    data.realtionshipname !== 'wife'
                )
                .map((member: RelationshipProps, index: number) => {
                  return (
                    <FamilyMemberCard key={index}>
                      <UserDetailsWrapper>
                        <Label
                          color={DEFAULT_COLORS.Text.PrimaryStrong}
                          fontWeight={600}
                          lineHeight="1rem"
                        >
                          {getAvatar(member.relationshipfromname)}
                        </Label>
                      </UserDetailsWrapper>
                      <DetailsWrapper>
                        <AlignStartWrapper
                          onClick={() => routeToProfile(member)}
                        >
                          <WDLabelPrimaryTextMedium>
                            {member.relationshipfromname}
                          </WDLabelPrimaryTextMedium>
                        </AlignStartWrapper>

                        <AlignStartWrapper>
                          <WDLabelSmall>{member.realtionshipname}</WDLabelSmall>
                        </AlignStartWrapper>
                      </DetailsWrapper>
                      <ActionsWrapper>
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            onClick={() => showMemberDetails(member)}
                          >
                            <SvgContactCard />
                          </Button>
                        </WDButtonTransparent>
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            onClick={() => updateMemberPopup(member)}
                          >
                            <SvgEdit fillColor={DEFAULT_COLORS.Icons.Primary} />
                          </Button>
                        </WDButtonTransparent>
                      </ActionsWrapper>
                    </FamilyMemberCard>
                  )
                })}
            </FamilyDependantWrapper>
            {userAccessDetails.role === vars.adminRole && (
              <WDButtonPrimaryOutlined>
                <Button
                  type="button"
                  onClick={() => showActionPopup('add member')}
                >
                  <SvgUserAdd fillColor={DEFAULT_COLORS.Icons.Primary} />
                  {'ADD DEPENDENT'}
                </Button>
              </WDButtonPrimaryOutlined>
            )}
            {showEditPopup && (
              <EditRelationship
                setShowRelationship={(value) => setShowEditPopup(value)}
              />
            )}
            {showAddMemberPopup && (
              <AddMember
                updateRelData={fetchRelationshipData}
                leadDetails={leadDetails}
                setShowRelationship={(value) => {
                  setShowAddMemberPopup(value)
                }}
              />
            )}
            {showChangeHeadPopup && (
              <ChangeHead
                setShowRelationship={(value) => setShowChangeHeadPopup(value)}
              />
            )}
            {/* {showDeleteFamilyPopup && (
              <DeleteFamily
                setShowRelationship={(value) => setShowMoreActions(value)}
              />
            )} */}
            {showUpdateMemberPopup && (
              <UpdateMember
                type="lead"
                leadDetails={leadDetails}
                relationShipTo={relationshipToData}
                relationShipFrom={relationshipFromData}
                closeUpdateMemberPopup={closeUpdateMemberPopup}
              />
            )}
            {showContactCardMemberPopup && (
              <ContactCard
                type="lead"
                isHead={isHead}
                leadDetails={leadDetails}
                loadingLeadData={loadingLeadData}
                leadData={leadData?.lead_leads[0]}
                closeMemberDetails={closeMemberDetails}
              />
            )}
          </>
        )}
      </Wrapper>
    </UserProfileWrapper>
  )
}
export default LeadRelationship
