export const OpportunitiesDetailsColumn = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'Party/Lead',
    accessor: 'contactname',
    // Filter: MultiSelectFilter,
    // filter: 'textArrayFilter',
  },
  {
    Header: 'ASSIGNED TO',
    accessor: 'assignedtoname',
    // Filter: MultiSelectFilter,
    // filter: 'textArrayFilter',
  },
  {
    Header: 'NEXT STEPS',
    accessor: 'nextsteps',
  },
  {
    Header: 'STATUS',
    accessor: 'stage',
    // Filter: MultiSelectFilter,
    // filter: 'textArrayFilter',
  },
  {
    Header: 'SOURCE',
    accessor: 'source',
    // Filter: MultiSelectFilter,
    // filter: 'textArrayFilter',
  },
  {
    Header: 'PROBABILITY(%)',
    accessor: 'probablity',
  },
  {
    Header: 'CLOSED DATE',
    accessor: 'closedate',
  },
  {
    Header: 'AMOUNT',
    accessor: 'amount',
  },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  // },
  {
    Header: 'REP CODE',
    accessor: 'repcode',
  },
]
