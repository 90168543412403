import { useContext, useEffect, useState } from 'react'
import {
  BodyWrapper,
  BtnWrapper,
  ClientIdWrapper,
  CreateWrapper,
  CrossWrapper,
  FooterWrapper,
  HeaderWrapper,
  ItemWrapper,
  ItemsWrp,
  MainWrapper,
  MasterWrapper,
  OverlayTitleWrapper,
  OverlayWrapper,
  PartyWrapper,
  SelectBoxSmall,
  TextBoxWrap,
  Wrapper,
  WrapperRow,
} from './style'
import { WDSubFormLabelHeading } from '../../../components/ui/WDLabel'
import Label from '../../../components/label/Label'
import Button from '../../../components/button/Button'
import {
  WDBorderButtonPrimary,
  WDButton,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import { SvgClose } from '../../../components/svg/SvgClose'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { Textbox } from '../../../components/textbox/Textbox'
import { ThemeContext } from '../../../store/ThemeContext'
import {
  StrategicAllocationDataProps,
  StrategicModalProps,
} from './IFinancialDetails'
import { LeadInfoProps } from '../../createContactList/ICreateLead'
import { ErrorMessage } from '../../../composites/opportunitiesDetails/styles'
import ToastContext from '../../../services/toast/ToastContext'
import { vars } from '../../../assets/utils/CommonFunctions'

const StrategicModal = ({
  setIsStrategicModal,
  leadDetails,
  updateLeadInfoData,
}: StrategicModalProps) => {
  const [inputErrors, setInputErrors] = useState<{
    [key: string]: string | number | [] | null
  } | null>(null)
  const { setToast } = useContext(ToastContext)
  const { isDarkMode } = useContext(ThemeContext)
  const [strategicAllocationData, setStrategicAllocationData] =
    useState<StrategicAllocationDataProps>({
      investmentObjectives: '',
      timeHorizon: '',
      riskTolerance: '',
      experienceFunds: 0,
      experiencePartnerships: 0,
      experienceStocks: 0,
      experienceOthers: 0,
    })
  const objectiveData = [
    { value: 'Aggressive Growth', label: 'Aggressive Growth' },
    { value: 'Growth', label: 'Growth' },
    { value: 'Income', label: 'Income' },
    { value: 'Safety of Principal', label: 'Safety of Principal' },
  ]
  const timeHorizonData = [
    { value: '1-3 Years', label: '1-3 Years' },
    { value: '3-5 Years', label: '3-5 Years' },
    { value: '5+ Years', label: '5+ Years' },
  ]
  const riskToleranceData = [
    { value: 'High', label: 'High' },
    { value: 'Low', label: 'Low' },
    { value: 'Moderate', label: 'Moderate' },
  ]

  useEffect(() => {
    setStrategicAllocationData({
      investmentObjectives: leadDetails?.investmentObjectives ?? '',
      timeHorizon: leadDetails?.timeHorizon ?? '',
      riskTolerance: leadDetails?.riskTolerance ?? '',
      experienceFunds: leadDetails?.experienceFunds ?? 0,
      experiencePartnerships: leadDetails?.experiencePartnerships ?? 0,
      experienceStocks: leadDetails?.experienceStocks ?? 0,
      experienceOthers: leadDetails?.experienceOthers ?? 0,
    })
  }, [leadDetails])

  const updateDetails = () => {
    const isLeadObj = {
      investmentObjectives: '',
      timeHorizon: '',
      riskTolerance: '',
    }
    const validationObj = isLeadObj

    const emptyKeysInLeadInfoData = Object.fromEntries(
      Object.entries(validationObj).filter(
        ([key]) =>
          strategicAllocationData[key] === '' ||
          strategicAllocationData[key] === null
      )
    )
    setInputErrors(emptyKeysInLeadInfoData)
    if (
      vars.validationRequired &&
      Object.keys(emptyKeysInLeadInfoData).length > 0
    ) {
      setToast(false, 'Please enter mandatory detail!')
      return
    } else {
      updateLeadInfoData?.(
        { ...leadDetails, ...strategicAllocationData } ?? ({} as LeadInfoProps)
      )
      setIsStrategicModal(false)
    }
  }

  const updateData = (data: Partial<LeadInfoProps>, fieldName?: string) => {
    if (inputErrors && Object.keys(inputErrors).length > 0) {
      if (fieldName && fieldName in inputErrors) {
        delete inputErrors[fieldName as keyof typeof inputErrors]
      }
    }
    setStrategicAllocationData((prev: StrategicAllocationDataProps) => {
      return { ...prev, ...data }
    })
  }

  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <MasterWrapper>
            <HeaderWrapper>
              <OverlayTitleWrapper>
                <WDSubFormLabelHeading>
                  <Label>{'UPDATE STRATEGIC ALLOCATION MODEL'}</Label>
                </WDSubFormLabelHeading>
                <CrossWrapper>
                  <WDButtonTransparent>
                    <Button
                      type="button"
                      onClick={() => setIsStrategicModal(false)}
                    >
                      <SvgClose fillColor="#000"></SvgClose>
                    </Button>
                  </WDButtonTransparent>
                </CrossWrapper>
              </OverlayTitleWrapper>
              <PartyWrapper>
                <BodyWrapper>
                  <CreateWrapper>
                    <Label>Objective</Label>
                    <SelectBoxSmall
                      error={inputErrors?.['investmentObjectives'] === ''}
                    >
                      <SelectDropdown
                        className="w-100"
                        borderColor={DEFAULT_COLORS.Border.Neutral}
                        padding="0rem"
                        options={objectiveData}
                        height="2.62rem"
                        placeholder={'Select'}
                        value={{
                          value: strategicAllocationData.investmentObjectives,
                          label: strategicAllocationData.investmentObjectives,
                        }}
                        onChange={(option) =>
                          updateData(
                            { investmentObjectives: option.value },
                            'investmentObjectives'
                          )
                        }
                      />
                    </SelectBoxSmall>
                    {inputErrors?.['investmentObjectives'] === '' && (
                      <ErrorMessage>{'Objective is required'}</ErrorMessage>
                    )}
                  </CreateWrapper>
                  <WrapperRow>
                    <CreateWrapper>
                      <Label>Time Horizon</Label>
                      <SelectBoxSmall
                        error={inputErrors?.['timeHorizon'] === ''}
                      >
                        <SelectDropdown
                          className="w-100"
                          borderColor={DEFAULT_COLORS.Border.Neutral}
                          padding="0rem"
                          options={timeHorizonData}
                          height="2.62rem"
                          placeholder={'Select'}
                          value={{
                            value: strategicAllocationData.timeHorizon,
                            label: strategicAllocationData.timeHorizon,
                          }}
                          onChange={(option) =>
                            updateData(
                              { timeHorizon: option.value },
                              'timeHorizon'
                            )
                          }
                        />
                      </SelectBoxSmall>
                      {inputErrors?.['timeHorizon'] === '' && (
                        <ErrorMessage>
                          {'Time Horizon is required'}
                        </ErrorMessage>
                      )}
                    </CreateWrapper>
                    <CreateWrapper>
                      <Label>Risk Tolerance</Label>
                      <SelectBoxSmall
                        error={inputErrors?.['riskTolerance'] === ''}
                      >
                        <SelectDropdown
                          className="w-100"
                          borderColor={DEFAULT_COLORS.Border.Neutral}
                          padding="0rem"
                          options={riskToleranceData}
                          height="2.62rem"
                          placeholder={'Select'}
                          value={{
                            value: strategicAllocationData.riskTolerance,
                            label: strategicAllocationData.riskTolerance,
                          }}
                          onChange={(option) =>
                            updateData(
                              { riskTolerance: option.value },
                              'riskTolerance'
                            )
                          }
                        />
                      </SelectBoxSmall>
                      {inputErrors?.['riskTolerance'] === '' && (
                        <ErrorMessage>
                          {'Risk Tolerance is required'}
                        </ErrorMessage>
                      )}
                    </CreateWrapper>
                  </WrapperRow>

                  {leadDetails && leadDetails?.status !== 'Client' && (
                    <WrapperRow>
                      <ItemsWrp>
                        <ItemWrapper>
                          <CreateWrapper>
                            <Label>Exp. Funds</Label>
                            <ClientIdWrapper>
                              <TextBoxWrap>
                                <Textbox
                                  value={
                                    strategicAllocationData.experienceFunds
                                  }
                                  onChange={(value) => {
                                    setStrategicAllocationData({
                                      ...strategicAllocationData,
                                      experienceFunds: !isNaN(Number(value))
                                        ? Number(value)
                                        : 0,
                                    })
                                  }}
                                />
                              </TextBoxWrap>
                              <Label>years</Label>
                            </ClientIdWrapper>
                          </CreateWrapper>
                        </ItemWrapper>
                        <ItemWrapper>
                          <CreateWrapper>
                            <Label>Exp. Stocks</Label>
                            <ClientIdWrapper>
                              <TextBoxWrap>
                                <Textbox
                                  value={
                                    strategicAllocationData.experienceStocks
                                  }
                                  onChange={(value) => {
                                    setStrategicAllocationData({
                                      ...strategicAllocationData,
                                      experienceStocks: !isNaN(Number(value))
                                        ? Number(value)
                                        : 0,
                                    })
                                  }}
                                />
                              </TextBoxWrap>
                              <Label>years</Label>
                            </ClientIdWrapper>
                          </CreateWrapper>
                        </ItemWrapper>
                      </ItemsWrp>
                      <ItemsWrp>
                        <ItemWrapper>
                          <CreateWrapper>
                            <Label>Exp. Partnerships</Label>
                            <ClientIdWrapper>
                              <TextBoxWrap>
                                <Textbox
                                  value={
                                    strategicAllocationData.experiencePartnerships
                                  }
                                  onChange={(value) => {
                                    setStrategicAllocationData({
                                      ...strategicAllocationData,
                                      experiencePartnerships: !isNaN(
                                        Number(value)
                                      )
                                        ? Number(value)
                                        : 0,
                                    })
                                  }}
                                />
                              </TextBoxWrap>
                              <Label>years</Label>
                            </ClientIdWrapper>
                          </CreateWrapper>
                        </ItemWrapper>
                        <ItemWrapper>
                          <CreateWrapper>
                            <Label>Exp. Other</Label>
                            <ClientIdWrapper>
                              <TextBoxWrap>
                                <Textbox
                                  value={
                                    strategicAllocationData.experienceOthers
                                  }
                                  onChange={(value) => {
                                    setStrategicAllocationData({
                                      ...strategicAllocationData,
                                      experienceOthers: !isNaN(Number(value))
                                        ? Number(value)
                                        : 0,
                                    })
                                  }}
                                />
                              </TextBoxWrap>
                              <Label>years</Label>
                            </ClientIdWrapper>
                          </CreateWrapper>
                        </ItemWrapper>
                      </ItemsWrp>
                    </WrapperRow>
                  )}
                </BodyWrapper>
              </PartyWrapper>
              <FooterWrapper>
                <BtnWrapper>
                  <WDBorderButtonPrimary>
                    <Button
                      type="button"
                      onClick={() => setIsStrategicModal(false)}
                      color={
                        isDarkMode
                          ? DEFAULT_COLORS.Text.UserDark
                          : DEFAULT_COLORS.Text.Primary
                      }
                    >
                      {'Cancel'}
                    </Button>
                  </WDBorderButtonPrimary>
                  <WDButton>
                    <Button type="button" onClick={updateDetails}>
                      {'Save'}
                    </Button>
                  </WDButton>
                </BtnWrapper>
              </FooterWrapper>
            </HeaderWrapper>
          </MasterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}

export default StrategicModal
