import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  align-self: center;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${DEFAULT_COLORS.BoxShadow.BackgroundShadow};
  z-index: 999;
`
export const MainWrapper = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`
export const BtnWrapper = styled.div`
  display: flex;
  float: right;
  gap: 1rem;
`
export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0.5rem 0.5rem;
  width: 100%;
  gap: 0.5rem;
  min-width: 50vw;
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  box-shadow: 0px 2px 6px 0.25px
    ${() => DEFAULT_COLORS.BoxShadow.StyledCardShadow};
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  padding: ${SIZES[3]};
  z-index: 1;
  height: 95vh;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`
export const LabelWrapper = styled.div`
  gap: 0.25rem;
`
export const PartyWrapper = styled.div`
  width: 100%;
  align-self: start;
  justify-content: space-between;
  gap: 0.5rem;
`
export const CrossWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const OverlayTitleWrapper = styled.div`
  background: ${DEFAULT_COLORS.Background.Neutral};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const LargeTextBoxWrap = styled.div`
  display: block;
  input {
    min-width: 24rem;
    height: ${SIZES[7]} !important;
  }
`
export const SelectBoxLarge = styled.div`
  min-width: 24rem;
`
export const SelectBoxSmall = styled.div`
  min-width: 12rem;
`
export const CheckboxWrapper = styled.div`
  display: flex;
  padding: 0.5rem 0;
  gap: 0.5rem;
  align-items: center;
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const ProfileHeadingWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`
export const DataWrapper = styled.div`
  display: flex;
  flex: 1;
`
export const LabelSectionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
`
export const EditSectionWrapper = styled.div`
  display: flex;
  flex: 0 1 10%;
  flex-direction: column;
  gap: 0.5rem;
`
export const LabelSectionValueWrapper = styled.div`
  display: flex;
  flex: 0 1 60%;
  flex-direction: column;
  gap: 0.5rem;
`
export const LabelSectionRightWrapper = styled.div`
  display: flex;
  flex: 0 1 60%;
  flex-direction: column;
  gap: 0.5rem;
`
export const HeadingTextWrapper = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[5]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  float: left;
  text-align: center;
  padding: 0.75rem 0rem;
`
export const TitleTextWrp = styled.div`
  vertical-align: middle;
  box-sizing: border-box;
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`
export const TitleEditWrp = styled.div`
  display: flex;
  position: relative;
  flex: 0 1 15%;
  justify-content: center;
  display-content: center;
  height: 1.5rem;
  gap: 1rem;
  margin-left: 0.75rem;
  @media (max-width: 1040px) {
    flex: 0 1 30%;
  }
  @media (max-width: 744px) {
    flex: 0 1 40%;
  }
`

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`
export const AddressCardWrapper = styled.div`
  background-color: ${DEFAULT_COLORS.Background.Neutral};
  min-height: 1rem;
  width: 18rem;
  height: auto;
  word-wrap: no-wrap;
  border: solid 2px blue;
  border: 1px solid ${DEFAULT_COLORS.Border.NeutralWeak};
  padding: 0.75rem;
  margin-bottom: 20px;
`

export const AddressContentWrapper = styled.div`
  padding: 1rem;
`
export const AddressLineWrapper = styled.div`
  display: block;
  padding: 0.25rem;
`
export const ContactDetailsTable = styled.table`
  padding: 0.25rem;
  row-gap: 0.4rem;
  tbody {
    display: inline-table;
    width: 100%;
    >tr {
      td {
        padding-bottom: 0.5rem;
        vertical-align: baseline;
        box-sizing: border-box;
        color: rgb(68, 68, 68);
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', lato;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
      }
      >td:nth-child(1) {
        width: 130px !important;
      }
      td:nth-child(2) {
        padding: 0 10px !important;
        width: 10px;
      }
      td:nth-child(3) {
        width: auto !important;
      }
    }
  }
`
