import { TabWrapper } from '../../global'
import { TitleWrapper } from './styles'

interface CommonTabProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
}

const CommonTabs = ({ selectedTab, setSelectedTab }: CommonTabProps) => {
  return (
    <TabWrapper>
      <button
        onClick={() => setSelectedTab('Created By')}
        className={
          'nav-link' + (selectedTab === 'Created By' ? ' selected' : '')
        }
      >
        <TitleWrapper>{'Created By'}</TitleWrapper>
      </button>
      <button
        onClick={() => setSelectedTab('By Book')}
        className={'nav-link' + (selectedTab === 'By Book' ? ' selected' : '')}
      >
        <TitleWrapper>{'By Book'}</TitleWrapper>
      </button>
    </TabWrapper>
  )
}

export default CommonTabs
