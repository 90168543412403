import { useContext, useEffect, useState } from 'react'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import {
  CreateWrapper,
  ItemWrapper,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
  PermissionWrapper,
  TextBoxWrap,
  SearchBoxWrap,
  SearchButtonWrap,
  ResponseWrap,
  ItemSubWrp,
  Heading,
  SectionWrapper,
  DataWrapper,
  DataItem,
  DataItemClose,
  AllButtonWrapper,
  Divider,
  Note,
  DataBadge,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import { WDButton, WDButtonTransparent } from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import { WDContactInfoWrapper } from '../../components/ui/WDWrapper'
import { SelectBoxSmall } from '../individualInformation/styles'
import { Textbox } from '../../components/textbox/Textbox'
import { getADUsersByRep } from '../../services/clientContext/ClientsApi'
import ListSearch from '../listSearch/ListSearch'
import { AuthContext } from '../../services/AuthContext'
import { ErrorMessage, SearchWrapper } from '../opportunitiesDetails/styles'
import { PermissionInfoData, PermissionInfoProps } from './IPermissions'
import { GET_PRIMARY_OWNER } from '../../services/graphQl/LeadsGraphQL'
import { useLazyQuery } from '@apollo/client'

const PermissionAndRoles = ({
  updateData,
  permissionError,
  theme = 'card',
  isAdditionalRepRequired = true,
}: PermissionInfoProps) => {
  const { userAccessDetails } = useContext(AuthContext)
  const [isShippinInformation, setIsbillingInformationOpen] = useState(true)
  const repCodeType = [
    {
      label: 'Rep Code',
      value: 'Rep Code',
    },
    {
      label: 'Branch Code',
      value: 'Branch Code',
    },
    {
      label: 'Office Code',
      value: 'Office Code',
    },
  ]

  const [selectedRepType, setSelectedRepType] = useState<any>()
  const [isRepValid, setIsRepValid] = useState<any>()
  const [isAdditionalRepValid, setIsAdditionalRepValid] = useState<any>()
  const [isAllPermission, setIsAllPermission] = useState(false)
  const [additionalCode, setAdditionalCode] = useState('')
  const [repCodeList, setRepCodeList] = useState<any[]>([])
  const [officeCodeList, setOfficeCodeList] = useState<any[]>([])
  const [branchCodeList, setBranchCodeList] = useState<any[]>([])
  const [ADUsers, setADUsers] = useState<any[]>([])
  const [primaryOwner, setPrimaryOwner] = useState('')
  const [repDataTable, setRepDataTable] = useState<any>()
  const [repData, setRepData] = useState<any[]>([])
  const [primaryRepCode, setPrimaryRepCode] = useState<any>('')
  const [callbackData, setCallbackData] = useState<PermissionInfoData>()
  const [getPrimaryOwner] = useLazyQuery<any>(GET_PRIMARY_OWNER)

  // const [getAdvisorDetails, { data: accessData, loading: leadLoading }] =
  //   useLazyQuery<any>(GET_REP_ACCESS_DETAILS)

  useEffect(() => {
    userAccessDetails?.repAccess?.forEach((code: any) => {
      setRepData((prev: any) => [...prev, { repCode: code }])
    })
  }, [userAccessDetails])

  const validateRepCode = (rep: any) => {
    setIsRepValid(undefined)

    getPrimaryOwner({ variables: { RepCode: rep }, fetchPolicy: 'no-cache' })
      .then((res) => {
        if (res?.data?.advisor_AdvisorRepAccess?.length > 0) {
          const advisor = res?.data?.advisor_AdvisorRepAccess?.[0]
          setPrimaryOwner(rep)

          setIsRepValid(true)
          const users: any[] = []

          if (advisor) {
            users.push(
              `${advisor?.PrimaryOwner?.FirstName} ${advisor?.PrimaryOwner?.MiddleName} ${advisor?.PrimaryOwner?.LastName}(${advisor.ADUserName})`
            )
          }

          setADUsers([{ repCode: rep, users: users, type: 'Primary Owner' }]) //, users.join(" • ")])

          setCallbackData({
            ...callbackData,
            primaryOwner: rep,
          })
        } else {
          setIsRepValid(false)
        }
      })
      .catch(() => {
        setIsRepValid(undefined)
        setADUsers([{ repCode: rep, users: undefined, type: '' }])
      })
  }

  useEffect(() => {
    updateData({
      ...(callbackData ?? { primaryOwner: '' }),
      branchCodes: isAllPermission
        ? []
        : branchCodeList.map((item) => {
            return item.repCode
          }),
      repCodes: isAllPermission
        ? []
        : repCodeList.map((item) => {
            return item.repCode
          }),
      officeCodes: isAllPermission
        ? []
        : officeCodeList.map((item) => {
            return item.repCode
          }),
      isAll: isAllPermission,
    })
  }, [callbackData])

  const updateRepCodeLists = () => {
    if (additionalCode) {
      getADUsersByRep({ repCode: additionalCode })
        .then((res) => {
          if (res?.data?.length > 0) {
            //setIsAdditionalRepValid(true)
            const users: any[] = []
            res?.data?.map((user: any) => {
              users.push(`${user.advisorFullName} (${user.adUserName})`)
            })

            const tRepCode = repCodeList.find(
              (item) => item.repCode == additionalCode
            )
            const tBranchCode = branchCodeList.find(
              (item) => item.repCode == additionalCode
            )
            const tOfficeCode = officeCodeList.find(
              (item) => item.repCode == additionalCode
            )

            if (tRepCode || tBranchCode || tOfficeCode) {
              setAdditionalCode('')
              setIsAdditionalRepValid(undefined)
              return
            }
            switch (selectedRepType?.value) {
              case 'Rep Code': // Rep Code
                setRepCodeList((prev: any) => [
                  ...prev,
                  {
                    repCode: additionalCode,
                    users: users,
                    type: selectedRepType?.value,
                  },
                ])

                break
              case 'Branch Code': // Branch Code
                setBranchCodeList((prev: any) => [
                  ...prev,
                  {
                    repCode: additionalCode,
                    users: users,
                    type: selectedRepType?.value,
                  },
                ])

                break
              case 'Office Code': // Office Code
                setOfficeCodeList((prev: any) => [
                  ...prev,
                  {
                    repCode: additionalCode,
                    users: users,
                    type: selectedRepType?.value,
                  },
                ])

                break
            }

            setAdditionalCode('')
            setIsAdditionalRepValid(undefined)
          } else {
            setIsAdditionalRepValid(false)
          }
        })
        .catch((err) => {
          setIsAdditionalRepValid(undefined)
          console.log('err', err)
        })
    } else {
      setIsAdditionalRepValid(false)
    }
  }

  useEffect(() => {
    if (isAllPermission) {
      updateData({
        primaryOwner: primaryOwner,
        branchCodes: [],
        repCodes: [],
        officeCodes: [],
        isAll: isAllPermission,
      })
    } else {
      updateData({
        ...(callbackData ?? { primaryOwner: '' }),
        branchCodes: branchCodeList.map((item) => {
          return item.repCode
        }),
        repCodes: repCodeList.map((item) => {
          return item.repCode
        }),
        officeCodes: officeCodeList.map((item) => {
          return item.repCode
        }),
        isAll: isAllPermission,
      })
    }
  }, [isAllPermission])

  const updateTable = () => {
    const table: any = []

    //repCode: additionalCode, users: users, type: 1

    branchCodeList.length > 0 &&
      branchCodeList?.map((item) => {
        //table.push({ type: item.type, code: item.repCode, users: item.users })
        table.push({
          type: item.type,
          repCode: item.repCode,
          users: item.users,
        })
      })

    officeCodeList.length > 0 &&
      officeCodeList?.map((item) => {
        table.push({
          type: item.type,
          repCode: item.repCode,
          users: item.users,
        })
      })

    repCodeList.length > 0 &&
      repCodeList?.map((item) => {
        table.push({
          type: item.type,
          repCode: item.repCode,
          users: item.users,
        })
      })

    setRepDataTable(table)
  }

  useEffect(() => {
    updateTable()
  }, [branchCodeList, repCodeList, officeCodeList])

  useEffect(() => {
    setPrimaryRepCode('')
    setIsRepValid(undefined)
  }, [ADUsers])

  useEffect(() => {
    setIsAdditionalRepValid(undefined)
  }, [additionalCode])

  const removeRepCode = (code: string) => {
    const updated = repCodeList?.filter((item) => {
      return item.repCode != code
    })

    setRepCodeList(updated)

    setCallbackData({
      ...(callbackData ?? { primaryOwner: '' }),
      branchCodes: branchCodeList,
      repCodes: updated,
      officeCodes: officeCodeList,
    })
  }

  const removeBranchCode = (code: string) => {
    const updated = branchCodeList?.filter((item) => {
      return item.repCode != code
    })

    setBranchCodeList(updated)
  }

  const removeOfficeCode = (code: string) => {
    const updated = officeCodeList?.filter((item) => {
      return item.repCode != code
    })

    setOfficeCodeList(updated)
    setCallbackData({
      ...(callbackData ?? { primaryOwner: '' }),
      branchCodes: branchCodeList,
      repCodes: repCodeList,
      officeCodes: updated,
    })
  }

  return (
    <CollaspPanelWrapper theme={theme}>
      <WDContactInfoWrapper>
        {theme == 'card' && (
          <CollapseWrapper
            onClick={() => setIsbillingInformationOpen(!isShippinInformation)}
          >
            <CollapseHeaderWrapper>
              <WDSubFormLabelHeading>
                <Label fontFamily="SourceSansPro-SemiBold">
                  {'Permission And Roles'}
                </Label>
              </WDSubFormLabelHeading>
            </CollapseHeaderWrapper>
            <CollapseButtonWrapper>
              <WDButtonTransparent>
                <Button
                  type="button"
                  onClick={() =>
                    setIsbillingInformationOpen(!isShippinInformation)
                  }
                >
                  <Image
                    image={
                      isShippinInformation ? Icons.ChevronUp : Icons.ChevronDown
                    }
                    alt="Chevron Down"
                  />
                </Button>
              </WDButtonTransparent>
            </CollapseButtonWrapper>
          </CollapseWrapper>
        )}

        {!isAllPermission &&
          ((ADUsers && ADUsers.length > 0) ||
            (repDataTable && repDataTable.length > 0)) && (
            <>
              <Heading mb={'0rem'} mt={'0.25rem'} fontWeight={'normal'}>
                {'Selected Rep Codes'}
              </Heading>
              <DataWrapper>
                {ADUsers &&
                  ADUsers.map((item: any, i: number) => {
                    return (
                      <DataItem key={i} type="primary">
                        <DataBadge>P</DataBadge>
                        {item.repCode}
                        <DataItemClose
                          onClick={() => {
                            setADUsers([])
                            setCallbackData({
                              ...callbackData,
                              primaryOwner: '',
                            })
                          }}
                        >
                          &#10006;
                        </DataItemClose>
                      </DataItem>
                    )
                  })}
                {repDataTable &&
                  repDataTable.map((item: any, i: number) => {
                    return (
                      <DataItem key={i}>
                        {item.repCode}
                        <DataItemClose
                          onClick={() => {
                            item.type == 'Rep Code'
                              ? removeRepCode(item.repCode)
                              : item.type == 'Branch Code'
                              ? removeBranchCode(item.repCode)
                              : removeOfficeCode(item.repCode)
                          }}
                        >
                          &#10006;
                        </DataItemClose>
                      </DataItem>
                    )
                  })}
              </DataWrapper>
            </>
          )}
        <PermissionWrapper display={isShippinInformation}>
          <ItemWrapper>
            <SectionWrapper>
              <Heading mb={'0.5rem'} mt={'0.25rem'} fontWeight={'normal'}>
                {'Primary Owner*'}
              </Heading>
              <ItemSubWrp>
                <CreateWrapper>
                  <Label>{'Rep Code'}</Label>
                  <SearchWrapper error={permissionError}>
                    {userAccessDetails?.accessLevels === 'Open' ? (
                      <TextBoxWrap width={'100%'}>
                        <SearchBoxWrap>
                          <Textbox
                            width="100%"
                            placeholder="Enter Rep Code"
                            onChange={(value) => {
                              setIsRepValid(undefined)
                              setPrimaryRepCode(value.toUpperCase())
                            }}
                            max={4}
                            value={primaryRepCode}
                          />
                          <SearchButtonWrap>
                            <WDButton>
                              <Button
                                onClick={() => validateRepCode(primaryRepCode)}
                                type="button"
                              >
                                Add
                              </Button>
                            </WDButton>
                          </SearchButtonWrap>
                        </SearchBoxWrap>
                      </TextBoxWrap>
                    ) : (
                      repData &&
                      repData.length > 0 && (
                        <ListSearch
                          width="100%"
                          key={20}
                          callback={validateRepCode}
                          behavior="search"
                          valueField="repCode"
                          isMultiple={false}
                          textField={['repCode']}
                          searchFields={['repCode']}
                          data={[...repData]}
                          placeholder="Search Rep Code"
                        />
                      )
                    )}
                    {isRepValid != undefined && primaryRepCode != '' && (
                      <ResponseWrap
                        accessType={repData && repData.length > 0 ? '' : 'open'}
                        type={isRepValid == true ? 'success' : 'error'}
                      >
                        {isRepValid == true ? 'Valid' : 'Invalid'}
                      </ResponseWrap>
                    )}
                  </SearchWrapper>
                  {permissionError && (
                    <ErrorMessage>{'Rep Code is required'}</ErrorMessage>
                  )}
                  <Note>
                    {'Valid Rep code will be added as primary owner.'}
                  </Note>
                  {/* {[...ADUsers]?.map((user, i) => {
                    return (
                      <ResponseWrap
                        key={i}
                        type={
                          user.users && user.users?.length > 0
                            ? 'success'
                            : 'error'
                        }
                      >
                        {`${user.repCode}`}
                        <Divider />
                        {`${
                          user.users && user.users?.length > 0
                            ? user.users?.join(' • ')
                            : 'Primary owner not found.'
                        }`}
                      </ResponseWrap>
                    )
                  })} */}
                </CreateWrapper>
              </ItemSubWrp>
            </SectionWrapper>
            {isAdditionalRepRequired && (
              <SectionWrapper>
                <Heading mb={'0.5rem'} mt={'0.25rem'} fontWeight={'normal'}>
                  {'Additional Permissions'}
                </Heading>
                <ItemSubWrp>
                  <CreateWrapper>
                    <AllButtonWrapper>
                      <input
                        type="checkbox"
                        id={`chkAll`}
                        key={123}
                        onChange={(event) => {
                          setIsAllPermission(event.target.checked)
                        }}
                        checked={isAllPermission}
                      />
                      <label htmlFor={`chkAll`}>All Permissions</label>
                    </AllButtonWrapper>
                  </CreateWrapper>
                  <Divider display={!isAllPermission} />
                  <CreateWrapper display={!isAllPermission}>
                    <Label>{'Rep Code Type'}</Label>
                    <SelectBoxSmall>
                      <SelectDropdown
                        className="w-100"
                        borderColor={DEFAULT_COLORS.Border.Neutral}
                        padding="0rem"
                        options={repCodeType}
                        height="2.62rem"
                        placeholder={'Select'}
                        value={{
                          value: selectedRepType?.value,
                          label: selectedRepType?.label,
                        }}
                        onChange={(option) => {
                          setSelectedRepType(option)
                        }}
                      />
                    </SelectBoxSmall>
                    <TextBoxWrap>
                      <SearchBoxWrap>
                        <Textbox
                          placeholder={`${
                            selectedRepType?.value ? selectedRepType?.value : ''
                          }`}
                          onChange={(value) => {
                            setAdditionalCode(value.toUpperCase())
                          }}
                          // style={{ textTransform: 'uppercase' }}
                          max={4}
                          value={additionalCode.toUpperCase()}
                        />
                        <SearchButtonWrap>
                          <WDButton>
                            {
                              // (isAdditionalRepValid != undefined && additionalCode != '') ? <WDButton>
                              <Button
                                type="button"
                                onClick={updateRepCodeLists}
                                color={DEFAULT_COLORS.Text.Primary}
                              >
                                {'Add'}
                              </Button>
                              // </WDButton> :
                              //   <Button
                              //     onClick={validateAdditionalRepCode}
                              //     type="button"
                              //   >
                              //     <SvgSearch fillColor="#fff"></SvgSearch>
                              //   </Button>
                            }
                          </WDButton>
                        </SearchButtonWrap>
                        {isAdditionalRepValid != undefined && (
                          <ResponseWrap
                            type={isAdditionalRepValid ? 'success' : 'error'}
                          >
                            {isAdditionalRepValid
                              ? 'Valid'
                              : additionalCode == ''
                              ? 'Enter Rep Code '
                              : 'Invalid'}
                          </ResponseWrap>
                        )}
                      </SearchBoxWrap>
                    </TextBoxWrap>
                    {/* {additionalADUsers &&
                    additionalADUsers.length > 0 &&
                    isAdditionalRepValid && (
                      <BtnWrapper padding={0} justifyContent={'end'}>
                        <WDButton>
                          <Button
                            type="button"
                            onClick={updateRepCodeLists}
                            color={DEFAULT_COLORS.Text.Primary}
                          >
                            {'Add'}
                          </Button>
                        </WDButton>
                      </BtnWrapper>
                    )}
                  {(repCodeList.length > 0 ||
                    branchCodeList.length > 0 ||
                    officeCodeList.length > 0) && (
                      <Spacer height={'20px'} width={'0px'} />
                    )} */}
                  </CreateWrapper>
                </ItemSubWrp>
              </SectionWrapper>
            )}
          </ItemWrapper>
        </PermissionWrapper>
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default PermissionAndRoles
