import { createContext, ReactNode, useState } from 'react'

export const ThemeContext = createContext({
  isDarkMode: false,
  setDarkMode: Function(),
})

interface ContextProps{
  children?:ReactNode
}

export const ThemeContextProvider = ({children}:ContextProps) => {
  const [isDarkMode, setDarkMode] = useState(false)
  return (
    <ThemeContext.Provider value={{ isDarkMode, setDarkMode }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
