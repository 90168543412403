import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import ToastContext from './ToastContext'

export interface SetToastContextInterface {
  setToast: (isOpen: boolean | null, message: string) => void
}

const SetToastContext = createContext<SetToastContextInterface>(
  {} as SetToastContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const SetToastContextProvider = ({ children }: ContextProps) => {
  const { setToastOpen, setToastMessage } = useContext(ToastContext)

  const setToast = useCallback(
    (isOpen: boolean | null, message: string) => {
      setToastOpen(isOpen)
      setToastMessage(message)
    },
    [setToastMessage, setToastOpen]
  )

  const values = useMemo(
    () => ({
      setToast,
    }),
    [setToast]
  )

  return (
    <SetToastContext.Provider value={values}>
      {children}
    </SetToastContext.Provider>
  )
}

export default SetToastContext
