export const dashboardLeadsData = [
  {
    firstName: 'George',
    lastName: 'Philippopoulos',
    companyAccount: 'AltolRA',
    email: 'george@altoria.com',
    leadSource: 'Contact Us',
    leadOwner: 'Millie Khoe',
    leadStatus: 'Referred Internally',
    description:
      "Hi - as an Introducing Broker we're seeking to connect with a Clearing Broker for trade execution for registered securities (stocks, bonds, options). Looking for additional information on potential partnership with Wedbush clearing services -- servic...",
    createDate: '1/3/2020',
  },
  {
    firstName: 'John',
    lastName: 'Dave',
    companyAccount: 'AltolRA',
    email: 'john@altoria.com',
    leadSource: 'Contact Us',
    leadOwner: 'Millie Khoe',
    leadStatus: 'Referred Internally',
    description:
      'Looking for additional information on potential partnership with Wedbush clearing services',
    createDate: '1/9/2020',
  },
]
export const columnData = [
  {
    Header: 'Party/Lead',
    accessor: 'name',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Last Contacted Date',
    accessor: 'lastContacted',
  },
]
