import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { msalConfig } from './settings/authConfig'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import platform from 'platform'
import { Glue42DesktopFactory, GlueProvider } from '@glue42/react-hooks'
import Glue from '@glue42/desktop'
import GlueWorkspaces from '@glue42/workspaces-api'
import AuthContextProvider from './services/AuthContext'
import { Provider } from 'react-redux'
import { SearchContextProvider } from './services/search/SearchContext'
import { UserContextProvider } from './services/user/UserContext'
import store from './store/store'

export const msalInstance = new PublicClientApplication(msalConfig)
const GlueContextProvider = () => (
  <GlueProvider
    fallback={<h1>Loading...</h1>}
    settings={{
      desktop: {
        factory: Glue as Glue42DesktopFactory,
        config: {
          channels: true,
          appManager: 'full',
          layouts: 'full',
          libraries: [GlueWorkspaces],
        },
      },
    }}
  >
    <App />
  </GlueProvider>
)
const AppProvider = () => (
  <AuthContextProvider>
    {platform.name === 'Electron' ? <GlueContextProvider /> : <App />}
  </AuthContextProvider>
)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <SearchContextProvider>
            <UserContextProvider>
              <AppProvider />
            </UserContextProvider>
          </SearchContextProvider>
        </MsalProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
