import { gql } from '@apollo/client'

// Insert queries
export const CREATE_LEAD = gql`
  mutation createLead(
    $createdOn: date
    $createdBy: String
    $honorific: String
    $firstName: String
    $middleName: String
    $lastName: String
    $suffix: String
    $pronouns: String
    $nickname: String
    $pronunciations: String
    $jobTitle: String
    $familyName: String
    $gender: String
    $interestHobbies: String
    $notes: String
    $owner: String
    $ownerName: String
    $pets: String
    $riskTolerance: String
    $timeHorizon: String
    $status: String
    $referredBy: String
    $potentialSizeOfAum: Int
    $taxIdNumber: Int
    $leadSource: String
    $maritalStatus: String
    $objective: String
    $accountTier: String
    $clientCategory: String
    $clientSince: String
    $advisor: String
    $dateOfBirth: timestamp
    $currentAdvisoryFirms: String
    $dateOfMarriage: timestamp
    $account: String
    $houseHoldMembers: String
    $totalAssets: Int
    $netWorth: bigint!
    $clientTerminationDate: timestamp
    $financialInterests: String
    $type: String
    $investmentExperience: String
    $businessName: String
    $trustName: String
    $websiteURL: String
  ) {
    insert_lead_leads(
      objects: {
        websiteurl: $websiteURL
        investmentexperience: $investmentExperience
        businessname: $businessName
        trustname: $trustName
        createdon: $createdOn
        createdby: $createdBy
        honorific: $honorific
        firstname: $firstName
        type: $type
        middlename: $middleName
        lastname: $lastName
        suffix: $suffix
        pronouns: $pronouns
        nickname: $nickname
        pronunciations: $pronunciations
        jobtitle: $jobTitle
        familyname: $familyName
        gender: $gender
        interesthobbies: $interestHobbies
        notes: $notes
        owner: $owner
        ownername: $ownerName
        pets: $pets
        risktolerance: $riskTolerance
        timehorizon: $timeHorizon
        status: $status
        referredby: $referredBy
        potentialsizeofaum: $potentialSizeOfAum
        taxidnumber: $taxIdNumber
        leadsource: $leadSource
        maritalstatus: $maritalStatus
        objective: $objective
        accounttier: $accountTier
        clientcategory: $clientCategory
        clientsince: $clientSince
        advisor: $advisor
        dateofbirth: $dateOfBirth
        currentadvisoryfirms: $currentAdvisoryFirms
        dateofmarriage: $dateOfMarriage
        account: $account
        householdmembers: $houseHoldMembers
        totalassets: $totalAssets
        networth: $netWorth
        clientterminationdate: $clientTerminationDate
        financialinterests: $financialInterests
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const CREATE_LEAD_ADDRESS = gql`
  mutation createLeadAddress($payload: [lead_address_insert_input!]!) {
    insert_lead_address(objects: $payload) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const CREATE_LEAD_PHONE = gql`
  mutation createLeadPhone($payload: [lead_contacts_insert_input!]!) {
    insert_lead_contacts(objects: $payload) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const CREATE_LEAD_EMAIL = gql`
  mutation createLeadEmail($payload: [lead_emails_insert_input!]!) {
    insert_lead_emails(objects: $payload) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const CREATE_LEAD_PERMISSION = gql`
  mutation createLeadPermission($payload: [lead_permissions_insert_input!]!) {
    insert_lead_permissions(objects: $payload) {
      affected_rows
      returning {
        id
      }
    }
  }
`
// Update queries
export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: bigint!
    $honorific: String
    $firstName: String
    $middleName: String
    $lastName: String
    $suffix: String
    $pronouns: String
    $nickname: String
    $pronunciations: String
    $jobTitle: String
    $familyName: String
    $gender: String
    $interestHobbies: String
    $notes: String
    $owner: String
    $ownerName: String
    $pets: String
    $riskTolerance: String
    $timeHorizon: String
    $status: String
    $referredBy: String
    $potentialSizeOfAum: Int
    $taxIdNumber: Int
    $leadSource: String
    $maritalStatus: String
    $objective: String
    $accountTier: String
    $clientCategory: String
    $clientSince: String
    $dateOfBirth: timestamp
    $currentAdvisoryFirms: String
    $dateOfMarriage: timestamp
    $account: String
    $houseHoldMembers: String
    $clientTerminationDate: timestamp
    $financialInterests: String
    $experienceFunds: Int
    $experienceStocks: Int
    $experiencePartnerships: Int
    $experienceOthers: Int
    $totalAssets: Int
    $nonLiquidAssets: Int
    $netWorth: bigint!
    $netWorthTotalLiabilities: Int
    $netWorthLiquidLiabilities: Int
    $liabilities: Int
    $taxyear: Int
    $estimatedtaxes: Int
    $estimatedtaxbracket: Int
    $confirmedbytaxreturn: Boolean
    $adjustedgrossincome: Int
    $investmentExperience: String
    $businessName: String
    $trustName: String
    $websiteURL: String
  ) {
    update_lead_leads(
      _set: {
        websiteurl: $websiteURL
        investmentexperience: $investmentExperience
        businessname: $businessName
        trustname: $trustName
        honorific: $honorific
        firstname: $firstName
        middlename: $middleName
        lastname: $lastName
        suffix: $suffix
        pronouns: $pronouns
        nickname: $nickname
        pronunciations: $pronunciations
        jobtitle: $jobTitle
        familyname: $familyName
        gender: $gender
        interesthobbies: $interestHobbies
        notes: $notes
        owner: $owner
        ownername: $ownerName
        pets: $pets
        risktolerance: $riskTolerance
        timehorizon: $timeHorizon
        status: $status
        referredby: $referredBy
        potentialsizeofaum: $potentialSizeOfAum
        taxidnumber: $taxIdNumber
        leadsource: $leadSource
        maritalstatus: $maritalStatus
        objective: $objective
        accounttier: $accountTier
        clientcategory: $clientCategory
        clientsince: $clientSince
        dateofbirth: $dateOfBirth
        currentadvisoryfirms: $currentAdvisoryFirms
        dateofmarriage: $dateOfMarriage
        account: $account
        householdmembers: $houseHoldMembers
        clientterminationdate: $clientTerminationDate
        financialinterests: $financialInterests
        experiencefunds: $experienceFunds
        experiencestocks: $experienceStocks
        experiencepartnership: $experiencePartnerships
        experienceothers: $experienceOthers
        totalassets: $totalAssets
        nonliquidassets: $nonLiquidAssets
        networth: $netWorth
        networthtotalliabilities: $netWorthTotalLiabilities
        networthliquidliabilities: $netWorthLiquidLiabilities
        liabilities: $liabilities
        taxyear: $taxyear
        estimatedtaxes: $estimatedtaxes
        estimatedtaxbracket: $estimatedtaxbracket
        confirmedbytaxreturn: $confirmedbytaxreturn
        adjustedgrossincome: $adjustedgrossincome
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_LEAD_ADDRESS = gql`
  mutation updateLeadAddress($payload: [lead_address_insert_input!]!) {
    insert_lead_address(
      objects: $payload
      on_conflict: {
        constraint: Address_pkey
        update_columns: [
          id
          leadid
          addresstype
          isprimary
          country
          addressline1
          addressline2
          city
          state
          zipcode
          activeflag
        ]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UPDATE_LEAD_PHONE = gql`
  mutation createLeadPhone($payload: [lead_contacts_insert_input!]!) {
    insert_lead_contacts(
      objects: $payload
      on_conflict: {
        constraint: contacts_pkey
        update_columns: [
          type
          isprimary
          phonenumber
          extension
          speeddial
          activeflag
        ]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UPDATE_LEAD_EMAIL = gql`
  mutation updateLeadEmail($payload: [lead_emails_insert_input!]!) {
    insert_lead_emails(
      objects: $payload
      on_conflict: {
        constraint: emails_pkey
        update_columns: [isprimary, type, emailid, activeflag]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

// Get queries
// export const GET_LEADS = gql`
//   query GetLeadDetails {
//     lead_leads(
//       distinct_on: id
//       order_by: [{ id: desc }, { createdon: desc }]
//       where: { _and: [{ partyid: { _is_null: true } }] }
//     ) {
//       id
//       firstname
//       middlename
//       lastname
//       type
//       account
//       accounttier
//       advisor
//       emailList {
//         id
//         leadid
//         isprimary
//         type
//         emailid
//       }
//       contactList {
//         id
//         leadid
//         isprimary
//         type
//         phonenumber
//         extension
//         speeddial
//       }
//     }
//   }
// `
// Get queries
export const GET_PERMISSIONS_BY_LEAD = gql`
  query GetLeadPermissions($id: bigint!) {
    lead_permissions(distinct_on: repcode, where: { leadid: { _eq: $id } }) {
      id
      type
      repcode
    }
  }
`
export const GET_All_LEADS = gql`
  query GetLeadDetails {
    lead_leads(
      distinct_on: id
      order_by: [{ id: desc }, { createdon: desc }]
      where: {
        _and: [
          { firstname: { _is_null: false } }
          { partyid: { _is_null: true } }
          { activeflag: { _eq: 1 } }
          { Lead_Permissions: { status: { _eq: true } } }
        ]
      }
    ) {
      id
      dateofbirth
      firstname
      middlename
      ownername
      lastname
      businessname
      trustname
      status
      type
      account
      activeflag
      accounttier
      emailList {
        id
        leadid
        isprimary
        type
        emailid
      }
      contactList {
        id
        leadid
        isprimary
        type
        phonenumber
        extension
        speeddial
      }
      Lead_Permissions {
        id
        leadid
        type
        repcode
        createdon
        createdby
        updatedon
        updatedby
        status
      }
    }
  }
`
export const GET_LEADS = gql`
  query GetLeadDetails($payload: lead_leads_bool_exp!) {
    lead_leads(
      distinct_on: id
      order_by: [{ id: desc }, { createdon: desc }]
      where: $payload
    ) {
      id
      dateofbirth
      firstname
      middlename
      ownername
      lastname
      businessname
      trustname
      status
      type
      account
      activeflag
      accounttier
      emailList {
        id
        leadid
        isprimary
        type
        emailid
      }
      contactList {
        id
        leadid
        isprimary
        type
        phonenumber
        extension
        speeddial
      }
      Lead_Permissions {
        id
        leadid
        type
        repcode
        createdon
        createdby
        updatedon
        updatedby
        status
      }
    }
  }
`

export const GET_PARTIES = gql`
  query GetParty($userName: String!) {
    advisor_vw_leadpartyvisibility(
      where: {
        _and: [
          { partyid: { _is_null: false } }
          { ADUserName: { _iregex: $userName } }
          { party_details: { PartyFullName: { _is_null: false } } }
        ]
      }
      distinct_on: partyid
    ) {
      party_details {
        PartyFullName
        PartyId
        PartyTypeId
        PartyTypeName
        PartyContact
        PartyEmail
      }
      repcode
    }
  }
`

export const GET_LEAD_DETAILS = gql`
  query GetLeadDetails($id: bigint!) {
    lead_leads(where: { id: { _eq: $id } }) {
      id
      firstname
      middlename
      lastname
      account
      clientcategory
      createdon
      createdby
      honorific
      suffix
      pronouns
      nickname
      pronunciations
      jobtitle
      familyname
      gender
      interesthobbies
      notes
      owner
      ownername
      pets
      risktolerance
      timehorizon
      status
      referredby
      potentialsizeofaum
      taxidnumber
      leadsource
      maritalstatus
      objective
      accounttier
      clientsince
      advisor
      dateofbirth
      investmentexperience
      businessname
      trustname
      websiteurl
      currentadvisoryfirms
      dateofmarriage
      householdmembers
      clientterminationdate
      financialinterests
      experiencefunds
      experiencestocks
      experiencepartnership
      experienceothers
      totalassets
      nonliquidassets
      networth
      type
      networthtotalliabilities
      networthliquidliabilities
      liabilities
      taxyear
      estimatedtaxes
      estimatedtaxbracket
      confirmedbytaxreturn
      adjustedgrossincome
      addresslist(
        where: {
          _and: [{ activeflag: { _neq: false } }, { leadid: { _eq: $id } }]
        }
      ) {
        id
        leadid
        addresstype
        isprimary
        city
        country
        addressline1
        addressline2
        state
        zipcode
        activeflag
      }
      emailList(
        where: {
          _and: [{ activeflag: { _neq: false } }, { leadid: { _eq: $id } }]
        }
      ) {
        id
        leadid
        isprimary
        type
        emailid
        activeflag
      }
      contactList(
        where: {
          _and: [{ activeflag: { _neq: false } }, { leadid: { _eq: $id } }]
        }
      ) {
        id
        leadid
        isprimary
        type
        phonenumber
        extension
        speeddial
        activeflag
      }
      Lead_Permissions {
        id
        leadid
        type
        repcode
        createdon
        createdby
        updatedon
        updatedby
        status
      }
    }
  }
`

export const GET_INDIVIDUAL_PARTY_DETAILS = gql`
  query GetIndividualPartyDetails($partyPayload: ClientDetailsPayload!) {
    getIndividualClientDetails(payload: $partyPayload) {
      partyId
      lastName
      businessType
      clientType
      firstName
      fullName
      middleName
      nameOfEntityOne
      nameOfEntityTwo
      organizationStructure
      partyDemographic {
        addressId
        addressTypeId
        businessAddress
        businessPhone
        citizenshipCountry
        city
        cityId
        contactId
        contactTypeId
        countryAlpha2Code
        countryName
        countryOfResidence
        countryOfTaxResidence
        dateOfBirth
        deceasedDate
        deceasedFlag
        employerName
        employerPhone
        employmentStatus
        maritalStatus
        numberOfDependents
        occupation
        occupationCategoryId
        parent
        partyDemographicId
        stateCode
        stateName
        stateProvinceId
        typeOfBusiness
        zipCode
      }
      partyTypeId
      prefix
      salutation
      socialSecurityNumber
      suffix
      updateBy
      taxIDNumber
    }
  }
`
export const GET_PARTY_CONTACT_DETAILS = gql`
  query GetPartyContactDetails($clientPayload: ClientAdditionalPayload!) {
    getIndividualClientAddress(payload: $clientPayload) {
      addressId
      addressNickName
      addressType
      city
      addressTypeId
      country
      countryAlpha2Code
      endDate
      startDate
      state
      stateCode
      stateProvinceId
      streetAddress1
      streetAddress2
      zipCode
    }
    getIndividualClientContact(payload: $clientPayload) {
      contactId
      contactTypeId
      phone
      phoneType
    }
    getIndividualClientEmail(payload: $clientPayload) {
      emailAddress
      emailAddressType
      emailId
      emailTypeId
      partyEmailId
    }
  }
`

export const GET_PARTY_INVESTMENTS_DETAILS = gql`
  query GetPartyInvestmentsDetails($partyPayload: ClientDetailsPayload!) {
    getIndividualClientInvestments(payload: $partyPayload) {
      annualIncome
      anualIncomeSTranslation
      liquidNetWorth
      investExperienceOther
      investExperienceOtherTranslation
      investExperiencePartnership
      investExperiencePartnershipTranslation
      investmentExperienceAnnuities
      investmentExperienceAnnuitiesTranslation
      investmentExperienceBond
      investmentExperienceBondTranslation
      investmentExperienceMutualFunds
      investmentExperienceMutualFundsTranslation
      investmentExperienceOptions
      investmentExperienceOptionsTranslation
      investmentExperienceStock
      investmentExperienceStockTranslation
      investmentKnowledge
      investmentKnowledgeTranslation
      investmentObjectiveIncome
      liquidNetWorthSTranslation
      partyFieldTranslationResponseDTOs {
        fieldName
        sTranslation
        sValue
      }
      sourceOfFundsResponse
      taxBracket
      totalNetWorth
      totalNetWorthStranslation
    }
  }
`
export const GET_PARTY_ADDITIONAL_DETAILS = gql`
  query GetPartyAdditionalDetails($partyId: bigint!) {
    party_additionalinfo(where: { id: { _eq: $partyId } }) {
      accounttier
      clientcategory
      clientsince
      clientterminationdate
      confirmedbytaxreturn
      createdby
      createdon
      currentadvisoryfirms
      dateofmarriage
      durationofmarriage
      estimatedtaxbracket
      estimatedtaxes
      familyname
      financialinterests
      gender
      householdmembers
      id
      interesthobbies
      investmentexperience
      leadsource
      liabilities
      networth
      networthliquidliabilities
      networthtotalliabilities
      nonliquidassets
      notes
      objective
      pets
      potentialsizeofaum
      referredby
      risktolerance
      taxidnumber
      taxyear
      timehorizon
      totalassets
      updatedby
      updatedon
      websiteurl
    }
  }
`
export const GET_PARTY_ACCOUNTS = gql`
  query getPartyAccounts($partyId: bigint!) {
    account_partyaccountrel(where: { partyid: { _eq: $partyId } }) {
      relationshiptypedesc
      accountDetails {
        accountnumber
        accountname
        accountType {
          description
        }
      }
    }
  }
`

export const CREATE_PARTY_DETAILS = gql`
  mutation createParty(
    $id: bigint
    $accountTier: String
    $maritalStatus: String
    $gender: String
    $referredBy: String
    $dateOfMarriage: timestamp
    $clientTerminationDate: timestamp
    $websiteURL: String
    $interestHobbies: String
    $houseHoldMembers: String
    $familyName: String
    $clientSince: String
    $pets: String
    $createdOn: date
    $createdBy: String
    $notes: String
    $riskTolerance: String
    $timeHorizon: String
    $potentialSizeOfAum: Int
    $leadSource: String
    $objective: String
    $totalAssets: Int
    $nonLiquidAssets: Int
    $netWorth: bigint
    $netWorthTotalLiabilities: Int
    $netWorthLiquidLiabilities: Int
    $liabilities: Int
    $taxyear: Int
    $estimatedtaxes: Int
    $estimatedtaxbracket: Int
    $confirmedbytaxreturn: Boolean
    $investmentExperience: String
  ) {
    insert_party_additionalinfo(
      objects: {
        id: $id
        accounttier: $accountTier
        gender: $gender
        leadsource: $leadSource
        referredby: $referredBy
        dateofmarriage: $dateOfMarriage
        clientterminationdate: $clientTerminationDate
        interesthobbies: $interestHobbies
        householdmembers: $houseHoldMembers
        familyname: $familyName
        clientsince: $clientSince
        pets: $pets
        investmentexperience: $investmentExperience
        websiteurl: $websiteURL
        createdon: $createdOn
        createdby: $createdBy
        notes: $notes
        risktolerance: $riskTolerance
        timehorizon: $timeHorizon
        potentialsizeofaum: $potentialSizeOfAum
        objective: $objective
        totalassets: $totalAssets
        nonliquidassets: $nonLiquidAssets
        networth: $netWorth
        networthtotalliabilities: $netWorthTotalLiabilities
        networthliquidliabilities: $netWorthLiquidLiabilities
        liabilities: $liabilities
        taxyear: $taxyear
        estimatedtaxes: $estimatedtaxes
        estimatedtaxbracket: $estimatedtaxbracket
        confirmedbytaxreturn: $confirmedbytaxreturn
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UPDATE_PARTY_DETAILS = gql`
  mutation updateParty(
    $id: bigint
    $accountTier: String
    $gender: String
    $referredBy: String
    $dateOfMarriage: timestamp
    $clientTerminationDate: timestamp
    $interestHobbies: String
    $houseHoldMembers: String
    $familyName: String
    $clientSince: String
    $pets: String
    $websiteURL: String
    $createdOn: date
    $createdBy: String
    $notes: String
    $riskTolerance: String
    $timeHorizon: String
    $potentialSizeOfAum: Int
    $leadSource: String
    $objective: String
    $totalAssets: Int
    $nonLiquidAssets: Int
    $netWorth: bigint
    $netWorthTotalLiabilities: Int
    $netWorthLiquidLiabilities: Int
    $liabilities: Int
    $taxyear: Int
    $estimatedtaxes: Int
    $estimatedtaxbracket: Int
    $confirmedbytaxreturn: Boolean
    $investmentExperience: String
  ) {
    update_party_additionalinfo(
      _set: {
        id: $id
        accounttier: $accountTier
        gender: $gender
        leadsource: $leadSource
        referredby: $referredBy
        dateofmarriage: $dateOfMarriage
        clientterminationdate: $clientTerminationDate
        interesthobbies: $interestHobbies
        householdmembers: $houseHoldMembers
        familyname: $familyName
        clientsince: $clientSince
        pets: $pets
        investmentexperience: $investmentExperience
        websiteurl: $websiteURL
        createdon: $createdOn
        createdby: $createdBy
        notes: $notes
        risktolerance: $riskTolerance
        timehorizon: $timeHorizon
        potentialsizeofaum: $potentialSizeOfAum
        objective: $objective
        totalassets: $totalAssets
        nonliquidassets: $nonLiquidAssets
        networth: $netWorth
        networthtotalliabilities: $netWorthTotalLiabilities
        networthliquidliabilities: $netWorthLiquidLiabilities
        liabilities: $liabilities
        taxyear: $taxyear
        estimatedtaxes: $estimatedtaxes
        estimatedtaxbracket: $estimatedtaxbracket
        confirmedbytaxreturn: $confirmedbytaxreturn
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const GetSavedFiles = gql`
  query GetFileDetails($tranId: bigint!, $tranType: Int!) {
    note_trandocuments(
      where: {
        _and: [
          { tranid: { _eq: $tranId } }
          { trantype: { _eq: $tranType } }
          { activeflag: { _neq: 0 } }
        ]
      }
    ) {
      trantype
      tranid
      id
      filepath
      filename
      createdon
      createdby
      activeflag
    }
  }
`

export const GET_REP_ACCESS_DETAILS = gql`
  query GetAdvisorRepAccess($AdId: String!) {
    advisor_AdvisorRepAccess(where: { ADUserName: { _eq: $AdId } }) {
      AccessLevel
      ADUserName
      BranchCode
      RRCode
      SubEntityCode
    }
    advisor_AdvisorDetails(where: { ADUserName: { _eq: $AdId } }) {
      FirstName
      MiddleName
      LastName
      RegisteredRepId
    }
  }
`
export const GET_PRIMARY_OWNER = gql`
  query GetAdvisorRepAccess($RepCode: String!) {
    advisor_AdvisorRepAccess(
      where: {
        _and: [{ RRCode: { _eq: $RepCode } }, { AccessLevel: { _eq: "Rep" } }]
      }
    ) {
      ADUserName
      BranchCode
      RRCode
      SubEntityCode
      PrimaryOwner {
        FirstName
        MiddleName
        LastName
      }
    }
  }
`

export const GetAccountsByRepCode = gql`
  query GetAccount($repCodes: [String!]) {
    account_account(
      where: { repcode: { _in: $repCodes } }
      order_by: { accountid: desc }
      distinct_on: accountnumber
    ) {
      accountid
      accountnumber
    }
  }
`
