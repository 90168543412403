import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgFilter = ({ fillColor, width, height }: ISvg) => {
  return (
    <StyledDiv>
      <svg
        width={width ?? "14"}
        height={height ?? "14"}
        viewBox="0 0 30 30"
        fill={'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12,25l6.67,6.67a1,1,0,0,0,.7.29.91.91,0,0,0,.39-.08,1,1,0,0,0,.61-.92V13.08L31.71,1.71A1,1,0,0,0,31.92.62,1,1,0,0,0,31,0H1A1,1,0,0,0,.08.62,1,1,0,0,0,.29,1.71L11.67,13.08V24.33A1,1,0,0,0,12,25ZM3.41,2H28.59l-10,10a1,1,0,0,0-.3.71V28.59l-4.66-4.67V12.67a1,1,0,0,0-.3-.71Z"
          fill={fillColor}
        ></path>
      </svg>
    </StyledDiv>
  )
}
