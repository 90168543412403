import { gql } from '@apollo/client'

export const FetchGenericDetails = gql`
  query FetchGenericDetails($id: bigint!) {
    note_notes(where: { id: { _eq: $id } }) {
      typeid
      subtypeid
      ownername
      ownerid
      subject
      qccflag
      updatedby
      updatedon
      createdby
      createdon
      opportunityid
      opportunityname
      id
      notedate
    }
  }
`
export const FetchAccountsParties = gql`
  query FetchAccountsParties($tranType: Int!, $tranId: bigint) {
    note_tranmappings(
      where: { trantype: { _eq: $tranType }, tranid: { _eq: $tranId } }
    ) {
      accountid
      accountname
      accountnumber
      partyid
      partyname
      repcode
    }
  }
`
export const FetchActionDesc = gql`
  query FetchActionDesc($tranId: bigint) {
    note_text(
      where: {tranid: { _eq: $tranId } }
    ) {
      detail
    }
  }
`

export const FetchTaskDetails = gql`
  query FetchTaskDetails($tranId: bigint!) {
    note_task(where: { noteid: { _eq: $tranId } }) {
      duedate
      assignedtoname
      assignedto
      status
      updatedby
      updateddate
    }
  }
`
export const FetchMeetingDetails = gql`
  query FetchMeetingDetails($tranId: bigint!) {
    note_meeting(where: { noteid: { _eq: $tranId } }) {
      id
      enddate
      endtime
      location
      meetingstatusid
      postmeetingcomment
      premeetingcomment
      startdate
      starttime
      timezone
    }
  }
`
export const FetchMeetingAssociates = gql`
  query FetchMeetingAssociates($tranId: bigint!) {
    note_meetingassociates(where: { meetingid: { _eq: $tranId } }) {
      employeename
      employeeid
    }
  }
`
export const GetSavedFiles = gql`
  query GetFileDetails($tranId: bigint!) {
    note_trandocuments(
      where: {
        _and: [
          { tranid: { _eq: $tranId } }
          { activeflag: { _neq: 0 } }
        ]
      }
    ) {
      trantype
      tranid
      id
      filepath
      filename
      createdon
      createdby
      activeflag
    }
  }
`
