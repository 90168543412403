import Select, {
  IndicatorsContainerProps,
  MultiValue,
  SingleValue,
} from 'react-select'
import { DefaultProps, SelectDropdownProps } from './ISelectDropdown'
import { Wrapper } from './styles'
import { DEFAULT_COLORS } from '../../theme/Colors'

const SelectDropdown = ({
  onChange,
  onInputChange,
  onMultipleChange,
  onBlur,
  defaultValue,
  value,
  isMulti,
  borderRadius,
  borderColor,
  options,
  isDisabled,
  isSearchable,
  isClearable,
  indicatorSeparator,
  width,
  height,
  name,
  placeholder,
  innerRef,
  padding,
  placeholderColor,
  indicatorzIndex,
  singleValueColor,
  indicatorContainer,
  className,
  display,
  alignContent,
}: SelectDropdownProps) => {
  const handleChange = (
    newValue: MultiValue<DefaultProps> | SingleValue<DefaultProps>
  ) => {
    onChange && onChange(newValue as DefaultProps)
  }

  const handleChangeMultiple = (
    newValue: MultiValue<DefaultProps> | SingleValue<DefaultProps>
  ) => {
    onMultipleChange && onMultipleChange(newValue as DefaultProps[])
  }

  const handleOnInputChange = (newValue: string) => {
    onInputChange && onInputChange(newValue)
  }

  const customStyles = {
    control: (provided: any) => {
      return {
        ...provided,
        width: width,
        height: height,
        minHeight: height,
        padding: padding,
        borderRadius: borderRadius,
        borderColor: borderColor,
        alignContent: 'center',
      }
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: placeholderColor,
        margin: '0.125rem 0rem',
      }
    },
    singleValue: (provided: any) => {
      return {
        ...provided,
        color: singleValueColor,
      }
    },
    indicatorContainer: (provided: IndicatorsContainerProps) => {
      return {
        ...provided,
        color: indicatorContainer,
      }
    },
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: `${DEFAULT_COLORS.Icons.Gray}`, // Custom colour
      padding: '0.25rem',
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: indicatorzIndex ? indicatorzIndex : 999,
      backgroundColor: "white",
      marginTop: "-5px"
    }),
  }

  return (
    <>
      {!indicatorSeparator ? (
        <Wrapper
          width={width}
          padding={padding}
          align={alignContent}
          display={display}
        >
          <Select
            options={options}
            onChange={isMulti ? handleChangeMultiple : handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            components={{
              IndicatorSeparator: () => null,
            }}
            menuPortalTarget={document.body}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}
            className={className}
            onInputChange={handleOnInputChange}
            isClearable={isClearable}
          />
        </Wrapper>
      ) : (
        <Wrapper width={width} padding={padding}>
          <Select
            menuPortalTarget={document.body}
            options={options}
            onChange={isMulti ? handleChangeMultiple : handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}
            className={className}
            onInputChange={handleOnInputChange}
            isClearable={isClearable}
          />
        </Wrapper>
      )}
    </>
  )
}

export default SelectDropdown
