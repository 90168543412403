import styled, { css } from 'styled-components'
import { DividerProps } from './IDivider'
const toggleHeight = (height: DividerProps) => {
  if (height) {
    return height
  }
  return '100vh'
}
export const DividerLine = styled.div<DividerProps>`
  ${({ horizontal, thickness, bgColor }: DividerProps) =>
    horizontal
      ? css`
      display:block;
          width: 100%;
          height: ${thickness};
          background-color: ${bgColor};
        `
      : css`
          width: ${thickness};
          height: ${toggleHeight};
          background-color: ${bgColor};
          margin: 0rem;
          padding: 0rem;
          display:block;

        `}
`
