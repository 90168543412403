import { Wrapper, StyledCheckbox, StyledLabel } from './styles'
import { CheckboxProps } from './ICheckbox'
import { BaseSyntheticEvent, useRef } from 'react'
const Checkbox = ({
  title,
  checkBoxId,
  disabled,
  checked,
  indeterminate,
  onChange,
}: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const handleChange = (event: BaseSyntheticEvent) => {
    onChange && onChange((event.target as HTMLInputElement).checked)
  }
  return (
    <Wrapper>
      <StyledCheckbox
        type={'checkbox'}
        ref={checkboxRef}
        id={checkBoxId}
        disabled={disabled}
        checked={checked}
        indeterminate={indeterminate}
        onChange={handleChange}
      />
      {title && <StyledLabel htmlFor={checkBoxId}>{title}</StyledLabel>}
    </Wrapper>
  )
}

export default Checkbox
