import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${DEFAULT_COLORS.BoxShadow.BackgroundShadow};
  z-index: 999;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  background-color: rgb(255, 255, 255);
  padding: 0 1rem 1rem;
  margin: 0px auto;
  max-height: 90vh;
  max-width: 1100px;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 21%);
`
export const Header = styled.div`
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 0rem;
  width: 100%;
`
export const FormWrapper = styled.div`
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
  gap: 0.5rem;
`
export const ContentWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: ${(props: { direction?: string }) => {
    return props.direction
  }};
`
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0.5rem 0rem;
  width: 100%;
  gap: 1rem;
`
export const LeftSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${SIZES[1]};
`

export const RightSubWrapper = styled.div`
  ${(props: { flex?: string }) => {
    return `
  display: flex;
  flex-direction: column;
  flex:${props.flex ?? 'auto'};
  gap: ${SIZES[1]};
  position: relative;`
  }}
`
export const DivWrapper = styled.div``

export const ErrorMessage = styled.span`
  color: ${DEFAULT_COLORS.Text.Danger};
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  gap: 1rem;
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
`

export const ClientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  gap: 0.25rem;
`

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${DEFAULT_COLORS.Background.NeutralWeak};
  border: 1px solid ${() => DEFAULT_COLORS.Border.LightBlue};
  border-radius: 4px;
  gap: 0.5rem;
  padding: 0.25rem;
  height: max-content;
`

export const DetailsSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 92%;
`

export const ClientNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`

export const ClientIDWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

export const IDWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.775rem;
`

export const ButtonWrapClient = styled.div`
  position: relative;
  left: 1.5%;
  right: 1.5%;
  bottom: 0%;
  display: flex;
  justify-content: flex-end;
  flex: 0 1 5%;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid
    ${(props: { errorMsg: string }) =>
      props.errorMsg ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
`

export const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const SvgDiv = styled.div`
  height: 1rem;
`
