import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SIZES[12]};
  height: ${SIZES[12]};
  padding: ${SIZES[1]};
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: ${DEFAULT_COLORS.Text.Neutral};
  top: ${SIZES[12]};
  right: ${SIZES[5]};
  z-index: 999;
`
export const SpinnerSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
`
