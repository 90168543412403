import React, { useEffect, useState, useRef } from 'react'
import { ListSearchInterface } from './IListSearch'
import {
  MainWrapper,
  InputWrapper,
  PanelWrapper,
  PanelItem,
  DataWrapper,
  DataItem,
  DataItemClose,
  Arrow,
  ExtraItems,
  ClearFilter,
} from './style'
import { SvgCloseSmall } from '../../components/svg/SvgCloseSmall'
export default function ListSearch({
  className,
  showTags = true,
  data,
  isMultiple,
  placeholder,
  width,
  closeOnSelect,
  minCharLength,
  searchFields,
  textField,
  valueField,
  callback,
  behavior = 'search',
  extraDetails = [],
  extraDetailsClass,
  wrapDetails,
  distinctValues = true,
  preSelectionField = '',
  preSelectionValues,
  clearOnSelect = false,
}: ListSearchInterface) {
  const [searchTerm, setSearchTerm] = useState('')
  const [orgData, setOrgData] = useState([])
  const [panelState, setPanelState] = useState(false)
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const preValue: any = []
  useEffect(() => {
    if (data) setOrgData(JSON.parse(JSON.stringify(data)))
  }, [])

  const updateSelections = () => {
    const selectedItems = orgData.filter((item: any) => {
      return item?.checked
    })

    setSelectedItems([...selectedItems])
  }

  useEffect(() => {
    if (isMultiple) {
      const callbackValues = [...selectedItems].map((item) => {
        return valueField ? item?.[valueField] : item
      })

      callback([...callbackValues])
    }
  }, [selectedItems])

  const catMenu: any = useRef(null)
  const closeButton: any = useRef(null)

  const closePanelIfOutSide = (event: any) => {
    if (catMenu.current && !catMenu.current.contains(event.target)) {
      setPanelState(false)
    }
  }

  const setPreValues = () => {
    const selectedItems = orgData.map((item: any) => {
      if (preSelectionValues && preSelectionValues?.length > 0) {
        item.checked =
          preSelectionValues?.indexOf(item?.[preSelectionField]) !== -1
      } else {
        item.checked = false
      }

      if (item.checked == true) {
        return valueField ? item?.[valueField] : item
      } else return false
    })

    setSelectedItems([...selectedItems])
    updateSelections()
  }

  useEffect(() => {
    if (preSelectionField && preSelectionValues) setPreValues()
  }, [orgData])

  const clearFilter = () => {
    orgData.map((item: any) => {
      item.checked = false
    })

    setSelectedItems([])
    updateSelections()
    setSearchTerm('')
  }

  useEffect(() => {
    document.addEventListener('click', (e) => {
      closePanelIfOutSide(e)
    })
    return () => {
      document.removeEventListener('click', () => {
        console.log('')
      })
    }
  }, [])

  return (
    <MainWrapper ref={catMenu} width={width}>
      {showTags && selectedItems.length > 0 && (
        <DataWrapper>
          {selectedItems.map((ele: any, i: number) => {
            const textObj: any = []
            textField &&
              textField?.forEach((text) => {
                textObj.push(ele?.[text])
              })
            return (
              <DataItem
                key={i}
                onClick={(event: any) => {
                  event.stopPropagation()
                }}
              >
                {isMultiple && (
                  <>
                    {textObj.join(' ')}
                    <input
                      type="checkbox"
                      id={`chkListSearchTop${i}`}
                      key={i}
                      onChange={(event) => {
                        ele.checked = event.target.checked
                        updateSelections()
                      }}
                      checked={ele.checked}
                    />
                    <DataItemClose>
                      <label htmlFor={`chkListSearchTop${i}`}>&#10006;</label>
                    </DataItemClose>
                  </>
                )}
              </DataItem>
            )
          })}
        </DataWrapper>
      )}
      <InputWrapper
        onClick={() => {
          //if (behavior == 'filter') {
          setPanelState(!panelState)
          //}
        }}
      >
        <input
          autoComplete="off"
          id={`txtSearch`}
          placeholder={behavior == 'search' ? placeholder : `Select items`}
          value={
            behavior == 'search'
              ? searchTerm
              : selectedItems.length > 0
              ? `${selectedItems.length} selected`
              : ``
          }
          style={{ width: width ?? '100%' }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setPanelState(e.target.value.length >= (minCharLength || 3))
          }}
          onClick={() => {
            if (!panelState) {
              setPanelState(true)
            }
          }}
          className={className}
          readOnly={behavior == 'filter'}
        />
        {isMultiple && selectedItems.length > 0 && (
          <ClearFilter
            type={behavior}
            ref={closeButton}
            onClick={(event: any) => {
              event.stopPropagation()
              clearFilter()
            }}
          >
            <SvgCloseSmall fillColor="#000" />
          </ClearFilter>
        )}
        {behavior == 'filter' && <Arrow />}
      </InputWrapper>
      {/* {((searchTerm.length >= (minCharLength || 3)) || behavior == 'filter') && panelState && (
        
        <PanelWrapper> */}
      <PanelWrapper display={panelState}>
        {orgData?.map((ele: any, i: number) => {
          const textObj: any = []
          const searchText: any = []
          const extraItems: string[] = []
          textField &&
            textField?.forEach((text) => {
              textObj.push(ele?.[text])
            })
          if (behavior == 'search') {
            searchFields &&
              searchFields.forEach((field) => {
                searchText.push(ele?.[field])
              })
          }

          if (extraDetails.length > 0) {
            extraDetails.forEach((eItem) => {
              if (eItem && ele?.[eItem]) extraItems.push(ele?.[eItem])
            })
          }
          if (
            textObj.join(' ').trim() !== '' &&
            (!distinctValues ||
              preValue.indexOf(textObj.join(' ').trim()) == -1 ||
              !preValue)
          ) {
            preValue.push(textObj.join(' ').trim())
            return (
              ((behavior == 'search' &&
                searchText
                  .join(' ')
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())) ||
                behavior == 'filter') && (
                <PanelItem
                  data-checked={ele.checked}
                  key={i}
                  onClick={() => {
                    //getSelectedData()
                    if (!isMultiple) {
                      valueField ? callback(ele?.[valueField]) : callback(ele)
                    }

                    if (closeOnSelect || !isMultiple) {
                      setPanelState(false)
                      setSearchTerm(textObj.join(' '))
                    }
                    if (clearOnSelect && !isMultiple) {
                      setSearchTerm('')
                    }
                  }}
                >
                  {isMultiple && (
                    <input
                      type="checkbox"
                      id={`chkListSearchPanel${i}`}
                      name="chkListSearchName"
                      onChange={(event) => {
                        event.stopPropagation()
                        ele.checked = event.target.checked
                        updateSelections()
                      }}
                      checked={ele.checked}
                    />
                  )}
                  <label htmlFor={isMultiple ? `chkListSearchPanel${i}` : ``}>
                    {textObj.join(' ') ?? `match not found`}
                    {extraItems.length > 0 && (
                      <ExtraItems
                        wrapDetails={
                          extraDetailsClass ? undefined : wrapDetails
                        }
                        className={extraDetailsClass}
                      >
                        {extraDetailsClass
                          ? extraItems.map((detail, i) => {
                              return (
                                <span data-value={detail} key={i}>
                                  {detail}
                                </span>
                              )
                            })
                          : extraItems.join(' • ')}
                      </ExtraItems>
                    )}
                  </label>
                </PanelItem>
              )
            )
          }
          // else {
          //   return (<label key={i} htmlFor={isMultiple ? `chkListSearchPanel${i}` : ``}>
          //     {`match not found`}
          //   </label>)
          // }
        })}
      </PanelWrapper>
      {/* )} */}
    </MainWrapper>
  )
}
