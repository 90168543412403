import { useState } from 'react'
import Label from '../../components/label/Label'
import { Textbox } from '../../components/textbox/Textbox'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { AddressInformationProps } from './IURLInformation'
import {
  CreateWrapper,
  ItemWrapper,
  TextBoxWrap,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import { WDContactInfoWrapper } from '../../components/ui/WDWrapper'
import { ErrorMessage } from '../opportunitiesDetails/styles'

const URLInformation = ({
  updateData,
  leadInfoData,
  inputErrors,
}: AddressInformationProps) => {
  const [isShippinInformation, setIsShippingInformationOpen] = useState(true)
  // const [addressType, setAddressType] = useState('shipping')

  // const addressTypeList = [
  //   { value: 'home', label: 'Home' },
  //   { value: 'work', label: 'Work' },
  //   { value: 'other', label: 'Other' },
  // ]
  // const handleChange = (option: OptionProps) => {
  //   if (option) {
  //     setAddressType(option.value)
  //   }
  // }

  // const handleChangeText = (value: string, fieldName: string) => {
  //   const field = addressType + fieldName
  //   updateData({ [field]: value })
  // }

  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper
          onClick={() => setIsShippingInformationOpen(!isShippinInformation)}
        >
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">{'URLs'}</Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() =>
                  setIsShippingInformationOpen(!isShippinInformation)
                }
              >
                <Image
                  image={
                    isShippinInformation ? Icons.ChevronUp : Icons.ChevronDown
                  }
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isShippinInformation && (
          <>
            <ItemWrapper>
              <CreateWrapper>
                <Label>{'Website Address'}</Label>
                <TextBoxWrap error={inputErrors && inputErrors.includes('websiteURL')}>
                  <Textbox
                    onChange={(value) =>
                      updateData({ websiteURL: value }, 'websiteURL')
                    }
                    value={leadInfoData.websiteURL}
                  />
                </TextBoxWrap>
                {inputErrors && inputErrors.includes('websiteURL')&& (
                  <ErrorMessage>{'Website Address is required'}</ErrorMessage>
                )}
              </CreateWrapper>
            </ItemWrapper>
          </>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default URLInformation
