import styled from 'styled-components'
import { SIZES } from '../../theme/Sizes'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  textarea {
    height: 5rem;
    min-width: 22.5rem;
    box-sizing: border-box;
    resize: none;
    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
    ::-webkit-scrollbar-track {
      background: ${DEFAULT_COLORS.Background.Neutral};
    }
    ::-webkit-scrollbar-thumb {
      background: ${DEFAULT_COLORS.Background.PrimaryActive};
      border-radius: 4px;
    }
    &:hover {
      border: 1px solid ${DEFAULT_COLORS.Background.NeutralGray};
    }
    /* &:active {
      border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    } */
    &:focus {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      border-color: ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      color: ${() => DEFAULT_COLORS.Text.Black};
    }
  }
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: ${SIZES[1]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  padding: ${SIZES[1]};
  border: 1px solid ${() => DEFAULT_COLORS.Background.Grey};
`
export const NoteMainWrapper = styled.div`
  width: 100%;
`
