import styled from 'styled-components'
import { SelectDropdownProps } from './ISelectDropdown'

export const Wrapper = styled.div<SelectDropdownProps>`
  display: ${(props: SelectDropdownProps) => props.display ?? 'flex'};
  flex-direction: column;
  align-items: center;
  width: ${(props: SelectDropdownProps) => props.width ?? 'inherit'};
  padding: ${(props: SelectDropdownProps) =>
    props.padding ? props.padding : '0.5rem 0'};
`
