import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect, useRef, useState } from 'react'
import Table from '../../components/table/Table'
import { AuthContext } from '../../services/AuthContext'
import {
  GET_NOTES_BOOK,
  GET_NOTES_BOOK_COUNT,
  GET_NOTES_CREATEDBY,
  GET_NOTES_CREATEDBY_COUNT,
  GET_NOTES_REPCODE_LIST,
} from '../../services/graphQl/CustomerGraphQl'
import UserContext from '../../services/user/UserContext'
import { ContentItemWrapper, Wrapper } from '../../shared/GlobalStyle'
import { leadColumnData } from './NotesData'
//import { CreateNoteComponent } from '../../noteWidget'
import { CreateNoteComponent } from 'note-widget'
import { useSelector } from 'react-redux'
import { uniqueArrayOfObjects, vars } from '../../assets/utils/CommonFunctions'
import CommonTabs from '../../composites/commonTabs/CommonTabs'
import Loader from '../../composites/loader/Loader'
import {
  leadsData,
  partiesData,
  subTypeData,
  typeData,
} from '../../reducers/leadPartySlice'
import EditActionContext from '../../services/editActionsContext/EditActionsContext'
import FilterContext, {
  FilterPayloadInterface,
} from '../../services/filterContext/FilterContext'
import appConfig from '../../settings/appConfig'
import { useAppSelector } from '../../store/store'
import {
  SubTypesDataInterface,
  TaskRepCodeInterface,
  TypesDataInterface,
} from './INotes'

const Notes = () => {
  const { user } = useContext(UserContext)
  const [selectedTab, setSelectedTab] = useState('Created By')
  const [showNewNotes, setNewNotes] = useState<boolean>(false)
  const leadsDetails = useAppSelector(leadsData)
  const partyDetails = useAppSelector(partiesData)
  const { accessToken } = useContext(AuthContext)
  const [noteData, setNoteData] = useState<any>([])
  const { loadingEdit, loadingConvert } = useContext(EditActionContext)
  const {
    filterPayload,
    setFilterPayload,
    setInteractionsPayload,
    setScrollPosition,
    resetTableFilter,
  } = useContext(FilterContext)
  const typesData = useSelector(typeData)
  const subTypesData = useSelector(subTypeData)
  const [allTypes, setAllTypes] = useState<any[]>([])
  const [subTypes, setSubTypes] = useState<any[]>([])
  const [repCodeData, setRepCodeData] = useState<any[]>([])
  const tableRef = useRef<any>(null)

  const [
    getNotesCreatedByCount,
    { data: notesCreatedByCount, loading: notesCountLoading },
  ] = useLazyQuery(GET_NOTES_CREATEDBY_COUNT, {
    variables: {
      payload: setInteractionsPayload('note'),
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getNotesCreatedBy,
    { data: noteDataCreatedBy, loading: notesLoadingCreatedBy },
  ] = useLazyQuery(GET_NOTES_CREATEDBY, {
    variables: {
      payload: setInteractionsPayload('note'),
      limit: vars.InitRecordCount,
      offset: filterPayload.offset,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getNotesByBookCount,
    { data: notesByBookCount, loading: countLoading },
  ] = useLazyQuery(GET_NOTES_BOOK_COUNT, {
    variables: {
      payload: setInteractionsPayload('noteBook'),
    },
    fetchPolicy: 'no-cache',
  })

  const [
    getNotesByBook,
    { data: noteDataBookBy, loading: notesLoadingBookBy },
  ] = useLazyQuery(GET_NOTES_BOOK, {
    variables: {
      payload: setInteractionsPayload('noteBook'),
      limit: vars.InitRecordCount,
      offset: filterPayload.offset,
    },
    fetchPolicy: 'no-cache',
  })
  const noteLoadings =
    notesLoadingCreatedBy ||
    notesLoadingBookBy ||
    notesCountLoading ||
    countLoading
  const [getNotesRepCode, { data: noteRepCode }] = useLazyQuery(
    GET_NOTES_REPCODE_LIST
  )

  const fetchData = (scrollHeight: number) => {
    if (
      !noteLoadings &&
      filterPayload.offset + vars.InitRecordCount <
        notesCreatedByCount?.note_vw_wealth_notes_createdby_aggregate?.aggregate
          ?.count &&
      notesCreatedByCount?.note_vw_wealth_notes_createdby_aggregate?.aggregate
        ?.count > vars.InitRecordCount
    ) {
      setScrollPosition(scrollHeight)
      setFilterPayload((prev) => ({
        ...prev,
        offset: filterPayload.offset + vars.InitRecordCount,
      }))
    }
  }

  const getTypeByTranType = () => {
    return typesData
      .filter((type: TypesDataInterface) => type.trantype === 1)
      .map((type: TypesDataInterface) => {
        return { value: type.typeid, label: type.type }
      })
  }

  const getSubTypeByType = (typeId: number) => {
    return subTypesData
      .filter(
        (type: SubTypesDataInterface) =>
          type.typeid === typeId && type.trantype === 1
      )
      .map((type: SubTypesDataInterface) => {
        return { value: type.subtypeid, label: type.subtype }
      })
  }

  const getAllSubTypeByType = () => {
    return subTypesData
      .filter((type: SubTypesDataInterface) => type.trantype === 1)
      .map((type: SubTypesDataInterface) => {
        return { value: type.subtypeid, label: type.subtype }
      })
  }
  const getNotesRepcodes = () => {
    if (
      noteRepCode &&
      noteRepCode?.note_vw_wealth_notes_createdby &&
      noteRepCode?.note_vw_wealth_notes_book
    ) {
      const temp = [
        ...noteRepCode.note_vw_wealth_notes_createdby,
        ...noteRepCode.note_vw_wealth_notes_book,
      ]

      return uniqueArrayOfObjects(temp)
        ?.filter((rep: TaskRepCodeInterface) => rep.repcode)
        ?.map((ele: TaskRepCodeInterface) => ({
          label: ele.repcode,
          value: ele.repcode,
        }))
    }
  }
  useEffect(() => {
    if (typesData) setAllTypes(getTypeByTranType())
    if (subTypesData) setSubTypes(getAllSubTypeByType())
    if (noteRepCode) setRepCodeData(getNotesRepcodes())
  }, [typesData, subTypesData, noteRepCode])

  useEffect(() => {
    if (
      selectedTab == 'Created By' &&
      noteDataCreatedBy &&
      noteDataCreatedBy?.note_vw_wealth_notes_createdby
    ) {
      filterPayload.offset > 0
        ? setNoteData((prev: any) => [
            ...prev,
            ...noteDataCreatedBy.note_vw_wealth_notes_createdby,
          ])
        : setNoteData(noteDataCreatedBy.note_vw_wealth_notes_createdby)
    }
  }, [noteDataCreatedBy?.note_vw_wealth_notes_createdby, selectedTab])

  useEffect(() => {
    if (
      selectedTab == 'By Book' &&
      noteDataBookBy &&
      noteDataBookBy?.note_vw_wealth_notes_book
    ) {
      const noteBook = noteDataBookBy?.note_vw_wealth_notes_book.map(
        (note: any) => {
          return { ...note, partyid: note.notepartyid }
        }
      )
      filterPayload.offset > 0
        ? setNoteData((prev: any) => [...prev, ...noteBook])
        : setNoteData(noteBook)
    }
  }, [noteDataBookBy?.note_vw_wealth_notes_book, selectedTab])

  const refreshData = () => {
    setSelectedTab('Created By')
    getNotesCreatedBy()
    getNotesCreatedByCount()
  }

  useEffect(() => {
    getNotesRepCode({
      variables: {
        ADUserName: user.username,
      },
      fetchPolicy: 'no-cache',
    })
  }, [])

  useEffect(() => {
    resetTableFilter()
    if (selectedTab == 'By Book') {
      getNotesByBookCount()
      getNotesByBook()
    } else {
      getNotesCreatedByCount()
      getNotesCreatedBy()
    }
  }, [selectedTab])

  return (
    <Wrapper>
      <CommonTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      ></CommonTabs>
      <ContentItemWrapper>
        {noteData.length > 0 &&
          (loadingEdit ||
            loadingConvert ||
            notesLoadingCreatedBy ||
            notesLoadingBookBy) && <Loader position="absolute" />}
        <Table
          ref={tableRef}
          source={'NoteAndTaskData'}
          tableColumns={leadColumnData}
          fetchData={fetchData}
          refetchInteraction={refreshData}
          allDataCount={
            selectedTab == 'Created By'
              ? notesCreatedByCount?.note_vw_wealth_notes_createdby_aggregate
                  ?.aggregate?.count
              : notesByBookCount?.note_vw_wealth_notes_book_aggregate?.aggregate
                  ?.count
          }
          key={noteData?.length}
          isLoading={
            noteData.length === 0 &&
            (notesLoadingCreatedBy || notesLoadingBookBy)
          }
          tableProperties={{
            tableHeader: 'Notes',
            globalSearchRequired: true,
            tileFilterRequired: false,
            paginatorRequired: true,
            headerButtonRequired: true,
            buttonLabel: 'New Note',
            createCallback: () => setNewNotes(true),
          }}
          allData={noteData ?? []}
          typeArray={allTypes}
          subTypeArray={subTypes}
          repCodeList={repCodeData}
          sortingFields={[
            'partyname',
            'accountname',
            'accountnumber',
            'typedescription',
            'subtypedescription',
            'subject',
            'notedate',
            'ownername',
            'repcode',
          ]}
        />
      </ContentItemWrapper>
      {showNewNotes && (
        <CreateNoteComponent
          onTypeChange={(typeId: number) => {
            setSubTypes(getSubTypeByType(typeId))
          }}
          typeData={allTypes}
          subTypeData={subTypes}
          user={user}
          refetchContents={refreshData}
          closeNote={setNewNotes}
          hostName={appConfig.apiEndPoint}
          accessToken={accessToken}
          combinedData={[...leadsDetails, ...partyDetails]}
          validationRequired={vars.validationRequired}
        />
      )}
    </Wrapper>
  )
}

export default Notes
