export interface PartyDataProps {
  sourceId: number
  relationshipFrom: string
  relationshipTo: string
  relationshipId: number
  realtionshipFName?: string
  realtionshipTName?: string
}
export const CreateRelationshipValue: PartyDataProps = {
  sourceId: 0,
  relationshipFrom: '',
  relationshipTo: '',
  relationshipId: 0,
  realtionshipFName: '',
  realtionshipTName: '',
}
