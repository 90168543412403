import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgEdit = ({ fillColor }: ISvg) => {
  return (
    <StyledDiv>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6667 3.82666C13.6672 3.73892 13.6504 3.65194 13.6172 3.57072C13.5841 3.48949 13.5352 3.41562 13.4734 3.35332L10.6467 0.526657C10.5844 0.46487 10.5105 0.415986 10.4293 0.38281C10.3481 0.349634 10.2611 0.332817 10.1734 0.333324C10.0856 0.332817 9.99867 0.349634 9.91745 0.38281C9.83622 0.415986 9.76235 0.46487 9.70005 0.526657L7.81339 2.41332L0.526718 9.69999C0.464931 9.76228 0.416047 9.83616 0.382871 9.91739C0.349695 9.99861 0.332878 10.0856 0.333385 10.1733V13C0.333385 13.1768 0.403623 13.3464 0.528647 13.4714C0.653672 13.5964 0.823241 13.6667 1.00005 13.6667H3.82672C3.92 13.6717 4.01331 13.6571 4.1006 13.6238C4.18788 13.5905 4.26719 13.5393 4.33339 13.4733L11.5801 6.18666L13.4734 4.33332C13.5342 4.26871 13.5838 4.19435 13.6201 4.11332C13.6265 4.06018 13.6265 4.00646 13.6201 3.95332C13.6232 3.92229 13.6232 3.89102 13.6201 3.85999L13.6667 3.82666ZM3.55339 12.3333H1.66672V10.4467L8.28672 3.82666L10.1734 5.71332L3.55339 12.3333ZM11.1134 4.77332L9.22672 2.88666L10.1734 1.94666L12.0534 3.82666L11.1134 4.77332Z"
          fill={fillColor}
        />
      </svg>
    </StyledDiv>
  )
}
