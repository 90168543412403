import { MiddleContainer, TabContainer, TitleWrapper } from './styles'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import Divider from '../../../components/divider/Divider'
import LeadRelationship from '../../partyProfile/relationship/LeadRelationship'
import PermissionView from '../../../composites/permissionAndRoles/PermissionView'
import Opportunities from './Opportunities'
import AccountDetails from '../../partyProfile/ProfileTab/AccountDetails'
import Interactions from './Interactions'
import { RecordWrapper } from '../../../shared/GlobalStyle'
import Button from '../../../components/button/Button'
import { WDButton } from '../../../components/ui/WDButton'
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import PlusMenu from '../../../composites/plusMenu/PlusMenu'
import AddOpportunitiesModal from '../../../composites/opportunitiesDetails/AddOpportunitiesModal'
import UserContext from '../../../services/user/UserContext'
import {
  CreateNoteComponent,
  CreateTaskComponent,
  CreateEventComponent,
} from 'note-widget'
//} from '../../../noteWidget'
import { AuthContext } from '../../../services/AuthContext'
import {
  leadsData,
  partiesData,
  subTypeData,
  typeData,
} from '../../../reducers/leadPartySlice'
import { useAppSelector } from '../../../store/store'
import { plusInteractionsMenudata } from '../../../composites/plusMenu/PlusMenudata'
import { useLazyQuery } from '@apollo/client'
import { GET_NOTE_TASK_EVENT } from '../../../services/graphQl/CustomerGraphQl'
import { vars } from '../../../assets/utils/CommonFunctions'
import appConfig from '../../../settings/appConfig'
import EditActionContext from '../../../services/editActionsContext/EditActionsContext'
import Loader from '../../../composites/loader/Loader'
import { useSelector } from 'react-redux'
import { SubTypesDataInterface, TypesDataInterface } from '../../notes/INotes'

function ProfileTab({
  selectedTab,
  setSelectedTab,
  leadDetails,
  reference,
}: any) {
  const { user } = useContext(UserContext)
  const { accessToken, userAccessDetails } = useContext(AuthContext)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const leadsDetails = useAppSelector(leadsData)
  const partyDetails = useAppSelector(partiesData)
  const [togglePlusMenu, setTogglePlusMenu] = useState(false)
  const [selectedTranType, setSelectedTranType] = useState<number>(0)
  const [dataId, setId] = useState<number>(0)
  const [tType, setTType] = useState<number>(0)
  const [addOpportunitiesModal, setAddOpportunitiesModal] = useState(false)
  const [profileId, setProfileId] = useState<string>('')
  const [getInteractionsData, { data: allData, loading: interactionLoading }] =
    useLazyQuery<any>(GET_NOTE_TASK_EVENT)

  const tabChange = (value: string) => {
    setTogglePlusMenu(false)
    setSelectedTab(value)
  }

  const {
    editNotesProps,
    editEventsProps,
    editTaskProps,
    showNewEvents,
    showNewNotes,
    showTasks,
    setNewEvents,
    setShowTasks,
    setNewNotes,
    loadingEdit,
    loadingConvert,
    updateActionsFlow,
    taskPropsByNote,
    resetInterActions,
  } = useContext(EditActionContext)

  const fetchData = useCallback(() => {
    if (leadDetails?.id) {
      setProfileId(leadDetails?.id.toString())
      getInteractionsData({
        variables: {
          partyid: parseInt(leadDetails?.id),
        },
        fetchPolicy: 'no-cache',
      })
    }
  }, [leadDetails?.id])

  useEffect(() => {
    fetchData()
  }, [leadDetails.id])

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (!reference?.current?.contains(event.target as Node)) {
            setTogglePlusMenu(false)
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideAlerter(wrapperRef)

  const typesData = useSelector(typeData)
  const subTypesData = useSelector(subTypeData)
  const [allTypes, setAllTypes] = useState<any[]>([])
  const [subTypes, setSubTypes] = useState<any[]>([])

  const getTypeByTranType = (tranType: number) => {
    return typesData
      .filter((type: TypesDataInterface) => type.trantype === tranType)
      .map((type: TypesDataInterface) => {
        return { value: type.typeid, label: type.type }
      })
  }

  const getSubTypeByType = (typeId: number) => {
    return subTypesData
      .filter(
        (type: SubTypesDataInterface) =>
          type.typeid === typeId && type.trantype === selectedTranType
      )
      .map((type: SubTypesDataInterface) => {
        return { value: type.subtypeid, label: type.subtype }
      })
  }
  useEffect(() => {
    if (typesData) {
      setAllTypes(getTypeByTranType(selectedTranType))
    }
  }, [typesData, selectedTranType])

  const getPartyName = () => {
    let name = ''
    if (leadDetails?.type == 'Trust') {
      name = leadDetails?.trustName
    } else if (leadDetails?.type === 'Business') {
      name = leadDetails?.businessName
    } else {
      name =
        (leadDetails?.firstName?.toString() ?? '') +
        ' ' +
        (leadDetails?.middleName?.toString() ?? '') +
        ' ' +
        (leadDetails?.lastName?.toString() ?? '')
    }
    return name
  }

  useEffect(() => {
    if (showNewEvents) {
      setSelectedTranType(3)
    }
    if (showNewNotes) {
      setSelectedTranType(1)
    }
    if (showTasks) {
      setSelectedTranType(2)
    }
  }, [showNewEvents, showNewNotes, showTasks])

  const openTasktoConvert = () => {
    updateActionsFlow(dataId, 1, undefined, 'convert')
    // setShowTasks(true)
    //setNewNotes(false)
  }

  return (
    <TabContainer>
      <MiddleContainer ref={wrapperRef}>
        <button
          onClick={() => tabChange('Interactions')}
          className={
            'nav-link' + (selectedTab === 'Interactions' ? ' selected' : '')
          }
        >
          <TitleWrapper>{'Interactions'}</TitleWrapper>
        </button>

        <button
          onClick={() => tabChange('Opportunities')}
          className={
            'nav-link' + (selectedTab === 'Opportunities' ? ' selected' : '')
          }
        >
          <TitleWrapper>{'Opportunities'}</TitleWrapper>
        </button>
        {leadDetails.status === 'Client' && (
          <button
            onClick={() => tabChange('Accounts')}
            className={
              'nav-link' + (selectedTab === 'Accounts' ? ' selected' : '')
            }
          >
            <TitleWrapper>{'Accounts'}</TitleWrapper>
          </button>
        )}
        <button
          onClick={() => tabChange('Related contacts')}
          className={
            'nav-link' + (selectedTab === 'Related contacts' ? ' selected' : '')
          }
        >
          <TitleWrapper>{'Related Contacts'}</TitleWrapper>
        </button>
        {leadDetails.status !== 'Client' && (
          <button
            onClick={() => tabChange('Permissions')}
            className={
              'nav-link' + (selectedTab === 'Permissions' ? ' selected' : '')
            }
          >
            <TitleWrapper>{'Permissions'}</TitleWrapper>
          </button>
        )}
        {userAccessDetails.role === vars.adminRole &&
          selectedTab === 'Interactions' && (
            <RecordWrapper>
              <WDButton>
                <Button
                  type="button"
                  onClick={() => setTogglePlusMenu(!togglePlusMenu)}
                >
                  {'New Interactions'}
                </Button>
              </WDButton>
            </RecordWrapper>
          )}
        {userAccessDetails.role === vars.adminRole &&
          selectedTab === 'Opportunities' && (
            <RecordWrapper>
              <WDButton>
                <Button
                  type="button"
                  onClick={() => setAddOpportunitiesModal(true)}
                >
                  {'New Opportunity'}
                </Button>
              </WDButton>
            </RecordWrapper>
          )}

        {userAccessDetails.role === vars.adminRole &&
          togglePlusMenu &&
          selectedTab === 'Interactions' && (
            <PlusMenu
              plusMenuData={plusInteractionsMenudata}
              setTogglePlusMenu={setTogglePlusMenu}
              setNewNotes={() => {
                resetInterActions()
                setSelectedTranType(1)
                setNewNotes(true)
              }}
              setShowTask={() => {
                resetInterActions()
                setSelectedTranType(2)
                setShowTasks(true)
              }}
              setNewEvent={() => {
                resetInterActions()
                setSelectedTranType(3)
                setNewEvents(true)
              }}
              setThompsonSearch={() => null}
              setShowOpportunity={setAddOpportunitiesModal}
            />
          )}

        <Divider
          horizontal={true}
          thickness={'1px'}
          bgColor={DEFAULT_COLORS.Border.NeutralWeak}
        />
      </MiddleContainer>
      {selectedTab === 'Interactions' && (
        <div style={{ position: 'relative' }}>
          {(loadingEdit || loadingConvert) && <Loader position="absolute" />}
          <Interactions
            setId={(id, tranType) => {
              setId(id)
              setTType(tranType)
            }}
            allData={allData}
            interactionLoading={interactionLoading}
          />
        </div>
      )}
      {selectedTab === 'Opportunities' && (
        <Opportunities leadDetails={leadDetails} />
      )}
      {selectedTab === 'Related contacts' && (
        <LeadRelationship leadDetails={leadDetails} />
      )}
      {selectedTab === 'Permissions' && <PermissionView leadId={profileId} />}
      {selectedTab === 'Accounts' && (
        <AccountDetails leadDetails={leadDetails} />
      )}

      {showNewNotes &&
        (editNotesProps?.clients?.length ||
          editNotesProps?.accounts?.length ||
          leadDetails.id) &&
        allTypes.length > 0 && (
          <CreateNoteComponent
            onTypeChange={(typeId: number) => {
              setSubTypes(getSubTypeByType(typeId))
            }}
            typeData={allTypes}
            subTypeData={subTypes}
            noteParams={editNotesProps}
            openTask={openTasktoConvert}
            user={user}
            refetchContents={fetchData}
            clientParams={{
              clientId: +leadDetails.id,
              clientName: getPartyName(),
              repcode: leadDetails?.advisor?.toString(),
            }}
            closeNote={setNewNotes}
            hostName={appConfig.apiEndPoint}
            accessToken={accessToken}
            combinedData={[...leadsDetails, ...partyDetails]}
            validationRequired={vars.validationRequired}
          />
        )}
      {showTasks &&
        (editTaskProps?.clients?.length ||
          editTaskProps?.accounts?.length ||
          taskPropsByNote?.clients?.length ||
          taskPropsByNote?.accounts?.length ||
          leadDetails.id) && (
          <CreateTaskComponent
            onTypeChange={(typeId: number) => {
              setSubTypes(getSubTypeByType(typeId))
            }}
            typeData={allTypes}
            subTypeData={subTypes}
            taskParams={tType == 1 ? taskPropsByNote : editTaskProps}
            user={user}
            clientParams={{
              clientId: +leadDetails.id,
              clientName: getPartyName(),
              repcode: leadDetails?.advisor?.toString(),
            }}
            refetchContents={fetchData}
            closeTask={setShowTasks}
            hostName={appConfig.apiEndPoint}
            accessToken={accessToken}
            combinedData={[...leadsDetails, ...partyDetails]}
            validationRequired={vars.validationRequired}
          />
        )}
      {showNewEvents &&
        (editEventsProps?.clients?.length || leadDetails.id) && (
          <CreateEventComponent
            onTypeChange={(typeId: number) => {
              setSubTypes(getSubTypeByType(typeId))
            }}
            typeData={allTypes}
            subTypeData={subTypes}
            eventParams={editEventsProps}
            user={user}
            clientParams={{
              clientId: +leadDetails.id,
              clientName: getPartyName(),
              repcode: leadDetails?.advisor?.toString(),
            }}
            closeEvent={setNewEvents}
            refetchContents={fetchData}
            hostName={appConfig.apiEndPoint}
            accessToken={accessToken}
            combinedData={[...leadsDetails, ...partyDetails]}
            validationRequired={vars.validationRequired}
          />
        )}
      {addOpportunitiesModal && (
        <AddOpportunitiesModal
          setAddOpportunitiesModal={setAddOpportunitiesModal}
          leadPartyId={leadDetails?.id}
          clientParams={{
            id: +leadDetails?.id,
            firstname:
              leadDetails.firstName +
                ' ' +
                (leadDetails.middleName ? leadDetails.middleName + ' ' : '') +
                leadDetails.lastName ?? '',
            lastname: leadDetails.lastname,
            familyname: leadDetails.familyname,
            accounttier: leadDetails.accounttier,
            advisor: leadDetails.advisor,
            account: leadDetails.account,
            middlename: leadDetails.middlename,
          }}
        />
      )}
    </TabContainer>
  )
}
export default ProfileTab
