import Button from '../../components/button/Button'
import Divider from '../../components/divider/Divider'
import Label from '../../components/label/Label'
import { WDButtonGrayTransparent } from '../../components/ui/WDButton'
import { WDCardBlue } from '../../components/ui/WDCard'
import {
  WDLabelClient,
  WDLabelPrimaryText,
  WDLabelSemiBold,
} from '../../components/ui/WDLabel'
import { ClientSearchProps } from './IClientInfo'
import {
  ClientInfoContainer,
  MainContainer,
  DetailsWrapper,
  DetailsSubWrapper,
  ButtonWrapClient,
  ClientNameWrapper,
  IDWrapper,
  ClientIDWrapper,
  DividerWrapper,
  SvgDiv,
} from './styles'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SvgCancelSmall } from '../../components/svg/SvgCancelSmall'
import ListSearch from '../listSearch/ListSearch'
import { useAppSelector } from '../../store/store'
import { leadsData, partiesData } from '../../reducers/leadPartySlice'
import { vars } from '../../assets/utils/CommonFunctions'
import { useContext } from 'react'
import { AuthContext } from '../../services/AuthContext'

const ClientInfo = ({
  onCancel,
  updateSelectedClient,
  clientName,
  clientId,
  repCode,
  header,
  headerLabel,
  errorMsg,
}: ClientSearchProps) => {
  const { userAccessDetails } = useContext(AuthContext)
  const leadsDetails = useAppSelector(leadsData)
  const partyDetails = useAppSelector(partiesData)

  const getSelectedData = (item: any) => {
    updateSelectedClient(item)
  }

  return (
    <MainContainer errorMsg={errorMsg}>
      {!clientId ? (
        <ListSearch
          key={`search1`}
          width="100%"
          callback={getSelectedData}
          placeholder="Search Client / Contact..."
          data={[...leadsDetails, ...partyDetails]}
          isMultiple={false}
          searchFields={['firstname']}
          textField={['firstname']}
          extraDetails={['status', 'email', 'phone']}
          wrapDetails={false}
          extraDetailsClass="extraItems"
        />
      ) : (
        <ClientInfoContainer>
          <WDLabelSemiBold>
            <Label>{header ? header : headerLabel}</Label>
          </WDLabelSemiBold>
          <WDCardBlue>
            <DetailsWrapper>
              <DetailsSubWrapper>
                <ClientNameWrapper>
                  <IDWrapper>
                    <WDLabelPrimaryText>
                      <Label>{clientName}</Label>
                    </WDLabelPrimaryText>
                  </IDWrapper>
                </ClientNameWrapper>
                <DividerWrapper>
                  <Divider
                    horizontal={true}
                    bgColor={`${DEFAULT_COLORS.Border.Iron}`}
                    thickness={'0.0625rem'}
                  />
                </DividerWrapper>
                <ClientIDWrapper>
                  <IDWrapper>
                    <WDLabelClient>
                      <Label>{repCode ?? '--'}</Label>
                    </WDLabelClient>
                  </IDWrapper>
                </ClientIDWrapper>
              </DetailsSubWrapper>
              {userAccessDetails.role === vars.adminRole && <ButtonWrapClient>
                <WDButtonGrayTransparent>
                  <Button type={'button'} onClick={onCancel}>
                    <SvgDiv>
                      <SvgCancelSmall
                        fillColor={DEFAULT_COLORS.Text.NeutralWeak}
                      />
                    </SvgDiv>
                  </Button>
                </WDButtonGrayTransparent>
              </ButtonWrapClient>}
            </DetailsWrapper>
          </WDCardBlue>
        </ClientInfoContainer>
      )}
    </MainContainer>
  )
}

export default ClientInfo
