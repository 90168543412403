import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES, TextWeight, TextSizes } from '../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`
export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
`
export const WDLabelAttachment = styled.div`
  font-size: ${SIZES[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  padding: ${SIZES[1]} ${SIZES[0]};
  border-radius: ${SIZES[0]};
`
export const WDButtonGrayTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${SIZES[0]};
  button {
    border: none;
    background: none;
    color: ${DEFAULT_COLORS.Text.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: ${TextSizes[3]};
    font-weight: ${TextWeight[3]};
    cursor: pointer;
  }
`
export const FileViewWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  grid-template-columns: repeat(4, 0.25fr);
  border-radius: ${SIZES[1]};
`
export const DownloadFileWrapper = styled.div`
  display: flex;
  overflow-wrap: normal;
  gap: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
`
export const DownloadFileCallingApiWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  border: 2px dashed ${() => DEFAULT_COLORS.Border.PrimaryStrong};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
`
export const FileNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 80%;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const MainWrapper = styled.div`
  background: rgb(239 246 255);
  border: 1px solid #bbd9ff;
  border-radius: 5px;
`
export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 20%;
  width: 100%;
  justify-content: flex-start;
`
