import { gql } from '@apollo/client'

export const ADD_CONTACTS_BY_ID = gql`
  query ADD_CONTACTS_BY_ID($contactId: Int!) {
    customer_vw_contacts(where: { contactid: { _eq: $contactId } }) {
      city
      crossstreets
      countryname
      countryid
      customername
      fullname
      contactid
      honorific
      firstname
      middle
      lastname
      suffix
      gender
      jobtitle
      primaryemail
      telnumber
      faxnumber
      address1
      address2
      state
      zipcode
      customerid
      createddate
      updateddate
    }
  }
`

export const GET_All_OPPORTUNITIES_LIST_COUNT = gql`
  query GET_OPPORTUNITIES_LIST_COUNT(
    $payload: opportunities_opportunities_bool_exp!
  ) {
    opportunities_opportunities_aggregate(where: $payload) {
      aggregate {
        count
      }
    }
  }
`

export const GET_All_OPPORTUNITIES_LIST = gql`
  query GET_All_OPPORTUNITIES_LIST(
    $payload: opportunities_opportunities_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    opportunities_opportunities(
      limit: $limit
      offset: $offset
      where: $payload
      order_by: { id: asc }
    ) {
      id
      repcode
      contactid
      type
      stage
      name
      assignedtoid
      assignedtoname
      nextsteps
      probablity
      amount
      actualrevenue
      createdby
      createddate
      isparty
      projectedrevenue
      source
      updatedby
      updateddate
      contactname
      description
      products
      closedate
    }
  }
`
export const GET_All_OPPORTUNITIES_BY_BOOK_COUNT = gql`
  query GET_All_OPPORTUNITIES_BY_BOOK_COUNT(
    $payload: opportunities_vw_opportunities_bool_exp!
  ) {
    opportunities_vw_opportunities_aggregate(where: $payload) {
      aggregate {
        count
      }
    }
  }
`
export const GET_OPPORTUNITIES_LIST_BY_BOOK = gql`
  query GET_OPPORTUNITIES_LIST_BY_BOOK(
    $payload: opportunities_vw_opportunities_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    opportunities_vw_opportunities(
      limit: $limit
      offset: $offset
      where: $payload
      order_by: { id: asc }
    ) {
      amount
      assignedtoname
      closedate
      contactname
      contactid
      createdby
      id
      name
      nextsteps
      probablity
      repcode
      source
      stage
      updateddate
    }
  }
`
export const GET_OPPORTUNITIES_REPCODE_LIST = gql`
  query GET_OPPORTUNITIES_REPCODE_LIST($adId: String!) {
    opportunities_vw_opportunities(
      distinct_on: repcode
      where: { ADUserName: { _iregex: $adId } }
    ) {
      repcode
    }
    opportunities_opportunities(
      distinct_on: repcode
      where: { createdby: { _iregex: $adId } }
    ) {
      repcode
    }
  }
`

export const GET_OPPORTUNITIES_LIST_FILTER_DATA = gql`
  query GET_OPPORTUNITIES_LIST_FILTER_DATA(
    $limit: Int!
    $offset: Int!
    $stage: Int
    $repCodes: [String!]!
  ) {
    opportunities_opportunities(
      limit: $limit
      offset: $offset
      order_by: { id: asc }
      where: {
        _and: [
          { stage: { _eq: $stage } }
          {
            Opportunities_Permissions: {
              _or: [{ repcode: { _in: $repCodes } }, { type: { _eq: "All" } }]
            }
          }
        ]
      }
    ) {
      id
      contactid
      type
      stage
      name
      assignedtoid
      assignedtoname
      nextsteps
      probablity
      amount
      actualrevenue
      createdby
      createddate
      isparty
      projectedrevenue
      source
      updatedby
      updateddate
      contactname
      description
      products
      Opportunities_Permissions {
        id
        opportunityid
        type
        repcode
        createdon
        createdby
        updatedon
        updatedby
        status
      }
    }
  }
`
export const GET_OPPORTUNITIES_LIST_DATA = gql`
  query GET_OPPORTUNITIES_LIST_DATA($stage: Int) {
    opportunities_opportunities(
      order_by: { id: asc }
      where: { stage: { _eq: $stage } }
    ) {
      id
      contactid
      type
      stage
      name
      assignedtoid
      assignedtoname
      nextsteps
      probablity
      amount
      actualrevenue
      createdby
      createddate
      isparty
      projectedrevenue
      source
      updatedby
      updateddate
      contactname
      description
      products
      closedate
    }
  }
`

export const GET_OPPORTUNITIES_CALCULATION = gql`
  query GET_OPPORTUNITIES_CALCULATION($adId: String!) {
    opportunities_vw_oppscalculation(where: { createdby: { _iregex: $adId } }) {
      closedlost
      closedwon
      countclosedlost
      countclosedwon
      openops
      potentialrevenue
    }
  }
`
export const GET_OPPORTUNITIES_CALCULATION_BY_Book = gql`
  query GET_OPPORTUNITIES_CALCULATION($adId: String!) {
    opportunities_vw_opportunitiesbybook(
      where: { ADUserName: { _iregex: $adId } }
    ) {
      closedlost
      closedwon
      countclosedlost
      countclosedwon
      openops
      potentialrevenue
      ADUserName
    }
  }
`
export const GET_LEAD_PARTY_RELATIONSHIP = gql`
  query GET_LEAD_PARTY_RELATIONSHIP(
    $sourceId: bigint!
    $sourceRelationshipto: String!
  ) {
    lead_partyrelationship(
      where: {
        _or: [
          { relationshipto: { _eq: $sourceRelationshipto } }
          { relationshipfrom: { _eq: $sourceRelationshipto } }
          { sourceid: { _eq: $sourceId } }
        ]
      }
    ) {
      sourceid
      relationshipto
      relationshipid
      relationshipfromname
      relationshipfrom
      realtionshiptoname
      realtionshipname
      isparty
      id
    }
  }
`

export const GET_OPPORTUNITIES_DATA_HEADER = gql`
  query GET_OPPORTUNITIES_DATA_HEADER($adUserName: String!) {
    opportunities_opportunities(
      order_by: { id: desc }
      limit: 1
      where: { createdby: { _iregex: $adUserName } }
    ) {
      id
      name
      createddate
    }
  }
`
export const GET_OPPORTUNITIES_HISTORY_DATA = gql`
  query GET_OPPORTUNITIES_HISTORY_DATA($opportunityid: bigint) {
    opportunities_history(
      where: { opportunityid: { _eq: $opportunityid } }
      order_by: { id: desc }
    ) {
      id
      description
      opportunityid
      updateddate
      #updatedby
    }
  }
`
export const GET_CLIENT_DATA = gql`
  query GET_CLIENT_DATA($queryString: String!, $payload: PayloadModel!) {
    lead_leads(
      distinct_on: firstname
      where: { firstname: { _iregex: $queryString } }
    ) {
      firstname
      lastname
      id
      familyname
      accounttier
      account
      advisor
      middlename
      emailList {
        emailid
      }
      contactList {
        phonenumber
      }
      addresslist {
        addressline1
      }
    }
  }
`
export const INSERT_OPPORTUNITIES_DATA = gql`
  mutation INSERT_OPPORTUNITIES_DATA(
    $contactid: bigint!
    $contactname: String!
    $name: String!
    $type: Int!
    $description: String!
    $timezone: String!
    $stage: Int!
    $createddate: timestamptz
    $createdby: String!
    $repcode: String!
  ) {
    insert_opportunities_opportunities(
      objects: {
        contactid: $contactid
        contactname: $contactname
        name: $name
        timezone: $timezone
        type: $type
        description: $description
        createddate: $createddate
        stage: $stage
        createdby: $createdby
        repcode: $repcode
      }
    ) {
      affected_rows
      returning {
        id
        name
        products
        description
        type
        contactid
        contactname
      }
    }
  }
`
export const INSERT_PARTY_RELATIONSHIP = gql`
  mutation INSERT_PARTY_RELATIONSHIP(
    $payload: [lead_partyrelationship_insert_input!]!
  ) {
    insert_lead_partyrelationship(objects: $payload) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UPDATE_PARTY_RELATIONSHIP = gql`
  mutation UPDATE_PARTY_RELATIONSHIP(
    $id: bigint
    $relationshipfrom: String
    $relationshipto: String
    $sourceid: bigint
    $relationshipid: Int
    $realtionshipname: String
    $relationshipfromname: String
    $realtionshiptoname: String
    $isparty: Boolean
  ) {
    update_lead_partyrelationship(
      _set: {
        relationshipfrom: $relationshipfrom
        relationshipto: $relationshipto
        sourceid: $sourceid
        relationshipid: $relationshipid
        realtionshipname: $realtionshipname
        relationshipfromname: $relationshipfromname
        realtionshiptoname: $realtionshiptoname
        isparty: $isparty
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const DELETE_PARTY_RELATIONSHIP = gql`
  mutation DELETE_PARTY_RELATIONSHIP($id: bigint) {
    delete_lead_partyrelationship(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UPDATE_CONTACT_DETAILS = gql`
  mutation UPDATE_CONTACT_DETAILS(
    $businessName: String!
    $status: String!
    $accountTier: String!
    $leadSource: String!
    $dateOfBirth: timestamp!
    $clientTerminationDate: timestamp!
    $taxIdNumber: Int!
    $gender: String!
    $maritalStatus: String!
    $createdBy: String!
    $id: bigint
  ) {
    update_lead_leads(
      _set: {
        businessname: $businessName
        status: $status
        accounttier: $accountTier
        leadsource: $leadSource
        dateofbirth: $dateOfBirth
        clientterminationdate: $clientTerminationDate
        taxidnumber: $taxIdNumber
        gender: $gender
        maritalstatus: $maritalStatus
        createdby: $createdBy
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const UPDATE_INDIVIDUAL_DATA = gql`
  mutation UPDATE_INDIVIDUAL_DATA(
    $honorific: String!
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $suffix: String!
    $nickName: String!
    $jobTitle: String!
    $pronouciation: String!
    $account: String!
    $id: bigint
  ) {
    update_lead_leads(
      _set: {
        honorific: $honorific
        firstname: $firstName
        middlename: $middleName
        lastname: $lastName
        suffix: $suffix
        nickname: $nickName
        jobtitle: $jobTitle
        pronunciations: $pronouciation
        account: $account
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_OPPORTUNITIES_DATA = gql`
  mutation UPDATE_OPPORTUNITIES_DATA(
    $opportunitiesList: opportunities_opportunities_set_input!
    $id: bigint
  ) {
    update_opportunities_opportunities(
      _set: $opportunitiesList
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        name
      }
      affected_rows
    }
  }
`
export const INSERT_OPPORTUNITIES_HISTORY_DATA = gql`
  mutation INSERT_OPPORTUNITIES_HISTORY_DATA(
    $opportunityid: bigint!
    $updateddate: timestamptz
    $description: String!
  ) {
    insert_opportunities_history(
      objects: {
        opportunityid: $opportunityid
        description: $description
        updateddate: $updateddate
      }
    ) {
      affected_rows
      returning {
        description
        id
        opportunityid
        updateddate
      }
    }
  }
`

export const INSERT_OPPORTUNITIES_PERMISSIONS = gql`
  mutation createOpportunitiesPermission(
    $payload: [opportunities_permissions_insert_input!]!
  ) {
    insert_opportunities_permissions(objects: $payload) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const GET_OPPORTUNITIES_PERMISSIONS = gql`
  query getOpportunitiesPermission($id: bigint!) {
    opportunities_permissions(
      distinct_on: repcode
      where: { opportunityid: { _eq: $id } }
    ) {
      id
      type
      repcode
    }
  }
`

export const GET_EDIT_OPPORTUNITIES_DATA = gql`
  query GET_EDIT_OPPORTUNITIES_DATA($id: bigint!) {
    opportunities_opportunities(where: { id: { _eq: $id } }) {
      id
      contactid
      repcode
      type
      stage
      name
      assignedtoid
      assignedtoname
      nextsteps
      probablity
      amount
      actualrevenue
      createdby
      createddate
      isparty
      projectedrevenue
      source
      updatedby
      updateddate
      contactname
      description
      products
      closedate
    }
  }
`
export const GET_OWNER_FILTER_OPTIONS = gql`
  query GET_OWNER_FILTER_OPTIONS($queryString: String!) {
    associate_associate(
      distinct_on: firstname
      limit: 10
      where: { firstname: { _iregex: $queryString } }
    ) {
      associateid
      firstname
      lastname
      middlename
    }
  }
`
export const GET_OWNER_NAME = gql`
  query GET_OWNER_NAME($queryString: Int!) {
    associate_associate(where: { associateid: { _eq: $queryString } }) {
      associateid
      firstname
      lastname
      middlename
    }
  }
`
export const GET_NOTE_TASK_EVENT = gql`
  query GET_NOTE_TASK_EVENT($partyid: bigint!) {
    note_vw_wealth_notes_client(where: { partyid: { _eq: $partyid } }) {
      id
      createdby
      createdon
      detail
      ownerid
      ownername
      partyid
      partyname
      subject
      trantype
    }
  }
`
export const GET_NOTE_VW_WEALTH_NOTES = gql`
  query GET_NOTE_VW_WEALTH_NOTES($partyid: String) {
    note_vw_wealth_notes(
      where: { partyid: { _eq: $partyid } }
      order_by: { updatedon: desc }
    ) {
      id
      accountname
      accountnumber
      acctnum
      activeflag
      createdby
      createdon
      detail
      isparty
      legacytextid
      notedate
      notestatusid
      ownerid
      partyid
      partyname
      qccflag
      subject
      subtypeid
      subtypedescription
      text
      textid
      tranmappingid
      trantype
      typedescription
      typeid
      updatedby
      updatedon
    }
  }
`
export const GET_NOTE_VW_WEALTH_OPPORTUNITIES = gql`
  query GET_OPPORTUNITIES_LIST_DATA($partyid: bigint!) {
    opportunities_opportunities(where: { contactid: { _eq: $partyid } }) {
      id
      contactid
      type
      stage
      name
      assignedtoid
      assignedtoname
      nextsteps
      probablity
      amount
      actualrevenue
      createdby
      createddate
      isparty
      projectedrevenue
      source
      updatedby
      updateddate
      contactname
      description
      products
      closedate
    }
  }
`
export const GET_NOTE_VW_WEALTH_TASK_DETAILS = gql`
  query GET_NOTE_VW_WEALTH_TASK_DETAILS($partyid: String) {
    note_vw_wealth_task_createdby(
      where: { partyid: { _eq: $partyid } }
      order_by: { updatedon: desc }
    ) {
      accountname
      accountnumber
      createdby
      createdon
      id
      ownerid
      partyid
      partyname
      qccflag
      subject
      subtypedescription
      subtypeid
      tranmappingid
      typedescription
      typeid
      updatedby
      updatedon
    }
  }
`
export const GET_NOTE_VW_WEALTH_MEETING = gql`
  query note_vw_wealth_events_createdby($partyid: String) {
    note_vw_wealth_events_createdby(
      where: { partyid: { _eq: $partyid } }
      order_by: { updatedon: desc }
    ) {
      accountname
      accountnumber
      acctnum
      activeflag
      createdby
      createdon
      detail
      endtime
      id
      isparty
      legacytextid
      location
      meetingstatusid
      ownerid
      partyid
      partyname
      postmeetingcomment
      premeetingcomment
      qccflag
      starttime
      subject
      subtypedescription
      subtypeid
      text
      textid
      trantype
      tranmappingid
      typedescription
      typeid
      updatedby
      updatedon
    }
  }
`
export const GET_NOTES_BY_OPP = gql`
  query GET_NOTES(
    $limit: Int!
    $offset: Int!
    $userName: String!
    $oppid: Int!
  ) {
    note_vw_wealth_notes_createdby(
      limit: $limit
      offset: $offset
      where: { ownerid: { _iregex: $userName }, opportunityid: { _eq: $oppid } }
      distinct_on: id
      order_by: { id: desc }
    ) {
      accountname
      accountnumber
      createdby
      createdon
      detail
      id
      notedate
      opportunityid
      opportunityname
      ownerid
      ownername
      partyid
      partyname
      qccflag
      repcode
      subject
      subtypedescription
      subtypeid
      tranmappingid
      trantype
      typedescription
      typeid
      updatedby
      updatedon
    }
  }
`
export const GET_DASHBOARD_NOTES = gql`
  query GET_NOTES_CREATEDBY($limit: Int, $offset: Int, $userName: String!) {
    note_vw_wealth_notes_createdby(
      limit: $limit
      offset: $offset
      where: { ownerid: { _iregex: $userName } }
      order_by: { createdon: desc }
    ) {
      id
      ownerid
      ownername
      partyid
      partyname
      accountnumber
      subject
    }
  }
`

export const GET_NOTES_CREATEDBY_COUNT = gql`
  query GET_NOTES_CREATEDBY_COUNT(
    $payload: note_vw_wealth_notes_createdby_bool_exp!
  ) {
    note_vw_wealth_notes_createdby_aggregate(where: $payload) {
      aggregate {
        count
      }
    }
  }
`

export const GET_NOTES_CREATEDBY = gql`
  query GET_NOTES_CREATEDBY(
    $payload: note_vw_wealth_notes_createdby_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    note_vw_wealth_notes_createdby(
      where: $payload
      limit: $limit
      offset: $offset
      order_by: { createdon: desc }
    ) {
      id
      accountname
      accountnumber
      notedate
      ownerid
      ownername
      partyid
      partyname
      subject
      typeid
      subtypeid
      typedescription
      subtypedescription
      repcode
    }
  }
`

export const GET_NOTES_BOOK_COUNT = gql`
  query GET_NOTES_BOOK_COUNT($payload: note_vw_wealth_notes_book_bool_exp!) {
    note_vw_wealth_notes_book_aggregate(where: $payload) {
      aggregate {
        count
      }
    }
  }
`

export const GET_NOTES_BOOK = gql`
  query GET_NOTES_BOOK(
    $payload: note_vw_wealth_notes_book_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    note_vw_wealth_notes_book(
      where: $payload
      limit: $limit
      offset: $offset
      order_by: { createdon: desc }
    ) {
      id
      accountname
      accountnumber
      notedate
      ownerid
      ownername
      notepartyid
      partyname
      subject
      typeid
      subtypeid
      typedescription
      subtypedescription
      repcode
    }
  }
`

export const GET_NOTES_COUNT = gql`
  query GET_NOTES_COUNT($userName: String!) {
    note_vw_wealth_notes_aggregate(
      where: {
        _and: [{ activeflag: { _neq: 0 } }, { ownerid: { _iregex: $userName } }]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_DASHBOARD_TASKS = gql`
  query GetTasks($limit: Int, $offset: Int, $userName: String!) {
    note_vw_wealth_task_createdby(
      limit: $limit
      offset: $offset
      where: {
        _or: [
          { ownerid: { _iregex: $userName } }
          { assignedto: { _iregex: $userName } }
        ]
      }
      order_by: { createdon: desc }
    ) {
      id
      ownername
      partyid
      partyname
      accountnumber
      subject
      subtypedescription
      typedescription
      duedate
    }
  }
`
export const GET_NOTES_REPCODE_LIST = gql`
  query GetNotesRepcodeList($ADUserName: String!) {
    note_vw_wealth_notes_createdby(
      where: { ownerid: { _iregex: $ADUserName } }
      distinct_on: repcode
    ) {
      repcode
    }
    note_vw_wealth_notes_book(
      where: {
        _or: [
          { accountad: { _eq: $ADUserName } }
          { partyad: { _eq: $ADUserName } }
        ]
        trantype: { _eq: 1 }
      }
    ) {
      repcode
    }
  }
`
export const GET_TASKS_COUNT = gql`
  query GET_TASKS_COUNT($payload: note_vw_wealth_task_createdby_bool_exp!) {
    note_vw_wealth_task_createdby_aggregate(where: $payload) {
      aggregate {
        count
      }
    }
  }
`
export const GET_EVENTS_COUNTS = gql`
  query GET_EVENTS_COUNTS($payload: note_vw_wealth_events_createdby_bool_exp!) {
    note_vw_wealth_events_createdby_aggregate(where: $payload) {
      aggregate {
        count
      }
    }
  }
`
export const GET_TASKS = gql`
  query GetTasks(
    $payload: note_vw_wealth_task_createdby_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    note_vw_wealth_task_createdby(
      where: $payload
      limit: $limit
      offset: $offset
      order_by: { createdon: desc }
    ) {
      accountname
      accountnumber
      duedate
      id
      ownerid
      ownername
      partyid
      partyname
      repcode
      status
      subject
      typeid
      subtypeid
      subtypedescription
      typedescription
    }
  }
`
export const GET_TASKS_REPCODE_LIST = gql`
  query GetTasksRepcodeList($ADUserName: String!) {
    note_vw_wealth_task_createdby(
      where: {
        _or: [
          { ownerid: { _iregex: $ADUserName } }
          { assignedto: { _iregex: $ADUserName } }
        ]
      }
      distinct_on: repcode
    ) {
      repcode
    }
  }
`

export const GET_DASHBOARD_EVENTS = gql`
  query note_vw_wealth_events_createdby(
    $limit: Int
    $offset: Int
    $userName: String!
  ) {
    note_vw_wealth_events_createdby(
      limit: $limit
      offset: $offset
      where: { ownerid: { _iregex: $userName } }
      order_by: { starttime: desc }
    ) {
      id
      ownerid
      ownername
      partyid
      partyname
      startdate
      starttime
      enddate
      endtime
      subject
      typedescription
      subtypedescription
      timezone
    }
  }
`

export const GET_EVENTS = gql`
  query note_vw_wealth_events_createdby(
    $payload: note_vw_wealth_events_createdby_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    note_vw_wealth_events_createdby(
      where: $payload
      limit: $limit
      offset: $offset
      order_by: { starttime: desc }
    ) {
      id
      accountname
      accountnumber
      startdate
      starttime
      enddate
      endtime
      location
      meetingstatusid
      ownerid
      ownername
      partyid
      partyname
      repcode
      subject
      typeid
      subtypeid
      typedescription
      subtypedescription
      meetingid
      timezone
    }
  }
`
export const GET_EVENTS_REPCODE_LIST = gql`
  query GetEventsRepcodeList($ADUserName: String!) {
    note_vw_wealth_events_createdby(
      where: { ownerid: { _iregex: $ADUserName } }
      distinct_on: repcode
    ) {
      repcode
    }
  }
`

export const GET_LEADS_NOT_CONTACTED = gql`
  query MyQuery($userName: String!) {
    advisor_vw_leadpartyvisibility(
      where: { ADUserName: { _iregex: $userName } }
    ) {
      partyid
      leadNotContacted {
        lastcontactdate
      }
      lead_details {
        firstname
        emailList {
          id
          emailid
        }
        lastname
        middlename
        businessname
        trustname
        id
        status
        type
        contactList {
          id
          phonenumber
        }
      }
      party_details {
        PartyFullName
        PartyEmail
        PartyContact
        PartyTypeName
      }
    }
  }
`

export const UPDATE_EVENT_STATUS = gql`
  mutation UPDATE_EVENT_STATUS($statusId: Int!, $tranId: bigint!) {
    update_note_meeting(
      _set: { meetingstatusid: $statusId }
      where: { noteid: { _eq: $tranId } }
    ) {
      returning {
        noteid
      }
      affected_rows
    }
  }
`
export const UPDATE_TASK_STATUS = gql`
  mutation UPDATE_TASK_STATUS($statusId: Int!, $tranId: bigint!) {
    update_note_task(
      _set: { status: $statusId }
      where: { noteid: { _eq: $tranId } }
    ) {
      returning {
        noteid
      }
      affected_rows
    }
  }
`

export const GET_CONTACTED_DATES = gql`
  query GET_CONTACTED_DATES($_eq: bigint) {
    note_leadpartylastcontact(where: { leadpartyid: { _eq: $_eq } }) {
      lastcontactdate
      leadpartyid
      nextactivitydate
      pastdueactivitydate
    }
  }
`

export const GET_EVENTS_COUNT = gql`
  query GET_EVENTS_COUNT($userName: String!) {
    note_vw_wealth_meeting_aggregate(
      where: { ownerid: { _iregex: $userName } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const SEARCH_LEADS = gql`
  query GET_LEADS($lead: String!) {
    lead_leads(where: { firstname: { _ilike: $lead } }) {
      account
      firstname
      id
      lastname
      middlename
      partyid
    }
  }
`
export const GET_LEAD_RELATIONSHIP = gql`
  query lead_relationship {
    lead_relationship {
      id
      relationship
    }
  }
`
export const GET_PARTY_RELATIONSHIP = gql`
  query GET_PARTY_RELATIONSHIP($relationshipfrom: String!) {
    lead_partyrelationship(
      where: { relationshipfrom: { _eq: $relationshipfrom } }
    ) {
      relationship_name {
        id
        relationship
      }
      relationship_from {
        PartyId
        PartyFullName
      }
      relationship_to {
        PartyId
        PartyFullName
      }
      id
      relationshipfrom
      relationshipid
      relationshipto
      sourceid
    }
  }
`

export const UPDATE_PARTY = gql`
  mutation updateParty(
    $partyId: bigint!
    $createdOn: date
    $createdBy: String
    $honorific: String
    $firstName: String
    $middleName: String
    $lastName: String
    $suffix: String
    $pronouns: String
    $nickname: String
    $pronunciations: String
    $jobTitle: String
    $familyName: String
    $gender: String
    $interestHobbies: String
    $notes: String
    $owner: String
    $pets: String
    $riskTolerance: String
    $timeHorizon: String
    $status: String
    $referredBy: String
    $potentialSizeOfAum: Int
    $taxIdNumber: Int
    $leadSource: String
    $maritalStatus: String
    $objective: String
    $accountTier: String
    $clientCategory: String
    $clientSince: String
    $advisor: String
    $dateOfBirth: timestamp
    $currentAdvisoryFirms: String
    $dateOfMarriage: timestamp
    $account: String
    $houseHoldMembers: String
    $clientTerminationDate: timestamp
    $financialInterests: String
    $experienceFunds: Int
    $experienceStocks: Int
    $experiencePartnerships: Int
    $experienceOthers: Int
    $totalAssets: Int
    $nonLiquidAssets: Int
    $netWorth: bigint
    $netWorthTotalLiabilities: Int
    $netWorthLiquidLiabilities: Int
    $liabilities: Int
    $taxyear: Int
    $estimatedtaxes: Int
    $estimatedtaxbracket: Int
    $confirmedbytaxreturn: Boolean
    $adjustedgrossincome: Int
  ) {
    update_lead_leads(
      _set: {
        createdon: $createdOn
        createdby: $createdBy
        honorific: $honorific
        firstname: $firstName
        middlename: $middleName
        lastname: $lastName
        suffix: $suffix
        pronouns: $pronouns
        nickname: $nickname
        pronunciations: $pronunciations
        jobtitle: $jobTitle
        familyname: $familyName
        gender: $gender
        interesthobbies: $interestHobbies
        notes: $notes
        owner: $owner
        pets: $pets
        risktolerance: $riskTolerance
        timehorizon: $timeHorizon
        status: $status
        referredby: $referredBy
        potentialsizeofaum: $potentialSizeOfAum
        taxidnumber: $taxIdNumber
        leadsource: $leadSource
        maritalstatus: $maritalStatus
        objective: $objective
        accounttier: $accountTier
        clientcategory: $clientCategory
        clientsince: $clientSince
        advisor: $advisor
        dateofbirth: $dateOfBirth
        currentadvisoryfirms: $currentAdvisoryFirms
        dateofmarriage: $dateOfMarriage
        account: $account
        householdmembers: $houseHoldMembers
        clientterminationdate: $clientTerminationDate
        financialinterests: $financialInterests
        experiencefunds: $experienceFunds
        experiencestocks: $experienceStocks
        experiencepartnership: $experiencePartnerships
        experienceothers: $experienceOthers
        totalassets: $totalAssets
        nonliquidassets: $nonLiquidAssets
        networth: $netWorth
        networthtotalliabilities: $netWorthTotalLiabilities
        networthliquidliabilities: $netWorthLiquidLiabilities
        liabilities: $liabilities
        taxyear: $taxyear
        estimatedtaxes: $estimatedtaxes
        estimatedtaxbracket: $estimatedtaxbracket
        confirmedbytaxreturn: $confirmedbytaxreturn
        adjustedgrossincome: $adjustedgrossincome
      }
      where: { partyid: { _eq: $partyId } }
    ) {
      affected_rows
      returning {
        partyid
      }
    }
  }
`

export const GET_PARTY_DETAILS = gql`
  query GetPartyDetails($partyId: bigint!) {
    lead_leads(where: { partyid: { _eq: $partyId } }) {
      partyid
      firstname
      middlename
      lastname
      account
      clientcategory
      createdon
      createdby
      honorific
      suffix
      pronouns
      nickname
      pronunciations
      jobtitle
      familyname
      gender
      interesthobbies
      notes
      owner
      pets
      risktolerance
      timehorizon
      status
      referredby
      potentialsizeofaum
      taxidnumber
      leadsource
      maritalstatus
      objective
      accounttier
      clientsince
      advisor
      dateofbirth
      currentadvisoryfirms
      dateofmarriage
      householdmembers
      clientterminationdate
      financialinterests
      experiencefunds
      experiencestocks
      experiencepartnership
      experienceothers
      totalassets
      nonliquidassets
      networth
      networthtotalliabilities
      networthliquidliabilities
      liabilities
    }
  }
`
export const GET_LEAD_PARTY_CONTACTED = gql`
  query getMonth($adUserName: String!) {
    note_vw_partyleadscontactbymonth(
      where: { adusername: { _iregex: $adUserName } }
    ) {
      count
      mth
    }
  }
`

export const GET_MONTH_WISE_TRANSACTION_COUNT = gql`
  query GET_MONTH_WISE_TRANSACTION_COUNT {
    note_vw_monthwisetransactionscount {
      createdon
      Contacts: contacts
    }
  }
`
export const GET_ACCOUNT_TIER_WISE_COUNT = gql`
  query GET_ACCOUNT_TIER_WISE_COUNT {
    note_vw_accounttierwisecount {
      accounttier
      sum
    }
  }
`

export const GET_BIRTHDAYS = gql`
  query GetBirthDays($adUserName: String!) {
    lead_vw_upcomingbirthdaylist(where: { adusername: { _eq: $adUserName } }) {
      name
      to_char
      partyid
    }
  }
`

export const GET_TYPES = gql`
  query GetTypes {
    note_vw_type_reference {
      typeid
      trantype
      type
    }
  }
`
export const GET_SUBTYPES = gql`
  query GetSubTypes {
    note_vw_reference_subtype {
      typeid
      subtypeid
      trantype
      subtype
    }
  }
`
