import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 50%;
  justify-content: center;
  align-self: center;
  padding: 1rem 2rem;
  background-color: ${DEFAULT_COLORS.Background.Neutral};
  /* border-radius: 0.25rem; */
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${DEFAULT_COLORS.BoxShadow.BackgroundShadow};
  z-index: 999;
`
export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props: { padding?: string }) => {
    props.padding ?? '1rem 0'
  }};
  gap: 1rem;
`
export const CardContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`
export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`
export const FooterSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`
export const FooterSectionEndWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
`
export const ContentFormWrapper = styled.div`
  display: flex;
  padding-top: 1rem;
  flex-direction: ${(props: { direction: string }) => props.direction ?? 'column'};
  justify-content: start;
  width: 100%;
  align-items: ${(props: { align: string }) => props.align ?? ''};
  gap: 1rem;
`
export const ContentFormSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`
export const SelectBoxSmall = styled.div`
  width: 100%;
  > div:first-child {
    padding: 0 !important;
  }
`
export const ContactLeftSectionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 20%;
`
export const ContactLeftSectionIconWrapper = styled.div`
  display: flex;
`
export const ContactLeftSectionTextWrapper = styled.div`
  display: flex;
`
export const ContactCenterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`
export const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Primary};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Neutral};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const SelectItem = styled.div`
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
`
