import styled from 'styled-components'

interface CardProps {
  children?: React.ReactNode
}

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
`
export const StyledColumnWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
`
export const StyledColumnWrapperCompany = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`
export const StyledContactInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`

export const ButtonWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 0;
`

export const ButtonWrapperTwoSide = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  color: #fff;
`
export const ButtonWrapperLeft = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  justify-content: flex-start;
  align-items: center;
`
export const ButtonWrapperRight = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`

export const WDWrapper = ({ children }: CardProps) => {
  return <StyledWrapper>{children}</StyledWrapper>
}
export const WDColumnWrapper = ({ children }: CardProps) => {
  return <StyledColumnWrapper>{children}</StyledColumnWrapper>
}
export const WDCompanyWrapper = ({ children }: CardProps) => {
  return <StyledColumnWrapperCompany>{children}</StyledColumnWrapperCompany>
}
export const WDContactInfoWrapper = ({ children }: CardProps) => {
  return <StyledContactInfoWrapper>{children}</StyledContactInfoWrapper>
}
