import { useLazyQuery, useMutation } from '@apollo/client'
import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { vars } from '../../assets/utils/CommonFunctions'
import { AddDays } from '../../components/customCalendar/Util'
import Label from '../../components/label/Label'
import { SvgCrown } from '../../components/svg/SvgCrown'
import {
  WDLabelNickName,
  WDLabelPrimaryHeading,
  WDLabelWeak,
} from '../../components/ui/WDLabel'
import {
  ClientAddressPayloadInterface,
  ShippingAddressInterface,
  ShippingAddressPayloadInterface,
} from '../../composites/addressInformation/IAdressInformation'
import {
  ClientEmailInformationPayloadInterface,
  EmailInformationInterface,
  EmailInformationPayloadInterface,
} from '../../composites/emailInformation/IEmailInformation'
import GraphQLLoader from '../../composites/loader/GraphQLLoader'
import {
  ClientPhonePayloadInterface,
  PhoneInterface,
  PhonePayloadInterface,
} from '../../composites/phoneInformation/IPhoneInformation'
import { partiesData } from '../../reducers/leadPartySlice'
import {
  CREATE_PARTY_DETAILS,
  GET_INDIVIDUAL_PARTY_DETAILS,
  GET_LEAD_DETAILS,
  GET_PARTY_ADDITIONAL_DETAILS,
  GET_PARTY_CONTACT_DETAILS,
  GET_PARTY_INVESTMENTS_DETAILS,
  UPDATE_LEAD,
  UPDATE_PARTY_DETAILS,
} from '../../services/graphQl/LeadsGraphQL'
import ToastContext from '../../services/toast/ToastContext'
import { useAppSelector } from '../../store/store'
import { DEFAULT_COLORS } from '../../theme/Colors'
import {
  LeadGraphQLResponseInterface,
  LeadInfoPayloadType,
  LeadInfoProps,
  LeadInfoResponseInterface,
} from '../createContactList/ICreateLead'
import ProfileSubTab from '../partyProfile/ProfileTab/ProfileSubTab'
import { LeadContactCard } from '../partyProfile/overviewSection/LeadContactCard'
import { LeadContactSection } from '../partyProfile/overviewSection/LeadContactSection'
import {
  CardOverview,
  ContactDetailsWrapper,
  CrownWrapper,
  ProfileDesignationWrapper,
  ProfileTabWrapper,
  TitleTextWrapper,
  UserProfileWrapper,
  WrapperOne,
} from '../partyProfile/styles'
import {
  businessValidationKeys,
  individualValidationKeys,
  trustValidationKeys,
} from '../utils/Util'
import ProfileTab from './ProfileTab/ProfileTab'
import {
  DetailsWrapper,
  LeftUserProfile,
  LineHeightWrapper,
  OverviewWrapper,
  ProfileNameWrapper,
  QuantityWrapper,
  TabContainer,
  TopWrapper,
  UserDetailsWrapper,
  UserWrapper,
  Wrapper,
} from './styles'
import { GET_CONTACTED_DATES } from '../../services/graphQl/CustomerGraphQl'

export interface Card {
  title: string
  status: number
  key: number | string
}
function LeadProfile() {
  const wrapperRef = useRef(null)
  const { contactId } = useParams()
  const [selectedTab, setSelectedTab] = useState('Interactions')
  const [clientResponseId, setClientResponseId] = useState(null)
  const [selectedSubTab, setSelectedSubTab] = useState('Financials')
  const { setToast, setLoaderWithMessage } = useContext(ToastContext)
  const { state } = useLocation()
  const [leadId, setLeadId] = useState<number | null>(null)
  const [clientId, setClientId] = useState<number | null>(null)
  const [leadDetails, setLeadDetails] = useState<LeadInfoProps>(
    {} as LeadInfoProps
  )
  const [showEditContact, setShowEditContact] = useState(false)
  const [inputErrors, setInputErrors] = useState<string[]>([])
  const partyDetails = useAppSelector(partiesData)
  const [getLeadDetails, { loading: loadingLeadProfile }] =
    useLazyQuery<LeadGraphQLResponseInterface>(GET_LEAD_DETAILS, {
      variables: { id: leadId },
      fetchPolicy: 'no-cache',
    })
  const [
    getIndividualPartyDetails,
    { loading: loadingIndividualPartyProfile },
  ] = useLazyQuery<any>(GET_INDIVIDUAL_PARTY_DETAILS, {
    variables: {
      partyPayload: { partyId: Number(clientId) ?? 0 },
    },
    fetchPolicy: 'no-cache',
  })
  const [getPartyContactDetails, { loading: loadingPartyContactProfile }] =
    useLazyQuery<any>(GET_PARTY_CONTACT_DETAILS, {
      variables: {
        clientPayload: { clientId: Number(clientId) ?? 0 },
      },
      fetchPolicy: 'no-cache',
    })
  const [
    getPartyInvestmentsDetails,
    { loading: loadingPartyInvestmentsProfile },
  ] = useLazyQuery<any>(GET_PARTY_INVESTMENTS_DETAILS, {
    variables: {
      partyPayload: { partyId: Number(clientId) ?? 0 },
    },
    fetchPolicy: 'no-cache',
  })
  const [
    getPartyAdditionalDetails,
    { loading: loadingAdditionalPartyProfile },
  ] = useLazyQuery<any>(GET_PARTY_ADDITIONAL_DETAILS, {
    variables: {
      partyId: Number(clientId) ?? 0,
    },
    fetchPolicy: 'no-cache',
  })
  const [createParty] = useMutation<any, Partial<LeadInfoPayloadType>>(
    CREATE_PARTY_DETAILS
  )
  const [updateContact] = useMutation<any, Partial<LeadInfoProps>>(UPDATE_LEAD)
  const [updateParty] = useMutation<any, Partial<LeadInfoPayloadType>>(
    UPDATE_PARTY_DETAILS
  )
  const [cardList, setCardList] = useState<Card[]>([])
  const leadPartyLoading =
    loadingLeadProfile ||
    loadingIndividualPartyProfile ||
    loadingPartyContactProfile ||
    loadingPartyInvestmentsProfile ||
    loadingAdditionalPartyProfile
  const onChangeLeadInfoData = (data: Partial<LeadInfoProps>) => {
    setLeadDetails((prev) => {
      return { ...prev, ...data }
    })
  }

  const prepareInputVariables = (leadInfoData: Partial<LeadInfoProps>) => {
    const payload: Partial<LeadInfoPayloadType> = {
      id: leadInfoData.id,
      honorific: leadInfoData.honorific,
      firstName: leadInfoData.firstName,
      middleName: leadInfoData.middleName,
      lastName: leadInfoData.lastName,
      suffix: leadInfoData.suffix,
      preferedPronouce: leadInfoData.preferedPronouce,
      nickName: leadInfoData.nickName,
      jobTitle: leadInfoData.jobTitle,
      pronouciation: leadInfoData.pronouciation,
      account: leadInfoData.account,
      businessName: leadInfoData.businessName,
      trustName: leadInfoData.trustName,
      status: leadInfoData.status,
      accountTier: leadInfoData.accountTier,
      gender: leadInfoData.gender,
      leadSource: leadInfoData.leadSource,
      referredby: leadInfoData.referredby,
      clientSince: leadInfoData.clientSince,
      maritalStatus: leadInfoData.martialStatus,
      maritalAnniversary: leadInfoData.maritalAnniversary,
      clientTerminationDate:
        leadInfoData.clientTerminationDate &&
        AddDays(leadInfoData.clientTerminationDate, 0),
      dateOfBirth:
        leadInfoData.dateOfBirth && AddDays(leadInfoData.dateOfBirth, 0),
      interestHobbies: leadInfoData.interestHobbies,
      houseHoldMembers: leadInfoData.householdMembers,
      familyName: leadInfoData.familyName,
      pets: leadInfoData.pets,
      taxIdNumber: leadInfoData.taxIdNumber,
      websiteURL: leadInfoData.websiteURL,
      pronouns: leadInfoData.preferedPronouce,
      nickname: leadInfoData.nickName,
      pronunciations: leadInfoData.pronouciation,
      notes: leadInfoData.notes,
      owner: leadInfoData.owner,
      riskTolerance: leadInfoData.riskTolerance,
      timeHorizon: leadInfoData.timeHorizon,
      investmentExperience: leadInfoData.investmentExperience,
      referredBy: leadInfoData.referredby,
      potentialSizeOfAum: leadInfoData.aumSize,
      objective: leadInfoData.investmentObjectives,
      clientCategory: leadInfoData.category,
      currentAdvisoryFirms: leadInfoData.advisoryFirm,
      dateOfMarriage: leadInfoData.maritalAnniversary ?? null,
      totalAssets: leadInfoData.totalAssets,
      netWorth: leadInfoData.netWorth ?? 0,
      netWorthLiquidLiabilities: leadInfoData.netWorthLiquidLiabilities,
      netWorthTotalLiabilities: leadInfoData.netWorthTotalLiabilities,
      financialInterests: leadInfoData.financialInterests,
      experienceFunds: leadInfoData.experienceFunds,
      experienceStocks: leadInfoData.experienceStocks,
      experiencePartnerships: leadInfoData.experiencePartnerships,
      experienceOthers: leadInfoData.experienceOthers,
      nonLiquidAssets: leadInfoData.totalNonLiquidAssets,
      liabilities: leadInfoData.liabilities,
      taxyear: leadInfoData.taxyear,
      estimatedtaxes: leadInfoData.estimatedtaxes,
      estimatedtaxbracket: leadInfoData.estimatedtaxbracket,
      confirmedbytaxreturn: leadInfoData.confirmedbytaxreturn,
      adjustedgrossincome: leadInfoData.adjustedgrossincome,
    }
    return payload
  }

  const prepareClientInputVariables = (
    clientInfoData: Partial<LeadInfoProps>
  ) => {
    const payload: Partial<LeadInfoPayloadType> = {
      id: clientId ?? 0,
      accountTier: clientInfoData.accountTier,
      gender: clientInfoData.gender,
      leadSource: clientInfoData.leadSource,
      referredBy: clientInfoData.referredby,
      maritalStatus: clientInfoData.martialStatus,
      dateOfMarriage: clientInfoData.maritalAnniversary ?? null,
      clientTerminationDate: clientInfoData.clientTerminationDate ?? null,
      interestHobbies: clientInfoData.interestHobbies,
      houseHoldMembers: clientInfoData.householdMembers,
      familyName: clientInfoData.familyName,
      clientSince: clientInfoData.clientSince,
      pets: clientInfoData.pets,
      investmentExperience: clientInfoData.investmentExperience ?? '',
      createdOn: clientInfoData.createdOn,
      createdBy: clientInfoData.createdBy,
      notes: clientInfoData.notes,
      riskTolerance: clientInfoData.riskTolerance,
      timeHorizon: clientInfoData.timeHorizon,
      potentialSizeOfAum: clientInfoData.aumSize,
      objective: clientInfoData.investmentObjectives,
      experienceFunds: clientInfoData.experienceFunds,
      experienceStocks: clientInfoData.experienceStocks,
      experiencePartnerships: clientInfoData.experiencePartnerships,
      experienceOthers: clientInfoData.experienceOthers,
      totalAssets: clientInfoData.totalAssets,
      nonLiquidAssets: clientInfoData.totalNonLiquidAssets,
      netWorth: clientInfoData.netWorth ?? 0,
      netWorthTotalLiabilities: clientInfoData.netWorthTotalLiabilities,
      netWorthLiquidLiabilities: clientInfoData.netWorthLiquidLiabilities,
      liabilities: clientInfoData.liabilities,
      taxyear: clientInfoData.taxyear ?? 0,
      estimatedtaxes: clientInfoData.estimatedtaxes,
      estimatedtaxbracket: clientInfoData.estimatedtaxbracket,
      confirmedbytaxreturn: clientInfoData.confirmedbytaxreturn,
      websiteURL: clientInfoData.websiteURL,
      adjustedgrossincome: clientInfoData.adjustedgrossincome,
    }
    return payload
  }

  const validateContactDetails = (leadInfoData: Partial<LeadInfoProps>) => {
    const validationKeys =
      leadInfoData?.type === 'Business'
        ? businessValidationKeys
        : leadInfoData?.type === 'Trust'
        ? trustValidationKeys
        : individualValidationKeys
    let isInValid = false
    const emptyLeadInfoKeys: string[] = []
    validationKeys.forEach((key) => {
      if (
        leadInfoData[key] === '' ||
        leadInfoData[key] === 0 ||
        leadInfoData[key] === null
      ) {
        emptyLeadInfoKeys.push(key)
      }
    })
    setInputErrors(emptyLeadInfoKeys)
    if (emptyLeadInfoKeys.length > 0) {
      isInValid = true
    }
    return isInValid
  }

  const updateLeadInfoData = (data: Partial<LeadInfoProps>) => {
    if (vars.validationRequired && validateContactDetails(data)) {
      setToast(false, 'Please enter mandatory detail!')
    } else {
      if (data?.id && data.status !== 'Client') {
        setLoaderWithMessage(true)
        updateContact({ variables: prepareInputVariables(data) })
          .then((res) => {
            const affectedRows = res.data.update_lead_leads.affected_rows
            if (affectedRows > 0) {
              setToast(true, 'Lead information updated successfully!')
              setLeadDetails((prev) => {
                return { ...prev, ...data }
              })
              setLoaderWithMessage(false)
            } else {
              setToast(false, 'Something went wrong!')
              setLoaderWithMessage(false)
            }
          })
          .catch(() => {
            setToast(false, 'Something went wrong!')
            setLoaderWithMessage(false)
          })
          .finally(() => {
            setLoaderWithMessage(false)
            setShowEditContact(false)
          })
      } else if (!clientResponseId && data.status === 'Client') {
        setLoaderWithMessage(true)
        createParty({ variables: prepareClientInputVariables(data) })
          .then((res) => {
            const affectedRows =
              res.data.insert_party_additionalinfo.affected_rows
            const clientId =
              res?.data.insert_party_additionalinfo?.returning[0]?.id ?? null
            if (affectedRows > 0) {
              setToast(true, 'Client information updated successfully!')
              setClientResponseId(clientId)
              setLeadDetails((prev) => {
                return { ...prev, ...data }
              })
              setLoaderWithMessage(false)
            } else {
              setToast(false, 'Something went wrong!')
              setLoaderWithMessage(false)
            }
          })
          .catch(() => {
            setToast(false, 'Something went wrong!')
            setLoaderWithMessage(false)
          })
          .finally(() => {
            setLoaderWithMessage(false)
            setShowEditContact(false)
          })
      } else if (clientResponseId && data.status === 'Client') {
        setLoaderWithMessage(true)
        updateParty({ variables: prepareClientInputVariables(data) })
          .then((res) => {
            const affectedRows =
              res.data.update_party_additionalinfo.affected_rows
            if (affectedRows > 0) {
              setToast(true, 'Client information updated successfully!')
              setLeadDetails((prev) => {
                return { ...prev, ...data }
              })
              setLoaderWithMessage(false)
            } else {
              setToast(false, 'Something went wrong!')
              setLoaderWithMessage(false)
            }
          })
          .catch(() => {
            setToast(false, 'Something went wrong!')
            setLoaderWithMessage(false)
          })
          .finally(() => {
            setLoaderWithMessage(false)
            setShowEditContact(false)
          })
      }
    }
  }

  const formatDate = (date: Date) => {
    const day = date.getDate()?.toString()?.padStart(2, '0')
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, '0')
    const year = date?.getFullYear()?.toString()
    return month.concat('/').concat(day).concat('/').concat(year)
  }

  const [getContactedDates, { data: contactedDates }] =
    useLazyQuery(GET_CONTACTED_DATES)

  useEffect(() => {
    getContactedDates({
      variables: {
        _eq: clientId ? +clientId : leadId ? leadId : 0,
      },
    })
  }, [clientId, getContactedDates, leadId])

  useEffect(() => {
    const overview: Card[] = [
      {
        title: 'CRM STATUS',
        status: 1,
        key: leadDetails?.status?.length > 0 ? leadDetails?.status : '--',
      },
      {
        title: 'TYPE',
        status: 2,
        key: leadDetails?.type?.length > 0 ? leadDetails?.type : '--',
      },
      {
        title: 'LAST CONTACTED DATE',
        status: 3,
        key: contactedDates?.note_leadpartylastcontact?.[0]?.lastcontactdate
          ? formatDate(
              new Date(
                contactedDates?.note_leadpartylastcontact?.[0]?.lastcontactdate
              )
            )
          : '--',
      },
      {
        title: 'NEXT ACTIVITY',
        status: 4,
        key: contactedDates?.note_leadpartylastcontact?.[0]?.nextactivitydate
          ? formatDate(
              new Date(
                contactedDates?.note_leadpartylastcontact?.[0]?.nextactivitydate
              )
            )
          : '--',
      },
      {
        title: 'PAST DUE ACTIVITY',
        status: 5,
        key: contactedDates?.note_leadpartylastcontact?.[0]?.pastdueactivitydate
          ? formatDate(
              new Date(
                contactedDates?.note_leadpartylastcontact?.[0]?.pastdueactivitydate
              )
            )
          : '--',
      },
    ]

    setCardList(overview)
  }, [leadDetails])

  const getAvatar = () => {
    if (leadDetails.type === 'Business') {
      return (
        (leadDetails &&
          leadDetails.businessName &&
          leadDetails.businessName.charAt(0)) ??
        ''
      )
    } else if (leadDetails.type === 'Trust') {
      return (
        (leadDetails &&
          leadDetails.trustName &&
          leadDetails.trustName.charAt(0)) ??
        ''
      )
    } else if (leadDetails.leadStatus == 'Client' && leadDetails?.fullName) {
      const clientName: string[] = leadDetails?.fullName.split(' ')
      if (clientName?.length > 1) {
        const firstLetter = clientName?.shift()?.charAt(0) ?? ''
        const lastLetter = clientName?.pop()?.charAt(0) ?? ''
        return firstLetter + lastLetter
      } else if (clientName?.length == 1) {
        return clientName?.pop()?.charAt(0) ?? ''
      }
    } else {
      const firstName =
        (leadDetails &&
          leadDetails.firstName &&
          leadDetails.firstName.charAt(0)) ??
        ''
      const lastname =
        (leadDetails &&
          leadDetails.lastName &&
          leadDetails.lastName.charAt(0)) ??
        ''
      return firstName + lastname
    }
  }

  const getEmailList = (emails: EmailInformationPayloadInterface[]) => {
    const emailObj: EmailInformationInterface[] = []
    emails?.forEach((email) => {
      emailObj.push({
        id: email.id,
        leadId: email.leadid,
        email: email.emailid,
        emailType: email.type,
        isPrimary: email.isprimary,
        activeFlag: email.activeflag,
      })
    })
    return emailObj
  }

  const getAddressList = (addresses: ShippingAddressPayloadInterface[]) => {
    const addressObj: ShippingAddressInterface[] = []
    addresses?.forEach((address) => {
      addressObj.push({
        id: address.id,
        leadId: address.leadid,
        isPrimary: address.isprimary,
        activeFlag: address.activeflag,
        addressLine1: address.addressline1,
        addressLine2: address.addressline2,
        city: address.city,
        country:
          address?.country?.trim() == ''
            ? 'United States of America (the)'
            : address.country,
        state: address.state,
        postalCode: address.zipcode,
        addressType: address.addresstype,
      })
    })
    return addressObj
  }

  const getContactList = (contacts: PhonePayloadInterface[]) => {
    const contactObj: PhoneInterface[] = []
    contacts?.forEach((contact) => {
      contactObj.push({
        id: contact.id,
        leadId: contact.leadid,
        isPrimary: contact.isprimary,
        activeFlag: contact.activeflag,
        phoneType: contact.type,
        phoneNo: contact.phonenumber,
        phoneExtension: contact.extension,
        speedDial: contact.speeddial,
      })
    })
    return contactObj
  }

  const getClientEmailList = (
    emails: ClientEmailInformationPayloadInterface[]
  ) => {
    const emailObj: EmailInformationInterface[] = []
    emails?.forEach((email, emailIndex) => {
      emailObj.push({
        id: email.emailId,
        leadId: clientId ?? 0,
        email: email.emailAddress,
        emailType: email.emailAddressType,
        isPrimary: emailIndex === 0,
        activeFlag: true,
      })
    })
    return emailObj
  }

  const getClientAddressList = (addresses: ClientAddressPayloadInterface[]) => {
    const addressObj: ShippingAddressInterface[] = []
    addresses?.forEach((address, addressIndex) => {
      addressObj.push({
        id: address.addressId,
        leadId: clientId ?? 0,
        isPrimary: addressIndex === 0,
        activeFlag: true,
        addressLine1: address.streetAddress1,
        addressLine2: address.streetAddress2,
        city: address.city,
        country:
          address?.country?.trim() == ''
            ? 'United States of America (the)'
            : address.country,
        state: address.state,
        postalCode: address.zipCode,
        addressType: address.addressType,
      })
    })
    return addressObj
  }

  const getClientContactList = (contacts: ClientPhonePayloadInterface[]) => {
    const contactObj: PhoneInterface[] = []
    contacts?.forEach((contact, conatactIndex) => {
      contactObj.push({
        id: contact.contactId,
        leadId: clientId ?? 0,
        isPrimary: conatactIndex === 0,
        activeFlag: true,
        phoneType: contact.phoneType,
        phoneNo: Number(contact?.phone?.replaceAll(' ', '')),
        phoneExtension: 0,
        speedDial: 0,
      })
    })
    return contactObj
  }

  useEffect(() => {
    if (state && state?.leadId) {
      setClientId(null)
      setLeadId(state.leadId)
    } else if (state && state?.clientId) {
      setLeadId(null)
      setClientId(parseInt(state?.clientId))
    } else if (contactId) {
      setLeadId(null)
      setClientId(parseInt(contactId))
    }
  }, [contactId, state])

  useEffect(() => {
    if (leadId) {
      getLeadDetails().then((response: any) => {
        const responseObj =
          response.data?.lead_leads[0] ?? ({} as LeadInfoResponseInterface)
        const leadInfo: LeadInfoProps = {
          account: responseObj.account,
          partyId: responseObj.partyid ?? 0,
          createdBy: responseObj.createdby,
          addressList: getAddressList(responseObj.addresslist),
          businessName: responseObj?.businessname,
          trustName: responseObj?.trustname,
          advisor: responseObj?.advisor,
          advisoryFirm: responseObj.currentadvisoryfirms,
          totalAssets: responseObj.totalassets,
          aumSize: responseObj.potentialsizeofaum,
          category: responseObj.clientcategory,
          accountTier: responseObj.accounttier,
          clientSince: responseObj.clientsince,
          csa: '',
          dateOfBirth: responseObj.dateofbirth,
          clientTerminationDate: responseObj.clientterminationdate,
          emailList: getEmailList(responseObj.emailList),
          familyName: responseObj.familyname,
          firstName: responseObj.firstname,
          gender: responseObj.gender,
          type: responseObj.type,
          honorific: responseObj.honorific,
          householdMembers: responseObj.householdmembers,
          id: responseObj.id,
          interestHobbies: responseObj.interesthobbies,
          investmentExperience: responseObj.investmentexperience,
          investmentObjectives: responseObj.objective,
          jobTitle: responseObj.jobtitle,
          lastName: responseObj.lastname,
          leadSource: responseObj.leadsource,
          leadStatus: responseObj.status,
          maritalAnniversary: responseObj.dateofmarriage,
          martialStatus: responseObj.maritalstatus,
          middleName: responseObj.middlename,
          nickName: responseObj.nickname,
          notes: responseObj.notes,
          owner: responseObj.owner,
          pets: responseObj.pets,
          phoneList: getContactList(responseObj.contactList),
          preferedPronouce: responseObj.pronouns,
          pronouciation: responseObj.pronunciations,
          referredby: responseObj.referredby,
          riskTolerance: responseObj.risktolerance,
          status: responseObj.status,
          suffix: responseObj.suffix,
          taxIdNumber: responseObj.taxidnumber,
          timeHorizon: responseObj.timehorizon,
          websiteURL: responseObj.websiteurl,
          netWorth: responseObj.networth,
          experienceFunds: responseObj.experiencefunds,
          experienceStocks: responseObj.experiencestocks,
          experiencePartnerships: responseObj.experiencepartnership,
          experienceOthers: responseObj.experienceothers,
          totalLiquidAssets:
            responseObj.totalassets - responseObj.nonliquidassets,
          totalNonLiquidAssets: responseObj.nonliquidassets,
          liabilities: responseObj.liabilities,
          netWorthLiquidLiabilities: responseObj.networthliquidliabilities,
          netWorthTotalLiabilities: responseObj.networthtotalliabilities,
          taxyear: responseObj.taxyear,
          estimatedtaxes: responseObj.estimatedtaxes,
          estimatedtaxbracket: responseObj.estimatedtaxbracket,
          confirmedbytaxreturn: responseObj.confirmedbytaxreturn,
          adjustedgrossincome: responseObj.adjustedgrossincome,
          ownerName: responseObj.ownername,
        }
        setLeadDetails(leadInfo)
      })
    } else if (clientId) {
      const clientDetails = [
        getIndividualPartyDetails(),
        getPartyContactDetails(),
        getPartyInvestmentsDetails(),
        getPartyAdditionalDetails(),
      ]
      Promise.all(clientDetails).then((clientResponse: any) => {
        const [
          clientIndividualDetails,
          clientContactDetails,
          clientInvestmentDetails,
          clientAdditionalDetails,
        ] = clientResponse
        const SelectedPartyProfile = partyDetails.find(
          (party: any) => party.id === clientId
        )
        setClientResponseId(
          clientAdditionalDetails?.data?.party_additionalinfo?.[0]?.id
        )
        const clientInfo: LeadInfoProps = {
          account:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.partyDemographic?.employerName,
          type: SelectedPartyProfile?.type,
          createdBy: '',
          addressList: getClientAddressList(
            clientContactDetails?.data?.getIndividualClientAddress
          ),
          phoneList: getClientContactList(
            clientContactDetails?.data?.getIndividualClientContact
          ),
          emailList: getClientEmailList(
            clientContactDetails?.data?.getIndividualClientEmail
          ),
          advisor: SelectedPartyProfile?.advisor ?? '',
          advisoryFirm:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.currentadvisoryfirms,
          totalAssets:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.totalassets,
          aumSize:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.potentialsizeofaum,
          category:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.clientcategory,
          accountTier:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.accounttier ?? '',
          clientSince:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.clientsince,
          csa: '',
          dateOfBirth:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.partyDemographic?.dateOfBirth ?? '',
          clientTerminationDate:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.clientterminationdate,
          familyName:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.familyname,
          fullName:
            clientIndividualDetails?.data?.getIndividualClientDetails?.fullName,
          firstName:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.firstName,
          gender:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]?.gender,
          honorific:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.salutation,
          householdMembers:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.householdmembers,
          id: clientIndividualDetails?.data?.getIndividualClientDetails
            ?.partyId,
          interestHobbies:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.interesthobbies,
          investmentExperience:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.investmentexperience,
          investmentObjectives:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]?.objective,
          jobTitle:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.partyDemographic?.occupation,
          lastName:
            clientIndividualDetails?.data?.getIndividualClientDetails?.lastName,
          leadSource:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.leadsource,
          leadStatus: 'Client',
          maritalAnniversary:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.dateofmarriage,
          martialStatus:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.partyDemographic?.maritalStatus ?? '',
          middleName:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.middleName,
          nickName: '',
          notes:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]?.notes,
          owner:
            clientIndividualDetails?.data?.getIndividualClientDetails?.owner,
          pets: clientAdditionalDetails?.data?.party_additionalinfo?.[0]?.pets,
          preferedPronouce:
            clientIndividualDetails?.data?.getIndividualClientDetails?.pronouns,
          pronouciation:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.pronunciations,
          referredby:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.referredby,
          riskTolerance:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.risktolerance,
          status: 'Client',
          suffix:
            clientIndividualDetails?.data?.getIndividualClientDetails?.suffix,
          taxIdNumber:
            clientIndividualDetails?.data?.getIndividualClientDetails
              ?.taxIDNumber,
          timeHorizon:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.timehorizon,
          websiteURL:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.websiteurl,
          netWorth:
            clientInvestmentDetails?.data?.getIndividualClientInvestments
              ?.totalNetWorth,
          experienceFunds:
            clientInvestmentDetails?.data?.getIndividualClientInvestments
              ?.investmentExperienceMutualFunds,
          experienceStocks:
            clientInvestmentDetails?.data?.getIndividualClientInvestments
              ?.investmentExperienceStock,
          experiencePartnerships:
            clientInvestmentDetails?.data?.getIndividualClientInvestments
              ?.investExperiencePartnership,
          experienceOthers:
            clientInvestmentDetails?.data?.getIndividualClientInvestments
              ?.investExperienceOther,
          totalLiquidAssets:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.totalassets -
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.nonliquidassets,
          totalNonLiquidAssets:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.nonliquidassets,
          liabilities:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.liabilities,
          netWorthLiquidLiabilities:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.networthliquidliabilities,
          netWorthTotalLiabilities:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.networthtotalliabilities,
          taxyear:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]?.taxyear,
          estimatedtaxes:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.estimatedtaxes,
          estimatedtaxbracket:
            clientInvestmentDetails?.data?.getIndividualClientInvestments
              ?.taxBracket,
          confirmedbytaxreturn:
            clientAdditionalDetails?.data?.party_additionalinfo?.[0]
              ?.confirmedbytaxreturn ?? false,
          adjustedgrossincome:
            clientInvestmentDetails?.data?.getIndividualClientInvestments
              ?.annualIncome,
        }
        setLeadDetails(clientInfo)
      })
    }
  }, [
    clientId,
    leadId,
    getLeadDetails,
    getIndividualPartyDetails,
    getPartyContactDetails,
    getPartyInvestmentsDetails,
    getPartyAdditionalDetails,
    partyDetails,
  ])

  const getProfileName = () => {
    if (leadDetails.type === 'Business') {
      return leadDetails.businessName
    } else if (leadDetails.type === 'Trust') {
      return leadDetails.trustName
    } else if (leadDetails.leadStatus == 'Client' && leadDetails?.fullName) {
      return leadDetails?.fullName ?? ''
    } else {
      return `${leadDetails?.honorific ?? ''} ${leadDetails?.firstName ?? ''} ${
        leadDetails?.lastName ?? ''
      }`
    }
  }

  const getAccountTierColor = (accountTier: string) => {
    switch (accountTier) {
      case 'Platinum':
        return DEFAULT_COLORS.Icons.Platinum
      case 'Gold':
        return DEFAULT_COLORS.Icons.Gold
      case 'Silver':
        return DEFAULT_COLORS.Icons.Silver
      case 'Bronze':
        return DEFAULT_COLORS.Icons.Bronze
      default:
        return DEFAULT_COLORS.Icons.Bronze
    }
  }

  return (
    <>
      {leadPartyLoading ? (
        <Wrapper>
          <GraphQLLoader />
        </Wrapper>
      ) : (
        <Wrapper>
          <UserProfileWrapper flex={'none'}>
            <LeftUserProfile>
              <ProfileNameWrapper>
                <UserWrapper>
                  <UserDetailsWrapper>
                    <Label
                      color={DEFAULT_COLORS.Text.PrimaryStrong}
                      fontWeight={600}
                      lineHeight="1rem"
                    >
                      {getAvatar()}
                    </Label>
                    {leadDetails?.accountTier && (
                      <CrownWrapper>
                        <SvgCrown
                          fillColor={getAccountTierColor(
                            leadDetails.accountTier
                          )}
                        ></SvgCrown>
                      </CrownWrapper>
                    )}
                  </UserDetailsWrapper>
                </UserWrapper>
                <DetailsWrapper>
                  <WDLabelPrimaryHeading>
                    <Label lineHeight="1rem">{getProfileName()}</Label>
                  </WDLabelPrimaryHeading>
                  <ProfileDesignationWrapper>
                    <QuantityWrapper>
                      <WDLabelNickName>
                        {`${leadDetails?.nickName ?? ''} ${
                          leadDetails?.pronouciation
                            ? '(' + leadDetails?.pronouciation + ')'
                            : ''
                        }`}
                      </WDLabelNickName>
                    </QuantityWrapper>
                    <QuantityWrapper>
                      {leadDetails?.jobTitle && (
                        <>
                          <WDLabelWeak>
                            <Label>
                              {`${leadDetails?.jobTitle ?? 'Employee'} at ${
                                leadDetails?.account
                              } |`}
                            </Label>
                          </WDLabelWeak>

                          <LineHeightWrapper>
                            {leadDetails && leadDetails?.advisoryFirm}
                          </LineHeightWrapper>
                        </>
                      )}
                    </QuantityWrapper>
                  </ProfileDesignationWrapper>
                </DetailsWrapper>
              </ProfileNameWrapper>
            </LeftUserProfile>
          </UserProfileWrapper>
          <UserProfileWrapper flex={'1'}>
            {cardList.map((card) => (
              <CardOverview key={card.status}>
                <WrapperOne>
                  <Label>{card.key}</Label>
                </WrapperOne>
                <TitleTextWrapper>{card.title}</TitleTextWrapper>
              </CardOverview>
            ))}
          </UserProfileWrapper>
          <TabContainer>
            <TopWrapper>
              <ProfileTabWrapper>
                <ProfileTab
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  leadPartyId={
                    leadDetails.leadStatus == 'Client' ? clientId : leadId
                  }
                  leadDetails={leadDetails}
                  reference={wrapperRef}
                />
              </ProfileTabWrapper>
              <OverviewWrapper>
                <ContactDetailsWrapper flex={'0'}>
                  <LeadContactCard
                    contacts={leadDetails}
                    updateData={onChangeLeadInfoData}
                  />
                </ContactDetailsWrapper>
                <ContactDetailsWrapper flex={'1'}>
                  <LeadContactSection
                    contactDetails={leadDetails}
                    updateLeadInfoData={updateLeadInfoData}
                    inputErrors={inputErrors}
                    setInputErrors={setInputErrors}
                    showEditContact={showEditContact}
                    setShowEditContact={setShowEditContact}
                  />
                </ContactDetailsWrapper>
              </OverviewWrapper>
            </TopWrapper>
          </TabContainer>
          <ProfileTabWrapper>
            <ProfileSubTab
              selectedTab={selectedSubTab}
              setSelectedTab={setSelectedSubTab}
              leadDetails={leadDetails}
              updateData={onChangeLeadInfoData}
              updateLeadInfoData={updateLeadInfoData}
            />
          </ProfileTabWrapper>
          {/* {showIndividualDetails && (
            <EditContactDetails
              setShowEditContact={setShowIndividualDetails}
              leadDetails={leadDetails}
              updateData={onChangeLeadInfoData}
              updateLeadInfoData={updateLeadInfoData}
            />
          )} */}
        </Wrapper>
      )}
    </>
  )
}
export default LeadProfile
