import Lottie from 'lottie-react'
import { LottieWrapperSmall } from './styles'
import LottieLoader from '../../../assets/lottiefiles/loader.json'

const OptionLoader = () => {
  return (
    <LottieWrapperSmall>
      <Lottie animationData={LottieLoader} loop={true} />
    </LottieWrapperSmall>
  )
}

export default OptionLoader
