import { useEffect } from 'react'
import {
  HistoryMainWrapper,
  ListItems,
  SubListView,
  TimeWrp,
  TimelineListWrp,
} from './styles'
import { WDLabelBold } from '../../components/ui/WDLabel'
import Label from '../../components/label/Label'
import { useParams } from 'react-router-dom'
import { SvgClock } from '../../components/svg/SvgClock'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { useLazyQuery } from '@apollo/client'
import { GET_OPPORTUNITIES_HISTORY_DATA } from '../../services/graphQl/CustomerGraphQl'

const OpportunitiesHistory = () => {
  const { opportunityId } = useParams()
  const [historyData, { data: opportunityHistory }] = useLazyQuery(
    GET_OPPORTUNITIES_HISTORY_DATA,
    {
      variables: { opportunityid: opportunityId ? +opportunityId : 0 },
      fetchPolicy: 'no-cache',
    }
  )
  useEffect(() => {
    if (opportunityId) {
      historyData()
    }
  }, [historyData, opportunityId])
  const dateFormate = (inputDateString: string) => {
    const inputDate =
      inputDateString === '' ? new Date() : new Date(inputDateString)

    // Check if the date is valid before formatting
    if (!isNaN(inputDate.getTime())) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour clock format
      }
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
        inputDate
      )
      return formattedDate
    } else {
      return '-'
    }
  }

  return (
    <HistoryMainWrapper>
      <WDLabelBold>
        <Label>{'Opportunity Timeline'}</Label>
      </WDLabelBold>
      {opportunityHistory?.opportunities_history?.map((item: any) => (
        <TimelineListWrp key={item.id}>
          <SubListView borderColor={item.description}>
            <ListItems>
              <WDLabelBold>
                <Label>{`${item.description.split('opportunity')[0]}`}</Label>
              </WDLabelBold>
              <TimeWrp>
                <SvgClock fillColor={DEFAULT_COLORS.Icons.Gray}></SvgClock>
                <span>{dateFormate(item.updateddate ?? '')}</span>
              </TimeWrp>
            </ListItems>
          </SubListView>
        </TimelineListWrp>
      ))}
    </HistoryMainWrapper>
  )
}

export default OpportunitiesHistory
