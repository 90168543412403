import React, { useContext, useEffect, useState } from 'react'
import {
  BodyWrapper,
  BtnWrapper,
  CreateWrapper,
  CrossWrapper,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
  MasterWrapper,
  OverlayTitleWrapper,
  OverlayWrapper,
  PartyWrapper,
  TextBoxWrap,
  Wrapper,
} from './style'
import { WDSubFormLabelHeading } from '../../../components/ui/WDLabel'
import Label from '../../../components/label/Label'
import Button from '../../../components/button/Button'
import {
  WDBorderButtonPrimary,
  WDButton,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import { SvgClose } from '../../../components/svg/SvgClose'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { Textbox } from '../../../components/textbox/Textbox'
import { ThemeContext } from '../../../store/ThemeContext'
import { EstimatedNetWorthProps } from './IFinancialDetails'
import { LeadInfoProps } from '../../createContactList/ICreateLead'

const EstimatedNetWorth = ({
  setEstimatedNetWorthInfoModal,
  leadDetails,
  updateLeadInfoData,
}: EstimatedNetWorthProps) => {
  const { isDarkMode } = useContext(ThemeContext)
  const [estimatedNetWorth, setEstimatedNetWorth] = useState({
    totalAssets: 0,
    liabilities: 0,
    totalNonLiquidAssets: 0,
  })

  const updateDetails = () => {
    updateLeadInfoData?.(
      { ...leadDetails, ...estimatedNetWorth } ?? ({} as LeadInfoProps)
    )
    setEstimatedNetWorthInfoModal(false)
  }

  useEffect(() => {
    setEstimatedNetWorth({
      totalAssets: leadDetails?.totalAssets ?? 0,
      liabilities: leadDetails?.liabilities ?? 0,
      totalNonLiquidAssets: leadDetails?.totalNonLiquidAssets ?? 0,
    })
  }, [leadDetails])

  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <MasterWrapper>
            <HeaderWrapper>
              <OverlayTitleWrapper>
                <WDSubFormLabelHeading>
                  <Label>{'ESTIMATED NET WORTH'}</Label>
                </WDSubFormLabelHeading>
                <CrossWrapper>
                  <WDButtonTransparent>
                    <Button
                      type="button"
                      onClick={() => setEstimatedNetWorthInfoModal(false)}
                    >
                      <SvgClose fillColor="#000"></SvgClose>
                    </Button>
                  </WDButtonTransparent>
                </CrossWrapper>
              </OverlayTitleWrapper>
              <PartyWrapper>
                <BodyWrapper>
                  <CreateWrapper>
                    <Label>{'Estimated Total Assets'}</Label>
                    <TextBoxWrap>
                      <Textbox
                        width="24rem"
                        value={estimatedNetWorth?.totalAssets}
                        onChange={(value) => {
                          setEstimatedNetWorth({
                            ...estimatedNetWorth,
                            totalAssets: !isNaN(Number(value))
                              ? Number(value)
                              : 0,
                          })
                        }}
                      />
                    </TextBoxWrap>
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Estimated Non-Liquid Assets'}</Label>
                    <TextBoxWrap>
                      <Textbox
                        width="24rem"
                        value={estimatedNetWorth?.totalNonLiquidAssets}
                        onChange={(value) => {
                          setEstimatedNetWorth({
                            ...estimatedNetWorth,
                            totalNonLiquidAssets: !isNaN(Number(value))
                              ? Number(value)
                              : 0,
                          })
                        }}
                      />
                    </TextBoxWrap>
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Estimated Liabilities'}</Label>
                    <TextBoxWrap>
                      <Textbox
                        width="24rem"
                        value={estimatedNetWorth?.liabilities}
                        onChange={(value) => {
                          setEstimatedNetWorth({
                            ...estimatedNetWorth,
                            liabilities: !isNaN(Number(value))
                              ? Number(value)
                              : 0,
                          })
                        }}
                      />
                    </TextBoxWrap>
                  </CreateWrapper>
                </BodyWrapper>
              </PartyWrapper>
              <FooterWrapper>
                <BtnWrapper>
                  <WDBorderButtonPrimary>
                    <Button
                      type="button"
                      onClick={() => setEstimatedNetWorthInfoModal(false)}
                      color={
                        isDarkMode
                          ? DEFAULT_COLORS.Text.UserDark
                          : DEFAULT_COLORS.Text.Primary
                      }
                    >
                      {'Cancel'}
                    </Button>
                  </WDBorderButtonPrimary>
                  <WDButton>
                    <Button type="button" onClick={() => updateDetails()}>
                      {'Save'}
                    </Button>
                  </WDButton>
                </BtnWrapper>
              </FooterWrapper>
            </HeaderWrapper>
          </MasterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}

export default EstimatedNetWorth
