import { FileDownloadResponseInterface, PreviewFileProps } from './IPreviewFile'
import {
  DownloadFileCallingApiWrapper,
  DownloadFileWrapper,
  FileNameWrapper,
  FileViewWrapper,
  MainWrapper,
  WDButtonGrayTransparent,
  WDLabelAttachment,
  Wrapper,
} from './styles'

import { useContext } from 'react'
import { vars } from '../../assets/utils/CommonFunctions'
import { SvgAttachment } from '../../components/svg/SvgAttachment'
import { SvgDownloadFile } from '../../components/svg/SvgDownloadFile'
import {
  fileDeleteRequest,
  fileDownloadRequest,
} from '../../services/fileAttachment/FileTransferApi'
import { FileDeletePayloadInterface } from '../../services/fileAttachment/IFileTransfer'
import ToastContext from '../../services/toast/ToastContext'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SvgDeleteIconWithoutBorder } from '../svg/SvgDeleteIconWithoutBorder'

const PreviewFile = ({ fromView, allFiles, setAllFiles }: PreviewFileProps) => {
  const { setToast } = useContext(ToastContext)
  const deleteFile = (deleteFileProp: FileDownloadResponseInterface) => {
    if (deleteFileProp.id) {
      fileDeleteRequest({
        FileName: deleteFileProp.name,
        UploadTranType: '4',
        FolderName: vars.OppFolderName,
        TranId: deleteFileProp.tranId.toString(),
      })
        .then((res: any) => {
          if (res.data > 1) {
            setToast(true, 'File deleted Successfully')
            setAllFiles(
              allFiles.filter((items) => items.id !== deleteFileProp.id)
            )
          } else {
            setToast(false, 'Something went wrong, please try again')
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong, please try again')
        })
    } else {
      setAllFiles(
        allFiles.filter((items) => items.name !== deleteFileProp.name)
      )
    }
  }

  const downloadFile = (file: FileDownloadResponseInterface) => {
    const payload: FileDeletePayloadInterface = {
      FileName: file.name,
      UploadTranType: '4',
      FolderName: vars.OppFolderName,
      TranId: file.tranId.toString(),
    }
    fileDownloadRequest(payload)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/' + file.fileExtension,
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        const fileName = file.name.includes('.' + file.fileExtension)
          ? file.name
          : file.name + '.' + file.fileExtension
        link.download = fileName
        link.click()
      })
      .catch(() => {
        setToast(false, 'Something went wrong, please try again')
      })
  }

  return (
    <Wrapper>
      <FileViewWrapper>
        {allFiles &&
          allFiles.map((file, index) => {
            return (
              <MainWrapper key={index}>
                {file.isCallingAPI ? (
                  <DownloadFileCallingApiWrapper key={index}>
                    <FileNameWrapper>
                      <WDLabelAttachment>
                        <label>{file.name}</label>
                      </WDLabelAttachment>
                    </FileNameWrapper>
                    {file.id && (
                      <WDButtonGrayTransparent>
                        <button
                          type={'button'}
                          onClick={() => downloadFile(file)}
                        >
                          <SvgDownloadFile height="16" width="16" />
                        </button>
                      </WDButtonGrayTransparent>
                    )}

                    <WDButtonGrayTransparent>
                      <button type={'button'} onClick={() => deleteFile(file)}>
                        <SvgDeleteIconWithoutBorder />
                      </button>
                    </WDButtonGrayTransparent>
                  </DownloadFileCallingApiWrapper>
                ) : (
                  <DownloadFileWrapper key={index}>
                    <FileNameWrapper>
                      <WDLabelAttachment>
                        <label>{file.name}</label>
                      </WDLabelAttachment>
                    </FileNameWrapper>
                    {file.id && (
                      <WDButtonGrayTransparent>
                        <button
                          type={'button'}
                          onClick={() => downloadFile(file)}
                        >
                          <SvgDownloadFile height="16" width="16" />
                        </button>
                      </WDButtonGrayTransparent>
                    )}
                    <WDButtonGrayTransparent>
                      {!fromView ? (
                        <button
                          type={'button'}
                          onClick={() => deleteFile(file)}
                        >
                          <SvgDeleteIconWithoutBorder />
                        </button>
                      ) : (
                        <SvgAttachment
                          fillColor={DEFAULT_COLORS.Text.NeutralWeak}
                        />
                      )}
                    </WDButtonGrayTransparent>
                  </DownloadFileWrapper>
                )}
              </MainWrapper>
            )
          })}
      </FileViewWrapper>
    </Wrapper>
  )
}

export default PreviewFile
