import { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import LeadPartyTable from '../../components/table/LeadPartyTable'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import SelectContactType from '../createContactList/SelectContactType'
import { leadColumnData } from './LeadData'

const LeadParty = ({
  leadPartyData,
  RepCodeData,
  loadingData,
}: {
  leadPartyData: any[]
  RepCodeData: any[]
  loadingData: boolean
}) => {
  const navigate = useNavigate()
  const { setCategoryName } = useContext(DashboardContext)
  const [showCreateContactCategoryPopup, setShowCreateContactCategoryPopup] =
    useState<boolean>(false)

  const [summaryFilters, setSummaryFilters] = useState<
    { id: string; value: string | string[] | undefined | null }[]
  >([])

  const onCategorySelection = (category: string) => {
    setShowCreateContactCategoryPopup(false)
    if (
      category === 'Individual' ||
      category === 'Business' ||
      category === 'Trust' ||
      category === 'New Recruit' ||
      category === 'Personal Contact'
    ) {
      setCategoryName(category)
      navigate('/createContactList/' + category.replace(' ', ''))
    }
  }

  return (
    <>
      <LeadPartyTable
        source="leadData"
        tableProperties={{
          tableHeader: 'Lead/Party',
          globalSearchRequired: true,
          tileFilterRequired: false,
          paginatorRequired: true,
          headerButtonRequired: true,
          buttonLabel: 'New Contact',
          createCallback: () => setShowCreateContactCategoryPopup(true),
        }}
        isLoading={loadingData}
        tableColumns={leadPartyData ? leadColumnData : []}
        allData={leadPartyData ?? []}
        filterData={summaryFilters}
        setSummaryFilters={setSummaryFilters}
        sortingFields={[
          'firstname',
          'status',
          'type',
          'category',
          'phone',
          'email',
          'advisor',
        ]}
        repCodeList={RepCodeData}
      />

      {showCreateContactCategoryPopup && (
        <SelectContactType
          setShowRelationship={setShowCreateContactCategoryPopup}
          returnContactCategory={onCategorySelection}
          OthersRequired={false}
        />
      )}
    </>
  )
}

export default LeadParty
