import { useState } from 'react'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { Textbox } from '../../components/textbox/Textbox'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { AddressInformationProps } from './IfinancialInformation'
import {
  CreateWrapper,
  ItemWrapper,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
  ItemSubWrp,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import { WDContactInfoWrapper } from '../../components/ui/WDWrapper'

const FinancialInformation = ({
  updateData,
  leadInfoData,
}: AddressInformationProps) => {
  const [isContactOpen, setIsContactOpen] = useState(true)

  const financialInterestsList = [
    { value: 'Technology', label: 'Technology' },
    { value: 'Emerging', label: 'Emerging' },
    { value: 'Markets', label: 'Markets' },
    { value: 'Bonds', label: 'Bonds' },
    { value: 'FixedIncome', label: 'Fixed Income' },
  ]

  const investmentExperienceList = [
    { value: 'Experienced', label: 'Experienced' },
    { value: 'InExperienced', label: 'Inexperienced' },
    { value: 'Extensive', label: 'Extensive' },
  ]

  const riskToleranceList = [
    { value: 'Low', label: 'Low' },
    { value: 'Medium', label: 'Medium' },
    { value: 'High', label: 'High' },
  ]
  const timeHorizonList = [
    { value: '1-3 Years', label: '1-3 Years' },
    { value: '3-5 Years', label: '3-5 Years' },
    { value: '5+ Years', label: '5+ Years' },
  ]
 
  const formatUSD = (value: string | number | undefined) => {
    if (!value) return value
    const amount = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    return '$' + amount
  }
  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper onClick={() => setIsContactOpen(!isContactOpen)}>
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">
                {'Financial Information'}
              </Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() => setIsContactOpen(!isContactOpen)}
              >
                <Image
                  image={isContactOpen ? Icons.ChevronUp : Icons.ChevronDown}
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isContactOpen && (
          <>
            <ItemWrapper>
              <ItemSubWrp>
                <CreateWrapper>
                  <Label>{'AUM'}</Label>
                  <Textbox
                    onChange={(value) => {
                      const numericValue = value.replace(/[^0-9.]/g, '')
                      updateData({ totalAssets: +numericValue }, 'totalAssets')
                    }}
                    value={
                      leadInfoData.totalAssets
                        ? formatUSD(leadInfoData.totalAssets)
                        : 0
                    }
                  />
                </CreateWrapper>
                <CreateWrapper>
                  <Label>{'Potentials Size Of AUM'}</Label>
                  <Textbox
                    width="16.5rem"
                    onChange={(value) => {
                      const numericValue = value.replace(/[^0-9.]/g, '')
                      updateData({ aumSize: +numericValue }, 'aumSize')
                    }}
                    value={
                      leadInfoData.aumSize ? formatUSD(leadInfoData.aumSize) : 0
                    }
                  />
                </CreateWrapper>
                <CreateWrapper>
                  <Label>{'Financial Interests'}</Label>
                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    padding="0rem"
                    options={financialInterestsList}
                    height="2.62rem"
                    placeholder={'Select'}
                    value={{
                      value: leadInfoData?.financialInterests ?? '',
                      label: leadInfoData?.financialInterests ?? '',
                    }}
                    onChange={(option) =>
                      updateData(
                        { financialInterests: option.value },
                        'financialInterests'
                      )
                    }
                  />
                </CreateWrapper>
              </ItemSubWrp>
              <ItemSubWrp>
                <CreateWrapper>
                  <Label>{'Current Advisory Firms'}</Label>
                  <Textbox
                    width="16.5rem"
                    onChange={(value) =>
                      updateData({ advisoryFirm: value }, 'advisoryFirm')
                    }
                    value={leadInfoData.advisoryFirm}
                  />
                </CreateWrapper>
                <CreateWrapper>
                  <Label>Investment Experience</Label>
                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    padding="0rem"
                    options={investmentExperienceList}
                    height="2.62rem"
                    placeholder={'Select'}
                    value={{
                      value: leadInfoData?.investmentExperience,
                      label: leadInfoData?.investmentExperience,
                    }}
                    onChange={(option) =>
                      updateData(
                        { investmentExperience: option.value },
                        'investmentExperience'
                      )
                    }
                  />
                </CreateWrapper>
                <CreateWrapper>
                  <Label>Investment Objectives</Label>
                  <Textbox
                    onChange={(value) =>
                      updateData(
                        { investmentObjectives: value },
                        'investmentObjectives'
                      )
                    }
                    value={leadInfoData.investmentObjectives}
                  />
                </CreateWrapper>
              </ItemSubWrp>
              <ItemSubWrp>
                <CreateWrapper>
                  <Label>Net Worth</Label>
                  <Textbox
                    onChange={(value) => {
                      const numericValue = value.replace(/[^0-9.]/g, '')
                      updateData({ netWorth: +numericValue }, 'netWorth')
                    }}
                    value={
                      leadInfoData.netWorth
                        ? formatUSD(leadInfoData.netWorth)
                        : 0
                    }
                  />
                </CreateWrapper>
                <CreateWrapper>
                  <Label>Risk Tolerance</Label>
                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    options={riskToleranceList}
                    padding="0rem"
                    height="2.62rem"
                    placeholder={'Select'}
                    value={{
                      value: leadInfoData.riskTolerance,
                      label: leadInfoData.riskTolerance,
                    }}
                    onChange={(option) =>
                      updateData(
                        { riskTolerance: option.value },
                        'riskTolerance'
                      )
                    }
                  />
                </CreateWrapper>
                <CreateWrapper>
                  <Label>Time Horizon</Label>

                  <SelectDropdown
                    className="w-100"
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    padding="0rem"
                    options={timeHorizonList}
                    height="2.62rem"
                    placeholder={'Select'}
                    value={{
                      value: leadInfoData.timeHorizon,
                      label: leadInfoData.timeHorizon,
                    }}
                    onChange={(option) =>
                      updateData({ timeHorizon: option.value }, 'timeHorizon')
                    }
                  />
                </CreateWrapper>
              </ItemSubWrp>
            </ItemWrapper>
          </>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default FinancialInformation
