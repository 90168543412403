import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
`
export const HeaderWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  position: relative;
  justify-content: space-between;
`
export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 30vh;
  display: flex;
  background-color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
  overflow-y: auto;
`
export const EventsTitle = styled.div`
  display: flex;
  gap: 0.3rem;
`
