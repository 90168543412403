import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { SvgClose } from '../../../components/svg/SvgClose'
import { Textbox } from '../../../components/textbox/Textbox'
import {
  WDButton,
  WDButtonDisabled,
  WDButtonTransparentWithoutHeight,
} from '../../../components/ui/WDButton'
import { WDLabelPrimaryBold } from '../../../components/ui/WDLabel'
import { WDTextbox } from '../../../components/ui/WDTextbox'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import {
  ContentFormSectionWrapper,
  ContentFormWrapper,
  ContentWrapper,
  FooterSectionEndWrapper,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
  OverlayWrapper,
  Wrapper,
} from './styles'

interface PartyRelationshipProps {
  setShowRelationship: (value: boolean) => void
}

const EditRelationship = ({ setShowRelationship }: PartyRelationshipProps) => {
  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <HeaderWrapper>
            <WDLabelPrimaryBold>UPDATE FAMILY</WDLabelPrimaryBold>
            <WDButtonTransparentWithoutHeight>
              <Button type="button" onClick={() => setShowRelationship(false)}>
                <SvgClose fillColor={DEFAULT_COLORS.Icons.Primary} />
              </Button>
            </WDButtonTransparentWithoutHeight>
          </HeaderWrapper>
          <ContentWrapper>
            <ContentFormWrapper>
              <ContentFormSectionWrapper>
                <Label>Family Name</Label>
                <WDTextbox>
                  <Textbox />
                </WDTextbox>
              </ContentFormSectionWrapper>
            </ContentFormWrapper>
          </ContentWrapper>
          <FooterWrapper>
            <FooterSectionEndWrapper>
              <WDButtonDisabled>
                <Button
                  type="button"
                  onClick={() => setShowRelationship(false)}
                >
                  Cancel
                </Button>
              </WDButtonDisabled>
              <WDButton>
                <Button
                  type="button"
                  bgColor={DEFAULT_COLORS.Background.DangerStrong}
                >
                  Update family
                </Button>
              </WDButton>
            </FooterSectionEndWrapper>
          </FooterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}
export default EditRelationship
