import axiosProtectedInstance from '../http/axiosService'
import ENDPOINTS from '../httpEndpoints'
import { FileDeletePayloadInterface } from './IFileTransfer'
import httpEndpoints from '../httpEndpoints'
import appConfig from '../../settings/appConfig'

const host = appConfig.apiEndPoint
const fileDownloadUrl = host + httpEndpoints.FILE_DOWNLOAD
const fileDeleteUrl = host + httpEndpoints.FILE_DELETE

export const fileUploadRequest = (payload: FormData) => {
  return axiosProtectedInstance.post(host + ENDPOINTS.FILE_UPLOAD, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const fileDownloadRequest = (payload: FileDeletePayloadInterface) => {
  return axiosProtectedInstance(
    fileDownloadUrl
      .concat('?FolderName=')
      .concat(payload.FolderName)
      .concat('&FileName=')
      .concat(payload.FileName)
      .concat('&UploadTranType=')
      .concat(payload.UploadTranType)
      .concat('&TranId=')
      .concat(payload.TranId),
    {
      method: 'POST',
      responseType: 'blob',
    }
  )
}

export const fileDeleteRequest = (payload: FileDeletePayloadInterface) => {
  return axiosProtectedInstance.delete(
    fileDeleteUrl
      .concat('?FolderName=')
      .concat(payload.FolderName)
      .concat('&FileName=')
      .concat(payload.FileName)
      .concat('&UploadTranType=')
      .concat(payload.UploadTranType)
      .concat('&TranId=')
      .concat(payload.TranId)
  )
}
