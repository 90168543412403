import { useContext, useEffect } from 'react'
import {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import axiosProtectedInstance from '../../services/http/axiosService'
import { AuthContext } from '../../services/AuthContext'
import Loader from '../loader/Loader'
import ToastContext from '../../services/toast/ToastContext'

const AxiosProtectedInterceptor = () => {
  const { isLoading, setLoader } = useContext(ToastContext)
  const authCtx = useContext(AuthContext)

  useEffect(() => {
    const requestStack: number[] = []

    const onRequest: (
      config: AxiosRequestConfig
    ) => Promise<InternalAxiosRequestConfig> = async (config) => {
      requestStack.push(requestStack.length + 1)
      if (config.headers && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${authCtx.accessToken}`
      }
      return Promise.resolve(config as InternalAxiosRequestConfig)
    }

    const onRequestError = (error: AxiosError): Promise<AxiosError> => {
      requestStack.pop()
      requestStack.length === 0 && setLoader(false)
      return Promise.reject(error)
    }

    const onResponseError = (error: AxiosError): Promise<AxiosError> => {
      requestStack.pop()
      requestStack.length === 0 && setLoader(false)
      return Promise.reject(error)
    }

    const onResponse = (response: AxiosResponse): AxiosResponse => {
      requestStack.pop()
      requestStack.length === 0 && setLoader(false)
      return response
    }

    axiosProtectedInstance.interceptors.request.use(onRequest, onRequestError)
    axiosProtectedInstance.interceptors.response.use(
      onResponse,
      onResponseError
    )
  }, [authCtx.accessToken, setLoader])

  return <>{isLoading && <Loader />}</>
}

export default AxiosProtectedInterceptor
