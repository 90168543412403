import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { SvgClose } from '../../../components/svg/SvgClose'
import {
  WDButton,
  WDButtonDisabled,
  WDButtonTransparentWithoutHeight,
} from '../../../components/ui/WDButton'
import { WDLabelPrimaryBold } from '../../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { SIZES } from '../../../theme/Sizes'
import {
  ContentFormSectionWrapper,
  ContentFormWrapper,
  ContentWrapper,
  FooterSectionEndWrapper,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
  OverlayWrapper,
  SelectBoxSmall,
  Wrapper,
} from './styles'

interface PartyRelationshipProps {
  setShowRelationship: (value: boolean) => void
}

const ChangeHead = ({ setShowRelationship }: PartyRelationshipProps) => {
  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <HeaderWrapper>
            <WDLabelPrimaryBold>RESET FAMILY HEAD</WDLabelPrimaryBold>
            <WDButtonTransparentWithoutHeight>
              <Button type="button" onClick={() => setShowRelationship(false)}>
                <SvgClose fillColor={DEFAULT_COLORS.Icons.Primary} />
              </Button>
            </WDButtonTransparentWithoutHeight>
          </HeaderWrapper>
          <ContentWrapper>
            <ContentFormWrapper>
              <ContentFormSectionWrapper>
                <Label>Member</Label>
                <SelectBoxSmall>
                  <SelectDropdown
                    borderRadius={SIZES[1]}
                    borderColor={DEFAULT_COLORS.Border.Neutral}
                    options={[
                      {
                        value: '',
                        label: 'Please select',
                      },
                    ]}
                    height="2.5rem"
                    placeholder={'Select'}
                    value={{
                      value: '',
                      label: 'Please select',
                    }}
                    onChange={(option) => console.log(option)}
                  />
                </SelectBoxSmall>
              </ContentFormSectionWrapper>
            </ContentFormWrapper>
          </ContentWrapper>
          <FooterWrapper>
            <FooterSectionEndWrapper>
              <WDButtonDisabled>
                <Button
                  type="button"
                  onClick={() => setShowRelationship(false)}
                >
                  Cancel
                </Button>
              </WDButtonDisabled>
              <WDButton>
                <Button
                  type="button"
                  bgColor={DEFAULT_COLORS.Background.DangerStrong}
                >
                  Reset family head
                </Button>
              </WDButton>
            </FooterSectionEndWrapper>
          </FooterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}
export default ChangeHead
