import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'

export interface bgColorProps {
  bgColor: string
}

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: ${(props: { width: string }) => props.width ?? 'auto'};
  flex-direction: column;
  max-width: 100%;
  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  position: relative;
  input {
    padding: 0.5rem 1rem;
    background: ${() => DEFAULT_COLORS.Background.Neutral};
    border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
    &:hover {
      border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      border-color: ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      color: ${() => DEFAULT_COLORS.Icons.NeutralHover};
    }
    /* &:active {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    } */
    &:disabled {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
      cursor: no-drop;
    }
    &:invalid {
      box-shadow: inset 0px 0px 0px 1px ${() => DEFAULT_COLORS.Border.Iron};
      border-color: ${() => DEFAULT_COLORS.Border.Iron};
    }
    &:focus {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      border-color: ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      color: ${() => DEFAULT_COLORS.Text.Black};
    }
    &:required:valid {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.DangerDark};
      border-color: ${() => DEFAULT_COLORS.Border.DangerDark};
      background-color: ${() => DEFAULT_COLORS.Background.Fantasy};
    }
    box-sizing: border-box;
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${() => DEFAULT_COLORS.Text.Neutral};
    outline: none;
  }
`

export const PanelWrapper = styled.div`
  display: ${(props: { display: boolean }) =>
    props.display ? `flex` : `none`};
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: inherit;
  max-width: 300px;
  max-height: 250px;
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 32%) 0px 7px 11px 0px;
  position: absolute;
  z-index: 99;
  padding: 5px 0;
  top: 100%;
  overflow: auto;
  min-width: ${(props: { width: string }) => props.width ?? '100%'};
`
export const PanelItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  width: 100%;
  font-size: 0.95rem;
  padding: 0.6rem;
  border-bottom: 1px solid #efefef;

  :hover {
    background-color: rgb(203 225 253);
    cursor: pointer !important;
  }
  &[data-checked='true'] {
    background-color: rgb(223 237 255);
  }
  *:hover {
    cursor: pointer !important;
  }
  :last-child {
    border-bottom: none;
  }
  input {
    height: auto !important;
    margin-right: 0.6rem;
    margin-top: 1px;
  }
  label {
    display: block;
    width: 100%;
  }
`
export const PanelItemSelector = styled.div((props: any) => {
  const baseStyle = `display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 5px;
  
  font-size:12px;
  height: 15px;
  width: 15px;
  background-color: ${props.checked == true ? '#f00' : '#eee'};
  margin-right: 5px;
`
  return baseStyle
})

export const DataWrapper = styled.div`
  display: inline;
  align-items: start;
  justify-content: start;
  background-color: #fff;
  overflow: auto;
  min-width: ${(props: { width: string }) => props.width ?? '100%'};
  border: 1px dotted #ccc;
`
export const DataItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: start;
  padding: 5px 5px;
  font-size: 12px;
  background-color: rgb(223 237 255);
  border-radius: 3px;
  margin: 5px;
  input {
    display: none;
  }
  & label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;
    margin-left: 0.5rem;
  }
`
export const DataItemClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  content: 'x';
`
export const ClearFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #ffa5a5;
  position: absolute;
  right: ${(props: { type: string }) =>
    props.type == 'filter' ? '25px' : '8px'};
  padding: 3px;
  border-radius: 9px;
  cursor: pointer;
  z-index: 3;
`

export const Arrow = styled.div`
  border-style: solid;
  border-color: #919191;
  border-image: initial;
  border-width: 0px 1px 1px 0px;
  display: inline-block;
  padding: 0.19rem;
  transform: rotate(45deg);
  position: absolute;
  right: 9px;
  top: 9px;
`
export const ExtraItems = styled.div`
  font-size: 0.85rem;
  padding: 8px 4px 4px 0;
  width: inherit;
  white-space: ${(props: { wrapDetails: boolean }) =>
    props.wrapDetails == false ? 'nowrap' : 'normal'};
`
