import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const FamilyHeadWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 5rem;
  padding: 0 1.5rem;
`

export const FamilyDependantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 25%;
`

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const FamilyHeadCard = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  border-bottom: 2px solid #c6d0e1;
  border-radius: 0.25rem;
  height: 5rem;
  background-color: #e7f0ff;
  gap: 1rem;
`

export const FamilyMemberCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
  border-bottom: 2px solid #c6d0e1;
  border-radius: 0.25rem;
  height: 5rem;
  background-color: #e7f0ff;
  gap: 1rem;
`

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 10%;
  height: 100%;
`
export const RelationAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  height: 100%;
`
export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 60%;
  height: 100%;
`
export const ActionsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 30%;
  height: 100%;
`

export const AlignStartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
`

export const MoreActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 2.25rem;
  border: 1px solid ${DEFAULT_COLORS.Border.Neutral};
  border-radius: 0.25rem;
  z-index: 9;
  background-color: ${DEFAULT_COLORS.Background.NeutralWeak};
`

export const MoreActionsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  min-width: 8rem;
`
