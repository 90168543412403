import { Store, combineReducers, configureStore } from '@reduxjs/toolkit';
import leadPartyReducer from '../reducers/leadPartySlice';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

const reducer = combineReducers({
  leadParty: leadPartyReducer,
});

const store: Store = configureStore({
  reducer,
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
