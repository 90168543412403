import { useLazyQuery } from '@apollo/client'
import {
  createContext,
  ReactNode,
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react'
import {
  FetchAccountsParties,
  FetchActionDesc,
  FetchGenericDetails,
  FetchMeetingAssociates,
  FetchMeetingDetails,
  FetchTaskDetails,
  GetSavedFiles,
} from './EditActionsGraphQl'
import {
  Accounts,
  Clients,
  NoteParamsInterface,
} from '../../components/table/INotes'
import { EventParamsInterface } from '../../components/table/IEvents'
import { TaskParamsInterface } from '../../components/table/ITasks'
import moment from 'moment'
import ToastContext from '../toast/ToastContext'
import { vars } from '../../assets/utils/CommonFunctions'

export interface EditActionContextInterface {
  loadingEdit: boolean
  loadingConvert: boolean
  updateActionsFlow: (
    id: number,
    tranType: number,
    meetingId?: number,
    actionFlag?: string
  ) => void
  editNotesProps: NoteParamsInterface | undefined
  editEventsProps: EventParamsInterface | undefined
  editTaskProps: TaskParamsInterface | undefined
  showNewNotes: boolean
  setNewNotes: (value: boolean) => void
  showTasks: boolean
  setShowTasks: (value: boolean) => void
  showNewEvents: boolean
  setNewEvents: (value: boolean) => void
  taskPropsByNote: TaskParamsInterface | undefined
  resetInterActions: () => void
}

const EditActionContext = createContext<EditActionContextInterface>(
  {} as EditActionContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const EditActionContextProvider = ({ children }: ContextProps) => {
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [loadingConvert, setLoadingConvert] = useState(false)
  const [editNotesProps, setEditNotesProps] = useState<NoteParamsInterface>()
  const [editEventsProps, setEditEventsProps] = useState<EventParamsInterface>()
  const [editTaskProps, setEditTaskProps] = useState<TaskParamsInterface>()
  const [taskPropsByNote, setTaskPropsByNote] = useState<TaskParamsInterface>()
  const [showNewNotes, setNewNotes] = useState<boolean>(false)
  const [showTasks, setShowTasks] = useState<boolean>(false)
  const [showNewEvents, setNewEvents] = useState<boolean>(false)
  const [getGenericDetails] = useLazyQuery(FetchGenericDetails)
  const [getAccountsParties] = useLazyQuery(FetchAccountsParties)
  const [getFetchDesc] = useLazyQuery(FetchActionDesc)
  const [getTaskDetails] = useLazyQuery(FetchTaskDetails)
  const [getEventDetails] = useLazyQuery(FetchMeetingDetails)
  const [getMeetingAssociate] = useLazyQuery(FetchMeetingAssociates)
  const [getSavedFiles] = useLazyQuery<any>(GetSavedFiles)
  const { setToast } = useContext(ToastContext)
  const timeZone = () => {
    const zone = moment.tz.guess()
    const abbreviation = moment.tz(zone).zoneAbbr()
    return abbreviation
  }
  function fetchGenericDetails(id: number) {
    const x = getGenericDetails({
      variables: {
        id: id,
      },
      fetchPolicy: 'no-cache',
    })
    return x
  }
  function fetchAccountParties(id: number, tranType: number) {
    const x = getAccountsParties({
      variables: {
        tranId: id,
        tranType: tranType,
      },
      fetchPolicy: 'no-cache',
    })
    return x
  }
  function fetchFunctionDesc(id: number, tranType: number) {
    const x = getFetchDesc({
      variables: {
        tranId: id,
        tranType: tranType,
      },
      fetchPolicy: 'no-cache',
    })
    return x
  }
  function fetchTaskDetails(id: number) {
    const x = getTaskDetails({
      variables: {
        tranId: id,
      },
      fetchPolicy: 'no-cache',
    })
    return x
  }
  function fetchEventDetails(id: number) {
    const x = getEventDetails({
      variables: {
        tranId: id,
      },
      fetchPolicy: 'no-cache',
    })
    return x
  }
  function fetchEventAssocs(id: number) {
    const x = getMeetingAssociate({
      variables: {
        tranId: id,
      },
      fetchPolicy: 'no-cache',
    })
    return x
  }

  function fetchSavedFiles(id: number, tranType: number) {
    const x = getSavedFiles({
      variables: {
        tranId: id,
        folderName: vars.OppFolderName,
        tranType: tranType,
      },
      fetchPolicy: 'no-cache',
    })
    return x
  }

  useEffect(() => {
    if (taskPropsByNote) {
      setShowTasks(true)
      setNewNotes(false)
    }
  }, [taskPropsByNote])

  const resetInterActions = () => {
    setEditNotesProps(undefined)
    setEditEventsProps(undefined)
    setEditTaskProps(undefined)
  }

  function updateActionsFlow(
    id: number,
    tranType: number,
    meetingId?: number,
    actionFlag?: string
  ) {
    if (actionFlag === 'convert') {
      setLoadingConvert(true)
    } else {
      setLoadingEdit(true)
    }
    resetInterActions()
    const promise: any[] = []
    if (tranType === 1) {
      promise.push(
        fetchGenericDetails(id),
        fetchAccountParties(id, tranType),
        fetchFunctionDesc(id, tranType),
        fetchSavedFiles(id, tranType)
      )
    } else if (tranType === 2) {
      promise.push(
        fetchGenericDetails(id),
        fetchAccountParties(id, tranType),
        fetchFunctionDesc(id, tranType),
        fetchSavedFiles(id, tranType),
        fetchTaskDetails(id)
      )
    } else if (tranType === 3) {
      promise.push(
        fetchGenericDetails(id),
        fetchAccountParties(id, tranType),
        fetchFunctionDesc(id, tranType),
        fetchSavedFiles(id, tranType),
        fetchEventDetails(id),
        fetchEventAssocs(meetingId ?? 0)
      )
    }
    Promise.all(promise)
      .then((res) => {
        if (res.filter((ele) => ele.data === undefined).length > 0) {
          setToast(false, 'Unable to fetch details, please try again')
          setLoadingEdit(false)
          setLoadingConvert(false)
          return
        }
        const genericDetails = res?.[0]?.data ?? {}
        const parties = res?.[1]?.data ?? {}
        const desc = res?.[2]?.data ?? {}
        const clients: Clients[] = []
        const accounts: Accounts[] = []
        const fileResponse = res?.[3]?.data?.note_trandocuments?.map(
          (file: any) => {
            return {
              id: file.id,
              tranId: file.tranid,
              tranType: file.trantype,
              name: file.filename,
              fileExtension:
                file.filename.split('.')[file.filename.split('.').length - 1],
              createdBy: file.createdby,
              createdOn: file.createdon,
              isCallingAPI: false,
              isUploaded: file.activeflag === 1 ? true : false,
            }
          }
        )
        if (parties?.note_tranmappings?.length > 0) {
          parties.note_tranmappings.forEach((element: any) => {
            if (element.partyid) {
              clients.push({
                clientId: parseInt(element.partyid),
                clientName: element.partyname,
                repcode: element.repcode,
              })
            }
            if (element.accountid) {
              accounts.push({
                accountId: element.accountid,
                accountNumber: element.accountnumber,
                accountName: element.accountname,
                repcode: element.repcode,
              })
            }
          })
        }

        const objVal: NoteParamsInterface = {
          typeid: parseInt(genericDetails?.note_notes?.[0]?.typeid),
          subtypeid: parseInt(
            genericDetails?.note_notes?.[0]?.subtypeid?.toString()
          ),
          subject: genericDetails?.note_notes?.[0]?.subject
            ? genericDetails?.note_notes?.[0]?.subject?.toString()
            : '--',
          detail: desc.note_text[0].detail
            ? desc.note_text[0].detail?.toString()
            : '--',
          ownername: genericDetails?.note_notes?.[0]?.ownername?.toString(),
          ownerid: genericDetails?.note_notes?.[0]?.ownerid?.toString(),
          entity: tranType == 1 ? 'Note' : tranType == 2 ? 'Task' : 'Event',
          contacts: '--',
          application: '--',
          clients: clients,
          qccflag: Boolean(genericDetails?.note_notes?.[0]?.qccflag) ?? false,
          accounts: accounts,
          action: 'edit',
          id: genericDetails?.note_notes?.[0]?.id,
          notedate: new Date(
            genericDetails?.note_notes?.[0]?.notedate?.toString()
          ),
          fileDetails: fileResponse,
        }

        if (tranType === 1) {
          if (actionFlag === 'convert') {
            //this is for conversion of note to task
            objVal.action = 'convert'
            objVal.entity = 'Task'
            setTaskPropsByNote((prev: any) => ({
              ...prev,
              ...objVal,
            }))
            //setShowTasks(true)
          } else {
            setEditNotesProps((prev) => ({
              ...prev,
              ...objVal,
            }))
            setNewNotes(true)
          }
        } else if (tranType === 3) {
          const meetingDetails = res?.[4]?.data ?? {}
          const meetingAssociate = res?.[5]?.data ?? {}

          objVal.entity = 'Event'
          setEditEventsProps((prev) => ({
            ...prev,
            ...objVal,
            location: String(meetingDetails?.note_meeting?.[0].location ?? ''),
            startTime: String(
              meetingDetails?.note_meeting?.[0]?.starttime ??
                new Date().toISOString()
            ),
            endTime: String(
              meetingDetails?.note_meeting?.[0]?.endtime ??
                new Date().toISOString()
            ),
            timezone: String(
              meetingDetails?.note_meeting?.[0]?.timezone ?? timeZone()
            ),
            createdBy: String(genericDetails?.note_notes?.[0]?.createdby ?? ''),
            updatedBy: String(genericDetails?.note_notes?.[0]?.updatedby ?? ''),
            postMeetingNote: String(
              meetingDetails?.note_meeting?.[0]?.postmeetingcomment ?? ''
            ),
            preMeetingNote: String(
              meetingDetails?.note_meeting?.[0]?.premeetingcomment ?? ''
            ),
            meetingStatusId: Number(
              meetingDetails?.note_meeting?.[0]?.meetingstatusid ?? 0
            ),
            meetingid: meetingDetails?.note_meeting?.[0]?.id,
            associates: meetingAssociate?.note_meetingassociates?.map(
              (assoc: any) => ({
                adAccount: assoc.employeeid,
                employeeName: assoc.employeename,
              })
            ),
          }))
          setNewEvents(true)
        } else if (tranType === 2) {
          const taskDetails = res?.[4]?.data ?? {}
          objVal.entity = 'Task'
          setEditTaskProps((prev: any) => ({
            ...prev,
            ...objVal,
            notesDes: desc?.note_text?.[0]?.detail
              ? desc?.note_text?.[0]?.detail?.toString()
              : '--',
            dueDate: taskDetails?.note_task?.[0]?.duedate?.toString(),
            assignedTo: taskDetails?.note_task?.[0]?.assignedto?.toString(),
            assignedToName:
              taskDetails?.note_task?.[0]?.assignedtoname?.toString(),
            status: +taskDetails?.note_task?.[0]?.status,
          }))
          setShowTasks(true)
        }
        setLoadingEdit(false)
        setLoadingConvert(false)
      })
      .catch((err) => {
        setLoadingConvert(false)
        setLoadingEdit(false)
        console.log(err)
      })
  }

  const value = useMemo(
    () => ({
      loadingEdit,
      updateActionsFlow,
      editNotesProps,
      editEventsProps,
      editTaskProps,
      showNewEvents,
      showNewNotes,
      showTasks,
      setNewEvents,
      setShowTasks,
      setNewNotes,
      taskPropsByNote,
      loadingConvert,
      resetInterActions,
    }),
    [
      editEventsProps,
      editNotesProps,
      editTaskProps,
      loadingConvert,
      loadingEdit,
      showNewEvents,
      showNewNotes,
      showTasks,
      taskPropsByNote,
    ]
  )

  return (
    <EditActionContext.Provider value={value}>
      {children}
    </EditActionContext.Provider>
  )
}

export default EditActionContext
