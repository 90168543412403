export const SearchTableData = [
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  // {
  //   Header: 'Description',
  //   accessor: 'detail',
  // },
  {
    Header: 'Party/Lead',
    accessor: 'partyname',
  },
  {
    Header: 'Account Name',
    accessor: 'accountname',
  },
  {
    Header: 'Account Number',
    accessor: 'accountnumber',
  },
  {
    Header: 'Type',
    accessor: 'typedescription',
  },
  {
    Header: 'Sub Type',
    accessor: 'subtypedescription',
  },

  {
    Header: 'Due Date',
    accessor: 'duedate',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Owner',
    accessor: 'ownername',
  },
  {
    Header: 'REP CODE',
    accessor: 'repcode',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]

export const leadColumnData = [
  {
    Header: 'Party',
    accessor: 'party',
  },
  {
    Header: 'Account',
    accessor: 'Account',
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Type',
    accessor: 'Type',
  },
  {
    Header: 'Sub Type',
    accessor: 'SubType',
  },
  {
    Header: 'Note Date',
    accessor: 'NoteDate',
  },
  {
    Header: 'Owner',
    accessor: 'owner',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
export const settingListData = [
  {
    Header: 'Custom Columns',
    accessor: 'Custom Columns',
  },
  {
    Header: 'Export',
    accessor: 'Export',
  },
]
