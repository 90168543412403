import { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../../services/AuthContext'

import ClientAndAccountSearchContext from '../../../services/clientAndAccountSearch/ClientAndAccountSearchContext'
import {
  AssociateSearchByNamePayloadInterface,
  AssociateSearchByNameResponseInterface,
} from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'

import { OptionProps } from '../../../components/selectDropdown/ISelectDropdown'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { FormContainer, MasterWrapper, SearchTerm } from './styles'
import OptionLoader from './OptionLoader'

export const AssignedToSearch = () => {
  const [associateList, setAssociateList] = useState<OptionProps[]>([])
  const {
    setAssociate,
    associate,
    associates,
    searchAssociates,
    isApiLoading,
  } = useContext(ClientAndAccountSearchContext)

  const wrapperRef = useRef(null)
  const { accessToken } = useContext(AuthContext)

  const handleAssociateChange = (value: OptionProps) => {
    setAssociate({
      adAccount: value?.value ?? '',
      employeeName: value?.label ?? '',
    } as AssociateSearchByNameResponseInterface)
  }
  function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout
    return (...args: Params) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, timeout)
    }
  }

  const openModal = (value: string) => {
    const clientName = value
    if (clientName.length > 1) {
      const updatedValue: AssociateSearchByNamePayloadInterface = {
        name: clientName,
      }
      searchAssociates(updatedValue, accessToken) // Pass accessToken as the second argument
    }
  }

  useEffect(() => {
    if (associates && associates.length) {
      const temp: OptionProps[] = []
      associates.forEach((item) => {
        temp.push({ ...item, label: item.employeeName, value: item.adAccount })
      })
      setAssociateList(temp)
    }
  }, [associates])

  const handleDebounce = debounce(openModal, 500)

  return (
    <div>
      <FormContainer>
        <SearchTerm>
          <SelectDropdown
            className="w-100"
            height="2.62rem"
            padding="0"
            borderColor={DEFAULT_COLORS.Border.Neutral}
            options={associateList}
            value={{
              label: associate?.employeeName ?? '',
              value: associate?.adAccount ?? '',
            }}
            onInputChange={(value) => handleDebounce(value)}
            onChange={(value) => handleAssociateChange(value)}
            indicatorzIndex={9}
          ></SelectDropdown>
          {isApiLoading ? (
            <MasterWrapper ref={wrapperRef}>
              <OptionLoader />
            </MasterWrapper>
          ) : null}
        </SearchTerm>
      </FormContainer>
    </div>
  )
}
