import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${() => DEFAULT_COLORS.BoxShadow.StyledCardShadow};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  z-index: 1;
  max-height: 30vh;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`
export const TopWrapper = styled.div`
  display: flex;
  margin-left: auto;
  height: 3rem;
  padding-right: 1rem;
  input {
    min-width: 4rem;
  }
`
export const FootWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
`
