import { useContext, useEffect, useRef, useState } from 'react'
import { Footer, TabWrapper, Wrapper } from './styles'
import 'react-quill/dist/quill.snow.css'
// import './style.css'
import { useParams } from 'react-router-dom'
import 'react-quill/dist/quill.bubble.css'
import ReactQuill from 'react-quill'
import { DeltaStatic } from 'quill'
import { LeadInfoProps } from '../overviewSection/IEditDetails'
import Button from '../../../components/button/Button'
import {
  WDBorderButtonPrimary,
  WDButton,
} from '../../../components/ui/WDButton'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { BtnWrapper } from '../styles'
import { vars } from '../../../assets/utils/CommonFunctions'
import { AuthContext } from '../../../services/AuthContext'

type ImportantInformationDetailsProps = {
  state?: LeadInfoProps
  updateLeadInfoData?: (fields: Partial<LeadInfoProps>) => void
}

const ImportantInformationDetails = ({
  state,
  updateLeadInfoData,
}: ImportantInformationDetailsProps) => {
  const { contactId } = useParams()
  const { userAccessDetails } = useContext(AuthContext)
  const [isEditableNotes, setIsEditableNotes] = useState(false)
  const [delta, setDelta] = useState<DeltaStatic>()
  const editorRef = useRef<ReactQuill | null>(null)

  const allColors = [
    '#000000',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#444444',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466',
  ]

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [
        {
          color: allColors,
        },
      ],
      [
        {
          background: allColors,
        },
      ],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'background',
    'align',
    'size',
    'font',
  ]

  function JSONChecker(text: string) {
    if (typeof text !== 'string') {
      return false
    }
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    if (!isEditableNotes) {
      const newContent: DeltaStatic =
        state?.notes && JSONChecker(state?.notes ?? '')
          ? JSON.parse(state?.notes)
          : ''

      newContent && setDelta(newContent)
    }
  }, [contactId, state?.notes, isEditableNotes])

  const updateNotesValue = () => {
    setIsEditableNotes(false)
    updateLeadInfoData?.({
      ...state,
      notes: JSON.stringify(delta),
    })
  }

  const handleProcedureContentChange = () => {
    if (editorRef.current) {
      const newContent = editorRef.current.getEditor().getContents()
      setDelta(newContent)
    }
  }

  return (
    <Wrapper>
      <TabWrapper>
        {!isEditableNotes && (
          <>
            {userAccessDetails.role === vars.adminRole && (
              <div className="panel-heading-two ">
                <BtnWrapper>
                  <WDBorderButtonPrimary>
                    <Button
                      type="button"
                      onClick={() => {
                        setIsEditableNotes(true)
                      }}
                    >
                      {'Edit'}
                    </Button>
                  </WDBorderButtonPrimary>
                </BtnWrapper>
              </div>
            )}
            <div className="panel-body">
              <ReactQuill
                theme="bubble"
                ref={editorRef}
                placeholder={
                  userAccessDetails.role === vars.adminRole
                    ? 'Click edit to add important information'
                    : ''
                }
                value={delta}
                readOnly={true}
              />
            </div>
          </>
        )}
        {isEditableNotes && (
          <>
            <div className="panel-heading-two ">
              <BtnWrapper>
                <WDButton>
                  <Button type="button" onClick={updateNotesValue}>
                    {'Save'}
                  </Button>
                </WDButton>
                <WDBorderButtonPrimary>
                  <Button
                    type="button"
                    onClick={() => setIsEditableNotes(false)}
                    color={DEFAULT_COLORS.Text.Primary}
                  >
                    {'Cancel'}
                  </Button>
                </WDBorderButtonPrimary>
              </BtnWrapper>
            </div>
            <div className="edit-notes">
              <ReactQuill
                theme="snow"
                ref={editorRef}
                value={delta}
                modules={modules}
                formats={formats}
                onChange={handleProcedureContentChange}
              />
            </div>
            <Footer>{'The input must not exceed 8000 characters.'}</Footer>
          </>
        )}
      </TabWrapper>
    </Wrapper>
  )
}
export default ImportantInformationDetails
