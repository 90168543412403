import CRMLogo from '../assets/logos/CRMLogo.svg'
import ChevronUp from '../assets/icons/size24/ChevronUp.svg'
import ChevronDown from '../assets/icons/size24/ChevronDown.svg'
import Success from '../assets/icons/Success.svg'
import Warning from '../assets/icons/Warning.svg'
import Default from '../assets/icons/Default.svg'
import Critical from '../assets/icons/Critical.svg'
import Cancel from '../assets/icons/size16/Cancel.svg'
import Search from '../assets/icons/Search.svg'
import Neutral_Search from '../assets/icons/SearchGray.svg'
import GroupBtnHorizontal from '../assets/icons/GroupBtnHorizontal.svg'
import Contact from '../assets/icons/Contact.svg'
import Avatar from '../assets/icons/Avatar.svg'
import Plus from '../assets/icons/PlusIcon.svg'
import Phone from '../assets/icons/Phone.svg'
import ExportLogo from '../assets/icons/ExportLogo.svg'
import Clock from '../assets/icons/Clock.svg'
import Categories from '../assets/icons/Categories.svg'
import Users from '../assets/icons/Users.svg'
import Calender from '../assets/icons/Calender.svg'
import Ticker from '../assets/icons/Ticker.svg'
import Industry from '../assets/icons/Industry.svg'
import DocSearch from '../assets/icons/DocSearch.svg'
import NameAvatar from '../assets/icons/NameAvatar.svg'
import BuildingBg from '../assets/icons/BuildingBg.svg'
import Edit from '../assets/icons/Edit.svg'
import Exclamation from '../assets/icons/ExclamationWarning.svg'
import styled from 'styled-components'
import { SIZES } from '../theme/Sizes'
export const Icons = {
  Exclamation,
  ExportLogo,
  Plus,
  Phone,
  CRMLogo,
  ChevronUp,
  ChevronDown,
  Success,
  Default,
  Warning,
  Critical,
  Cancel,
  Search,
  GroupBtnHorizontal,
  Contact,
  Avatar,
  Clock,
  Categories,
  Users,
  Calender,
  Ticker,
  Industry,
  DocSearch,
  NameAvatar,
  BuildingBg,
  Edit,
}
export const White_Icons = {}
export const WhiteIcons = {}
export const NeutralIcons = {
  Neutral_Search,
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props: { gap: string }) => props.gap ?? `1rem`};
  flex: 1;
  height: auto;
`

export const ContentItemWrapper = styled.div`
  ${(props: { flex: string; gap: string }) => {
    return `
  width: 100%;
  gap: ${SIZES[3]};
  background: #fff;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  display: flex;
  flex-grow: ${props.flex ?? '0.85'};
  flex-shrink: 1;
  flex-basis: 0%;
  flex-direction: column;
  overflow: auto;
  position: relative;
  `
  }}
`
export const DashboardItemWrappers = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
`
export const ContentTitleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding: ${(props: { padding?: string }) => props.padding ?? '1rem'};
  justify-content: flex-end;
  align-items: center;
  justify-content: ${(props: { justifycontent?: string }) =>
    props.justifycontent ? 'flex-end' : 'space-between'};
  white-space: pre;
`

export const FootWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
`
export const CollaspPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props: { theme?: string }) =>
    props.theme == 'white' ? '0.25rem' : `${SIZES[1]}`};
  background: ${(props: { theme?: string }) =>
    props.theme == 'white' ? '#fff' : `rgb(250 250 250)`};
  padding: ${(props: { theme?: string }) =>
    props.theme == 'white' ? '0px' : `${SIZES[3]}`};
  margin: ${(props: { theme?: string }) =>
    props.theme == 'white' ? '0px' : `1rem`};
  box-shadow: ${(props: { theme?: string }) =>
    props.theme == 'white' ? 'none' : `rgb(0 0 0 / 8%) 0px 2px 3px 0px`};
`
export const RecordWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  gap: 0.5rem;
`
