import {
  createContext,
  Dispatch,
  ReactNode,
  useState,
  useMemo,
  useCallback,
} from 'react'
import {
  AssociateSearchPayloadInterface,
  AssociateSearchResponseInterface,
} from './ISearchContext'
import { associateSearchAPI } from './SearchAPI'

interface AssociateSearchFn {
  (payload: AssociateSearchPayloadInterface, filteredList?: string[]): Promise<
    boolean | void
  >
}

export interface SearchContextInterface {
  errorText: string
  searchAssociates: AssociateSearchFn
  setAssociates: Dispatch<
    React.SetStateAction<AssociateSearchResponseInterface[] | null>
  >
  associates: AssociateSearchResponseInterface[] | null
}

const SearchContext = createContext<SearchContextInterface>(
  {} as SearchContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const SearchContextProvider = ({ children }: ContextProps) => {
  const [associates, setAssociates] = useState<
    AssociateSearchResponseInterface[] | null
  >(null)
  const [errorText, setErrorText] = useState('')


  const searchAssociates = useCallback(
    async (
      payload: AssociateSearchPayloadInterface,
      filteredList?: string[]
    ) => {
      try {

        let flag = false
        await associateSearchAPI(payload)
          .then((response) => {
            if (response.data.length === 0) {
              flag = true
              setErrorText('No Results Found')
            }
            if (filteredList?.length) {
              const updatedResponse = response.data.filter((associate) =>
                filteredList.includes(associate.adAccount)
              )
              setAssociates(updatedResponse)
            } else {
              setAssociates(response.data)
            }
          })
          .catch(() => {
            flag = false
            setErrorText('Please try again')
          })
      
        return flag
      } catch (error) {
       
        if (error instanceof Error) {
       
          console.log(error)
        }
      }
    },
    []
  )

  const value = useMemo(
    () => ({
      searchAssociates,
      setAssociates,
      associates,
      errorText,
    }),
    [searchAssociates, setAssociates, associates, errorText]
  )

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export default SearchContext
