import Button from '../../components/button/Button'
import Label from '../../components/label/Label'
import { SvgClose } from '../../components/svg/SvgClose'
import {
  WDButtonTransparent,
  WDButtonTransparentWithoutHeightNormal,
} from '../../components/ui/WDButton'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import {
  ContentFormWrapper,
  ContentWrapper,
  HeaderWrapper,
  MainWrapper,
  OverlayWrapper,
  Wrapper,
} from '../partyProfile/relationshipActionsPopups/styles'
import { CategoryWrapper, ContentFormSectionWrapper } from './styles'
import { CollaspPanelWrapper } from '../../shared/GlobalStyle'
import { WDContactInfoWrapper } from '../../components/ui/WDWrapper'

interface SelectContactTypeProps {
  setShowRelationship: (value: boolean) => void
  returnContactCategory: (value: string) => void
  OthersRequired?: boolean
}

const SelectContactType = ({
  setShowRelationship,
  returnContactCategory,
  OthersRequired = true,
}: SelectContactTypeProps) => {
  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <HeaderWrapper>
            <WDSubFormLabelHeading>
              <Label>{'Select Contact Type'}</Label>
            </WDSubFormLabelHeading>
            <WDButtonTransparent>
              <Button type="button" onClick={() => setShowRelationship(false)}>
                <SvgClose fillColor={DEFAULT_COLORS.Text.Black}></SvgClose>
              </Button>
            </WDButtonTransparent>
          </HeaderWrapper>
          <ContentWrapper padding={'0'}>
            <ContentFormWrapper>
              <CollaspPanelWrapper>
                <WDContactInfoWrapper>
                  <Label fontSize="1.2rem" fontFamily="SourceSansPro-SemiBold">
                    {'Lead/Prospect'}
                  </Label>
                  <ContentFormSectionWrapper>
                    <CategoryWrapper
                      onClick={() => returnContactCategory('Individual')}
                    >
                      <WDButtonTransparentWithoutHeightNormal>
                        <Button type="button">{'Individual'}</Button>
                      </WDButtonTransparentWithoutHeightNormal>
                    </CategoryWrapper>
                    <CategoryWrapper
                      onClick={() => returnContactCategory('Business')}
                    >
                      <WDButtonTransparentWithoutHeightNormal>
                        <Button type="button">{'Business'}</Button>
                      </WDButtonTransparentWithoutHeightNormal>
                    </CategoryWrapper>
                    <CategoryWrapper
                      onClick={() => returnContactCategory('Trust')}
                    >
                      <WDButtonTransparentWithoutHeightNormal>
                        <Button type="button">{'Trust'}</Button>
                      </WDButtonTransparentWithoutHeightNormal>
                    </CategoryWrapper>
                  </ContentFormSectionWrapper>
                </WDContactInfoWrapper>
              </CollaspPanelWrapper>
              {OthersRequired && (
                <CollaspPanelWrapper>
                  <WDContactInfoWrapper>
                    <Label
                      fontSize="1.2rem"
                      fontFamily="SourceSansPro-SemiBold"
                    >
                      {'Other'}
                    </Label>
                    <ContentFormSectionWrapper>
                      <CategoryWrapper
                        onClick={() => returnContactCategory('New Recruit')}
                      >
                        <WDButtonTransparentWithoutHeightNormal>
                          <Button type="button">{'New Recruit'}</Button>
                        </WDButtonTransparentWithoutHeightNormal>
                      </CategoryWrapper>
                      <CategoryWrapper
                        onClick={() =>
                          returnContactCategory('Personal Contact')
                        }
                      >
                        <WDButtonTransparentWithoutHeightNormal>
                          <Button type="button">{'Personal Contact'}</Button>
                        </WDButtonTransparentWithoutHeightNormal>
                      </CategoryWrapper>
                    </ContentFormSectionWrapper>
                  </WDContactInfoWrapper>
                </CollaspPanelWrapper>
              )}
            </ContentFormWrapper>
          </ContentWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}
export default SelectContactType
