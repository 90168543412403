import styled from 'styled-components'
import { SIZES } from '../../theme/Sizes'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const CreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES[1]};
  width: 100%;
`
export const ItemSubWrp = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
`
export const TextBoxWrap = styled.div`
  display: block;
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
  input {
    min-width: 16rem;
    padding: 0.5rem 1rem !important;
  }
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  /* align-items: center; */
  gap: ${SIZES[3]};
`
export const CollapseWrapper = styled.div`
  display: flex;
  border-radius: ${SIZES[1]};
  width: 100%;
  gap: ${SIZES[1]};
  cursor: pointer;
  align-items: center;
`
export const CollapseHeaderWrapper = styled.div`
  display: flex;
  flex: 0 1 90%;
  width: 100%;
`
export const CollapseButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 10%;
  justify-content: flex-end;
  width: 100%;
`

export const SelectBoxLarge = styled.div`
  min-width: 16rem;
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
`
