import { Wrapper } from '../../shared/GlobalStyle'
import ClientViewDashboard from './clientViewDashboard/ClientViewDashboard'

export const Dashboard = () => {

  return (
    <Wrapper>
      <ClientViewDashboard />
    </Wrapper>
  )
}

export default Dashboard
