import { useEffect, useMemo, useState } from 'react'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { Textbox } from '../../components/textbox/Textbox'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { AddressInformationProps, PhoneInterface } from './IPhoneInformation'
import {
  CreateWrapper,
  ItemWrapper,
  TextBoxWrap,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
  CheckboxWrapper,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import {
  WDButton,
  WDButtonTransparent,
  WDButtonMediumPrimaryBorder,
} from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import {
  ButtonWrapper,
  ButtonWrapperRight,
  WDContactInfoWrapper,
} from '../../components/ui/WDWrapper'
import Checkbox from '../../components/checkbox/Checkbox'
import {
  ItemSpaeWrapper,
  StyledButtonWrapper,
} from '../emailInformation/styles'
import { ItemSubWrp } from '../individualInformation/styles'
import { formatPhoneNumber } from '../../services/Utils'

const PhoneInformation = ({
  isUpdate,
  updateData,
  updatePhoneData,
  leadInfoData,
}: AddressInformationProps) => {
  const [isShippinInformation, setIsShippingInformationOpen] = useState(true)
  const [phoneList, setPhoneList] = useState<PhoneInterface[]>([
    {
      phoneType: '',
      isPrimary: true,
      activeFlag: true,
      phoneNo: 0,
      phoneExtension: 0,
      speedDial: 0,
    },
  ])

  const getPhoneList = (index: number, fieldName: string, value: string) => {
    if (phoneList.length > 1) {
      const filteredPhone: PhoneInterface[] = phoneList.map((phone, i) => {
        if (phone.phoneType === value && fieldName === 'phoneType') {
          return { ...phone, phoneType: '' }
        } else if (fieldName === 'isPrimary' && index !== i) {
          return { ...phone, isPrimary: false }
        }
        return { ...phone }
      })
      return filteredPhone
    } else {
      return phoneList
    }
  }

  const updatePhoneList = (index: number, fieldName: string, value: string) => {
    const currentPhoneList = getPhoneList(index, fieldName, value).map(
      (phone, i) => {
        if (index === i) {
          switch (fieldName) {
            case 'isPrimary':
              return { ...phone, isPrimary: value === 'true' }
            case 'phoneType':
              return { ...phone, phoneType: value }
            case 'phoneNo':
              return { ...phone, phoneNo: +value }
            case 'phoneExtension':
              return { ...phone, phoneExtension: +value }
            case 'speedDial':
              return { ...phone, speedDial: +value }
            default:
              return { ...phone }
          }
        } else {
          return { ...phone }
        }
      }
    )
    setPhoneList(currentPhoneList)
    updateData({ phoneList: currentPhoneList }, fieldName)
  }

  const contactTypeList = useMemo(
    () => [
      { value: 'Home', label: 'Home' },
      { value: 'Mobile', label: 'Mobile' },
      { value: 'Work', label: 'Work' },
    ],
    []
  )

  const [filteredContactTypeList, setFilteredContactTypeList] =
    useState(contactTypeList)

  const deleteContact = (indexNumber: number) => {
    const updatedPhoneList = phoneList.map((phone, index) => {
      if (index === indexNumber) {
        return { ...phone, activeFlag: false }
      } else {
        return { ...phone }
      }
    })
    setPhoneList([...updatedPhoneList])
    updateData({ phoneList: updatedPhoneList })
  }

  const addAnotherContact = () => {
    if (phoneList.length < 3) {
      const updatedPhoneList = phoneList
      updatedPhoneList.push({
        phoneType: '',
        isPrimary: false,
        activeFlag: true,
        phoneNo: 0,
        phoneExtension: 0,
        speedDial: 0,
      })
      setPhoneList([...updatedPhoneList])
    }
  }

  useEffect(() => {
    if (leadInfoData?.phoneList?.length) {
      setPhoneList(leadInfoData?.phoneList)
    }
  }, [leadInfoData.phoneList])

  useEffect(() => {
    if (phoneList.length) {
      setFilteredContactTypeList(
        contactTypeList.filter(
          (contact) =>
            !phoneList
              .filter((phone) => phone.activeFlag)
              .map((phone) => {
                return phone.phoneType
              })
              .includes(contact.value)
        )
      )
    }
  }, [contactTypeList, phoneList])

  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper
          onClick={() => setIsShippingInformationOpen(!isShippinInformation)}
        >
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">{'Phones'}</Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() =>
                  setIsShippingInformationOpen(!isShippinInformation)
                }
              >
                <Image
                  image={
                    isShippinInformation ? Icons.ChevronUp : Icons.ChevronDown
                  }
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isShippinInformation && (
          <>
            {phoneList.map((phone, index) => {
              if (phone.activeFlag) {
                return (
                  <>
                    <ItemWrapper key={index}>
                      <ItemSubWrp>
                        <CreateWrapper>
                          <Label>{'Phone Type'}</Label>
                          <SelectDropdown
                            className="w-100"
                            borderColor={DEFAULT_COLORS.Border.Neutral}
                            options={
                              phone.phoneType
                                ? contactTypeList
                                : filteredContactTypeList
                            }
                            padding="0rem"
                            height="2.62rem"
                            placeholder={'Select'}
                            value={{
                              value: phone.phoneType,
                              label: phone.phoneType,
                            }}
                            onChange={(option) =>
                              updatePhoneList(index, 'phoneType', option.value)
                            }
                          />
                        </CreateWrapper>
                        <CreateWrapper>
                          <Label>{'Phone'}</Label>
                          <Textbox
                            onChange={(value) => {
                              const val = value.replace(/[^\d]/g, '')
                              updatePhoneList(index, 'phoneNo', val)
                            }}
                            value={
                              phone.phoneNo == 0
                                ? ''
                                : formatPhoneNumber(phone.phoneNo.toString())
                            }
                            disabled={!phone.phoneType}
                            // type="number"
                          />
                        </CreateWrapper>
                        <CreateWrapper>
                          <Label>{'Extention'}</Label>
                          <Textbox
                            onChange={(value) =>
                              updatePhoneList(index, 'phoneExtension', value)
                            }
                            value={
                              phone.phoneExtension == 0
                                ? ''
                                : phone.phoneExtension
                            }
                            disabled={!phone.phoneType}
                            type="number"
                          />
                        </CreateWrapper>
                        <CreateWrapper>
                          <Label>{'Speed Dial'}</Label>
                          <TextBoxWrap>
                            <Textbox
                              onChange={(value) =>
                                updatePhoneList(index, 'speedDial', value)
                              }
                              value={
                                phone.speedDial == 0 ? '' : phone.speedDial
                              }
                              disabled={!phone.phoneType}
                              type="number"
                            />
                          </TextBoxWrap>
                        </CreateWrapper>
                      </ItemSubWrp>
                    </ItemWrapper>
                    <ItemSpaeWrapper>
                      <ItemWrapper>
                        <CreateWrapper>
                          <CheckboxWrapper>
                            <Checkbox
                              onChange={(checked) =>
                                updatePhoneList(
                                  index,
                                  'isPrimary',
                                  checked ? 'true' : 'false'
                                )
                              }
                              checked={phone.isPrimary}
                              disabled={phone.isPrimary}
                            ></Checkbox>
                            <Label>{'Primary'}</Label>
                          </CheckboxWrapper>
                        </CreateWrapper>
                      </ItemWrapper>
                      {!phone.isPrimary && (
                        <ItemWrapper>
                          <StyledButtonWrapper>
                            <Button
                              type="button"
                              bgColor={DEFAULT_COLORS.Background.DangerStrong}
                              disabled={phone.isPrimary}
                              onClick={() => deleteContact(index)}
                            >
                              {'Delete'}
                            </Button>
                          </StyledButtonWrapper>
                        </ItemWrapper>
                      )}
                    </ItemSpaeWrapper>
                  </>
                )
              }
            })}
            <ButtonWrapper>
              <ButtonWrapperRight>
                <WDButtonMediumPrimaryBorder>
                  <Button
                    type="button"
                    onClick={() => addAnotherContact()}
                    disabled={!(phoneList.length < 3)}
                  >
                    Add Another Phone Number
                  </Button>
                </WDButtonMediumPrimaryBorder>
                {isUpdate && (
                  <WDButton>
                    <Button type="button" onClick={updatePhoneData}>
                      {'Save'}
                    </Button>
                  </WDButton>
                )}
              </ButtonWrapperRight>
            </ButtonWrapper>
          </>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default PhoneInformation
