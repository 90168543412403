import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import {
  clientSearchByNameAPI,
  GetClientAccounts,
  getIndividualClientDetails,
  getPartyAddress,
  getPartyContact,
  getPartyEmail,
} from './ClientsApi'

import {
  ClientAccountInterface,
  ClientAddressParamsInterface,
  ClientDetailsResponseInterface,
  ClientEmailInterface,
  ClientPayloadInterface,
  ClientPhoneInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  PartyPayloadInterface,
} from './IClientsContext'

interface IndividualDetailsFn {
  (payload: PartyPayloadInterface): Promise<
    ClientDetailsResponseInterface | undefined
  >
}
interface AddressDetailsFn {
  (payload: ClientPayloadInterface): Promise<
    ClientAddressParamsInterface[] | undefined
  >
}
interface ContactDetailsFn {
  (payload: ClientPayloadInterface): Promise<ClientPhoneInterface[] | undefined>
}
interface EmailDetailsFn {
  (payload: ClientPayloadInterface): Promise<ClientEmailInterface[] | undefined>
}

interface AccountDetailsFn {
  (payload: PartyPayloadInterface): Promise<
    ClientAccountInterface[] | undefined
  >
}
interface ClientSearchFn {
  (payload: ClientSearchByNamePayloadInterface): Promise<void>
}

export interface ClientContextInterface {
  loading: boolean
  getIndividualClientDetailsAPI: IndividualDetailsFn
  getAddressDetailsApi: AddressDetailsFn

  getContactDetailsApi: ContactDetailsFn
  getEmailDetailsApi: EmailDetailsFn
  getAccountDetailsApi: AccountDetailsFn
  individualDetails: ClientDetailsResponseInterface | undefined
  addressDetails: ClientAddressParamsInterface[] | undefined
  phoneDetails: ClientPhoneInterface[] | undefined
  emailDetails: ClientEmailInterface[] | undefined
  accountDetails: ClientAccountInterface[] | undefined
  clients: ClientSearchByNameResponseInterface[] | null
  setClients: any
  searchClients: ClientSearchFn
  setSelectedClients: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface[]>
  >
  selectedClient: ClientSearchByNameResponseInterface[]
}

const ClientContext = createContext<ClientContextInterface>(
  {} as ClientContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const ClientContextProvider = ({ children }: ContextProps) => {
  const [loading, setLoading] = useState(false)
  const [individualDetails, setIndividualDetails] =
    useState<ClientDetailsResponseInterface>()
  const [addressDetails, setAddressDetails] = useState<
    ClientAddressParamsInterface[]
  >([])
  const [phoneDetails, setPhoneDetails] = useState<ClientPhoneInterface[]>([])
  const [emailDetails, setEmailDetails] = useState<ClientEmailInterface[]>([])
  const [accountDetails, setAccountDetails] = useState<
    ClientAccountInterface[]
  >([])

  const [clients, setClients] = useState<
    ClientSearchByNameResponseInterface[] | null
  >(null)
  const [selectedClient, setSelectedClients] = useState<
    ClientSearchByNameResponseInterface[]
  >([])

  const getIndividualClientDetailsAPI = useCallback(async function (
    payload: PartyPayloadInterface
  ) {
    try {
      const response = await getIndividualClientDetails(payload)
      setIndividualDetails(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  [])

  const getAddressDetailsApi = useCallback(async function (
    payload: ClientPayloadInterface
  ) {
    try {
      const response = await getPartyAddress(payload)
      setAddressDetails(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  [])
  const searchClients = useCallback(
    async (payload: ClientSearchByNamePayloadInterface) => {
      setLoading(true)
      try {
        const response = await clientSearchByNameAPI(payload)
        setClients(response.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    },
    []
  )
  const getContactDetailsApi = useCallback(async function (
    payload: ClientPayloadInterface
  ) {
    try {
      const response = await getPartyContact(payload)
      setPhoneDetails(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  [])
  const getEmailDetailsApi = useCallback(async function (
    payload: ClientPayloadInterface
  ) {
    try {
      const response = await getPartyEmail(payload)
      setEmailDetails(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  [])

  const getAccountDetailsApi = useCallback(async function (
    payload: PartyPayloadInterface
  ) {
    try {
      const response = await GetClientAccounts(payload)
      setAccountDetails(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  [])

  const value = useMemo(
    () => ({
      loading,
      getIndividualClientDetailsAPI,

      getContactDetailsApi,
      getEmailDetailsApi,
      getAddressDetailsApi,
      getAccountDetailsApi,
      individualDetails,
      phoneDetails,
      addressDetails,
      emailDetails,
      accountDetails,
      clients,
      setClients,
      searchClients,
      setSelectedClients,
      selectedClient,
    }),
    [
      accountDetails,
      addressDetails,
      clients,
      emailDetails,
      getAccountDetailsApi,
      getAddressDetailsApi,
      getContactDetailsApi,
      getEmailDetailsApi,
      getIndividualClientDetailsAPI,
      individualDetails,
      loading,
      phoneDetails,
      searchClients,
      selectedClient,
    ]
  )

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  )
}

export default ClientContext
