import styled from 'styled-components'
export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  svg{
    height: 100%;
  }
`
export const NoDataSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
