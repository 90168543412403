import { useContext, useEffect, useState } from 'react'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { SvgClose } from '../../../components/svg/SvgClose'
import {
  WDBorderButtonPrimary,
  WDButton,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import { WDSubFormLabelHeading } from '../../../components/ui/WDLabel'
import ContactDetails from '../../../composites/contactDetails/ContactDetails'
import ContactInformation from '../../../composites/individualInformation/individualInformation'
import URLInformation from '../../../composites/urlInformation/URLInformation'
import { ThemeContext } from '../../../store/ThemeContext'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { LeadInfoProps } from '../../createContactList/ICreateLead'
import { BtnWrapper } from '../styles'
import {
  CrossWrapper,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
  MasterWrapper,
  OverlayTitleWrapper,
  OverlayWrapper,
  Wrapper,
} from './styles'

interface EditContactProps {
  setShowEditContact: (value: boolean) => void
  leadDetails?: LeadInfoProps
  updateLeadInfoData: (fields: Partial<LeadInfoProps>) => void
  inputErrors: string[]
  setInputErrors: (errors: string[]) => void
}

const EditContactDetails = ({
  setShowEditContact,
  leadDetails,
  updateLeadInfoData,
  inputErrors,
  setInputErrors,
}: EditContactProps) => {
  const { isDarkMode } = useContext(ThemeContext)
  const [leadInfoDetails, setLeadInfoDetails] = useState<LeadInfoProps>(
    {} as LeadInfoProps
  )

  useEffect(() => {
    if (leadDetails) {
      setLeadInfoDetails(leadDetails)
    }
  }, [leadDetails])

  const formatPhoneNumber = (value: string) => {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`
  }

  const updateContactInfoData = (
    data: Partial<LeadInfoProps>,
    fieldName?: string
  ) => {
    if (data.phoneNumber) {
      setInputErrors(inputErrors.filter((key) => key !== fieldName))
      const formattedPhoneNumber = formatPhoneNumber(data.phoneNumber)
      leadInfoDetails.phoneNumber = formattedPhoneNumber
      setLeadInfoDetails({ ...leadInfoDetails })
    } else if (data.fax) {
      const formattedFaxNumber = formatPhoneNumber(data.fax)
      setInputErrors(inputErrors.filter((key) => key !== fieldName))
      leadInfoDetails.fax = formattedFaxNumber
      setLeadInfoDetails({ ...leadInfoDetails })
    } else {
      setInputErrors(inputErrors.filter((key) => key !== fieldName))
      setLeadInfoDetails((prev) => {
        return { ...prev, ...data }
      })
    }
  }

  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <MasterWrapper>
            <HeaderWrapper>
              <OverlayTitleWrapper>
                <WDSubFormLabelHeading>
                  <Label>{'Update Contact Details'}</Label>
                </WDSubFormLabelHeading>
                <CrossWrapper>
                  <WDButtonTransparent>
                    <Button
                      type="button"
                      onClick={() => setShowEditContact(false)}
                    >
                      <SvgClose fillColor="#000"></SvgClose>
                    </Button>
                  </WDButtonTransparent>
                </CrossWrapper>
              </OverlayTitleWrapper>
              {leadInfoDetails.status !== 'Client' && (
                <ContactInformation
                  {...leadInfoDetails}
                  updateData={updateContactInfoData}
                  leadInfoData={leadInfoDetails}
                  inputErrors={inputErrors}
                />
              )}
              <ContactDetails
                {...leadInfoDetails}
                updateData={updateContactInfoData}
                leadInfoData={leadInfoDetails}
                inputErrors={inputErrors}
              />
              <URLInformation
                {...leadInfoDetails}
                updateData={updateContactInfoData}
                leadInfoData={leadInfoDetails}
                inputErrors={inputErrors}
              />
              <FooterWrapper>
                <BtnWrapper>
                  <WDBorderButtonPrimary>
                    <Button
                      type="button"
                      onClick={() => setShowEditContact(false)}
                      color={
                        isDarkMode
                          ? DEFAULT_COLORS.Text.UserDark
                          : DEFAULT_COLORS.Text.Primary
                      }
                    >
                      {'Cancel'}
                    </Button>
                  </WDBorderButtonPrimary>
                  <WDButton>
                    <Button
                      type="button"
                      onClick={() => {
                        updateLeadInfoData?.(leadInfoDetails)
                      }}
                    >
                      {'Save'}
                    </Button>
                  </WDButton>
                </BtnWrapper>
              </FooterWrapper>
            </HeaderWrapper>
          </MasterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}
export default EditContactDetails
