import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgClose = ({ fillColor }: ISvg) => {
  return (
    <StyledDiv>
      <svg
        width="14"
        height="14"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.94002 4.9998L8.80669 2.1398C8.93222 2.01426 9.00275 1.844 9.00275 1.66646C9.00275 1.48893 8.93222 1.31867 8.80669 1.19313C8.68115 1.0676 8.51089 0.99707 8.33335 0.99707C8.15582 0.99707 7.98556 1.0676 7.86002 1.19313L5.00002 4.0598L2.14002 1.19313C2.01448 1.0676 1.84422 0.99707 1.66669 0.99707C1.48915 0.99707 1.31889 1.0676 1.19335 1.19313C1.06782 1.31867 0.997293 1.48893 0.997293 1.66646C0.997293 1.844 1.06782 2.01426 1.19335 2.1398L4.06002 4.9998L1.19335 7.8598C1.13087 7.92177 1.08127 7.99551 1.04743 8.07675C1.01358 8.15799 0.996155 8.24512 0.996155 8.33313C0.996155 8.42114 1.01358 8.50828 1.04743 8.58951C1.08127 8.67075 1.13087 8.74449 1.19335 8.80646C1.25533 8.86895 1.32906 8.91855 1.4103 8.95239C1.49154 8.98624 1.57868 9.00366 1.66669 9.00366C1.75469 9.00366 1.84183 8.98624 1.92307 8.95239C2.00431 8.91855 2.07805 8.86895 2.14002 8.80646L5.00002 5.9398L7.86002 8.80646C7.922 8.86895 7.99573 8.91855 8.07697 8.95239C8.15821 8.98624 8.24535 9.00366 8.33335 9.00366C8.42136 9.00366 8.5085 8.98624 8.58974 8.95239C8.67098 8.91855 8.74471 8.86895 8.80669 8.80646C8.86917 8.74449 8.91877 8.67075 8.95261 8.58951C8.98646 8.50828 9.00389 8.42114 9.00389 8.33313C9.00389 8.24512 8.98646 8.15799 8.95261 8.07675C8.91877 7.99551 8.86917 7.92177 8.80669 7.8598L5.94002 4.9998Z"
          fill={fillColor}
        />
      </svg>
    </StyledDiv>
  )
}
