import { StyledDiv } from './styles'

export const SvgDeleteIconWithoutBorder = () => {
  return (
    <StyledDiv>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3333 8.00016H14.6667V7.3335C14.6667 6.80306 14.456 6.29436 14.0809 5.91928C13.7058 5.54421 13.1971 5.3335 12.6667 5.3335H11.3333C10.8029 5.3335 10.2942 5.54421 9.91912 5.91928C9.54405 6.29436 9.33333 6.80306 9.33333 7.3335V8.00016H6.66667C6.48986 8.00016 6.32029 8.0704 6.19526 8.19543C6.07024 8.32045 6 8.49002 6 8.66683C6 8.84364 6.07024 9.01321 6.19526 9.13823C6.32029 9.26326 6.48986 9.3335 6.66667 9.3335H7.33333V16.6668C7.33333 17.1973 7.54405 17.706 7.91912 18.081C8.29419 18.4561 8.8029 18.6668 9.33333 18.6668H14.6667C15.1971 18.6668 15.7058 18.4561 16.0809 18.081C16.456 17.706 16.6667 17.1973 16.6667 16.6668V9.3335H17.3333C17.5101 9.3335 17.6797 9.26326 17.8047 9.13823C17.9298 9.01321 18 8.84364 18 8.66683C18 8.49002 17.9298 8.32045 17.8047 8.19543C17.6797 8.0704 17.5101 8.00016 17.3333 8.00016ZM10.6667 7.3335C10.6667 7.15669 10.7369 6.98712 10.8619 6.86209C10.987 6.73707 11.1565 6.66683 11.3333 6.66683H12.6667C12.8435 6.66683 13.013 6.73707 13.1381 6.86209C13.2631 6.98712 13.3333 7.15669 13.3333 7.3335V8.00016H10.6667V7.3335ZM15.3333 16.6668C15.3333 16.8436 15.2631 17.0132 15.1381 17.1382C15.013 17.2633 14.8435 17.3335 14.6667 17.3335H9.33333C9.15652 17.3335 8.98695 17.2633 8.86193 17.1382C8.7369 17.0132 8.66667 16.8436 8.66667 16.6668V9.3335H15.3333V16.6668Z"
          fill="#2563EB"
        />
      </svg>
    </StyledDiv>
  )
}
