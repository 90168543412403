import React, { useContext, useEffect, useState } from 'react'
import {
  CountWrapper,
  InfoItemWrapper,
  InfoTitle,
  InfoTitleDanger,
  InfoTitleGreen,
  InfoWrp,
  TitleWrapper,
} from './styles'
import OpportunitiesDetails from '../../composites/opportunitiesDetails/OpportunitiesDetails'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  GET_OPPORTUNITIES_CALCULATION,
  GET_OPPORTUNITIES_CALCULATION_BY_Book,
  GET_OPPORTUNITIES_DATA_HEADER,
} from '../../services/graphQl/CustomerGraphQl'
import { ContentItemWrapper, Wrapper } from '../../shared/GlobalStyle'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_COLORS } from '../../theme/Colors'
import Button from '../../components/button/Button'
import {
  StyledButtonTransparentWithoutHeight,
} from '../../components/ui/WDButton'
import UserContext from '../../services/user/UserContext'
import { dateFormate } from '../../assets/utils/CommonFunctions'

const Opportunities = () => {
  const { user } = useContext(UserContext)
  const [selectedTab, setSelectedTab] = useState('Created By')
  const [getOppCalculations, { data }] = useLazyQuery(
    selectedTab == 'Created By'
      ? GET_OPPORTUNITIES_CALCULATION
      : GET_OPPORTUNITIES_CALCULATION_BY_Book,
    { fetchPolicy: 'no-cache' }
  )
  const { data: headerData } = useQuery(GET_OPPORTUNITIES_DATA_HEADER, {
    variables: {
      adUserName: user.username,
    },
    fetchPolicy: 'no-cache',
  })
  const navigate = useNavigate()
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  useEffect(() => {
    getOppCalculations({
      variables: {
        adId: user.username,
      },
    })
  }, [getOppCalculations, user.username])

  const handleRoute = () => {
    navigate(
      `/opportunities/${headerData?.opportunities_opportunities?.[0]?.id}`
    )
  }
  return (
    <Wrapper>
      <InfoItemWrapper>
        <InfoWrp>
          <InfoTitle>
            {data?.opportunities_vw_oppscalculation?.[0]?.openops
              ? data?.opportunities_vw_oppscalculation?.[0]?.openops
              : data?.opportunities_vw_opportunitiesbybook?.[0]?.openops
              ? data?.opportunities_vw_opportunitiesbybook?.[0]?.openops
              : '-'}
          </InfoTitle>
          <span>OPEN OPS</span>
        </InfoWrp>
        <InfoWrp>
          <InfoTitle>
            {data?.opportunities_vw_oppscalculation?.[0]?.potentialrevenue
              ? USDollar.format(
                  data?.opportunities_vw_oppscalculation?.[0]?.potentialrevenue
                )
              : data?.opportunities_vw_opportunitiesbybook?.[0]
                  ?.potentialrevenue
              ? USDollar.format(
                  data?.opportunities_vw_opportunitiesbybook?.[0]
                    .potentialrevenue
                )
              : '-'}
          </InfoTitle>
          <span>POTENTIAL</span>
        </InfoWrp>
        <InfoWrp>
          <InfoTitleGreen>
            {data?.opportunities_vw_oppscalculation?.[0]?.closedwon
              ? USDollar.format(
                  data?.opportunities_vw_oppscalculation?.[0]?.closedwon
                )
              : data?.opportunities_vw_opportunitiesbybook?.[0]?.closedwon
              ? USDollar.format(
                  data?.opportunities_vw_opportunitiesbybook?.[0].closedwon
                )
              : '-'}
          </InfoTitleGreen>
          <TitleWrapper>
            <span>CLOSED WON</span>
            <CountWrapper>
              {data?.opportunities_vw_oppscalculation?.[0]?.countclosedwon}
            </CountWrapper>
          </TitleWrapper>
        </InfoWrp>
        <InfoWrp>
          <InfoTitleDanger>
            {data?.opportunities_vw_oppscalculation?.[0]?.closedlost
              ? USDollar.format(
                  data?.opportunities_vw_oppscalculation?.[0]?.closedlost
                )
              : data?.opportunities_vw_opportunitiesbybook?.[0]?.closedlost
              ? USDollar.format(
                  data?.opportunities_vw_opportunitiesbybook?.[0].closedlost
                )
              : '-'}
          </InfoTitleDanger>
          <TitleWrapper>
            <span>CLOSED LOST</span>
            <CountWrapper>
              {data?.opportunities_vw_oppscalculation?.[0]?.countclosedlost}
            </CountWrapper>
          </TitleWrapper>
        </InfoWrp>
        <InfoWrp>
          {headerData?.opportunities_opportunities?.[0]?.name ? (
            <StyledButtonTransparentWithoutHeight>
              <Button
                justify="center"
                type="button"
                color={DEFAULT_COLORS.Background.Primary}
                onClick={handleRoute}
              >
                {headerData?.opportunities_opportunities?.[0]?.name}
              </Button>
            </StyledButtonTransparentWithoutHeight>
          ) : (
            <p>-</p>
          )}
          <span>LAST ADDED OPS</span>
        </InfoWrp>
        <InfoWrp>
          <InfoTitle>
            {headerData?.opportunities_opportunities?.[0]?.createddate
              ? dateFormate(
                  headerData?.opportunities_opportunities?.[0]?.createddate,
                  true
                )
              : '-'}
          </InfoTitle>
          <span>LAST ADDED ON</span>
        </InfoWrp>
      </InfoItemWrapper>
      <ContentItemWrapper>
        <OpportunitiesDetails
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </ContentItemWrapper>
    </Wrapper>
  )
}

export default Opportunities
