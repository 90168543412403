import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { WDLabelPrimaryLarge } from '../../components/ui/WDLabel'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import { GET_DASHBOARD_EVENTS } from '../../services/graphQl/CustomerGraphQl'
import UserContext from '../../services/user/UserContext'
import { ContentTitleWrapper, FootWrapper } from '../../shared/GlobalStyle'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { ItemsWrapper } from '../dashboardTask/style'
import { columnData } from './DashboardEventData'
import Lottie from 'lottie-react'
import { LottieWrapperMedium } from '../loader/styles'
import LottieLoader from '../../assets/lottiefiles/loader.json'

const DashboardEvents = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const { refetchInteractionsFlag, setRefetchInteractionsFlag } =
    useContext(DashboardContext)

  const handleRoute = () => {
    navigate('/events')
  }
  const [getDashboardEvents, { data: eventData, loading: eventLoading }] =
    useLazyQuery(GET_DASHBOARD_EVENTS, {
      variables: {
        limit: 10,
        offset: 0,
        userName: user?.username,
      },
      fetchPolicy: 'no-cache',
    })

  useEffect(() => {
    getDashboardEvents()
    setRefetchInteractionsFlag(false)
  }, [refetchInteractionsFlag])

  useEffect(() => {
    if (eventData && eventData?.note_vw_wealth_events_createdby.length > 0) {
      const finalResponse = eventData.note_vw_wealth_events_createdby.filter(
        (meeting: any) =>
          meeting.starttime?.endsWith('Z')
            ? meeting.starttime >= new Date().toISOString()
            : `${meeting.starttime}Z` >= new Date().toISOString()
      )
      setUpcomingEvents(finalResponse)
    }
  }, [eventData])

  return (
    <>
      <ContentTitleWrapper>
        <WDLabelPrimaryLarge>{'Upcoming Events'}</WDLabelPrimaryLarge>
      </ContentTitleWrapper>
      <ItemsWrapper>
        {eventLoading ? (
          <LottieWrapperMedium>
            <Lottie animationData={LottieLoader} loop={true} />
          </LottieWrapperMedium>
        ) : (
          <Table
            refetchInteraction={getDashboardEvents}
            tableColumns={
              upcomingEvents && upcomingEvents.length > 0 ? columnData : []
            }
            allData={upcomingEvents ?? []}
          />
        )}
      </ItemsWrapper>
      {upcomingEvents && upcomingEvents.length > 0 && (
        <FootWrapper>
          <WDButtonTransparent>
            <Button
              type="button"
              color={DEFAULT_COLORS.Background.Primary}
              onClick={handleRoute}
            >
              {'Click here for more items'}
            </Button>
          </WDButtonTransparent>
        </FootWrapper>
      )}
    </>
  )
}

export default DashboardEvents
