import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateTaskComponent } from 'note-widget'
//import { CreateTaskComponent } from '../../noteWidget'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { vars } from '../../assets/utils/CommonFunctions'
import Table from '../../components/table/Table'
import Loader from '../../composites/loader/Loader'
import {
  leadsData,
  partiesData,
  subTypeData,
  typeData,
} from '../../reducers/leadPartySlice'
import { AuthContext } from '../../services/AuthContext'
import EditActionContext from '../../services/editActionsContext/EditActionsContext'
import {
  GET_NOTES_BOOK,
  GET_TASKS,
  GET_TASKS_COUNT,
  GET_TASKS_REPCODE_LIST,
  UPDATE_TASK_STATUS,
} from '../../services/graphQl/CustomerGraphQl'
import UserContext from '../../services/user/UserContext'
import appConfig from '../../settings/appConfig'
import { ContentItemWrapper, Wrapper } from '../../shared/GlobalStyle'
import { SearchTableData } from './TaskData'
import FilterContext, {
  FilterPayloadInterface,
} from '../../services/filterContext/FilterContext'
import {
  SubTypesDataInterface,
  TaskRepCodeInterface,
  TypesDataInterface,
} from '../notes/INotes'

const Task = () => {
  const leadsDetails = useSelector(leadsData)
  const { loadingEdit } = useContext(EditActionContext)
  const partyDetails = useSelector(partiesData)
  const [selectedTab, setSelectedTab] = useState('Created By')
  const [showTasks, setShowTasks] = useState<boolean>(false)
  // const [orgTypeArray, setOrgTypeArray] = useState<any[]>([])
  // const [orgSubTypeArray, setOrgSubTypeArray] = useState<any[]>([])
  const [taskData, setTaskData] = useState<any[]>([])
  const [repCodeData, setRepCodeData] = useState<any[]>([])
  // const [summaryFilters, setSummaryFilters] = useState<
  //   { id: string; value: string | string[] | undefined | null }[]
  // >([])
  const [updateTask] = useMutation(UPDATE_TASK_STATUS)
  const { accessToken } = useContext(AuthContext)
  // const [typeArray, setTypeArray] = useState<OptionProps[]>([])
  // const [subTypeArray, setSubTypeArray] = useState<OptionProps[]>([])
  const typesData = useSelector(typeData)
  const subTypesData = useSelector(subTypeData)
  const [allTypes, setAllTypes] = useState<any[]>([])
  const [subTypes, setSubTypes] = useState<any[]>([])
  const {
    filterPayload,
    setFilterPayload,
    setInteractionsPayload,
    setScrollPosition,
    resetTableFilter,
  } = useContext(FilterContext)
  const { user } = useContext(UserContext)

  const [
    getTasksCreatedBy,
    { data: taskDataCreatedBy, loading: tasksLoadingCreatedBy },
  ] = useLazyQuery(GET_TASKS, {
    variables: {
      payload: setInteractionsPayload('task'),
      limit: vars.InitRecordCount,
      offset: filterPayload.offset,
    },
    fetchPolicy: 'no-cache',
  })
  const [
    getTasksCreatedByCount,
    { data: taskDataCreatedByCount, loading: loadingCreatedByTaskCount },
  ] = useLazyQuery(GET_TASKS_COUNT, {
    variables: {
      payload: setInteractionsPayload('task'),
    },
    fetchPolicy: 'no-cache',
  })
  const [getTasksByBook, { data: taskDataByBook }] = useLazyQuery(
    GET_NOTES_BOOK,
    {
      variables: {
        userName: user.username,
        tranType: 2,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [getTasksRepCode, { data: taskRepCode }] = useLazyQuery(
    GET_TASKS_REPCODE_LIST,
    {
      variables: {
        ADUserName: user.username,
      },
      fetchPolicy: 'no-cache',
    }
  )
  const fetchData = (scrollHeight: number) => {
    if (
      !tasksLoadingCreatedBy &&
      !loadingCreatedByTaskCount &&
      filterPayload.offset + vars.InitRecordCount <
        taskDataCreatedByCount?.note_vw_wealth_task_createdby_aggregate
          ?.aggregate?.count &&
      taskDataCreatedByCount?.note_vw_wealth_task_createdby_aggregate?.aggregate
        ?.count > vars.InitRecordCount
    ) {
      setScrollPosition(scrollHeight)
      setFilterPayload((prev) => ({
        ...prev,
        offset: filterPayload.offset + vars.InitRecordCount,
      }))
    }
  }

  const getTypeByTranType = (tranType: number) => {
    return typesData
      .filter((type: TypesDataInterface) => type.trantype === tranType)
      .map((type: TypesDataInterface) => {
        return { value: type.typeid, label: type.type }
      })
  }

  const getSubTypeByType = (typeId: number) => {
    return (
      subTypesData
        .filter(
          (type: SubTypesDataInterface) =>
            type.typeid === typeId && type.trantype === 2
        )
        .map((type: SubTypesDataInterface) => {
          return { value: type.subtypeid, label: type.subtype }
        }) ?? []
    )
  }

  const getAllSubTypeByType = () => {
    return subTypesData
      .filter((type: SubTypesDataInterface) => type.trantype === 2)
      .map((type: SubTypesDataInterface) => {
        return { value: type.subtypeid, label: type.subtype }
      })
  }
  const getTasksRepcodes = () => {
    return taskRepCode?.note_vw_wealth_task_createdby
      ?.filter((item: TaskRepCodeInterface) => item.repcode)
      ?.map((rep: TaskRepCodeInterface) => ({
        label: rep?.repcode,
        value: rep?.repcode,
      }))
  }

  useEffect(() => {
    if (typesData) setAllTypes(getTypeByTranType(2))
    if (subTypesData) setSubTypes(getAllSubTypeByType())
    if (taskRepCode) setRepCodeData(getTasksRepcodes())
  }, [subTypesData, typesData, taskRepCode])

  useEffect(() => {
    if (taskDataCreatedBy && taskDataCreatedBy?.note_vw_wealth_task_createdby) {
      filterPayload.offset > 0
        ? setTaskData((prev) => [
            ...prev,
            ...taskDataCreatedBy.note_vw_wealth_task_createdby,
          ])
        : setTaskData(taskDataCreatedBy?.note_vw_wealth_task_createdby)
    }
  }, [taskDataCreatedBy?.note_vw_wealth_task_createdby])

  useEffect(() => {
    if (taskDataByBook?.note_vw_wealth_notes_book) {
      setTaskData(
        taskDataByBook?.note_vw_wealth_notes_book.map((note: any) => {
          return { ...note, partyid: note.notepartyid }
        })
      )
    }
  }, [taskDataByBook?.note_vw_wealth_notes_book])

  // const handleChangeType = (option: any) => {
  //   if (option) {
  //     if (option == -1) {
  //       setSubTypeArray(orgSubTypeArray)
  //     } else {
  //       const allTypes: any = []
  //       orgSubTypeArray?.map((item: any) => {
  //         if (
  //           option.toString().toLowerCase() ===
  //             item.type.toString().toLowerCase() ||
  //           item.type.toString().toLowerCase() == '-1'
  //         ) {
  //           allTypes.push(item)
  //         }
  //       })

  //       setSubTypeArray(allTypes)
  //     }
  //   }
  // }

  const refreshData = useCallback(() => {
    setSelectedTab('Created By')
    getTasksCreatedBy()
    getTasksCreatedByCount()
  }, [getTasksCreatedBy])

  useEffect(() => {
    resetTableFilter()
    if (selectedTab === 'Created By') {
      getTasksCreatedByCount()
      getTasksCreatedBy()
      getTasksRepCode()
    } else getTasksByBook()
  }, [selectedTab])

  // useEffect(() => {
  //   const filterType: any[] = []
  //   const filterSubType: any[] = []

  //   if (taskData?.length) {
  //     filterType.push({ value: '-1', label: 'All' })
  //     taskData?.map((item: any) => {
  //       if (
  //         item.typedescription.toString().trim() != '' &&
  //         item.typedescription != '--'
  //       )
  //         filterType.push({ value: item.typeid, label: item.typedescription })
  //     })

  //     //make unique list
  //     setOrgTypeArray(
  //       [...new Set(filterType.map((item) => item.value))].map((value) =>
  //         filterType.find((item) => item.value === value)
  //       )
  //     )

  //     filterSubType.push({ value: '-1', label: 'All', type: -1 })
  //     taskData?.map((item: any) => {
  //       if (
  //         item.subtypedescription.toString().trim() != '' &&
  //         item.subtypedescription != '--'
  //       )
  //         filterSubType.push({
  //           value: item.subtypeid,
  //           label: item.subtypedescription,
  //           type: item.typeid,
  //         })
  //     })

  //     //make unique list
  //     setOrgSubTypeArray(
  //       [...new Set(filterSubType.map((item) => item.value))].map((value) =>
  //         filterSubType.find((item) => item.value === value)
  //       )
  //     )

  //     //rep code
  //     const filterRepCode: any[] = []
  //     if (taskData.length > 0) {
  //       filterRepCode.push({ value: -1, label: 'All' })
  //       taskData.map((item: any) => {
  //         if (
  //           item.repcode != '' &&
  //           item.repcode != '--' &&
  //           item.repcode != null
  //         )
  //           filterRepCode.push({ value: item.repcode, label: item.repcode })
  //       })
  //     }
  //     //make unique list for rep code
  //     setFilterRepCodeData(
  //       [...new Set(filterRepCode.map((item) => item.value))].map((value) =>
  //         filterRepCode.find((item) => item.value === value)
  //       )
  //     )
  //   }
  // }, [taskData])

  // useEffect(() => {
  //   setTypeArray(orgTypeArray)
  //   setSubTypeArray(orgSubTypeArray)
  // }, [orgSubTypeArray, orgTypeArray])

  const updateTaskStatus = (statusType: string, tranId: number) => {
    let statusId: number
    if (statusType == 'open') {
      statusId = 0
    } else if (statusType == 'close') {
      statusId = 1
    } else {
      statusId = 2
    }

    updateTask({
      variables: {
        statusId: statusId, // Use the updated list
        tranId: tranId,
      },
    }).then(() => {
      refreshData()
    })
  }

  return (
    <Wrapper>
      {/* <CommonTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> */}
      <ContentItemWrapper>
        {taskData.length > 0 && (loadingEdit || tasksLoadingCreatedBy) && (
          <Loader position="absolute" />
        )}
        <Table
          tableProperties={{
            tableHeader: 'Tasks',
            globalSearchRequired: true,
            tileFilterRequired: false,
            paginatorRequired: true,
            headerButtonRequired: true,
            buttonLabel: 'New Task',
            createCallback: () => setShowTasks(true),
          }}
          key={taskData?.length}
          fetchData={fetchData}
          updateStatus={updateTaskStatus}
          refetchInteraction={refreshData}
          isLoading={taskData.length == 0 && tasksLoadingCreatedBy}
          source={'taskData'}
          tableColumns={taskData && taskData.length > 0 ? SearchTableData : []}
          allData={taskData ?? []}
          allDataCount={
            taskDataCreatedByCount?.note_vw_wealth_task_createdby_aggregate
              ?.aggregate?.count ?? 0
          }
          typeArray={allTypes}
          subTypeArray={subTypes}
          repCodeList={repCodeData}
          sortingFields={[
            'taskstatus',
            'subject',
            'partyname',
            'accountname',
            'accountnumber',
            'typedescription',
            'subtypedescription',
            'taskdate',
            'ownername',
            'repcode',
          ]}
        />
      </ContentItemWrapper>
      {showTasks && (
        <CreateTaskComponent
          onTypeChange={(typeId: number) => {
            setSubTypes(getSubTypeByType(typeId))
          }}
          typeData={allTypes}
          subTypeData={subTypes}
          user={user}
          refetchContents={refreshData}
          closeTask={setShowTasks}
          hostName={appConfig.apiEndPoint}
          accessToken={accessToken}
          combinedData={[...leadsDetails, ...partyDetails]}
          validationRequired={vars.validationRequired}
        />
      )}
    </Wrapper>
  )
}

export default Task
