import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const CSVWrapper = styled.div`
  a {
    text-decoration: none;
    pointer-events: none;
    color: ${() => DEFAULT_COLORS.Text.Black};
  }
`
