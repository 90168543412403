import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { TextboxProps } from './ITextbox'

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  /* border-radius: 8px; */
  width: 100%;
`
export const StyledError = styled.p`
  display: flex;
  color: ${() => DEFAULT_COLORS.Text.Danger};
`
export const Main = styled.input`
  padding: 0.65rem 1rem;
  flex: 0 1 100%;
  width: ${(Props: TextboxProps) => Props.width};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
  &:hover {
    border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
    color: ${() => DEFAULT_COLORS.Icons.NeutralHover};
  }
  /* &:active {
    border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
  } */
  &:disabled {
    border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
    color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
    cursor: no-drop;
  }
  &:invalid {
    border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 1px
      ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    border-color: ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    color: ${() => DEFAULT_COLORS.Text.Black};
  }
  &:required:valid {
    border: 2px solid ${() => DEFAULT_COLORS.Border.DangerDark};
    background-color: ${() => DEFAULT_COLORS.Background.Fantasy};
  }
  box-sizing: border-box;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-style: normal;
  min-height: 2.62rem;
  height: 2.62rem;
  font-weight: 400;
  font-size: 1rem;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  outline: none;
`
