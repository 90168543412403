import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const PlusMenuWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: ${(props: { minWidth: string }) => props.minWidth};
  height: auto;
  right: 0;
  top: 105%;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralWeak};
  box-shadow: ${() => DEFAULT_COLORS.BoxShadow.PlusMenuBoxShadow};
  z-index: 9;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover {
    background: ${() => DEFAULT_COLORS.Background.PrimaryActive};
  }
`
export const LabelWrapper = styled.span`
  padding-left: 20px;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
