import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../theme/Sizes'
import Divider from '../divider/Divider'

interface LabelProps {
  children?: React.ReactNode
  isSelected?: boolean
}
export const StyledLabelBlue = styled.div`
  font-style: normal;
  font-weight: ${TextWeight[2]};
  font-size: ${TextSizes[2]};
  line-height: ${SIZES[4]};
  text-align: right;
  color: ${() => DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelBlues = styled.div`
  font-style: normal;
  font-weight: ${TextWeight[0]};
  font-size: ${TextSizes[2]};
  line-height: ${SIZES[2]};
  text-align: right;
  color: ${() => DEFAULT_COLORS.Text.Primary};
`
export const StyledFormLabelHeading = styled.div`
  font-size: ${TextSizes[5]};
  font-style: normal;
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[5]};
  color: ${() => DEFAULT_COLORS.Background.EbonyClay};
`
export const StyledLabel = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelWhite = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.NeutralInverted};
`
export const StyledLabelBlack = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledParagraphBlack = styled.p`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledParagraphCompanyProfile = styled.p`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledHeaderCompanyProfile = styled.p`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelMenu = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  &.selected {
    color: ${() => DEFAULT_COLORS.Text.Primary};
  }
  &:hover {
    color: ${() => DEFAULT_COLORS.Text.Primary};
  }
`
export const StyledLabelLink = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelGray = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelWarning = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Warning};
`
export const StyledLabelGreen = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Success};
`
export const StyledLabelWeak = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledLabelBlackSmall = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Black};
`
export const StyledLabelWeakMedium = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledLabelDanger = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Danger};
`
export const StyledLabelWeakNeutral = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelWeakWhite = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.NeutralInverted};
`
export const StyledLabelFooter = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Black};
`
export const StyledLabelFooterGray = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.EbonyClay};
`
export const StyledLabelWhiteMed = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.NeutralInverted};
`
export const StyledLabelMenuList = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelBold = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelBoldGray = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.PrimaryStrong};
`
export const StyledHeaderSmallGray = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[4]};
  color: ${() => DEFAULT_COLORS.Text.PrimaryStrong};
`
export const StyledLabelPrimaryBold = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[4]};
  color: ${() => DEFAULT_COLORS.Text.PrimaryStrong};
`
export const StyledLabelPrimaryLarge = styled.div`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[2]};
  color: ${() => DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelPrimaryXLarge = styled.div`
  font-size: 1.4rem;
  font-weight: ${TextWeight[2]};
  color: ${() => DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelDisabled = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
  padding: 0.25rem 0;
`
export const StyledLabelNavBar = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledLabelSmallBold = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelSmallBoldPrimary = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelSmall = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledLabelPrimary = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.DodgerBlue};
`
export const StyledLabelPrimaryHeading = styled.div`
  font-size: ${TextSizes[3]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.DodgerBlue};
`
export const StyledLabelPrimaryWordBreak = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.DodgerBlue};
  max-width: 248px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const StyledLabelPrimaryEllipsis = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.DodgerBlue};
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const StyledLabelErrorSmall = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Danger};
`
export const StyledLabelErrorMedium = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Danger};
`
export const StyledLabelHeadingBold = styled.div`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Background.Elevation};
`
export const StyledLabelHeadingMedium = styled.div`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Background.Elevation};
`
export const StyledLabelMediumNeutral = styled.div`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelBrand = styled.div`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
`
export const StyledLabelHeadingLarge = styled.div`
  font-size: ${TextSizes[10]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[8]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelHeadingLargeWhite = styled.div`
  font-size: ${TextSizes[10]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[8]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
`
export const StyledLabelPageHeading = styled.div`
  font-size: ${TextSizes[10]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[8]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelTableHeading = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelTableDataStatus = styled.div`
  font-size: ${TextSizes[7]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[5]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelHeadingMediumWhite = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
`
export const StyledLabelHeadingMediumNeutral = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelHeadingWeakWhite = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[5]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
`
export const StyledLabelHeadingWeakNeutral = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[5]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelHeadingLargeBlue = styled.div`
  font-size: ${TextSizes[7]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[5]};
  color: ${() => DEFAULT_COLORS.Text.PrimaryStrong};
`
export const StyledLabelHeadingLargeNeutral = styled.div`
  font-size: ${TextSizes[7]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[5]};
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelError = styled.div`
  color: ${() => DEFAULT_COLORS.Text.Danger};
  font-size: ${TextSizes[2]};
`
export const StyledLabelSemiBold = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelDashboardCard = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledLabelDashboardCard1 = styled.div`
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[3]};
  font-style: normal;
`
export const StyledLabelClient = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelBackground = styled.div`
  display: contents;
  font-size: ${TextSizes[4]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledLabelAttachment = styled.div`
  font-size: ${SIZES[3]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  background: ${() => DEFAULT_COLORS.Background.PrimaryHover};
  padding: ${SIZES[1]} ${SIZES[0]};
  border-radius: ${SIZES[0]};
`
export const StyledLabelPrimaryText = styled.div`
  font-size: ${TextSizes[2]};
  font-family: SourceSansPro-SemiBold;
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelPrimaryTextMedium = styled.div`
  font-size: ${TextSizes[3]};
  font-family: SourceSansPro-SemiBold;
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelFloat = styled.div`
  font-size: ${TextSizes[5]};
  font-weight: ${TextWeight[3]};
  line-height: ${SIZES[5]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
const StyledLabelText = styled.div`
  font-weight: ${TextWeight[0]}; /*400 */
  font-size: ${TextSizes[3]}; /* 16px */
  line-height: ${SIZES[4]}; /* 24px */
  color: ${DEFAULT_COLORS.Text.Neutral}; /* #0F172A */
`
export const StyledLabelExSmall = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-size: 0.775rem;
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`

export const StyledLabelNickName = styled.div`
  font-size: 14px;
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledLabelProfile = styled.div`
  font-size: 0.690625rem;
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[3]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.NeutralWeak};
`
export const StyledFixedLabelHeadingLargeNeutral = styled.div`
  font-size: ${TextSizes[7]};
  font-weight: ${TextWeight[2]};
  line-height: ${SIZES[5]};
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  position: sticky;
  bottom: 0;
  top: 0;
  left: 0;
`
export const StyledSubFormLabelHeading = styled.div`
  font-size: ${TextSizes[5]};
  font-style: normal;
  font-weight: ${TextWeight[0]};
  line-height: ${SIZES[5]};
  color: ${() => DEFAULT_COLORS.Background.Primary};
`
export const StyledLabelPrimaryTextSmall = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[3]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.Primary};
`
export const StyledLabelNeutral = styled.div`
  font-size: ${TextSizes[2]};
  font-family: SourceSansPro-SemiBold;
  line-height: ${TextSizes[7]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.Neutral};
`
export const StyledLabelSearchHeader = styled.div`
  font-size: ${TextSizes[4]};
  font-family: SourceSansPro-SemiBold;
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${DEFAULT_COLORS.Text.NeutralInverted};
`
export const WDTextLabel = ({ children, ...props }: LabelProps) => {
  return <StyledLabelText {...props}>{children}</StyledLabelText>
}
export const WDLabelSearchHeader = ({ children }: LabelProps) => {
  return <StyledLabelSearchHeader>{children}</StyledLabelSearchHeader>
}

export const WDLabelBackground = ({ children }: LabelProps) => {
  return <StyledLabelBackground>{children}</StyledLabelBackground>
}
export const WDFormLabelHeading = ({ children }: LabelProps) => {
  return <StyledFormLabelHeading>{children}</StyledFormLabelHeading>
}

export const WDLabel = ({ children }: LabelProps) => {
  return <StyledLabel>{children}</StyledLabel>
}
export const WDLabelPrimaryTextSmall = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryTextSmall>{children}</StyledLabelPrimaryTextSmall>
}
export const WDLabelNeutral = ({ children }: LabelProps) => {
  return <StyledLabelNeutral>{children}</StyledLabelNeutral>
}
export const WDLabelBlue = ({ children }: LabelProps) => {
  return <StyledLabelBlue>{children}</StyledLabelBlue>
}
export const WDLabelBlues = ({ children }: LabelProps) => {
  return <StyledLabelBlues>{children}</StyledLabelBlues>
}
export const WDLabelLink = ({ children }: LabelProps) => {
  return <StyledLabelLink>{children}</StyledLabelLink>
}
export const WDLabelGray = ({ children }: LabelProps) => {
  return <StyledLabelGray>{children}</StyledLabelGray>
}
export const WDLabelGreen = ({ children }: LabelProps) => {
  return <StyledLabelGreen>{children}</StyledLabelGreen>
}
export const WDLabelWhite = ({ children }: LabelProps) => {
  return <StyledLabelWhite>{children}</StyledLabelWhite>
}
export const WDLabelCompanyProfilePara = ({ children }: LabelProps) => {
  return (
    <StyledParagraphCompanyProfile>{children}</StyledParagraphCompanyProfile>
  )
}
export const WDLabelCompanyProfileHeader = ({ children }: LabelProps) => {
  return <StyledHeaderCompanyProfile>{children}</StyledHeaderCompanyProfile>
}
export const WDLabelFloat = ({ children }: LabelProps) => {
  return <StyledLabelFloat>{children}</StyledLabelFloat>
}

export const WDLabelWhiteMed = ({ children }: LabelProps) => {
  return <StyledLabelWhiteMed>{children}</StyledLabelWhiteMed>
}

export const WDLabelBold = ({ children }: LabelProps) => {
  return <StyledLabelBold>{children}</StyledLabelBold>
}

export const WDLabelBoldGray = ({ children }: LabelProps) => {
  return <StyledLabelBoldGray>{children}</StyledLabelBoldGray>
}

export const WDLabelWeak = ({ children }: LabelProps) => {
  return <StyledLabelWeak>{children}</StyledLabelWeak>
}
export const WDLabelWeakMedium = ({ children }: LabelProps) => {
  return <StyledLabelWeakMedium>{children}</StyledLabelWeakMedium>
}
export const WDLabelDanger = ({ children }: LabelProps) => {
  return <StyledLabelDanger>{children}</StyledLabelDanger>
}
export const WDLabelWeakNeutral = ({ children }: LabelProps) => {
  return <StyledLabelWeakNeutral>{children}</StyledLabelWeakNeutral>
}
export const WDLabelWeakWhite = ({ children }: LabelProps) => {
  return <StyledLabelWeakWhite>{children}</StyledLabelWeakWhite>
}

export const WDLabelHeadingWeakWhite = ({ children }: LabelProps) => {
  return <StyledLabelHeadingWeakWhite>{children}</StyledLabelHeadingWeakWhite>
}

export const WDLabelHeadingWeakNeutral = ({ children }: LabelProps) => {
  return (
    <StyledLabelHeadingWeakNeutral>{children}</StyledLabelHeadingWeakNeutral>
  )
}
export const WDLabelErrorSmall = ({ children }: LabelProps) => {
  return <StyledLabelErrorSmall>{children}</StyledLabelErrorSmall>
}

export const WDLabelErrorMedium = ({ children }: LabelProps) => {
  return <StyledLabelErrorMedium>{children}</StyledLabelErrorMedium>
}
export const WDLabelHeadingLarge = ({ children }: LabelProps) => {
  return <StyledLabelHeadingLarge>{children}</StyledLabelHeadingLarge>
}

export const WDLabelHeadingLargeWhite = ({ children }: LabelProps) => {
  return <StyledLabelHeadingLargeWhite>{children}</StyledLabelHeadingLargeWhite>
}
export const WDLabelHeadingLargeBlue = ({ children }: LabelProps) => {
  return <StyledLabelHeadingLargeBlue>{children}</StyledLabelHeadingLargeBlue>
}

export const WDLabelHeadingLargeNeutral = ({ children }: LabelProps) => {
  return (
    <StyledLabelHeadingLargeNeutral>{children}</StyledLabelHeadingLargeNeutral>
  )
}
export const WDLabelHeadingMediumWhite = ({ children }: LabelProps) => {
  return (
    <StyledLabelHeadingMediumWhite>{children}</StyledLabelHeadingMediumWhite>
  )
}
export const WDLabelHeadingMediumNeutral = ({ children }: LabelProps) => {
  return (
    <StyledLabelHeadingMediumNeutral>
      {children}
    </StyledLabelHeadingMediumNeutral>
  )
}

export const WDLabelSemiBold = ({ children }: LabelProps) => {
  return <StyledLabelSemiBold>{children}</StyledLabelSemiBold>
}
export const WDHeaderSmallGray = ({ children }: LabelProps) => {
  return <StyledHeaderSmallGray>{children}</StyledHeaderSmallGray>
}

export const WDLabelDisabled = ({ children }: LabelProps) => {
  return <StyledLabelDisabled>{children}</StyledLabelDisabled>
}

export const WDLabelBlack = ({ children }: LabelProps) => {
  return <StyledLabelBlack>{children}</StyledLabelBlack>
}
export const WDLabelBlackSmall = ({ children }: LabelProps) => {
  return <StyledLabelBlackSmall>{children}</StyledLabelBlackSmall>
}

export const WDParagraphBlack = ({ children }: LabelProps) => {
  return <StyledParagraphBlack>{children} </StyledParagraphBlack>
}

export const WDLabelPrimary = ({ children }: LabelProps) => {
  return <StyledLabelPrimary>{children}</StyledLabelPrimary>
}
export const WDLabelPrimaryHeading = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryHeading>{children}</StyledLabelPrimaryHeading>
}
export const WDLabelPrimaryWordBreak = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryWordBreak>{children}</StyledLabelPrimaryWordBreak>
}

export const WDLabelFooter = ({ children }: LabelProps) => {
  return <StyledLabelFooter>{children}</StyledLabelFooter>
}
export const WDLabelBrandBold = ({ children }: LabelProps) => {
  return <StyledLabelHeadingBold>{children}</StyledLabelHeadingBold>
}

export const WDLabelBrandMedium = ({ children }: LabelProps) => {
  return <StyledLabelHeadingMedium>{children}</StyledLabelHeadingMedium>
}
export const WDLabelBrandMediumNeutral = ({ children }: LabelProps) => {
  return <StyledLabelMediumNeutral>{children}</StyledLabelMediumNeutral>
}
export const WDLabelFooterGray = ({ children }: LabelProps) => {
  return <StyledLabelFooterGray>{children}</StyledLabelFooterGray>
}

export const WDLabelBrand = ({ children }: LabelProps) => {
  return <StyledLabelBrand>{children}</StyledLabelBrand>
}

export const WDLabelPageHeading = ({ children }: LabelProps) => {
  return <StyledLabelPageHeading>{children}</StyledLabelPageHeading>
}
export const WDLabelTableHeading = ({ children }: LabelProps) => {
  return <StyledLabelTableHeading>{children}</StyledLabelTableHeading>
}

export const WDLabelTableDataStatus = ({ children }: LabelProps) => {
  return <StyledLabelTableDataStatus>{children}</StyledLabelTableDataStatus>
}
export const WDHorizontalDivider = () => {
  return (
    <Divider
      horizontal={true}
      thickness={'2px'}
      bgColor={DEFAULT_COLORS.Background.NeutralStrong}
    />
  )
}

export const WDLabelNavBar = ({ children }: LabelProps) => {
  return <StyledLabelNavBar>{children}</StyledLabelNavBar>
}
export const WDLabelSmallBold = ({ children }: LabelProps) => {
  return <StyledLabelSmallBold>{children}</StyledLabelSmallBold>
}

export const WDLabelSmallBoldPrimary = ({ children }: LabelProps) => {
  return <StyledLabelSmallBoldPrimary>{children}</StyledLabelSmallBoldPrimary>
}
export const WDLabelSmall = ({ children }: LabelProps) => {
  return <StyledLabelSmall>{children}</StyledLabelSmall>
}
export const WDLabelPrimaryBold = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryBold>{children}</StyledLabelPrimaryBold>
}
export const WDLabelPrimaryLarge = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryLarge>{children}</StyledLabelPrimaryLarge>
}
export const WDLabelPrimaryXLarge = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryXLarge>{children}</StyledLabelPrimaryXLarge>
}
export const WDLabelMenuList = ({ children }: LabelProps) => {
  return <StyledLabelMenuList>{children}</StyledLabelMenuList>
}
export const WDLabelMenu = ({ children, isSelected }: LabelProps) => {
  return (
    <StyledLabelMenu className={isSelected ? 'selected' : ''}>
      {children}
    </StyledLabelMenu>
  )
}
export const WDLabelDashboardCard = ({ children }: LabelProps) => {
  return <StyledLabelDashboardCard>{children}</StyledLabelDashboardCard>
}
export const WDLabelDashboardCard1 = ({ children }: LabelProps) => {
  return <StyledLabelDashboardCard1>{children}</StyledLabelDashboardCard1>
}
export const WDLabelError = ({ children }: LabelProps) => {
  return <StyledLabelError>{children}</StyledLabelError>
}
export const WDLabelClient = ({ children }: LabelProps) => {
  return <StyledLabelClient>{children}</StyledLabelClient>
}
export const WDLabelAttachment = ({ children }: LabelProps) => {
  return <StyledLabelAttachment>{children}</StyledLabelAttachment>
}
export const WDLabelPrimaryText = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryText>{children}</StyledLabelPrimaryText>
}
export const WDLabelPrimaryTextMedium = ({ children }: LabelProps) => {
  return <StyledLabelPrimaryTextMedium>{children}</StyledLabelPrimaryTextMedium>
}
export const WDLabelSmallExSmall = ({ children }: LabelProps) => {
  return <StyledLabelExSmall>{children}</StyledLabelExSmall>
}
export const WDLabelProfile = ({ children, ...props }: LabelProps) => {
  return <StyledLabelProfile {...props}>{children}</StyledLabelProfile>
}
export const WDFixedLabel = ({ children, ...props }: LabelProps) => {
  return (
    <StyledFixedLabelHeadingLargeNeutral {...props}>
      {children}
    </StyledFixedLabelHeadingLargeNeutral>
  )
}
export const WDLabelNickName = ({ children, ...props }: LabelProps) => {
  return <StyledLabelNickName {...props}>{children}</StyledLabelNickName>
}

export const WDSubFormLabelHeading = ({ children }: LabelProps) => {
  return <StyledSubFormLabelHeading>{children}</StyledSubFormLabelHeading>
}
