import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left !important;
  gap: 2rem;
  width: 100%;
`
export const ODWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  padding-bottom: 5rem;
  margin-bottom: 5rem;
    /* flex: 1; */
    height: max-content;
`
export const InfoItemWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  background: rgb(255 255 255);
  padding: 1rem;
  justify-content: space-around;
`
export const ODLeftMainWrapper = styled.div`
  display: flex;
  width: 70%;
  gap: 1rem;
  background: #fff;
  padding: 0.5rem 1rem 3rem 1rem;
  border-color: #ccc;
  flex-direction: column;
  height: fit-content;
`
export const ODRightMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 2rem;
  height: min-content;
`
export const ODRightMainCardWrapper = styled.div`
  flex: ${(props: { flex?: string }) => {
    props.flex ?? 'none'
  }};
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  background: #fff;
  padding: 1rem;
  border-color: #ccc;
  /* border-radius: 2px; */
  /* overflow: auto; */
  max-height: 411px;
  justify-content: space-between;
`
export const HistoryMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  background: #fff;
  padding: 1rem;
  border-color: #ccc;
  max-height: 41.4rem;
  justify-content: flex-start;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: ${SIZES[1]};
    height: ${SIZES[1]};
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: ${SIZES[0]};
  }
`
export const NoteMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  background: #fff;
  padding: 1rem;
  border-color: #ccc;
  max-height: 263px;
  justify-content: flex-start;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: ${SIZES[1]};
    height: ${SIZES[1]};
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: ${SIZES[0]};
  }
`
export const ODFormWrapper = styled.form`
  display: flex;
`
export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  gap: 1rem;
`
export const InfoWrp = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  gap: 0.5rem;
  p {
    align-self: center;
  }
  span {
    color: #444;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, lato;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    align-self: center;
  }
  a {
    font-size: 1rem;
    font-weight: 600;
    /* line-height: 1.4; */
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${() => DEFAULT_COLORS.Text.Primary};
    align-self: center;
  }
  a:hover {
    text-decoration: underline;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
`
export const CountWrapper = styled.div`
  background: ${DEFAULT_COLORS.Background.Primary};
  border-radius: 62.5rem;
  padding: 0 ${SIZES[0]};
  color: ${DEFAULT_COLORS.Text.NeutralInverted};
  font-weight: 700;
  font-size: 0.75rem;
  line-height: ${SIZES[3]};
`
export const InfoTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
`
export const InfoTitleGreen = styled.div`
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${() => DEFAULT_COLORS.Text.LightGreen};
  align-self: center;
`
export const InfoTitleDanger = styled.div`
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${() => DEFAULT_COLORS.Text.RedBerry};
  align-self: center;
`
export const DetailsWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const CreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SIZES[1]};
  input.date-time {
  padding: 0.65rem 1rem;
  flex: 0 1 100%;
  width: ${(Props: { width: string }) => Props.width};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
  &:hover {
    border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
    color: ${() => DEFAULT_COLORS.Icons.NeutralHover};
  }
  /* &:active {
    border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
  } */
  &:disabled {
    border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
    color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
    cursor: no-drop;
  }
  &:invalid {
    border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 1px
      ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    border-color: ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    color: ${() => DEFAULT_COLORS.Text.Black};
  }
  &:required:valid {
    border: 2px solid ${() => DEFAULT_COLORS.Border.DangerDark};
    background-color: ${() => DEFAULT_COLORS.Background.Fantasy};
  }
  box-sizing: border-box;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
  font-style: normal;
  min-height: 2.62rem;
  height: 2.62rem;
  font-weight: 400;
  font-size: 1rem;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
  outline: none;
  }
  input {
    height: 35px !important;
    width: 100%;
  }
`
export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 17%;
  gap: ${SIZES[1]};
  input {
    height: 35px !important;
  }
`
export const CalendarWrap = styled.div`
  display: flex;
  input {
    max-width: 8rem;
  }
`
export const ItemsWrp = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
`
export const ItemsSubWrp = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SIZES[3]};
  width: 100%;
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const BtnWrapper = styled.div`
  float: right;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
`
export const RightTitleWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ListPermission = styled.div`
  display: flex;
  table {
    min-height: 0.01%;
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    border-collapse: collapse;
    border-spacing: 0;
    thead {
      tr {
        th {
          border-bottom: 0 solid #ddd;
          vertical-align: middle;
          padding: 8px;
          line-height: 1.428571429;
          text-align: left;
          box-sizing: border-box;
        }
        td {
          text-align: end;
        }
      }
    }
    tbody {
      tr {
        th {
          border-bottom: 0 solid #ddd;
          vertical-align: middle;
          padding: 8px;
          line-height: 1.428571429;
          text-align: left;
          box-sizing: border-box;
        }
        td {
          padding: 8px;
          line-height: 1.428571429;
          vertical-align: middle;
          border-top: 1px solid #ddd;
        }
      }
    }
  }
`

export const TimelineListWrp = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  margin-left: 0;
  margin-right: 0;

  :before {
    display: table;
    content: ' ';
  }
`
export const SubListView = styled.div`
  border-left: 5px solid
    ${(borderColor: { borderColor: string }) =>
    borderColor.borderColor == 'Added new opportunity'
      ? DEFAULT_COLORS.Background.LightGreen
      : DEFAULT_COLORS.Background.FadedOrange};
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex: 1;
`
export const ListItems = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex: 1;
width: 100%;
`
export const NoteWrp = styled.div`
  display: flex;
  flex-direction: column;
  .ql-container.ql-snow {
    border: none;
  }
`
export const TimeWrp = styled.div`
  line-height: 3;
  color: ${() => DEFAULT_COLORS.Text.UserDark};
  display: flex;
`
export const TimesWrp = styled.div`
  line-height: 2;
  color: ${() => DEFAULT_COLORS.Text.UserDark};
  display: flex;
`
export const LinkWrp = styled.div`
  width: fit-content;
  a {
    color: ${() => DEFAULT_COLORS.Text.Primary};
  }
`
