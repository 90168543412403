import { useLazyQuery, useMutation } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { WDButtonTransparent } from '../../../components/ui/WDButton'
import { WDSubFormLabelHeading } from '../../../components/ui/WDLabel'
import ShippingInformation from '../../../composites/addressInformation/AddressInformation'
import {
  ShippingAddressInterface,
  ShippingAddressPayloadInterface,
} from '../../../composites/addressInformation/IAdressInformation'
import EmailInformation from '../../../composites/emailInformation/EmailInformation'
import {
  EmailInformationInterface,
  EmailInformationPayloadInterface,
} from '../../../composites/emailInformation/IEmailInformation'
import {
  PhoneInterface,
  PhonePayloadInterface,
} from '../../../composites/phoneInformation/IPhoneInformation'
import PhoneInformation from '../../../composites/phoneInformation/PhoneInformation'
import {
  GET_LEAD_DETAILS,
  UPDATE_LEAD_ADDRESS,
  UPDATE_LEAD_EMAIL,
  UPDATE_LEAD_PHONE,
} from '../../../services/graphQl/LeadsGraphQL'
import UserContext from '../../../services/user/UserContext'
import {
  LeadGraphQLResponseInterface,
  LeadInfoProps,
  LeadInfoResponseInterface,
  SourceProps,
} from './IEditDetails'
import {
  CrossWrapper,
  HeaderWrapper,
  MasterWrapper,
  OverlayTitleWrapper,
  Wrapper,
} from './styles'
import { SvgClose } from '../../../components/svg/SvgClose'
import ToastContext from '../../../services/toast/ToastContext'
import { MainWrapper, OverlayWrapper } from '../styles'

export const EditDetails = ({
  contactId,
  update,
  setShowEditAddress,
}: SourceProps) => {
  const { contactFor } = useParams()
  const [inputErrors, setInputErrors] = useState<string[]>([])
  const { user } = useContext(UserContext)
  // Get query for lead details
  const [getLeadDetails] = useLazyQuery<LeadGraphQLResponseInterface>(
    GET_LEAD_DETAILS,
    {
      variables: { id: contactId },
      fetchPolicy: 'no-cache',
    }
  )

  const [updateContactAddressList] = useMutation<
    any,
    { payload: ShippingAddressPayloadInterface[] }
  >(UPDATE_LEAD_ADDRESS)
  const [updateContactPhoneList] = useMutation<
    any,
    { payload: PhonePayloadInterface[] }
  >(UPDATE_LEAD_PHONE)
  const [updateContactEmailList] = useMutation<
    any,
    { payload: EmailInformationPayloadInterface[] }
  >(UPDATE_LEAD_EMAIL)

  const initialContactInfoData: LeadInfoProps = {
    id: 0,
    accountTier: '',
    honorific: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    gender: '',
    jobTitle: '',
    email: '',
    phoneNumber: '',
    fax: '',
    owner: user.username,
    notes: '',
    aumSize: 0,
    advisoryFirm: '',
    interestHobbies: '',
    householdMembers: '',
    pets: '',
    leadStatus: '',
    leadSource: '',
    account: '',
    totalAssets: 0,
    category: '',
    financialInterests: '',
    individualType: '',
    investmentExperience: '',
    investmentObjectives: '',
    marketingSegments: '',
    netWorth: 0,
    reviewFrequency: '',
    riskTolerance: '',
    serviceModel: '',
    timeHorizon: '',
    deceasedDate: '',
    numberOfEmployees: '',
    uploadImage: '',
    rating: '',
    repCode: '',
    shippingCity: '',
    shippingCountry: '',
    shippingAddress: '',
    shippingPostalCode: '',
    shippingState: '',
    shippingStreet: '',
    billingCity: '',
    billingCountry: '',
    billingAddress: '',
    billingPostalCode: '',
    billingState: '',
    billingStreet: '',
    status: '',
    martialStatus: '',
    taxIdNumber: 0,
    maritalAnniversary: null,
    referredby: '',
    clientSince: '',
    clientTerminationDate: null,
    servicingAdvisor: '',
    dateOfBirth: null,
    dateOfDeath: '',
    writingAdvisor: '',
    source: '',
    preferedPronouce: '',
    nickName: '',
    pronouciation: '',
    designation: '',
    spouseHonorific: '',
    spouseFirstName: '',
    spouseMiddleName: '',
    spouseLastName: '',
    spouseSuffix: '',
    spouseNickName: '',
    spouseDesignation: '',
    spouseJobTitle: '',
    spouseEmployer: '',
    familyName: '',
    addressList: [],
    phoneList: [],
    emailList: [],
    websiteURL: '',
    permissionTo: '',
    type: '',
    advisor: '',
    csa: '',
    taxyear: 0,
    estimatedtaxes: 0,
    estimatedtaxbracket: 0,
    confirmedbytaxreturn: false,
    adjustedgrossincome: 0,
  }
  const [leadInfoData, setLeadInfoData] = useState(initialContactInfoData)

  const { setToast, setLoadingMessage } = useContext(ToastContext)

  // const { isDarkMode } = useContext(ThemeContext)

  useEffect(() => {
    setLeadInfoData((prev) => ({ ...prev, category: contactFor ?? '' }))
  }, [contactFor])

  const getEmailList = (emails: EmailInformationPayloadInterface[]) => {
    const emailObj: EmailInformationInterface[] = []
    emails?.forEach((email) => {
      emailObj.push({
        id: email.id,
        leadId: email.leadid,
        email: email.emailid,
        emailType: email.type,
        isPrimary: email.isprimary,
        activeFlag: email.activeflag,
      })
    })
    return emailObj
  }

  const getAddressList = (addresses: ShippingAddressPayloadInterface[]) => {
    const addressObj: ShippingAddressInterface[] = []
    addresses?.forEach((address) => {
      addressObj.push({
        id: address.id,
        leadId: address.leadid,
        isPrimary: address.isprimary,
        activeFlag: address.activeflag,
        addressLine1: address.addressline1,
        addressLine2: address.addressline2,
        city: address.city,
        country:
          address?.country?.trim() == ''
            ? 'United States of America (the)'
            : address.country,
        state: address.state,
        postalCode: address.zipcode,
        addressType: address.addresstype,
      })
    })
    return addressObj
  }

  const getContactList = (contacts: PhonePayloadInterface[]) => {
    const contactObj: PhoneInterface[] = []
    contacts?.forEach((contact) => {
      contactObj.push({
        id: contact.id,
        leadId: contact.leadid,
        isPrimary: contact.isprimary,
        activeFlag: contact.activeflag,
        phoneType: contact.type,
        phoneNo: contact.phonenumber,
        phoneExtension: contact.extension,
        speedDial: contact.speeddial,
      })
    })
    return contactObj
  }

  const getLeadInfo = () => {
    getLeadDetails().then((response) => {
      const responseObj =
        response.data?.lead_leads[0] ?? ({} as LeadInfoResponseInterface)
      const leadInfo: LeadInfoProps = {
        account: responseObj.account,
        createdBy: responseObj.createdby,
        addressList: getAddressList(responseObj.addresslist),
        advisor: responseObj.advisor,
        advisoryFirm: responseObj.currentadvisoryfirms,
        totalAssets: responseObj.totalassets,
        aumSize: responseObj.potentialsizeofaum,
        category: responseObj.clientcategory,
        accountTier: responseObj.accounttier,
        clientSince: responseObj.clientsince,
        csa: '',
        dateOfBirth: responseObj.dateofbirth,
        clientTerminationDate: responseObj.clientterminationdate,
        emailList: getEmailList(responseObj.emailList),
        familyName: responseObj.familyname,
        firstName: responseObj.firstname,
        gender: responseObj.gender,
        honorific: responseObj.honorific,
        householdMembers: responseObj.householdmembers,
        id: responseObj.id,
        interestHobbies: responseObj.interesthobbies,
        investmentExperience: responseObj.investmentexperience,
        investmentObjectives: responseObj.objective,
        jobTitle: responseObj.jobtitle,
        lastName: responseObj.lastname,
        leadSource: responseObj.leadsource,
        leadStatus: responseObj.status,
        maritalAnniversary: responseObj.dateofmarriage,
        martialStatus: responseObj.maritalstatus,
        middleName: responseObj.middlename,
        nickName: responseObj.nickname,
        notes: responseObj.notes,
        owner: responseObj.owner,
        pets: responseObj.pets,
        phoneList: getContactList(responseObj.contactList),
        preferedPronouce: responseObj.pronouns,
        pronouciation: responseObj.pronunciations,
        referredby: responseObj.referredby,
        riskTolerance: responseObj.risktolerance,
        status: responseObj.status,
        suffix: responseObj.suffix,
        type: responseObj.type,
        netWorth: responseObj.networth,
        taxIdNumber: responseObj.taxidnumber,
        timeHorizon: responseObj.timehorizon,
        websiteURL: responseObj.websiteurl,
        taxyear: responseObj.taxyear,
        estimatedtaxes: responseObj.estimatedtaxes,
        estimatedtaxbracket: responseObj.estimatedtaxbracket,
        confirmedbytaxreturn: responseObj.confirmedbytaxreturn,
        adjustedgrossincome: responseObj.adjustedgrossincome,
      }
      setLeadInfoData(leadInfo)
    })
  }

  useEffect(() => {
    if (contactId) {
      getLeadInfo()
    }
  }, [contactId])

  const formatPhoneNumber = (value: string) => {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`
  }

  const prepareAddressInputVariables = (leadId: number, inputType?: string) => {
    const addressList: ShippingAddressPayloadInterface[] = []
    leadInfoData.addressList.forEach((address) => {
      addressList.push({
        id: address.id,
        leadid: leadId,
        addresstype: address.addressType,
        isprimary: address.isPrimary,
        activeflag: address.activeFlag,
        city: address.city,
        country:
          address?.country?.trim() == ''
            ? address.country
            : 'United States of America (the)',
        addressline1: address.addressLine1,
        addressline2: address.addressLine2,
        state: address.state,
        zipcode: address.postalCode,
      })
    })
    const inputVariables = {
      payload: addressList,
    }
    if (inputType === 'update') {
      return { ...inputVariables, leadId: leadInfoData.id }
    } else {
      return inputVariables
    }
  }

  const preparePhoneInputVariables = (leadId: number, inputType?: string) => {
    const phoneList: PhonePayloadInterface[] = []
    leadInfoData.phoneList.forEach((phone) => {
      phoneList.push({
        id: phone.id,
        leadid: leadId,
        type: phone.phoneType,
        isprimary: phone.isPrimary,
        activeflag: phone.activeFlag,
        phonenumber: +phone.phoneNo,
        extension: +phone.phoneExtension,
        speeddial: +phone.speedDial,
      })
    })
    const inputVariables = {
      payload: phoneList,
    }
    if (inputType === 'update') {
      return { ...inputVariables, leadId: leadInfoData.id }
    } else {
      return inputVariables
    }
  }

  const prepareEmailInputVariables = (leadId: number, inputType?: string) => {
    const emailList: EmailInformationPayloadInterface[] = []
    leadInfoData.emailList.forEach((email) => {
      emailList.push({
        id: email.id,
        leadid: leadId,
        type: email.emailType,
        isprimary: email.isPrimary,
        emailid: email.email,
        activeflag: email.activeFlag,
      })
    })
    const inputVariables = {
      payload: emailList,
    }
    if (inputType === 'update') {
      return { ...inputVariables, leadId: leadInfoData.id }
    } else {
      return inputVariables
    }
  }

  const handleUpdateAddress = () => {
    // Inserting addresses for the lead
    setLoadingMessage('Updating address...')
    if (leadInfoData.addressList?.length) {
      updateContactAddressList({
        variables: prepareAddressInputVariables(leadInfoData.id, 'update'),
      })
        .then((res) => {
          const affectedRows = res.data.insert_lead_address.affected_rows
          if (affectedRows > 0) {
            setToast(true, 'Address updated successfully!')
            getLeadInfo()
            update?.(leadInfoData)
          } else {
            setToast(false, 'Something went wrong!')
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong!')
        })
    }
  }

  const handleUpdatePhone = () => {
    // Inserting contact details for the lead

    setLoadingMessage('Updating contacts...')
    if (leadInfoData.phoneList?.length) {
      updateContactPhoneList({
        variables: preparePhoneInputVariables(leadInfoData.id, 'update'),
      })
        .then((res) => {
          const affectedRows = res.data.insert_lead_contacts.affected_rows
          if (affectedRows > 0) {
            setToast(true, 'Contacts updated successfully!')
            getLeadInfo()
            update?.(leadInfoData)
          } else {
            setToast(false, 'Something went wrong!')
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong!')
        })
    }
  }

  const handleUpdateEmail = () => {
    // Inserting email addresses for the lead
    setLoadingMessage('Updating emails...')
    if (leadInfoData.emailList?.length) {
      updateContactEmailList({
        variables: prepareEmailInputVariables(leadInfoData.id, 'update'),
      })
        .then((res) => {
          const affectedRows = res.data.insert_lead_emails.affected_rows
          if (affectedRows > 0) {
            setToast(true, 'Emails updated successfully!')
            getLeadInfo()
            update?.(leadInfoData)
          } else {
            setToast(false, 'Something went wrong!')
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong!')
        })
    }
  }

  const updateInfoData = (data: Partial<LeadInfoProps>, fieldName?: string) => {
    if (data.phoneNumber) {
      setInputErrors(inputErrors.filter((key) => key !== fieldName))
      const formattedPhoneNumber = formatPhoneNumber(data.phoneNumber)
      leadInfoData.phoneNumber = formattedPhoneNumber
      setLeadInfoData({ ...leadInfoData })
    } else if (data.fax) {
      const formattedFaxNumber = formatPhoneNumber(data.fax)
      leadInfoData.fax = formattedFaxNumber
      setLeadInfoData({ ...leadInfoData })
    } else {
      setInputErrors(inputErrors.filter((key) => key !== fieldName))
      setLeadInfoData((prev) => {
        return { ...prev, ...data }
      })
    }
  }

  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <MasterWrapper>
            <HeaderWrapper>
              <OverlayTitleWrapper>
                <WDSubFormLabelHeading>
                  <Label>{'Update Address'}</Label>
                </WDSubFormLabelHeading>
                <CrossWrapper>
                  <WDButtonTransparent>
                    <Button
                      type="button"
                      onClick={() => setShowEditAddress(false)}
                    >
                      <SvgClose fillColor="#000"></SvgClose>
                    </Button>
                  </WDButtonTransparent>
                </CrossWrapper>
              </OverlayTitleWrapper>
              <ShippingInformation
                isUpdate={true}
                {...leadInfoData}
                updateData={updateInfoData}
                updateAddressData={handleUpdateAddress}
                leadInfoData={leadInfoData}
                inputErrors={inputErrors}
              />
              <PhoneInformation
                isUpdate={true}
                {...leadInfoData}
                updateData={updateInfoData}
                updatePhoneData={handleUpdatePhone}
                leadInfoData={leadInfoData}
                inputErrors={inputErrors}
              />
              <EmailInformation
                isUpdate={true}
                {...leadInfoData}
                updateData={updateInfoData}
                updateEmailData={handleUpdateEmail}
                leadInfoData={leadInfoData}
                inputErrors={inputErrors}
              />
            </HeaderWrapper>
          </MasterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}

export default EditDetails
