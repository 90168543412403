import { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardWrapper,
  DateTime,
  Details,
  Icon,
  ListDate,
  Owner,
  Subject,
  Title,
  TitleWrapper,
  Type,
} from './style'
import moment from 'moment'
import ReactQuill from 'react-quill'
import { formatDelta } from '../../assets/utils/CommonFunctions'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import Button from '../../components/button/Button'
import Image from '../../components/image/Image'
import { Icons } from '../../shared/GlobalStyle'
import { SvgClock } from '../../components/svg/SvgClock'
import { SvgUser } from '../../components/svg/SvgUser'
import { SvgEdit } from '../../components/svg/SvgEdit'
import { DEFAULT_COLORS } from '../../theme/Colors'
import EditActionContext from '../../services/editActionsContext/EditActionsContext'

const DataCard = ({
  OrgData,
  setId,
}: {
  OrgData: any
  setId: (id: number, tranType: number) => void
}) => {
  const [data, setData] = useState<any>()
  const [dateArray, setDateArray] = useState<any>()
  const { updateActionsFlow } = useContext(EditActionContext)
  useEffect(() => {
    if (data) {
      setDateArray(Array.from(data.keys()))
    }
  }, [data])

  useEffect(() => {
    if (OrgData) {
      setData(OrgData)
    }
  }, [OrgData])

  const updateSelection = (id: string) => {
    const tempData = data
    dateArray &&
      dateArray.map((item: any) => {
        tempData &&
          tempData.get(item).map((d: any) => {
            if (d.id == id) {
              d.isVisible = !d.isVisible
            }
          })
      })
    const clone = new Map(tempData)
    setData(clone)
  }

  return (
    <>
      <CardWrapper>
        {dateArray &&
          dateArray.map((item: any, i: number) => {
            return (
              <>
                <ListDate key={`Date${i}`}>
                  {moment(item, 'YYYYMMDD').format('MM/DD/YYYY')}
                </ListDate>
                {data &&
                  data.get(item).map((d: any) => {
                    return (
                      <>
                        <Card key={`Card${d.id}`}>
                          <TitleWrapper key={`TitleWrapper${d.id}`}>
                            <Title>
                              <Type
                                color={
                                  d.type == 'note'
                                    ? '#4c7b26'
                                    : d.type == 'task'
                                    ? '#ff891d'
                                    : '#1182ff'
                                }
                              >
                                {d.type}
                              </Type>
                              <Subject>{d.subject}</Subject>
                            </Title>
                            <WDButtonTransparent width={'auto'}>
                              <DateTime>
                                <SvgClock fillColor="#333" /> {d.date}{' '}
                                {`(${d.timezone})`}
                              </DateTime>
                              <Button
                                margin={'0 1rem 0 0'}
                                padding={'2px 0 0 0'}
                                type="button"
                                onClick={() => {
                                  updateActionsFlow(+d.dataId, +d.tranType)
                                  setId(+d.dataId, +d.tranType)
                                }}
                              >
                                <SvgEdit
                                  fillColor={DEFAULT_COLORS.Icons.Primary}
                                />
                              </Button>

                              <Button
                                type="button"
                                onClick={() => {
                                  updateSelection(d.id)
                                }}
                              >
                                <Image
                                  key={`Image${d.id}`}
                                  image={
                                    d.isVisible
                                      ? Icons.ChevronUp
                                      : Icons.ChevronDown
                                  }
                                  alt="Chevron Down"
                                />
                              </Button>
                            </WDButtonTransparent>
                          </TitleWrapper>
                          {
                            <CardContent key={d.id} display={d.isVisible}>
                              <ReactQuill
                                readOnly
                                theme="bubble"
                                value={formatDelta(d.desc)}
                              />

                              {d.isVisible && (
                                <Details>
                                  <Owner>
                                    <Icon>
                                      <SvgUser
                                        width="11"
                                        fillColor="rgb(57, 57, 57)"
                                      />{' '}
                                    </Icon>{' '}
                                    Owner: {d.Owner}
                                  </Owner>
                                </Details>
                              )}
                            </CardContent>
                          }
                        </Card>
                      </>
                    )
                  })}
              </>
            )
          })}
      </CardWrapper>
    </>
  )
}
export default DataCard
