import { StyledDiv } from './styles'
export const SvgNoData = () => {
  return (
    <StyledDiv>
      <svg
        width="136"
        height="136"
        viewBox="0 0 136 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.51173 99.0672C4.29097 92.7963 2.06492 86.0627 0.913366 79.1078L6.2803 78.2192C7.33973 84.6177 9.38769 90.8126 12.3508 96.5818L7.51173 99.0672Z"
          fill="#F1F5F9"
        />
        <path
          d="M56.7153 135.057C46.7059 133.373 37.2005 129.469 28.8965 123.632C20.5925 117.795 13.7005 110.173 8.72608 101.326L13.468 98.6596C18.0445 106.799 24.3851 113.812 32.0248 119.181C39.6645 124.551 48.4094 128.143 57.6181 129.693L56.7153 135.057Z"
          fill="#F1F5F9"
        />
        <rect
          x="9"
          y="9"
          width="118.691"
          height="118.691"
          rx="59.3455"
          fill="#EFF6FF"
        />
        <rect x="0.5" y="46" width="115" height="24" rx="4" fill="#F8FAFC" />
        <path
          d="M0.5 67H115.5C115.5 68.6569 114.157 70 112.5 70H3.5C1.84315 70 0.5 68.6569 0.5 67Z"
          fill="#DBEAFE"
        />
        <rect x="17" y="75" width="115" height="24" rx="4" fill="#F8FAFC" />
        <rect x="78.5" y="52" width="33" height="13" rx="4" fill="#2563EB" />
        <path
          d="M100.373 55.5858C100.318 55.4535 100.213 55.3483 100.081 55.2933C100.016 55.2656 99.9458 55.2509 99.875 55.25H97.1667C97.023 55.25 96.8852 55.3071 96.7837 55.4087C96.6821 55.5102 96.625 55.648 96.625 55.7917C96.625 55.9353 96.6821 56.0731 96.7837 56.1747C96.8852 56.2763 97.023 56.3333 97.1667 56.3333H98.5696L95.5417 59.3613L93.7596 57.5738C93.7092 57.523 93.6493 57.4827 93.5833 57.4552C93.5173 57.4277 93.4465 57.4135 93.375 57.4135C93.3035 57.4135 93.2327 57.4277 93.1667 57.4552C93.1007 57.4827 93.0408 57.523 92.9904 57.5738L89.7404 60.8238C89.6897 60.8741 89.6494 60.934 89.6219 61C89.5944 61.066 89.5802 61.1368 89.5802 61.2083C89.5802 61.2798 89.5944 61.3506 89.6219 61.4166C89.6494 61.4827 89.6897 61.5426 89.7404 61.5929C89.7908 61.6437 89.8507 61.684 89.9167 61.7115C89.9827 61.739 90.0535 61.7531 90.125 61.7531C90.1965 61.7531 90.2673 61.739 90.3333 61.7115C90.3993 61.684 90.4592 61.6437 90.5096 61.5929L93.375 58.7221L95.1571 60.5096C95.2074 60.5604 95.2674 60.6006 95.3334 60.6281C95.3994 60.6556 95.4702 60.6698 95.5417 60.6698C95.6132 60.6698 95.684 60.6556 95.75 60.6281C95.816 60.6006 95.8759 60.5604 95.9263 60.5096L99.3333 57.0971V58.5C99.3333 58.6437 99.3904 58.7814 99.492 58.883C99.5936 58.9846 99.7313 59.0417 99.875 59.0417C100.019 59.0417 100.156 58.9846 100.258 58.883C100.36 58.7814 100.417 58.6437 100.417 58.5V55.7917C100.416 55.7209 100.401 55.651 100.373 55.5858Z"
          fill="white"
        />
        <rect x="95" y="81" width="33" height="13" rx="4" fill="#93C5FD" />
        <rect
          x="6.37305"
          y="53"
          width="50.5079"
          height="3.52381"
          rx="1.7619"
          fill="#CBD5E1"
        />
        <rect
          x="22.873"
          y="82"
          width="50.5079"
          height="3.52381"
          rx="1.7619"
          fill="#CBD5E1"
        />
        <rect
          x="6.37305"
          y="58.873"
          width="27.0159"
          height="3.52381"
          rx="1.7619"
          fill="#CBD5E1"
        />
        <rect
          x="22.873"
          y="87.873"
          width="27.0159"
          height="3.52381"
          rx="1.7619"
          fill="#CBD5E1"
        />
        <path
          d="M17 96H132C132 97.6569 130.657 99 129 99H20C18.3431 99 17 97.6569 17 96Z"
          fill="#DBEAFE"
        />
        <path
          d="M115.875 86.9586C115.731 86.9586 115.594 87.0157 115.492 87.1172C115.39 87.2188 115.333 87.3566 115.333 87.5003V88.9032L111.926 85.4907C111.876 85.4399 111.816 85.3996 111.75 85.3721C111.684 85.3446 111.613 85.3305 111.542 85.3305C111.47 85.3305 111.399 85.3446 111.333 85.3721C111.267 85.3996 111.207 85.4399 111.157 85.4907L109.375 87.2782L106.51 84.4073C106.408 84.3053 106.269 84.248 106.125 84.248C105.981 84.248 105.842 84.3053 105.74 84.4073C105.638 84.5093 105.581 84.6477 105.581 84.7919C105.581 84.9362 105.638 85.0745 105.74 85.1765L108.99 88.4265C109.041 88.4773 109.101 88.5176 109.167 88.5451C109.233 88.5726 109.303 88.5867 109.375 88.5867C109.446 88.5867 109.517 88.5726 109.583 88.5451C109.649 88.5176 109.709 88.4773 109.76 88.4265L111.542 86.639L114.57 89.6669H113.167C113.023 89.6669 112.885 89.724 112.784 89.8256C112.682 89.9272 112.625 90.0649 112.625 90.2086C112.625 90.3523 112.682 90.49 112.784 90.5916C112.885 90.6932 113.023 90.7503 113.167 90.7503H115.875C115.946 90.7494 116.016 90.7347 116.081 90.7069C116.213 90.652 116.318 90.5468 116.373 90.4144C116.401 90.3493 116.416 90.2794 116.417 90.2086V87.5003C116.417 87.3566 116.36 87.2188 116.258 87.1172C116.156 87.0157 116.019 86.9586 115.875 86.9586Z"
          fill="white"
        />
      </svg>
    </StyledDiv>
  )
}
