import {
  AssociateSearchByNamePayloadInterface,
  AssociateSearchByNameResponseInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
} from './IClientAndAccountSearchContext'
import ENDPOINTS from '../httpEndpoints'
import appConfig from '../../settings/appConfig'
import axiosProtectedInstance from '../http/axiosService'
import axios from 'axios'
const host = appConfig.apiEndPoint

export const clientSearchByNameAPI = (
  payload: ClientSearchByNamePayloadInterface
) => {
  return axiosProtectedInstance.post<ClientSearchByNameResponseInterface[]>(
    host + ENDPOINTS.GET_CLIENT_LIST_BY_NAME,
    payload
  )
}

export const associateSearchByNameAPI = (
  payload: AssociateSearchByNamePayloadInterface,
  token: string
) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  return axios.post<[AssociateSearchByNameResponseInterface]>(
    host + ENDPOINTS.GET_ASSOCIATE_BY_NAME,
    payload
  )
}
