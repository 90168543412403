import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { WDLabelPrimaryLarge } from '../../components/ui/WDLabel'
import { columnData } from '../../composites/dashboardTask/DashboardTaskData'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import { GET_DASHBOARD_TASKS } from '../../services/graphQl/CustomerGraphQl'
import UserContext from '../../services/user/UserContext'
import { ContentTitleWrapper, FootWrapper } from '../../shared/GlobalStyle'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { ItemsWrapper } from './style'
import Lottie from 'lottie-react'
import { LottieWrapperMedium } from '../loader/styles'
import LottieLoader from '../../assets/lottiefiles/loader.json'

const DashboardTask = () => {
  const { user } = useContext(UserContext)
  const { refetchInteractionsFlag, setRefetchInteractionsFlag } =
    useContext(DashboardContext)
  const navigate = useNavigate()

  const handleRoute = () => {
    navigate('/task')
  }

  const [getDashboardTask, { data: taskData, loading: taskLoading }] =
    useLazyQuery(GET_DASHBOARD_TASKS, {
      variables: { limit: 10, offset: 0, userName: user.username },
      fetchPolicy: 'no-cache',
    })

  useEffect(() => {
    getDashboardTask()
    setRefetchInteractionsFlag(false)
  }, [refetchInteractionsFlag])

  return (
    <>
      <ContentTitleWrapper>
        <WDLabelPrimaryLarge>{'Recent Tasks'}</WDLabelPrimaryLarge>
      </ContentTitleWrapper>
      <ItemsWrapper>
        {taskLoading ? (
          <LottieWrapperMedium>
            <Lottie animationData={LottieLoader} loop={true} />
          </LottieWrapperMedium>
        ) : (
          <Table
            source={'taskData'}
            tableColumns={columnData}
            refetchInteraction={getDashboardTask}
            allData={taskData?.note_vw_wealth_task_createdby ?? []}
          />
        )}
      </ItemsWrapper>
      {taskData &&
        taskData.note_vw_wealth_task_createdby &&
        taskData.note_vw_wealth_task_createdby.length > 0 && (
          <FootWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                color={DEFAULT_COLORS.Background.Primary}
                onClick={handleRoute}
              >
                {'Click here for more items'}
              </Button>
            </WDButtonTransparent>
          </FootWrapper>
        )}
    </>
  )
}

export default DashboardTask
