import styled from 'styled-components'
import { SIZES } from '../../../theme/Sizes'
import { DEFAULT_COLORS } from '../../../theme/Colors'

export const GraphWrapper = styled.div`
  width: 50%;
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
export const PieWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  place-items: left;
  width: 100%;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
`
export const PieLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const PieRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`
