import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import { leadColumnData } from './LeadData'
import LeadPartyTable from '../../components/table/LeadPartyTable'

const PersonalContact = ({
  personalContactData,
  RepCodeData,
  loadingData,
}: {
  personalContactData: any[]
  RepCodeData: any[]
  loadingData: boolean
}) => {
  const navigate = useNavigate()
  const { setCategoryName } = useContext(DashboardContext)

  const [summaryFilters, setSummaryFilters] = useState<
    { id: string; value: string | string[] | undefined | null }[]
  >([])

  const onCategorySelection = (category: string) => {
    setCategoryName(category)
    navigate('/createContactList/' + category.replace(' ', ''))
  }

  return (
    <>
      <LeadPartyTable
        source="leadData"
        tableProperties={{
          tableHeader: 'Personal Contact',
          globalSearchRequired: true,
          tileFilterRequired: false,
          paginatorRequired: true,
          headerButtonRequired: true,
          buttonLabel: 'New Personal Contact',
          createCallback: () => onCategorySelection('Personal Contact'),
        }}
        isLoading={loadingData}
        tableColumns={personalContactData ? leadColumnData : []}
        allData={personalContactData ?? []}
        filterData={summaryFilters}
        setSummaryFilters={setSummaryFilters}
        sortingFields={[
          'firstname',
          'status',
          'type',
          'category',
          'phone',
          'email',
          'advisor',
        ]}
        repCodeList={RepCodeData}
      />
    </>
  )
}

export default PersonalContact
