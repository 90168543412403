import {
  AssociateSearchPayloadInterface,
  AssociateSearchResponseInterface,
} from './ISearchContext'
import ENDPOINTS from '../../services/httpEndpoints'
import axiosProtectedInstance from '../http/axiosService'



export const associateSearchAPI = (
  payload: AssociateSearchPayloadInterface
) => {
  return axiosProtectedInstance.post<AssociateSearchResponseInterface[]>(
    ENDPOINTS.GET_ASSOCIATE_BY_NAME,
    payload
  )
}

