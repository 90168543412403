import { ISvg } from './ISvg'
import { StyledDiv } from './styles'

export const SvgUser = ({ fillColor, width, height }: ISvg) => {
  return (
    <StyledDiv>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.47333 7.47325C10.1269 6.95903 10.604 6.25387 10.8382 5.45588C11.0723 4.6579 11.0519 3.80676 10.7799 3.0209C10.5078 2.23503 9.9975 1.55352 9.32005 1.07116C8.64259 0.5888 7.83163 0.32959 7 0.32959C6.16836 0.32959 5.35741 0.5888 4.67995 1.07116C4.0025 1.55352 3.49223 2.23503 3.22014 3.0209C2.94805 3.80676 2.92767 4.6579 3.16184 5.45588C3.396 6.25387 3.87307 6.95903 4.52666 7.47325C3.40672 7.92195 2.42952 8.66615 1.69926 9.62653C0.968997 10.5869 0.51304 11.7275 0.379998 12.9266C0.370368 13.0141 0.378075 13.1027 0.402681 13.1873C0.427287 13.2719 0.468309 13.3508 0.523405 13.4195C0.634677 13.5582 0.79652 13.6471 0.973331 13.6666C1.15014 13.686 1.32744 13.6345 1.46621 13.5232C1.60499 13.4119 1.69388 13.2501 1.71333 13.0733C1.85972 11.7701 2.48112 10.5665 3.45881 9.69245C4.4365 8.81844 5.70193 8.33527 7.01333 8.33527C8.32473 8.33527 9.59016 8.81844 10.5679 9.69245C11.5455 10.5665 12.1669 11.7701 12.3133 13.0733C12.3315 13.2371 12.4096 13.3884 12.5327 13.4979C12.6559 13.6075 12.8152 13.6676 12.98 13.6666H13.0533C13.2281 13.6465 13.3878 13.5581 13.4977 13.4208C13.6076 13.2834 13.6587 13.1082 13.64 12.9333C13.5063 11.7307 13.0479 10.5872 12.3139 9.62539C11.5799 8.66354 10.5979 7.91957 9.47333 7.47325ZM7 6.99992C6.47258 6.99992 5.95701 6.84352 5.51848 6.55051C5.07995 6.25749 4.73815 5.84101 4.53632 5.35374C4.33449 4.86647 4.28168 4.3303 4.38457 3.81301C4.48746 3.29573 4.74144 2.82058 5.11438 2.44764C5.48732 2.0747 5.96248 1.82072 6.47976 1.71783C6.99704 1.61493 7.53322 1.66774 8.02049 1.86958C8.50776 2.07141 8.92423 2.4132 9.21725 2.85173C9.51027 3.29026 9.66667 3.80584 9.66667 4.33325C9.66667 5.0405 9.38571 5.71878 8.88562 6.21887C8.38552 6.71897 7.70724 6.99992 7 6.99992Z"
          fill={fillColor}
        />
      </svg>
    </StyledDiv>
  )
}
