import appConfig from '../../settings/appConfig'
import axiosProtectedInstance from '../http/axiosService'
import ENDPOINTS from '../httpEndpoints'
import {
  ClientAccountInterface,
  ClientAddressParamsInterface,
  ClientDetailsResponseInterface,
  ClientEmailInterface,
  ClientPayloadInterface,
  ClientPhoneInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  PartyPayloadInterface,
  RepCodeInterface,
  UserAccessInterface,
} from './IClientsContext'

const host = appConfig.apiEndPoint

export const getIndividualClientDetails = (payload: PartyPayloadInterface) => {
  return axiosProtectedInstance.post<ClientDetailsResponseInterface>(
    host + ENDPOINTS.GET_INDIVIDUAL_DETAILS_DATA,
    payload
  )
}
export const GetClientAccounts = (payload: PartyPayloadInterface) => {
  return axiosProtectedInstance.post<ClientAccountInterface[]>(
    host + ENDPOINTS.GET_CLIENT_ACCOUNTS,
    payload
  )
}
export const getPartyEmail = (payload: ClientPayloadInterface) => {
  return axiosProtectedInstance.post<ClientEmailInterface[]>(
    host + ENDPOINTS.GET_CLIENT_EMAIL,
    payload
  )
}
export const clientSearchByNameAPI = (
  payload: ClientSearchByNamePayloadInterface
) => {
  return axiosProtectedInstance.post<ClientSearchByNameResponseInterface[]>(
    host + ENDPOINTS.GET_CLIENT_LIST_BY_NAME,
    payload
  )
}
export const getPartyContact = (payload: ClientPayloadInterface) => {
  return axiosProtectedInstance.post<ClientPhoneInterface[]>(
    host + ENDPOINTS.GET_PARTY_CONTACTS,
    payload
  )
}
export const getPartyAddress = (payload: ClientPayloadInterface) => {
  return axiosProtectedInstance.post<ClientAddressParamsInterface[]>(
    host + ENDPOINTS.GET_CLIENT_ADDRESS,
    payload
  )
}


export const getUserAccess = () => {
  return axiosProtectedInstance.get<UserAccessInterface>(
    host + ENDPOINTS.GET_REP_ACCESS_LEVEL_BY_USER
  )
}

export const getADUsersByRep = (payload: RepCodeInterface) => {
  return axiosProtectedInstance.post(
    host + ENDPOINTS.GET_AD_USER_BY_REP_CODE,
    payload
  )
}