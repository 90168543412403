export const DEFAULT_COLORS = {
  Background: {
    LightBlue: '#bfdbfe',
    Neutral: '#FFFFFF',
    NeutralWeak: '#F8FAFC',
    NeutralStrong: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    Primary: '#2563EB',
    FadedOrange: '#FB923C',
    PrimaryLight: '#eff6ff',
    PrimaryStrong: '#1E3A8A',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryHover: '#DBEAFE',
    PrimaryActive: '#BFDBFE',
    NeutralDisabled: '#A7AFBC',
    DangerStrong: '#EA001E',
    PaleCerulean: '#A4C5E8',
    Success: '#EBF7E6',
    NeutralGray: '#CBD5E1',
    GrayOpacity: '#A7AFBC4D',
    GrayStrong: '#A7AFBC1A',
    Elevation: '#00245D',
    EbonyClay: '#1E293B',
    FunBlue: '#1F5EB7',
    Smalt: '#002698',
    Mercury: '#e5e5e5',
    Porcelain: '#F0F2F5',
    Iron: '#cbd5e1',
    Fantasy: '#FEF1EE',
    RoseWhite: '#FFF7ED',
    OverlayBackground: 'rgba(71, 85, 105, 0.25)',
    Warning: '#FFF7ED',
    WarningLight: '#EEEEEE',
    DodgerBlue: '#3B82F6',
    PrimaryCardTop: '#1d4ed8',
    Grey: '#ccc',
    GreyNeutral: '#9b9b9b',
    Platinum: '#e6e6e6',
    LightGreen: '#008000',
    Primary10: '#A4C5E8',
  },
  Text: {
    UserDark: '#303030',
    Elevation: '#00245D',
    Neutral: '#0F172A',
    NeutralWeak: '#475569',
    NeutralInverted: '#FFFFFF',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    Success: '#2E844A',
    DarkGreen: '#22C373',
    LightGreen: '#008000',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
    DodgerBlue: '#3B82F6',
    Danger: '#BA0517',
    DangerDark: '#C72F24',
    Black: '#000000',
    EbonyClay: '#1E293B',
    Warning: '#C2410C',
    DangerStrong: '#EA001E',
    RedBerry: '#8b0000',
  },
  Border: {
    Primary: '#2563EB',
    PrimaryStrong: '#60A5FA',
    PrimaryActive: '#2563EB',
    PrimaryMedium: '#2684FF',
    LightBlue: '#bfdbfe',
    Neutral: '#CBD5E1',
    NeutralMedium: '#F1F5F9',
    NeutralHover: '#64748B',
    NeutralWeak: '#E2E8F0',
    NeutralDisabled: '#A7AFBC',
    FunBlue: '#1F5EB7',
    Salmon: '#FE7765',
    FadedOrange: '#FB923C',
    DarkGreen: '#22C373',
    MediumGreen: '#41B658',
    DangerDark: '#C72F24',
    DangerStrong: '#EA001E',
    BlueberryBlue: '#0847BE',
    Porcelain: '#F0F2F5',
    Iron: '#cbd5e1',
    BlackDark: '#333',
    SilverChalice: '#adadad',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#475569',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
    Danger: '#BA0517',
    Success: '#2E844A',
    Gray: '#9b9b9b',
    Platinum: '#E5E4E2',
    Gold: '#FFD700',
    Silver: '#C0C0C0',
    Bronze: '#CD7F32',
    Black: '#000000',
  },
  BoxShadow: {
    StyledCardShadow: 'rgba(0, 36, 93, 0.3)',
    Shadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    Shadow2: 'rgba(0, 0, 0, 0.075)',
    Shadow3: 'rgba(0, 36, 93, 0.3)',
    BackgroundShadow: 'rgba(71, 85, 105, 0.25)',
    PlusMenuBoxShadow: 'rgba(0, 36, 93, 0.3) 0px 2px 6px 1px',
    IronShadow: 'inset 0px -1px 0px #D0D7E2',
    Shadow1: 'rgba(0, 36, 93, 0.3)',
  },
}

export const LIGHT_COLORS = {
  Background: {
    Neutral: '#FFFFFF',
    NeutralWeak: '#F8FAFC',
    PaleCerulean: '#A4C5E8',
    NeutralStrong: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    NeutralDisabled: '#A7AFBC',
    Primary: '#2563EB',
    PrimaryLight: '#eff6ff',
    PrimaryStrong: '#1E3A8A',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryHover: '#DBEAFE',
    PrimaryActive: '#BFDBFE',
    DangerStrong: '#EA001E',
    Success: '#EBF7E6',
    NeutralGray: '#CBD5E1',
    GrayOpacity: '#A7AFBC4D',
    GrayStrong: '#A7AFBC1A',
    Elevation: '#00245D',
    EbonyClay: '#1E293B',
    FunBlue: '#1F5EB7',
    Smalt: '#002698',
    Porcelain: '#F0F2F5',
    Mercury: '#e5e5e5',
    Iron: '#cbd5e1',
    Fantasy: '#FEF1EE',
    RoseWhite: '#FFF7ED',
    OverlayBackground: 'rgba(71, 85, 105, 0.25)',
    Warning: '#FFF7ED',
  },
  Text: {
    UserDark: '#303030',
    Elevation: '#00245D',
    Neutral: '#0F172A',
    NeutralWeak: '#475569',
    NeutralInverted: '#FFFFFF',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    Success: '#2E844A',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
    DodgerBlue: '#3B82F6',
    Danger: '#BA0517',
    DangerDark: '#C72F24',
    Black: '#000000',
    DarkGreen: '#22C373',
    EbonyClay: '#1E293B',
    Warning: '#C2410C',
  },
  Border: {
    Primary: '#2563EB',
    PrimaryStrong: '#60A5FA',
    PrimaryActive: '#2563EB',
    LightBlue: '#bfdbfe',
    Neutral: '#CBD5E1',
    NeutralMedium: '#F1F5F9',
    NeutralHover: '#64748B',
    NeutralWeak: '#E2E8F0',
    NeutralDisabled: '#A7AFBC',
    FunBlue: '#1F5EB7',
    Salmon: '#FE7765',
    FadedOrange: '#FB923C',
    DarkGreen: '#22C373',
    MediumGreen: '#41B658',
    DangerDark: '#C72F24',
    BlueberryBlue: '#0847BE',
    Porcelain: '#F0F2F5',
    Iron: '#cbd5e1',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#475569',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
    Danger: '#BA0517',
    Success: '#2E844A',
  },
  BoxShadow: {
    StyledCardShadow: 'rgba(0, 36, 93, 0.3)',
    Shadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    Shadow2: 'rgba(0, 0, 0, 0.075)',
    Shadow3: 'rgba(0, 0, 0, 0.075)',
    BackgroundShadow: 'rgba(71, 85, 105, 0.25)',
    PlusMenuBoxShadow: '0px 8px 16px -8px rgba(0, 36, 93, 0.3)',
    IronShadow: 'inset 0px -1px 0px #D0D7E2',
  },
}

export const DARK_COLORS = {
  Background: {
    Neutral: '#303030',
    NeutralWeak: '#424242',
    NeutralStrong: '#505357',
    NeutralMedium: '#5b5d5f',
    Primary: '#434446',
    NeutralDisabled: '#A7AFBC',
    PrimaryLight: '#eff6ff',
    PrimaryStrong: '#fff',
    PaleCerulean: '#A4C5E8',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryHover: '#DBEAFE',
    PrimaryActive: '#BFDBFE',
    DangerStrong: '#EA001E',
    Success: '#EBF7E6',
    NeutralGray: '#CBD5E1',
    GrayOpacity: '#A7AFBC4D',
    GrayStrong: '#A7AFBC1A',
    Elevation: '#00245D',
    EbonyClay: '#1E293B',
    FunBlue: '#1F5EB7',
    Mercury: '#e5e5e5',
    Smalt: '#002698',
    Porcelain: '#F0F2F5',
    Iron: '#cbd5e1',
    Fantasy: '#FEF1EE',
    RoseWhite: '#FFF7ED',
    OverlayBackground: 'rgba(71, 85, 105, 0.25)',
    Warning: '#FFF7ED',
  },
  Text: {
    UserDark: '#303030',
    Elevation: '#d4d4d4',
    Neutral: '#e7eeff',
    NeutralWeak: '#b7cae4',
    NeutralInverted: '#ffffff',
    Primary: '#fff',
    PrimaryStrong: '#fdfdfd',
    Success: '#2E844A',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
    DodgerBlue: '#3B82F6',
    Danger: '#BA0517',
    DangerDark: '#C72F24',
    Black: '#000000',
    DarkGreen: '#22C373',
    EbonyClay: '#1E293B',
    Warning: '#C2410C',
  },
  Border: {
    Primary: '#2563EB',
    PrimaryStrong: '#60A5FA',
    PrimaryActive: '#2563EB',
    LightBlue: '#bfdbfe',
    Neutral: '#CBD5E1',
    NeutralMedium: '#F1F5F9',
    NeutralHover: '#64748B',
    NeutralWeak: '#E2E8F0',
    NeutralDisabled: '#A7AFBC',
    FunBlue: '#1F5EB7',
    Salmon: '#FE7765',
    FadedOrange: '#FB923C',
    DarkGreen: '#22C373',
    MediumGreen: '#41B658',
    DangerDark: '#C72F24',
    BlueberryBlue: '#0847BE',
    Porcelain: '#F0F2F5',
    Iron: '#cbd5e1',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#ebedf0',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
    Danger: '#BA0517',
    Success: '#2E844A',
  },
  BoxShadow: {
    StyledCardShadow: 'rgba(0, 36, 93, 0.3)',
    Shadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    Shadow2: 'rgba(0, 0, 0, 0.075)',
    Shadow3: 'rgba(0, 0, 0, 0.075)',
    BackgroundShadow: 'rgba(71, 85, 105, 0.25)',
    PlusMenuBoxShadow: '0px 8px 16px -8px rgba(0, 36, 93, 0.3)',
    IronShadow: 'inset 0px -1px 0px #D0D7E2',
  },
}
