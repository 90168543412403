import { NoteMainWrapper, NoteWrp, TimesWrp } from './styles'
import { WDLabelBold } from '../../components/ui/WDLabel'
import Label from '../../components/label/Label'
import { SvgClock } from '../../components/svg/SvgClock'
import { DEFAULT_COLORS } from '../../theme/Colors'
import GraphQLLoader from '../../composites/loader/GraphQLLoader'
import ReactQuill from 'react-quill'

//const OpportunitiesNotes = (props: IOpportunitiesNotesProps) => {
const OpportunitiesNotes = ({ notesData, loadingNotes }: any) => {
  const dateFormate = (inputDateString: string) => {
    const inputDate =
      inputDateString === '' ? new Date() : new Date(inputDateString)

    // Check if the date is valid before formatting
    if (!isNaN(inputDate.getTime())) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour clock format
      }
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
        inputDate
      )
      return formattedDate
    } else {
      return '-'
    }
  }
  return (
    <NoteMainWrapper>
      {loadingNotes ? (
        <GraphQLLoader />
      ) : notesData?.note_vw_wealth_notes_createdby?.length > 0 ? (
        notesData?.note_vw_wealth_notes_createdby?.map(
          (note?: any, i?: number) => {
            return (
              <NoteWrp key={i}>
                <WDLabelBold>
                  <Label>{note?.subject}</Label>
                </WDLabelBold>
                <ReactQuill
                  theme="bubble"
                  value={JSON.parse(note?.detail) ?? ''}
                  readOnly={true}
                />
                <TimesWrp>
                  <SvgClock fillColor={DEFAULT_COLORS.Icons.Gray}></SvgClock>
                  <span>{dateFormate(note?.createdon ?? '')}</span>
                </TimesWrp>
              </NoteWrp>
            )
          }
        )
      ) : (
        <WDLabelBold>
          <Label>{'No Data'}</Label>
        </WDLabelBold>
      )}
    </NoteMainWrapper>
  )
}
export default OpportunitiesNotes
