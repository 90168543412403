import { ReactElement } from 'react'
import styled from 'styled-components'

export interface IconProps {
  icon?: string | SVGElement | ReactElement
  margin?: string
  width?: string
  height?: string
}

export const StyledIcon = styled.img<IconProps>`
  margin: 0rem 0rem 0rem ${(IconProps: IconProps) => IconProps.margin};
  width: ${(IconProps: IconProps) => IconProps.width};
  height: ${(IconProps: IconProps) => IconProps.height};
  cursor: pointer;
`
