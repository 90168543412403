import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'
export interface ColorProps {
  bgColor: string
  color?: string
}

export const CustomMarker = styled.div((props: ColorProps) => {
  const baseStyles = `
  display: inline;
  font-size: 0.8rem;
  background-color: ${props.bgColor};
  padding: 0 4px;
  color:${props.color ?? '#000'};
  `
  return baseStyles
})

export const CustomMarkerLead = styled.div`
  background: #b1efc5;
  color: #095522;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.25rem;
  font-weight: 600;
`
export const CustomMarkerClient = styled.div`
  background: ${DEFAULT_COLORS.Background.LightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${DEFAULT_COLORS.Background.Primary};
  width: 100%;
  padding: 0.25rem;
  font-weight: 600;
`

export const Wrapper = styled.div`
  margin: 1rem;
  min-height: ${(props: { height: string }) => props.height ?? ''};
  max-width: 100%;
  background: white;
  display: flex;
  flex: 1;
  overflow: auto;
  // overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
  table {
    table-layout: unset;
    height: max-content;
    width: 100%;
    border-collapse: collapse;
    min-width: 100%;
    flex-direction: column;
    border: none;
    text-align: left;

    thead {
      font-family: 'SFMono-Regular';
      font-style: normal;
      z-index: 1;
      tr {
        display: table-row;
        align-items: center;
        box-shadow: ${() => DEFAULT_COLORS.BoxShadow.IronShadow};
        background-color: rgb(37 99 235 / 16%);
        z-index: 1;
        th {
          display: table-cell;
          /* border-top: 0.15rem solid ${() =>
            DEFAULT_COLORS.Border.NeutralMedium}; */
          flex-wrap: wrap;
          align-items: center;
          gap: 0.5rem;
          box-shadow: ${() => DEFAULT_COLORS.BoxShadow.IronShadow};
          font-size: 0.8rem;
          line-height: 1.25rem;
          span {
            padding: 0.8rem 0px 0.8rem 0.8rem;
            font-family: 'SourceSansPro-Regular';
            letter-spacing: 1px;
            white-space: nowrap;
          }
          :not(:last-child) {
            span {
              border-right: 1px solid ${() => DEFAULT_COLORS.Background.Grey};
            }
          }
        }
      }
    }

    tbody {
      font-family: 'SFMono-Regular';
      font-style: normal;
      &:nth-child(2n + 1) {
        background-color: ${() => DEFAULT_COLORS.Background.GrayStrong};
      }

      tr {
        display: table-row;
        font-family: 'SFMono-Regular';
        &.description {
          border-bottom: 0.25rem solid
            ${() => DEFAULT_COLORS.Border.NeutralMedium};
        }
        &:nth-child(even) {
          background-color: rgb(223 237 255 / 24%);
        }
        &:hover {
          background-color: #f7f7f7;
        }
        &.selected {
          background-color: ${() => DEFAULT_COLORS.Background.GrayOpacity};
          border-bottom: 1px solid ${() => DEFAULT_COLORS.Border.Primary};
          td {
            color: ${DEFAULT_COLORS.Text.Primary};
            font-weight: 600;
          }
        }
        td {
          display: table-cell;
          flex-wrap: wrap;
          word-break: break-word;
          align-items: center;
          padding: 0.5rem 0.8rem;
          gap: 0.5rem;
          box-shadow: ${() => DEFAULT_COLORS.BoxShadow.IronShadow};
          font-size: 0.9rem;
          line-height: 1.25rem;
          &.Attribute {
            font-family: 'SFMono-Regular';
          }
          &#R {
            color: ${() => DEFAULT_COLORS.Text.DangerDark};
          }
          &#O {
            color: ${() => DEFAULT_COLORS.Text.DarkGreen};
          }
          &#C {
            color: ${() => DEFAULT_COLORS.Text.Primary};
            &.Status {
              color: ${() => DEFAULT_COLORS.Text.Neutral};
              font-weight: 700;
              font-size: 1rem;
              line-height: 1.25rem;
            }
            &#R {
              color: ${() => DEFAULT_COLORS.Text.DangerDark};
            }
            &#O {
              color: ${() => DEFAULT_COLORS.Text.DarkGreen};
            }
            &#C {
              color: ${DEFAULT_COLORS.Text.Primary};
            }
            &#Description {
              width: 3.125rem;
            }
          }
          td {
            padding: 0;
            border: none;
            box-shadow: none;
            font-size: 0.9rem;
            ul {
              padding: 0;
              line-height: 0;
              div {
                button {
                  padding: 0;
                  line-height: 0;
                  div {
                    padding: 0;
                    line-height: 1rem;
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }

      tfoot {
        font-family: 'SFMono-Regular';
        font-style: normal;

        tr {
          display: table-row;
          align-items: center;

          td {
            display: table-cell;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
`

export const ThContentWrapper = styled.div`
  width: 100%;
`
export const StyledRectangle = styled.div`
  width: 1px !important;
  height: 1rem;
  background: ${() => DEFAULT_COLORS.Background.Iron} !important;
  border-radius: 1px;
`
export const TableWrapper = styled.table``
export const TableRow = styled.tr``

export const TableHeader = styled.thead`
  position: sticky;
  text-transform: uppercase;
  top: 0px;
  margin: 0;
  // z-index: 20;
  background-color: ${() => DEFAULT_COLORS.Background.Neutral};
  tr {
    :nth-child(1) {
      img {
        display: none;
      }
    }
  }
`
export const TableFooter = styled.tfoot``
export const TableHeaderCell = styled.th`
  &:active {
    background-color: ${DEFAULT_COLORS.Background.NeutralStrong};
  }
`
export const TableBody = styled.tbody``
export const TableDataCell = styled.td`
  ${(props: { wrap: boolean; align: string }) => {
    return `
max-width: 200px;
min-width: 22px;
  white-space: ${props.wrap ? 'normal' : 'nowrap'};
  overflow: hidden;
  text-align:${props.align ?? 'left'};
  text-overflow: ellipsis;
  &.remove_box {
    box-shadow: none !important;
  }
  &.remove_box1 {
    box-shadow: none !important;
  }
  &.remove_box2 {
    box-shadow: none !important;
  }

`
  }}
`
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid red;
  tr {
    td {
      width: 100%;
    }
  }
`

export const HeaderWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`
export const SortWrapper = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
  gap: 0.2rem;
`
export const FilterWrapper = styled.div`
  position: relative;
`
export const BadgeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 5px;
  background-color: #ff5733;
  color: white;
  border-radius: 50%;
  padding: 1px 5px;
  font-size: 12px;
`

export const BtnWrapper = styled.span`
  button {
    padding: 0.125rem;
  }
`
export const LogoWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
`

export const LinkWrapper = styled.div`
  text-decoration: none;
`
export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 2rem;
`
export const NoDataSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const DetailsTableWrapper = styled.div`
  width: 100%;
  background-color: ${() => DEFAULT_COLORS.Background.Success};
  thead {
    background-color: ${() => DEFAULT_COLORS.Background.Success};
  }
`
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 0.25rem; */
`
export const CrmWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;
`
export const SpinnerTableWrapper = styled.div`
  display: flex;
  //  position: fixed;
  // margin-left: 50px;
  // top: 0;
  // left: 0;
  z-index: 999;
  width: 100%;
  height: 45vh;
  justify-content: center;
  align-items: center;
`
export const ActiveWrapper = styled.div`
  background: ${() => DEFAULT_COLORS.Background.Success};
  border-radius: 0.625rem;
  padding: ${SIZES[0]} ${SIZES[1]};
  width: fit-content;
  display: flex;
`
export const InActiveWrapper = styled.div`
  background: ${() => DEFAULT_COLORS.Background.NeutralStrong};
  border-radius: 0.625rem;
  padding: ${SIZES[0]} ${SIZES[1]};
  width: fit-content;
  display: flex;
`
export const PendingWrapper = styled.div`
  background: ${() => DEFAULT_COLORS.Background.Warning};
  border-radius: 0.625rem;
  padding: ${SIZES[0]} ${SIZES[1]};
  width: fit-content;
  display: flex;
`
export const ReactTable = styled.div`
  height: fit-content;
  display: flex;
  background-color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
  overflow-y: auto;
  max-height: 50vh;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`
export const TableHead = styled.thead`
  position: sticky;
  top: 0rem;
  margin: 0;
  background-color: ${DEFAULT_COLORS.Text.NeutralInverted};
`
export const TotalWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.75rem;
  flex-direction: row;
`
export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const CrmActionWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`
export const ParagraphWrp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const SearchWrapper = styled.div`
  position: absolute;
`
export const DisplayWrapper = styled.div``
export const DateTimeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
`
export const InputWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  margin-right: auto;
  width: inherit;
  gap: 0.5rem;
  position: relative;
  input {
    width: 10rem;
    padding: 0.34rem 1rem;
    height: 2rem;
    background: ${() => DEFAULT_COLORS.Background.Neutral};
    border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
    &:hover {
      border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      border-color: ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      color: ${() => DEFAULT_COLORS.Icons.NeutralHover};
    }
    &:active {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      border-color: ${() => DEFAULT_COLORS.Border.NeutralDisabled};
    }
    &:disabled {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      border-color: ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
      cursor: no-drop;
    }
    &:invalid {
      box-shadow: inset 0px 0px 0px 1px ${() => DEFAULT_COLORS.Border.Iron};
      border-color: ${() => DEFAULT_COLORS.Border.Iron};
    }
    // &:focus {
    //   box-shadow: inset 0px 0px 0px 1px ${() =>
      DEFAULT_COLORS.Border.FunBlue}
    //   border-color: ${() => DEFAULT_COLORS.Border.FunBlue};
    //   color: ${() => DEFAULT_COLORS.Text.Black};
    // }
    &:required:valid {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.DangerDark};
      border-color: ${() => DEFAULT_COLORS.Border.DangerDark};
      background-color: ${() => DEFAULT_COLORS.Background.Fantasy};
    }
    box-sizing: border-box;
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    color: ${() => DEFAULT_COLORS.Text.Neutral};
    outline: none;
  }
`
export const SelectWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
`
export const WDButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
export const AddButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: auto;
`
export const TileFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: pre;
`
export const FilterSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
