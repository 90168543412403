import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import Table from '../../../components/table/Table'
import GraphQLLoader from '../../../composites/loader/GraphQLLoader'
import NoDataFound from '../../../composites/noDataFound/NoDataFound'
import { GET_PARTY_ACCOUNTS } from '../../../services/graphQl/LeadsGraphQL'
import { LeadInfoProps } from '../overviewSection/IEditDetails'
import { Wrapper } from './styles'

const AccountColumnData = [
  {
    Header: 'Account Number',
    accessor: 'account',
  },
  {
    Header: 'Account Holders',
    accessor: 'accountName',
  },
  {
    Header: 'Account Type',
    accessor: 'role',
  },
  {
    Header: 'Relationship',
    accessor: 'accountRelationship',
  },
]

function AccountDetails({ leadDetails }: { leadDetails: LeadInfoProps }) {
  const [clientAccountDetails, setclientAccountDetails] = useState<any[]>([])
  const { data: clientAccountData, loading: clientAccountLoading } =
    useQuery<any>(GET_PARTY_ACCOUNTS, {
      variables: {
        partyId: Number(leadDetails?.id) ?? 0,
      },
    })

  useEffect(() => {
    if (clientAccountData?.account_partyaccountrel.length > 0) {
      const accountDetails = clientAccountData?.account_partyaccountrel?.map(
        (account: any) => ({
          account: account?.accountDetails?.accountnumber ?? '',
          accountName: account?.accountDetails?.accountname ?? '',
          role: account?.accountDetails?.accountType?.description ?? '',
          accountRelationship: account?.relationshiptypedesc ?? '',
        })
      )
      setclientAccountDetails(accountDetails)
    }
  }, [clientAccountData?.account_partyaccountrel])

  return (
    <Wrapper>
      {clientAccountLoading ? (
        <GraphQLLoader />
      ) : clientAccountData?.account_partyaccountrel &&
        clientAccountData?.account_partyaccountrel.length > 0 ? (
        <Table
          height="20rem"
          tableColumns={AccountColumnData}
          allData={clientAccountDetails}
        />
      ) : (
        !clientAccountLoading && <NoDataFound />
      )}
    </Wrapper>
  )
}
export default AccountDetails
