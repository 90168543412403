import { useState, useEffect } from 'react'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { Textbox } from '../../components/textbox/Textbox'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { AddressInformationProps } from './IindividualInformation'
import {
  CreateWrapper,
  ItemWrapper,
  TextBoxWrap,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
  ItemSubWrp,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import { WDContactInfoWrapper } from '../../components/ui/WDWrapper'
import { useLocation } from 'react-router'
import { ErrorMessage } from '../opportunitiesDetails/styles'

const ContactInformation = ({
  updateData,
  leadInfoData,
  inputErrors,
}: AddressInformationProps) => {
  const [isContactOpen, setIsContactOpen] = useState(true)
  const [contactType, setContactType] = useState<string>('')
  const [contactError, setContactError] = useState({
    firstName: false,
    lastName: false,
    businessName: false,
    trustName: false,
  })
  const location = useLocation()

  useEffect(() => {
    if (leadInfoData.type) {
      setContactType(leadInfoData.type.replace(' ', ''))
    } else {
      setContactType(location?.pathname?.split('/')?.pop() ?? '')
    }
  }, [leadInfoData.type, location?.pathname])

  useEffect(() => {
    if (inputErrors.length > 0) {
      setContactError({
        firstName: inputErrors.includes('firstName'),
        lastName: inputErrors.includes('lastName'),
        businessName: inputErrors.includes('businessName'),
        trustName: inputErrors.includes('trustName'),
      })
    }
  }, [inputErrors])

  const honorificList = [
    { value: 'Mr.', label: 'Mr.' },
    { value: 'Ms.', label: 'Ms.' },
  ]

  const preferedPronouceList = [
    { value: 'He/Him/His', label: 'He/Him/His' },
    { value: 'She/Her/Hers', label: 'She/Her/Hers' },
    { value: 'They/Them/Theirs', label: 'They/Them/Theirs' },
  ]

  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper onClick={() => setIsContactOpen(!isContactOpen)}>
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">
                {contactType === 'Individual' ||
                contactType === 'NewRecruit' ||
                contactType === 'PersonalContact'
                  ? 'Individual'
                  : contactType === 'Business'
                  ? 'Business'
                  : 'Trust'}
              </Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() => setIsContactOpen(!isContactOpen)}
              >
                <Image
                  image={isContactOpen ? Icons.ChevronUp : Icons.ChevronDown}
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isContactOpen &&
          (contactType === 'Individual' ||
            contactType === 'NewRecruit' ||
            contactType === 'PersonalContact') && (
            <>
              <ItemWrapper>
                <ItemSubWrp>
                  <CreateWrapper>
                    <Label>{'Honorific'}</Label>
                    <SelectDropdown
                      className="w-100"
                      borderColor={DEFAULT_COLORS.Border.Neutral}
                      options={honorificList}
                      height="2.62rem"
                      placeholder={'Select'}
                      value={{
                        value: leadInfoData.honorific,
                        label: leadInfoData.honorific,
                      }}
                      onChange={(option) =>
                        updateData({ honorific: option.value }, 'honorific')
                      }
                      padding={'0rem'}
                    />
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'First Name*'}</Label>
                    <TextBoxWrap error={contactError.firstName}>
                      <Textbox
                        onChange={(value) => {
                          updateData({ firstName: value }, 'firstName')
                        }}
                        value={leadInfoData.firstName}
                      />
                    </TextBoxWrap>
                    {contactError.firstName && (
                      <ErrorMessage>{'First Name is required'}</ErrorMessage>
                    )}
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Middle Name'}</Label>
                    <Textbox
                      onChange={(value) =>
                        updateData({ middleName: value }, 'middleName')
                      }
                      value={leadInfoData.middleName}
                    />
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Last Name*'}</Label>
                    <TextBoxWrap error={contactError.lastName}>
                      <Textbox
                        value={leadInfoData.lastName}
                        onChange={(value) =>
                          updateData({ lastName: value }, 'lastName')
                        }
                      />
                    </TextBoxWrap>
                    {contactError.lastName && (
                      <ErrorMessage>{'Last Name is required'}</ErrorMessage>
                    )}
                  </CreateWrapper>
                </ItemSubWrp>
              </ItemWrapper>

              <ItemWrapper>
                <ItemSubWrp>
                  <CreateWrapper>
                    <Label>{'Suffix'}</Label>
                    <Textbox
                      onChange={(value) =>
                        updateData({ suffix: value }, 'suffix')
                      }
                      value={leadInfoData.suffix}
                    />
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Preferred Pronouns'}</Label>
                    <SelectDropdown
                      className="w-100"
                      borderColor={DEFAULT_COLORS.Border.Neutral}
                      padding="0rem"
                      options={preferedPronouceList}
                      height="2.62rem"
                      placeholder={'Select'}
                      value={{
                        value: leadInfoData.preferedPronouce,
                        label: leadInfoData.preferedPronouce,
                      }}
                      onChange={(option) =>
                        updateData(
                          { preferedPronouce: option.value },
                          'preferedPronouce'
                        )
                      }
                    />
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Nickname / Preferred Name'}</Label>
                    <Textbox
                      onChange={(value) =>
                        updateData({ nickName: value }, 'nickName')
                      }
                      value={leadInfoData.nickName}
                    />
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Pronunciation'}</Label>
                    <Textbox
                      onChange={(value) =>
                        updateData({ pronouciation: value }, 'pronouciation')
                      }
                      value={leadInfoData.pronouciation}
                    />
                  </CreateWrapper>
                </ItemSubWrp>
              </ItemWrapper>
              <ItemWrapper>
                <ItemSubWrp>
                  <CreateWrapper>
                    <Label>{'Job Title'}</Label>
                    <Textbox
                      onChange={(value) =>
                        updateData({ jobTitle: value }, 'jobTitle')
                      }
                      value={leadInfoData.jobTitle}
                    />
                  </CreateWrapper>
                  <CreateWrapper>
                    <Label>{'Employer'}</Label>
                    <Textbox
                      placeholder="Company Name"
                      onChange={(value) =>
                        updateData({ account: value }, 'account')
                      }
                      value={leadInfoData.account}
                    />
                  </CreateWrapper>
                </ItemSubWrp>
              </ItemWrapper>
            </>
          )}

        {isContactOpen && contactType === 'Business' && (
          <>
            <ItemWrapper>
              <ItemSubWrp>
                <CreateWrapper>
                  <Label>{'Business Name'}</Label>
                  <TextBoxWrap error={contactError.businessName}>
                    <Textbox
                      onChange={(value) =>
                        updateData({ businessName: value }, 'businessName')
                      }
                      value={leadInfoData.businessName}
                    />
                  </TextBoxWrap>
                  {contactError.businessName && (
                    <ErrorMessage>{'Business Name is required'}</ErrorMessage>
                  )}
                </CreateWrapper>
              </ItemSubWrp>
            </ItemWrapper>
          </>
        )}
        {isContactOpen && contactType === 'Trust' && (
          <>
            <ItemWrapper>
              <ItemSubWrp>
                <CreateWrapper>
                  <Label>Trust Name</Label>
                  <TextBoxWrap error={contactError.trustName}>
                    <Textbox
                      onChange={(value) =>
                        updateData({ trustName: value }, 'trustName')
                      }
                      value={leadInfoData.trustName}
                    />
                  </TextBoxWrap>
                  {contactError.trustName && (
                    <ErrorMessage>{'Trust Name is required'}</ErrorMessage>
                  )}
                </CreateWrapper>
              </ItemSubWrp>
            </ItemWrapper>
          </>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default ContactInformation
