import { useContext } from 'react'
import { ToastWrapper } from './styles'
import { Toast } from '../../components/toast/Toast'
import ToastContext from '../../services/toast/ToastContext'
import Loader from '../loader/Loader'

const Interceptor = () => {
  const {
    toastOpen,
    toastMessage,
    isLoading,
    isMessageLoaderLoading,
    loadingMessage,
    setToast,
  } = useContext(ToastContext)

  return (
    <>
      {isLoading && <Loader />}
      {isMessageLoaderLoading && <Loader message={loadingMessage} />}
      {toastOpen !== null && (
        <ToastWrapper>
          <Toast
            text={toastMessage}
            type={toastOpen ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setToast(status ? status : null, '')
            }
          />
        </ToastWrapper>
      )}
    </>
  )
}

export default Interceptor
