import { Wrapper } from './styles'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { Tooltip } from 'recharts'
import { useQuery } from '@apollo/client'
import { GET_LEAD_PARTY_CONTACTED } from '../../../../services/graphQl/CustomerGraphQl'
import {
  LottieWrapper,
  SpinnerSectionWrapper,
} from '../../../../composites/interceptor/styles'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import UserContext from '../../../../services/user/UserContext'
import { useContext, useEffect, useState } from 'react'

const PartiesContacted = () => {
  const { user } = useContext(UserContext)
  const [graphData, setGraphData] = useState<any>([])

  const { data, loading } = useQuery(GET_LEAD_PARTY_CONTACTED, {
    variables: {
      adUserName: user.username,
    },
  })

  useEffect(() => {
    const monthList = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    const array: any = []
    const currentDate = new Date()
    currentDate.setDate(1)

    for (let i = 0; i < 12; i++) {
      const monthIndx = currentDate.getMonth()
      const month =
        monthList[currentDate.getMonth()] + ' ' + currentDate.getFullYear()

      currentDate.setMonth(currentDate.getMonth() - 1)
      array.push({
        mth: month,
        count:
          data?.note_vw_partyleadscontactbymonth?.find((ele: any) => {
            return ele.mth === monthIndx + 1
          })?.count ?? 0,
      })
    }
    setGraphData(array.reverse())
  }, [data?.note_vw_partyleadscontactbymonth])
  return (
    <Wrapper>
      <ResponsiveContainer width={'100%'} height={300}>
        {loading ? (
          <SpinnerSectionWrapper>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
          </SpinnerSectionWrapper>
        ) : (
          <ComposedChart height={300} data={graphData}>
            <XAxis dataKey="mth" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke="#f5f5f5" />
            <Bar dataKey={'count'} barSize={40} fill={'#8884d8'} width={150} />
          </ComposedChart>
        )}
      </ResponsiveContainer>
    </Wrapper>
  )
}

export default PartiesContacted
