import { FC, lazy, Suspense } from 'react'
import './App.css'
import { Routes, Route, Navigate } from 'react-router-dom'
import { GlobalStyle } from './global'
import CreateContactList from './pages/createContactList/CreateLead'
import Dashboard from './pages/dashboard/Dashboard'
import { ThemeContextProvider } from './store/ThemeContext'
import { ToastContextProvider } from './services/toast/ToastContext'
import AuthContextProvider from './services/AuthContext'
import { SetToastContextProvider } from './services/toast/SetToastContext'
import Interceptor from './composites/interceptor/Interceptor'
import AxiosProtectedInterceptor from './composites/interceptor/AxiosProtectedInterceptor'
import ErrorPage from './pages/errorPage/ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import Lead from './pages/lead/Lead'
import Task from './pages/task/Task'
import Notes from './pages/notes/Notes'
import Event from './pages/event/Event'
import LeadProfile from './pages/leadProfile/LeadProfile'
import { ClientContextProvider } from './services/clientContext/ClientsContext'
import Opportunities from './pages/opportunities/Opportunities'
import OpportunitiesEditDetails from './pages/opportunities/OpportunitiesEditDetails'
import { DashboardContextProvider } from './services/dashboardContext/DashboardContext'
import { ClientAndAccountSearchContextProvider } from './services/clientAndAccountSearch/ClientAndAccountSearchContext'

const HomePage = lazy(() => import('./pages/homepage/HomePage'))

const AppRoutes: FC = () => {
  const errorCode = {
    error: {
      response: {
        status: 404,
      },
    },
  }

  return (
    <AuthContextProvider>
      <ThemeContextProvider>
        <ToastContextProvider>
          <SetToastContextProvider>
            <ClientContextProvider>
              <ClientAndAccountSearchContextProvider>
                <DashboardContextProvider>
                  <ErrorBoundary
                    FallbackComponent={(error) => <ErrorPage error={error} />}
                  >
                    <Suspense>
                      <GlobalStyle />
                      <Routes>
                        <Route element={<HomePage />}>
                          <Route
                            path="/"
                            element={<Navigate to="/dashboard" />}
                          />
                          <Route
                            index
                            path="/dashboard"
                            element={<Dashboard />}
                          />
                          <Route
                            path="/Profile/:contactId?"
                            element={<LeadProfile />}
                          />
                          <Route
                            path="/Leadprofile"
                            element={<LeadProfile />}
                          />

                          <Route
                            path="/createContactList/:contactID?"
                            element={<CreateContactList />}
                          />
                          <Route
                            path="/updateContactList/:leadId?"
                            element={<CreateContactList />}
                          />
                          <Route path="/events" element={<Event />} />
                          <Route path="/lead" element={<Lead />} />
                          <Route path="/task" element={<Task />} />
                          <Route path="/note" element={<Notes />} />
                          <Route
                            path="/opportunities"
                            element={<Opportunities />}
                          />
                          <Route
                            path="/opportunities/:opportunityId?"
                            element={<OpportunitiesEditDetails />}
                          />
                          <Route
                            path="/*"
                            element={<ErrorPage error={errorCode} />}
                          />
                        </Route>
                      </Routes>
                    </Suspense>
                  </ErrorBoundary>
                </DashboardContextProvider>
              </ClientAndAccountSearchContextProvider>
            </ClientContextProvider>
          </SetToastContextProvider>
          <Interceptor />
          <AxiosProtectedInterceptor />
        </ToastContextProvider>
      </ThemeContextProvider>
    </AuthContextProvider>
  )
}

export default AppRoutes
