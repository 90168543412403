import { Quill } from 'react-quill'
import { DeltaStatic } from 'quill'
import moment from 'moment'

export const vars = {
  adminRole: 'WC-ADMIN',
  validationRequired: true,
  leadIdRange: 900000000,
  OppFolderName: 'opportunities',
  NotesFolderName: 'notes',
  InitRecordCount: 25,
}

export const getTimezone = () => {
  const zone = moment.tz.guess()
  const abbreviation = moment.tz(zone).zoneAbbr()

  return abbreviation
}

export const dateFormate = (
  inputDateString: any,
  timeZoneRequired: boolean
) => {
  const iso_datetime = inputDateString
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const converted_datetime = moment
    .utc(iso_datetime)
    .tz(timeZone)
    .format('MM/DD/YYYY hh:mm A')

  if (timeZoneRequired) return converted_datetime + ' (' + getTimezone() + ')'
  else return converted_datetime
}

export const quillGetHTML = (inputDelta: any): any => {
  const tempCont = document.createElement('div')
  const objs = new Quill(tempCont).setContents(inputDelta).map((item) => {
    return item
  })

  return objs
}

export const formatDelta = (value: string | DeltaStatic) => {
  try {
    if (value)
      if (typeof value == 'string')
        if (value.indexOf('insert') == -1) return [{ insert: value }]
        else return JSON.parse(value)
      else return value
    else return [{ insert: '' }]
  } catch {
    return [{ insert: '' }]
  }
}

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export const uniqueArrayOfObjects = (objectArray: any) => {
  return objectArray.filter((value: any, index: number) => {
    const _value = JSON.stringify(value)
    return (
      index ===
      objectArray.findIndex((obj: any) => {
        return JSON.stringify(obj) === _value
      })
    )
  })
}
