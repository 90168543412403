import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`
export const ContactWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
export const UserDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9eccfc2b;
  border: 1px solid ${DEFAULT_COLORS.Text.PrimaryStrong};
  padding: ${SIZES[1]};
  align-self: center;
  width: ${SIZES[6]};
  height: ${SIZES[6]};
  border-radius: ${SIZES[6]};
  cursor: pointer;
`
export const RightUserProfile = styled.div`
  display: flex;
  position: relative;
  flex: 0 1 80%;
  justify-content: flex-end;
  display-content: center;
  height: 2rem;
  gap: 0.5rem;
  @media (max-width: 1040px) {
    flex: 0 1 30%;
  }
  @media (max-width: 744px) {
    flex: 0 1 40%;
  }
`
export const LeftUserProfile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 0.5rem;
`
export const ProfileNameWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
export const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.25rem;
  flex-direction: column;
`
export const ProfileDesignationWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`
export const QuantityWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
`
export const LineHeightWrapper = styled.div`
  font-style: normal;
  font-weight: ${TextWeight[0]};
  font-size: ${TextSizes[2]};
  line-height: ${SIZES[3]};
  text-align: right;
  color: ${() => DEFAULT_COLORS.Text.Primary};
`

export const AccountsMainWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
`
export const AccountsSubWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  width: 100%;
`
export const AccountsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const AccountNameWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`
export const OverviewWrapper = styled.div`
display: flex;
flex: 0 1 30%;
flex-direction: column;
gap: 0.5rem;
max-width: 412px;
`
export const TabContainer = styled.nav`
  display: flex-column;
  gap: 0.5rem;
`
export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`