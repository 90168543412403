import { useContext, useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/button/Button'
import { WDBorderButtonPrimary, WDButton } from '../../components/ui/WDButton'
import { WDLabelPrimaryXLarge } from '../../components/ui/WDLabel'
import ContactInformation from '../../composites/individualInformation/individualInformation'
import { ThemeContext } from '../../store/ThemeContext'
import { DEFAULT_COLORS } from '../../theme/Colors'
import {
  LeadGraphQLResponseInterface,
  LeadInfoPayloadType,
  LeadInfoProps,
  LeadInfoResponseInterface,
} from './ICreateLead'
import {
  PermissionDataResponseInterface,
  PermissionInfoData,
  PermissionPayloadInterface,
} from '../../composites/permissionAndRoles/IPermissions'

import ToastContext from '../../services/toast/ToastContext'
import Notes from '../../composites/notes/Notes'
import FinancialInformation from '../../composites/financialInformation/FinancialInformation'
import ShippingInformation from '../../composites/addressInformation/AddressInformation'
import PhoneInformation from '../../composites/phoneInformation/PhoneInformation'
import EmailInformation from '../../composites/emailInformation/EmailInformation'
import URLInformation from '../../composites/urlInformation/URLInformation'
import ContactDetails from '../../composites/contactDetails/ContactDetails'
import PermissionAndRoles from '../../composites/permissionAndRoles/PermissionAndRoles'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CREATE_LEAD,
  CREATE_LEAD_ADDRESS,
  CREATE_LEAD_EMAIL,
  CREATE_LEAD_PERMISSION,
  CREATE_LEAD_PHONE,
  GET_LEADS,
  GET_LEAD_DETAILS,
  GET_PARTIES,
  UPDATE_LEAD,
  UPDATE_LEAD_ADDRESS,
  UPDATE_LEAD_EMAIL,
  UPDATE_LEAD_PHONE,
} from '../../services/graphQl/LeadsGraphQL'
import {
  ShippingAddressInterface,
  ShippingAddressPayloadInterface,
} from '../../composites/addressInformation/IAdressInformation'
import {
  PhoneInterface,
  PhonePayloadInterface,
} from '../../composites/phoneInformation/IPhoneInformation'
import {
  EmailInformationInterface,
  EmailInformationPayloadInterface,
} from '../../composites/emailInformation/IEmailInformation'
import UserContext from '../../services/user/UserContext'
import {
  ContentItemWrapper,
  ContentTitleWrapper,
  Wrapper,
} from '../../shared/GlobalStyle'
import { BtnWrapper } from '../../global'
import { LeadDataResponseInterface } from '../lead/ILead'
import { AuthContext } from '../../services/AuthContext'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import { useAppDispatch } from '../../store/store'
import { setLeadContents } from '../../reducers/leadPartySlice'
import {
  individualValidationKeys,
  businessValidationKeys,
  trustValidationKeys,
} from '../utils/Util'
import { vars } from '../../assets/utils/CommonFunctions'

export const CreateLead = () => {
  const { userAccessDetails } = useContext(AuthContext)
  const { categoryName, refetchFlag, setRefetchFlag } =
    useContext(DashboardContext)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { contactFor, leadId, contactID } = useParams()
  const { user } = useContext(UserContext)
  // Get query for lead details
  const [getLeadDetails] = useLazyQuery<LeadGraphQLResponseInterface>(
    GET_LEAD_DETAILS,
    {
      variables: { id: leadId },
      fetchPolicy: 'no-cache',
    }
  )

  const [graphQlLeadData, { data: leadData }] =
    useLazyQuery<LeadDataResponseInterface>(GET_LEADS)
  const [graphQlPartyData, { data: partyData }] =
    useLazyQuery<LeadDataResponseInterface>(GET_PARTIES)

  // Mutation query for creating contact
  const [createContact] = useMutation<any, Partial<LeadInfoProps>>(CREATE_LEAD)
  const [createContactAddressList] = useMutation<
    any,
    { payload: ShippingAddressPayloadInterface[] }
  >(CREATE_LEAD_ADDRESS)
  const [createContactPhoneList] = useMutation<
    any,
    { payload: PhonePayloadInterface[] }
  >(CREATE_LEAD_PHONE)
  const [createContactEmailList] = useMutation<
    any,
    { payload: EmailInformationPayloadInterface[] }
  >(CREATE_LEAD_EMAIL)
  const [createPermission] = useMutation<
    any,
    { payload: PermissionPayloadInterface }
  >(CREATE_LEAD_PERMISSION)
  const [updateContact] = useMutation<any, Partial<LeadInfoProps>>(UPDATE_LEAD)
  const [updateContactAddressList] = useMutation<
    any,
    { payload: ShippingAddressPayloadInterface[] }
  >(UPDATE_LEAD_ADDRESS)
  const [updateContactPhoneList] = useMutation<
    any,
    { payload: PhonePayloadInterface[] }
  >(UPDATE_LEAD_PHONE)
  const [updateContactEmailList] = useMutation<
    any,
    { payload: EmailInformationPayloadInterface[] }
  >(UPDATE_LEAD_EMAIL)

  const initialPermissionInfoData: PermissionInfoData = {
    primaryOwner: '',
    repCodes: [],
    branchCodes: [],
    officeCodes: [],
    isAll: false,
  }
  const [contactType, setContactType] = useState<string>('')
  const initialContactInfoData: LeadInfoProps = {
    id: 0,
    accountTier: '',
    type: categoryName ?? contactID ?? 'Individual',
    honorific: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    gender: '',
    jobTitle: '',
    phoneNumber: '',
    fax: '',
    owner: user.username,
    ownerName: user.name,
    notes: '',
    aumSize: 0,
    advisoryFirm: '',
    interestHobbies: '',
    householdMembers: '',
    pets: '',
    leadStatus: '',
    leadSource: '',
    account: '',
    totalAssets: 0,
    category: '',
    financialInterests: '',
    individualType: '',
    investmentExperience: '',
    businessName: '',
    trustName: '',
    investmentObjectives: '',
    marketingSegments: '',
    netWorth: 0,
    reviewFrequency: '',
    riskTolerance: '',
    serviceModel: '',
    timeHorizon: '',
    deceasedDate: '',
    numberOfEmployees: '',
    uploadImage: '',
    rating: '',
    repCode: '',
    shippingCity: '',
    shippingCountry: '',
    shippingAddress: '',
    shippingPostalCode: '',
    shippingState: '',
    shippingStreet: '',
    billingCity: '',
    billingCountry: '',
    billingAddress: '',
    billingPostalCode: '',
    billingState: '',
    billingStreet: '',
    status: 'Lead',
    martialStatus: '',
    taxIdNumber: 0,
    maritalAnniversary: null,
    referredby: '',
    clientSince: '',
    clientTerminationDate: null,
    servicingAdvisor: '',
    dateOfBirth: null,
    dateOfDeath: '',
    writingAdvisor: '',
    source: '',
    preferedPronouce: '',
    nickName: '',
    pronouciation: '',
    designation: '',
    spouseHonorific: '',
    spouseFirstName: '',
    spouseMiddleName: '',
    spouseLastName: '',
    spouseSuffix: '',
    spouseNickName: '',
    spouseDesignation: '',
    spouseJobTitle: '',
    spouseEmployer: '',
    familyName: '',
    addressList: [],
    phoneList: [],
    emailList: [],
    permissionList: [],
    websiteURL: '',
    permissionTo: '',
    advisor: '',
    csa: '',
    taxyear: 0,
    estimatedtaxes: 0,
    estimatedtaxbracket: 0,
    confirmedbytaxreturn: false,
    adjustedgrossincome: 0,
  }
  const [permissionInfoData, setPermissionInfoData] = useState(
    initialPermissionInfoData
  )
  const [leadInfoData, setLeadInfoData] = useState(initialContactInfoData)
  const [permissionError, setPermissionError] = useState(false)

  const { setToast, setLoaderWithMessage, setLoadingMessage } =
    useContext(ToastContext)

  const onClickHandler = useCallback(() => {
    navigate('/lead')
  }, [navigate])

  const { isDarkMode } = useContext(ThemeContext)

  useEffect(() => {
    if (contactID) {
      if (contactID === 'NewRecruit') {
        setContactType('New Recruit')
      } else if (contactID === 'PersonalContact') {
        setContactType('Personal Contact')
      } else {
        setContactType(contactID)
      }
    }
  }, [contactID])

  useEffect(() => {
    setLeadInfoData((prev) => ({ ...prev, category: contactFor ?? '' }))
  }, [contactFor])

  useEffect(() => {
    setLeadInfoData((prev) => ({
      ...prev,
      type: categoryName ?? '',
    }))
  }, [categoryName])

  const getEmailList = (emails: EmailInformationPayloadInterface[]) => {
    if (emails) {
      const emailObj: EmailInformationInterface[] = []
      emails.forEach((email) => {
        emailObj.push({
          id: email.id,
          leadId: email.leadid,
          email: email.emailid,
          emailType: email.type,
          isPrimary: email.isprimary,
          activeFlag: email.activeflag,
        })
      })
      return emailObj
    }
    return []
  }
  const getPermissionList = (
    permissions: PermissionDataResponseInterface[]
  ) => {
    if (permissions.length > 0) {
      const permissionObj: PermissionDataResponseInterface[] = []
      permissions.forEach((permission) => {
        permissionObj.push({
          id: permission.id,
          leadid: permission.leadid,
          type: permission.type,
          repcode: permission.repcode,
          createdby: permission.createdby,
          createdon: permission.createdon,
          updatedby: permission.updatedby,
          updatedon: permission.updatedon,
          status: permission.status,
        })
      })

      return permissionObj
    }
    return []
  }

  const getPrimaryOwner = (permissions: PermissionDataResponseInterface[]) => {
    if (permissions.length > 0) {
      permissions.map((permission) => {
        if (permission.type == 'Primary Owner') {
          return permission.repcode
        }
      })
    }

    return ''
  }

  const getAddressList = (addresses: ShippingAddressPayloadInterface[]) => {
    if (addresses) {
      const addressObj: ShippingAddressInterface[] = []
      addresses.forEach((address) => {
        addressObj.push({
          id: address.id,
          leadId: address.leadid,
          isPrimary: address.isprimary,
          activeFlag: address.activeflag,
          addressLine1: address.addressline1,
          addressLine2: address.addressline2,
          city: address.city,
          country:
            address?.country?.trim() == ''
              ? 'United States of America (the)'
              : address.country,
          state: address.state,
          postalCode: address.zipcode,
          addressType: address.addresstype,
        })
      })
      return addressObj
    }
    return []
  }

  const getContactList = (contacts: PhonePayloadInterface[]) => {
    if (contacts) {
      const contactObj: PhoneInterface[] = []
      contacts.forEach((contact) => {
        contactObj.push({
          id: contact.id,
          leadId: contact.leadid,
          isPrimary: contact.isprimary,
          activeFlag: contact.activeflag,
          phoneType: contact.type,
          phoneNo: contact.phonenumber,
          phoneExtension: contact.extension,
          speedDial: contact.speeddial,
        })
      })
      return contactObj
    }
    return []
  }

  useEffect(() => {
    if (leadId) {
      getLeadDetails().then((response) => {
        const responseObj =
          response.data?.lead_leads[0] ?? ({} as LeadInfoResponseInterface)

        const leadInfo: LeadInfoProps = {
          account: responseObj.account,
          createdBy: responseObj.createdby,
          addressList: getAddressList(responseObj.addresslist),
          advisor: getPrimaryOwner(responseObj.permissionList ?? []) ?? '',
          advisoryFirm: responseObj.currentadvisoryfirms,
          totalAssets: responseObj.totalassets,
          aumSize: responseObj.potentialsizeofaum,
          category: responseObj.clientcategory,
          accountTier: responseObj.accounttier,
          clientSince: responseObj.clientsince,
          csa: '',
          dateOfBirth: responseObj.dateofbirth,
          emailList: getEmailList(responseObj.emailList),
          permissionList: getPermissionList(responseObj.permissionList ?? []),
          familyName: responseObj.familyname,
          firstName: responseObj.firstname,
          gender: responseObj.gender,
          honorific: responseObj.honorific,
          type: responseObj.type,
          householdMembers: responseObj.householdmembers,
          id: responseObj.id,
          interestHobbies: responseObj.interesthobbies,
          financialInterests: responseObj.financialinterests,
          investmentExperience: responseObj.investmentexperience,
          businessName: responseObj?.businessname,
          trustName: responseObj?.trustname,
          websiteURL: responseObj.websiteurl,
          investmentObjectives: responseObj.objective,
          jobTitle: responseObj.jobtitle,
          lastName: responseObj.lastname,
          leadSource: responseObj.leadsource,
          leadStatus: responseObj.status,
          maritalAnniversary: responseObj.dateofmarriage,
          clientTerminationDate: responseObj.clientterminationdate,
          martialStatus: responseObj.maritalstatus,
          middleName: responseObj.middlename,
          nickName: responseObj.nickname,
          notes: responseObj.notes,
          owner: responseObj.owner,
          ownerName: responseObj.ownername,
          pets: responseObj.pets,
          phoneList: getContactList(responseObj.contactList),
          preferedPronouce: responseObj.pronouns,
          pronouciation: responseObj.pronunciations,
          referredby: responseObj.referredby,
          riskTolerance: responseObj.risktolerance,
          status: responseObj.status,
          suffix: responseObj.suffix,
          taxIdNumber: responseObj.taxidnumber,
          timeHorizon: responseObj.timehorizon,
          taxyear: responseObj.taxyear,
          estimatedtaxes: responseObj.estimatedtaxes,
          estimatedtaxbracket: responseObj.estimatedtaxbracket,
          confirmedbytaxreturn: responseObj.confirmedbytaxreturn,
          adjustedgrossincome: responseObj.adjustedgrossincome,
          netWorth: responseObj.networth,
        }
        setLeadInfoData(leadInfo)
      })
    }
  }, [getLeadDetails, leadId])

  const formatPhoneNumber = (value: string) => {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`
  }
  const updatePermissionInfoData = (data: PermissionInfoData) => {
    setPermissionError(false)
    setPermissionInfoData(data)
  }
  const [inputErrors, setInputErrors] = useState<string[]>([])
  const updateContactInfoData = (
    data: Partial<LeadInfoProps>,
    fieldName?: string
  ) => {
    if (data.fax) {
      const formattedFaxNumber = formatPhoneNumber(data.fax)
      leadInfoData.fax = formattedFaxNumber
      setLeadInfoData({ ...leadInfoData })
    } else {
      setLeadInfoData((prev) => {
        return { ...prev, ...data }
      })
      setInputErrors(inputErrors.filter((key) => key !== fieldName))
    }
  }

  const prepareInputVariables = (inputType?: string) => {
    const payload: Partial<LeadInfoPayloadType> = {
      createdOn: new Date(),
      type: leadInfoData.type.length > 0 ? leadInfoData.type : contactType,
      honorific: leadInfoData.honorific,
      firstName: leadInfoData.firstName,
      middleName: leadInfoData.middleName,
      lastName: leadInfoData.lastName,
      suffix: leadInfoData.suffix,
      pronouns: leadInfoData.preferedPronouce,
      nickname: leadInfoData.nickName,
      pronunciations: leadInfoData.pronouciation,
      jobTitle: leadInfoData.jobTitle,
      familyName: leadInfoData.familyName,
      gender: leadInfoData.gender,
      interestHobbies: leadInfoData.interestHobbies,
      notes: leadInfoData.notes,
      owner: leadInfoData.owner ?? user.username,
      ownerName: leadInfoData.ownerName ?? user.name,
      pets: leadInfoData.pets,
      riskTolerance: leadInfoData.riskTolerance,
      timeHorizon: leadInfoData.timeHorizon,
      status: leadInfoData.status,
      referredBy: leadInfoData.referredby,
      potentialSizeOfAum: leadInfoData.aumSize,
      taxIdNumber: leadInfoData.taxIdNumber,
      leadSource: leadInfoData.leadSource,
      maritalStatus: leadInfoData.martialStatus,
      objective: leadInfoData.investmentObjectives,
      clientCategory: leadInfoData.category,
      accountTier: leadInfoData.accountTier,
      clientSince: leadInfoData.clientSince,
      advisor: leadInfoData.advisor,
      dateOfBirth: leadInfoData.dateOfBirth,
      currentAdvisoryFirms: leadInfoData.advisoryFirm,
      dateOfMarriage: leadInfoData.maritalAnniversary,
      account: leadInfoData.account,
      houseHoldMembers: leadInfoData.householdMembers,
      totalAssets: leadInfoData.totalAssets,
      trustName: leadInfoData.trustName,
      businessName: leadInfoData.businessName,
      netWorth: leadInfoData.netWorth,
      financialInterests: leadInfoData.financialInterests,
      clientTerminationDate: leadInfoData.clientTerminationDate,
      investmentExperience: leadInfoData.investmentExperience,
      websiteURL: leadInfoData.websiteURL,
    }
    if (inputType === 'update') {
      return {
        ...payload,
        leadId: leadInfoData.id,
        createdBy: leadInfoData.createdBy,
      }
    } else {
      return { ...payload, createdBy: user.username }
    }
  }

  const prepareAddressInputVariables = (leadId: number, inputType?: string) => {
    const addressList: ShippingAddressPayloadInterface[] = []
    leadInfoData.addressList.forEach((address) => {
      addressList.push({
        id: address.id,
        leadid: leadId,
        addresstype: address.addressType,
        isprimary: address.isPrimary,
        activeflag: address.activeFlag,
        city: address.city,
        country:
          address?.country?.trim() == ''
            ? 'United States of America (the)'
            : address.country,
        addressline1: address.addressLine1,
        addressline2: address.addressLine2,
        state: address.state,
        zipcode: address.postalCode,
      })
    })
    const inputVariables = {
      payload: addressList,
    }
    if (inputType === 'update') {
      return { ...inputVariables, leadId: leadInfoData.id }
    } else {
      return inputVariables
    }
  }

  const preparePhoneInputVariables = (leadId: number, inputType?: string) => {
    const phoneList: PhonePayloadInterface[] = []
    leadInfoData.phoneList.forEach((phone) => {
      phoneList.push({
        id: phone.id,
        leadid: leadId,
        type: phone.phoneType,
        isprimary: phone.isPrimary,
        activeflag: phone.activeFlag,
        phonenumber: +phone.phoneNo,
        extension: +phone.phoneExtension,
        speeddial: +phone.speedDial,
      })
    })
    const inputVariables = {
      payload: phoneList,
    }
    if (inputType === 'update') {
      return { ...inputVariables, leadId: leadInfoData.id }
    } else {
      return inputVariables
    }
  }

  const prepareEmailInputVariables = (leadId: number, inputType?: string) => {
    const emailList: EmailInformationPayloadInterface[] = []
    leadInfoData.emailList.forEach((email) => {
      emailList.push({
        id: email.id,
        leadid: leadId,
        type: email.emailType,
        isprimary: email.isPrimary,
        emailid: email.email,
        activeflag: email.activeFlag,
      })
    })
    const inputVariables = {
      payload: emailList,
    }
    if (inputType === 'update') {
      return { ...inputVariables, leadId: leadInfoData.id }
    } else {
      return inputVariables
    }
  }

  useEffect(() => {
    if (leadData) {
      dispatch(setLeadContents(leadData))
    }
    if (partyData) {
      dispatch(setLeadContents(partyData))
    }

    if (leadData && partyData) {
      onClickHandler()
    }
  }, [dispatch, leadData, onClickHandler, partyData, refetchFlag])

  const getLeadPartyData = () => {
    const payload = {
      searchName: 'hte',
      repCode: userAccessDetails?.repAccess,
    }

    graphQlLeadData({
      variables: { repCodes: userAccessDetails.repAccess },
      fetchPolicy: 'no-cache',
    })
    graphQlPartyData({
      variables: { payload: payload },
      fetchPolicy: 'no-cache',
    }) //uncomment when client data available for perticular repCode

    //comment below code when client data available for perticular repCode
    // graphQlPartyData({
    //   variables: {
    //     payload: {
    //       searchName: 'hte',
    //       repCode: [],
    //     },
    //   },
    // })
  }

  const validateLeadProspectForm = () => {
    let validationKeys: string[]
    if (contactType === 'Business') {
      validationKeys = businessValidationKeys
    } else if (contactType === 'Trust') {
      validationKeys = trustValidationKeys
    } else {
      validationKeys = individualValidationKeys
    }
    let isInValid = false
    const emptyLeadInfoKeys: string[] = []
    validationKeys.forEach((key) => {
      if (
        leadInfoData[key] === '' ||
        leadInfoData[key] === 0 ||
        leadInfoData[key] === null
      ) {
        emptyLeadInfoKeys.push(key)
      }
    })
    setInputErrors(emptyLeadInfoKeys)
    if (emptyLeadInfoKeys.length > 0) {
      isInValid = true
    }
    if (permissionInfoData.primaryOwner === '') {
      isInValid = true
      setPermissionError(true)
    }
    return isInValid
  }

  const handleCreate = () => {
    if (vars.validationRequired && validateLeadProspectForm()) {
      setToast(false, 'Please enter mandatory details!')
    } else {
      setLoaderWithMessage(true)
      createContact({ variables: prepareInputVariables() })
        .then((res) => {
          const affectedRows = res.data.insert_lead_leads.affected_rows
          const insertedLeadId = res.data.insert_lead_leads.returning[0].id
          if (affectedRows > 0) {
            setToast(true, 'Lead Created Successfully!')
            setLoadingMessage('Adding address...')
            if (leadInfoData.addressList?.length) {
              createContactAddressList({
                variables: prepareAddressInputVariables(insertedLeadId),
              })
                .then((res) => {
                  const affectedRows =
                    res.data.insert_lead_address.affected_rows
                  if (affectedRows > 0) {
                    setToast(true, 'Address added successfully!')
                  } else {
                    setToast(false, 'Something went wrong!')
                  }
                })
                .catch(() => {
                  setToast(false, 'Something went wrong!')
                })
            }

            // Inserting contact details for the lead
            setLoadingMessage('Adding contacts...')
            if (leadInfoData.phoneList?.length) {
              createContactPhoneList({
                variables: preparePhoneInputVariables(insertedLeadId),
              })
                .then((res) => {
                  const affectedRows =
                    res.data.insert_lead_contacts.affected_rows
                  if (affectedRows > 0) {
                    setToast(true, 'Contacts added successfully!')
                  } else {
                    setToast(false, 'Something went wrong!')
                  }
                })
                .catch(() => {
                  setToast(false, 'Something went wrong!')
                })
            }

            // Inserting email addresses for the lead
            setLoadingMessage('Adding emails...')
            if (leadInfoData.emailList?.length) {
              createContactEmailList({
                variables: prepareEmailInputVariables(insertedLeadId),
              })
                .then((res) => {
                  const affectedRows = res.data.insert_lead_emails.affected_rows
                  if (affectedRows > 0) {
                    setToast(true, 'Emails added successfully!')
                  } else {
                    setToast(false, 'Something went wrong!')
                  }
                })
                .catch(() => {
                  setToast(false, 'Something went wrong!')
                })
            }
            setLeadInfoData(initialContactInfoData)
            setLoaderWithMessage(false)

            const permissionPayload: any = []

            const officeCodes = permissionInfoData.officeCodes
            const branchCodes = permissionInfoData.branchCodes
            const repCodes = permissionInfoData.repCodes

            permissionPayload.push({
              leadid: insertedLeadId,
              type: 'Primary Owner',
              createdby: user.username,
              createdon: new Date(),
              updatedby: user.username,
              updatedon: new Date(),
              repcode: permissionInfoData.primaryOwner,
            })

            if (permissionInfoData.isAll) {
              permissionPayload.push({
                leadid: insertedLeadId,
                type: 'All',
                createdby: user.username,
                createdon: new Date(),
                updatedby: user.username,
                updatedon: new Date(),
                repcode: '',
              })
            } else {
              officeCodes &&
                officeCodes?.map((code) => {
                  permissionPayload.push({
                    leadid: insertedLeadId,
                    type: 'Office Code',
                    createdby: user.username,
                    createdon: new Date(),
                    updatedby: user.username,
                    updatedon: new Date(),
                    repcode: code,
                  })
                })

              branchCodes &&
                branchCodes?.map((code) => {
                  permissionPayload.push({
                    leadid: insertedLeadId,
                    type: 'Branch Code',
                    createdby: user.username,
                    createdon: new Date(),
                    updatedby: user.username,
                    updatedon: new Date(),
                    repcode: code,
                  })
                })

              repCodes &&
                repCodes?.map((code) => {
                  permissionPayload.push({
                    leadid: insertedLeadId,
                    type: 'Rep Code',
                    createdby: user.username,
                    createdon: new Date(),
                    updatedby: user.username,
                    updatedon: new Date(),
                    repcode: code,
                  })
                })
            }

            const permissions: PermissionPayloadInterface = permissionPayload

            createPermission({ variables: { payload: permissions } })
              .then((res) => {
                const affectedRows =
                  res.data.insert_lead_permissions.affected_rows
                if (affectedRows > 0) {
                  setToast(true, 'Lead created successfully!')
                  getLeadPartyData()
                } else {
                  setToast(false, 'Something went wrong!')
                }
              })
              .catch(() => {
                setToast(false, 'Something went wrong!')
              })
              .finally(() => {
                setRefetchFlag(!refetchFlag)
              })
            onClickHandler()
          } else {
            setToast(false, 'Something went wrong!')
            setLoaderWithMessage(false)
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong!')
          setLoaderWithMessage(false)
        })
        .finally(() => {
          console.log('finally')
          setLoaderWithMessage(false)
        })
    }
  }

  const handleUpdate = () => {
    setLoaderWithMessage(true)
    updateContact({ variables: prepareInputVariables('update') })
      .then((res) => {
        const affectedRows = res.data.update_lead_leads.affected_rows
        if (affectedRows > 0) {
          setToast(true, 'Lead information updated successfully!')
          getLeadPartyData()
          setLoaderWithMessage(false)
        } else {
          setToast(false, 'Something went wrong!')
          setLoaderWithMessage(false)
        }
      })
      .catch(() => {
        setToast(false, 'Something went wrong!')
        setLoaderWithMessage(false)
      })
      .finally(() => {
        setLoaderWithMessage(false)
      })

    // Inserting addresses for the lead
    setLoadingMessage('Updating address...')
    if (leadInfoData.addressList?.length) {
      updateContactAddressList({
        variables: prepareAddressInputVariables(leadInfoData.id, 'update'),
      })
        .then((res) => {
          const affectedRows = res.data.insert_lead_address.affected_rows
          if (affectedRows > 0) {
            setToast(true, 'Address updated successfully!')
          } else {
            setToast(false, 'Something went wrong!')
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong!')
        })
    }

    // Inserting contact details for the lead
    setLoadingMessage('Updating contacts...')
    if (leadInfoData.phoneList?.length) {
      updateContactPhoneList({
        variables: preparePhoneInputVariables(leadInfoData.id, 'update'),
      })
        .then((res) => {
          const affectedRows = res.data.insert_lead_contacts.affected_rows
          if (affectedRows > 0) {
            setToast(true, 'Contacts updated successfully!')
          } else {
            setToast(false, 'Something went wrong!')
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong!')
        })
    }

    // Inserting email addresses for the lead
    setLoadingMessage('Updating emails...')
    if (leadInfoData.emailList?.length) {
      updateContactEmailList({
        variables: prepareEmailInputVariables(leadInfoData.id, 'update'),
      })
        .then((res) => {
          const affectedRows = res.data.insert_lead_emails.affected_rows
          if (affectedRows > 0) {
            setToast(true, 'Emails updated successfully!')
          } else {
            setToast(false, 'Something went wrong!')
          }
        })
        .catch(() => {
          setToast(false, 'Something went wrong!')
        })
        .finally(() => {
          setRefetchFlag(true)
        })
    }
  }

  return (
    <Wrapper gap={'0'}>
      <ContentItemWrapper>
        <ContentTitleWrapper>
          <WDLabelPrimaryXLarge>
            {/* {leadId ? `Update Lead` : `Create Lead`} */}
            {contactType === 'New Recruit'
              ? 'Create New Recruit'
              : contactType === 'Personal Contact'
              ? 'Create Personal Contact'
              : 'Create Lead'}
          </WDLabelPrimaryXLarge>
        </ContentTitleWrapper>

        <ContactInformation
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
          inputErrors={inputErrors}
        />
        <ContactDetails
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
          inputErrors={inputErrors}
        />
        <FinancialInformation
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
          inputErrors={inputErrors}
        />
        <ShippingInformation
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
          inputErrors={inputErrors}
          setInputErrors={setInputErrors}
        />
        <PhoneInformation
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
          inputErrors={inputErrors}
          setInputErrors={setInputErrors}
        />
        <EmailInformation
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
          inputErrors={inputErrors}
          setInputErrors={setInputErrors}
        />
        <URLInformation
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
          inputErrors={inputErrors}
        />

        <Notes
          {...leadInfoData}
          updateData={updateContactInfoData}
          leadInfoData={leadInfoData}
        />
        <PermissionAndRoles
          updateData={updatePermissionInfoData}
          permissionError={permissionError}
        />
      </ContentItemWrapper>
      <BtnWrapper justifyContent={'end'} bgcolor="#fff">
        <WDBorderButtonPrimary>
          <Button
            type="button"
            onClick={onClickHandler}
            color={
              isDarkMode
                ? DEFAULT_COLORS.Text.UserDark
                : DEFAULT_COLORS.Text.Primary
            }
          >
            {'Cancel'}
          </Button>
        </WDBorderButtonPrimary>
        {leadId ? (
          <WDButton>
            <Button type="button" onClick={handleUpdate}>
              {'Update'}
            </Button>
          </WDButton>
        ) : (
          <WDButton>
            <Button type="button" onClick={handleCreate}>
              {'Save'}
            </Button>
          </WDButton>
        )}
      </BtnWrapper>
    </Wrapper>
  )
}

export default CreateLead
