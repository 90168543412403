import styled from 'styled-components'
import { SIZES } from '../../../theme/Sizes'
import { DEFAULT_COLORS } from '../../../theme/Colors'

export const GraphTimeWrapper = styled.div`
  width: 100%;
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
`
export const GraphSegmentWrapper = styled.div`
  width: 40%;
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
`
