import { TopWrapper, Wrapper } from '../styles'
import {
  ContentItemWrapper,
  DashboardItemWrappers,
  ContentTitleWrapper,
} from '../../../shared/GlobalStyle'
import DashboardTask from '../../../composites/dashboardTask/DashboardTask'
import DashboardEvents from '../../../composites/dashboardEvents/DashboardEvents'
import DashboardLeads from '../../../composites/dashboardLeads/DashboardLeads'
import DashboardNote from '../../../composites/dashboardNote/DashboardNote'
import DashboardUpcomingBirthday from '../../../composites/dashboardUpcomingBirthday/DashboardUpcomingBirthday'
import { ContentWrapper } from '../salesViewDashboard/styles'
import { WDLabelPrimaryLarge } from '../../../components/ui/WDLabel'
import PartiesContacted from './partiesContacted/PartiesContacted'
import { GraphTimeWrapper } from './styles'

const ClientViewDashboard = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <GraphTimeWrapper>
          <ContentTitleWrapper>
            <WDLabelPrimaryLarge>
              {'Parties last contacted by Month-Year'}
            </WDLabelPrimaryLarge>
          </ContentTitleWrapper>
          <PartiesContacted />
        </GraphTimeWrapper>
        {/* <GraphSegmentWrapper>
          <ContentTitleWrapper>
            <WDLabelPrimaryLarge>
              {'Parties last contacted by Relationship Segment-Current Year'}
            </WDLabelPrimaryLarge>
          </ContentTitleWrapper>
          <PcByRelationship />
        </GraphSegmentWrapper> */}
      </ContentWrapper>
      <DashboardItemWrappers>
        <DashboardTask />
      </DashboardItemWrappers>
      <DashboardItemWrappers>
        <DashboardEvents />
      </DashboardItemWrappers>
      <DashboardItemWrappers>
        <DashboardLeads />
      </DashboardItemWrappers>
      <TopWrapper>
        <ContentItemWrapper>
          <DashboardNote />
        </ContentItemWrapper>
        <ContentItemWrapper>
          <DashboardUpcomingBirthday />
        </ContentItemWrapper>
      </TopWrapper>
    </Wrapper>
  )
}

export default ClientViewDashboard
