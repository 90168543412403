import { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { DeltaStatic } from 'quill'
import { StyleWrapper } from './style'
import { formatDelta } from '../../assets/utils/CommonFunctions'

export interface TextEditorInterface {
  value: string
  height?: string
  onChange: (data: DeltaStatic) => void
}

const TextEditor = ({ height, value, onChange }: TextEditorInterface) => {
  const editorRef = useRef<ReactQuill | null>(null)
  //const textValue = formatDelta(value)
  const [textValue, setTextValue] = useState<DeltaStatic>()
  const allColors = [
    '#000000',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#444444',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466',
  ]

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ size: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        {
          color: allColors,
        },
      ],
      [
        {
          background: allColors,
        },
      ],
    ],
  }

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'color',
    'background',
    'size',
  ]
  useEffect(() => {
    const newContent: DeltaStatic = formatDelta(value ?? '')
    newContent && setTextValue(newContent)
  }, [value])

  return (
    <StyleWrapper height={height}>
      <ReactQuill
        theme="snow"
        ref={editorRef}
        value={textValue}
        modules={modules}
        formats={formats}
        onChange={() => {
          if (editorRef.current) {
            setTextValue(editorRef.current.getEditor().getContents())
            onChange(editorRef.current.getEditor().getContents())
            //setTextLength(textLength == -1 ? 0 : editor.getLength() - 1);
          }
        }}
      />
      {/* {textLength} */}
    </StyleWrapper>
  )
}

export default TextEditor
