import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { SIZES } from '../../../theme/Sizes'

export const TabContainer = styled.nav`
  width: 100%;
`
export const TitleWrapper = styled.nav`
  display: flex;
  align-items: baseline;
  gap: 0.3rem;
`
export const CountWrapper = styled.nav`
  background: ${DEFAULT_COLORS.Background.Primary};
  border-radius: 62.5rem;
  padding: 0 ${SIZES[0]};
  color: ${DEFAULT_COLORS.Text.NeutralInverted};
  font-weight: 700;
  font-size: 0.75rem;
  line-height: ${SIZES[3]};
`
export const MiddleContainer = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  flex: 1 0 50%;
  flex-flow: row wrap;
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    display: flex;
    color: ${() => DEFAULT_COLORS.Text.Neutral};
    font-size: 0.875rem;
    line-height: ${SIZES[4]};
    font-weight: 600;
    text-decoration: none;
    padding: 0.375rem ${SIZES[2]};
    &.selected {
      border-bottom: 2px solid ${() => DEFAULT_COLORS.Border.Primary};
      color: ${() => DEFAULT_COLORS.Text.Primary};
    }
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 90vw;
  border-radius: ${SIZES[1]};
  ${() => DEFAULT_COLORS.BoxShadow.StyledCardShadow};
  padding: ${SIZES[3]};
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  z-index: 1;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`
export const Footer = styled.div`
  padding: 3px 5px;
  // background-color: #f5f5f5;
`
export const TabWrapper = styled.div`
  width: 100%;
  gap: 0.25rem;
  height: auto;
`
