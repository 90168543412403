import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { StyledInput } from '../customCalendar/styles'
import Button from '../button/Button'
import Checkbox from '../checkbox/Checkbox'
import { FilterWrapper } from './styles'
import { SvgFilter } from '../svg/SvgFilter'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { WDButton, WDButtonTransparent } from '../ui/WDButton'
import { Arrow, BtnWrap, DataWrapper, MainWrapper } from './filterStyle'
import Divider from '../divider/Divider'

export const updateFilterArray = (
  existingArray: { id: string; value: string }[],
  value: string | string[] | undefined | null,
  id: string
) => {
  if (existingArray?.length > 0) {
    const existingItem = existingArray.find((obj) => {
      return obj && obj.id === id
    })
    if (existingItem) {
      return existingArray.map((i) => {
        return i && i.id === id ? { id: id, value: value } : i
      })
    } else {
      return [...existingArray, { id: id, value: value }]
    }
  } else {
    return [{ id: id, value: value }]
  }
}

// Component for Global Filter
// export function GlobalFilter({
//    globalFilter,
//    setGlobalFilter
// }) {
//  const [value, setValue] = useState(globalFilter);

//  const onChange = useAsyncDebounce((value) => {
//    setGlobalFilter(value || undefined);
//  }, 200);

//  return (
//    <div>
//      <Label>Search Table: </Label>
//      <Input
//        value={value || ""}
//        onChange={(e) => {
//          setValue(e.target.value);
//          onChange(e.target.value);
//        }}
//        placeholder=" Enter value "
//        className="w-25"
//        style={{
//          fontSize: "1.1rem",
//          margin: "15px",
//          display: "inline",
//        }}
//      />
//    </div>
//  );
// }

// Component for Default Column Filter
export function DefaultFilterForColumn(column: any) {
  const {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  } = column
  return (
    <StyledInput
      value={filterValue || ''}
      onChange={(e:ChangeEvent<HTMLInputElement>) => {
        // Set undefined to remove the filter entirely
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${length} records..`}
      style={{ marginTop: '10px' }}
    />
  )
}

// Component for Custom Select Filter
export function SelectColumnFilter(column: {
  filterValue: any
  setFilter: any
  preFilteredRows: any
  id: any
}) {
  const { filterValue, setFilter, preFilteredRows, id } = column
  // Use preFilteredRows to calculate the options
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // UI for Multi-Select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option: any, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export const MultiSelectFilter = ({
  displayedRows,
  column,
  filterData,
  setSummaryFilters,
  tableData
}: any) => {
  const {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
  } = column
  const dropdownFilterDivRef = useRef<any>(null)
  const [filtersClickOutside] = useState([])

  const [unselectedValues, setUnselectedValues] = useState<any>([])
  const [textInputFilter] = useState('')
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isShowDropdownOptions, setisShowDropdownOptions] = useState(false)
  

  const handleClickOutsideDiv = (event: MouseEvent) => {
    if (
      dropdownFilterDivRef.current &&
      !dropdownFilterDivRef.current.contains(event.target)
    ) {
      setisShowDropdownOptions(false)
    }
  }

  useEffect(() => {
    if (
      filterData?.length === 0 ||
      filterData === undefined ||
      filterData === null
    ) {
      setUnselectedValues([])
    }
  }, [filterData])

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDiv, true)
    return () => {
      document.addEventListener('click', handleClickOutsideDiv, true)
    }
  }, [filtersClickOutside])

  useEffect(() => {
    unselectedValues?.length === 0 && setIsSelectAll(true)
    unselectedValues?.length > 0 && setIsSelectAll(false)
  }, [unselectedValues])

  useEffect(() => {
    if (
      filterValue !== undefined &&
      filterValue !== null &&
      filterValue?.length !== 0
    ) {
      setUnselectedValues(filterValue)
    }
  }, [isShowDropdownOptions])

  const inputFilteredValues = useMemo(() => {
    return preFilteredRows.filter((row: any) => {
      const value = row.values[id]
      return value?.toLowerCase()
        .includes(textInputFilter.toLowerCase().trimStart().trimEnd())
    })
  }, [preFilteredRows, id, textInputFilter])

  const dropdownValues = useMemo(() => {
    const optionsSet: any = new Set()

    tableData.forEach((row: any) => {
      const element = row[id]?.trim()
      optionsSet.add(element?.toLowerCase())
    })
    const dropdownOptions = [...optionsSet].filter(
      (option) =>
        option &&
        (displayedRows?.some((item: any) => item.original[id]?.toLowerCase() == option?.toLowerCase()) ||
          unselectedValues?.includes(option) ||
          filterValue?.includes(option))
    )
    return dropdownOptions
  }, [
    id,
    displayedRows,
    preFilteredRows,
    unselectedValues,
    isShowDropdownOptions,
    inputFilteredValues,
  ])

  const handleTextFilterOnFocus = () => {
    if (!isShowDropdownOptions) {
      if (
        filterValue === undefined ||
        filterValue === null ||
        unselectedValues?.length === 0 ||
        filterValue.length === 0
      ) {
        setUnselectedValues([])
        setIsSelectAll(true)
      } else {
        setUnselectedValues(filterValue)
      }
      setisShowDropdownOptions(true)
    }
  }

  const toggleSelectAllOption = () => {
    if (isSelectAll) {
      setIsSelectAll(false)
      setUnselectedValues(dropdownValues)
    } else {
      setUnselectedValues([])
      setIsSelectAll(true)
    }
  }

  const resetFilter = () => {
    setUnselectedValues([])
    setFilter([])

    setSummaryFilters((prev: any) => {
      return isSelectAll
        ? updateFilterArray(prev, [], id)
        : updateFilterArray(prev, unselectedValues, id)
    })

    setisShowDropdownOptions(false)
  }

  const toggleDropdownOptions = (option: any) => {
    setUnselectedValues((prevState: any) => {
      if (prevState.includes(option)) {
        return prevState.filter((prevOption: any) => prevOption != option)
      } else {
        setIsSelectAll(false)
        return [...prevState, option]
      }
    })
  }

  const getDropdownCheckedFlag = (option: any) => {
    if (isSelectAll) {
      return true
    } else {
      return !unselectedValues.includes(option)
    }
  }

  return (
    <div ref={dropdownFilterDivRef} style={{ position: 'relative' }}>
      <>
        {/* {dropdownValues.length ? (  */}
        <FilterWrapper onClick={() => handleTextFilterOnFocus()}>
          <WDButtonTransparent>
            <SvgFilter height='11' width='14' fillColor={DEFAULT_COLORS.Text.Black}></SvgFilter>
          </WDButtonTransparent>
        </FilterWrapper>
        {/* ) : null} */}
      </>
      {isShowDropdownOptions && (
        <MainWrapper>
          <DataWrapper>
            <Arrow />
            {/* <StyledInput
              style={{ marginTop: '5px', maxWidth: '125px' }}
              type="text"
              value={textInputFilter}
              onChange={(e: any) => {
                isSelectAll && toggleSelectAllOption()
                setTextInputFilter(e.target.value)
              }}
              onFocus={() => handleTextFilterOnFocus()}
              placeholder="Filter..."
            /> */}
            <div
              key={id}
              style={{
                margin: '3px auto',
                width: '100%',
                boxSizing: 'border-box',
                padding: '2px',
              }}
            >
              <Checkbox
                checkBoxId={'SelectAll'}
                checked={isSelectAll}
                onChange={() => toggleSelectAllOption()}
                title="Select all"
              />
            </div>
            <Divider height='1px' thickness='1px' horizontal={true} bgColor='#efefef' />
            {dropdownValues.map((option, id) => (
              <div key={id} style={{ margin: '3px auto', padding: '2px' }}>
                <Checkbox
                  checked={getDropdownCheckedFlag(option)}
                  onChange={() => toggleDropdownOptions(option)}
                  title={option}
                />
              </div>
            ))}
          </DataWrapper>
          <BtnWrap>
            <WDButton>
              <Button
                disabled={!dropdownValues.length}
                type={'Button'}
                onClick={() => {
                  resetFilter()
                }}
              >
                Reset
              </Button>
            </WDButton>
            <WDButton>
              <Button
                type={'Button'}
                disabled={dropdownValues.length === unselectedValues?.length}
                onClick={() => {
                  if (isSelectAll) {
                    setFilter([])
                  } else {
                    setFilter(unselectedValues)
                  }
                  setSummaryFilters((prev: any) => {
                    return isSelectAll
                      ? updateFilterArray(prev, [], id)
                      : updateFilterArray(prev, unselectedValues, id)
                  })

                  setisShowDropdownOptions(false)
                }}
              >
                Ok
              </Button>
            </WDButton>
          </BtnWrap>
        </MainWrapper>
      )}
    </div>
  )
}
