import {
  createContext,
  Dispatch,
  ReactNode,
  useState,
  useMemo,
  useCallback,
} from 'react'
import {
  ClientSearchByNameResponseInterface,
  ClientSearchByNamePayloadInterface,
  AssociateSearchByNameResponseInterface,
  AssociateSearchByNamePayloadInterface,
} from './IClientAndAccountSearchContext'
import { associateSearchByNameAPI } from './ClientAndAccountSearchAPI'

interface SelectClientFn {
  (client: ClientSearchByNameResponseInterface | null): void
}

interface ClientSearchFn {
  (payload: ClientSearchByNamePayloadInterface): Promise<boolean | void>
}

interface AssociateSearchByNameFn {
  (payload: AssociateSearchByNamePayloadInterface, token: string): Promise<void>
}
export interface SearchContextInterface {
  clients: ClientSearchByNameResponseInterface[] | null
  selectedClient: ClientSearchByNameResponseInterface | null
  setClients: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface[] | null>
  >
  selectClient: SelectClientFn
  searchClients: ClientSearchFn
  clientAccountApiError: object | null | string
  isApiLoading: boolean
  searchAssociates: AssociateSearchByNameFn
  errorText: string
  setErrorText: Dispatch<React.SetStateAction<string>>
  selectedAssociate: AssociateSearchByNameResponseInterface[] | null
  associates: AssociateSearchByNameResponseInterface[]
  selectAssociate: Dispatch<
    React.SetStateAction<AssociateSearchByNameResponseInterface[] | null>
  >
  associate: AssociateSearchByNameResponseInterface | null
  setAssociate: Dispatch<
    React.SetStateAction<AssociateSearchByNameResponseInterface| null>
  >
}

const ClientAndAccountSearchContext = createContext<SearchContextInterface>(
  {} as SearchContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const ClientAndAccountSearchContextProvider = ({
  children,
}: ContextProps) => {
  const [clients, setClients] = useState<
    ClientSearchByNameResponseInterface[] | null
  >(null)
  const [associates, setAssociates] = useState<
    AssociateSearchByNameResponseInterface[]
  >([])
  const [selectedClient, selectClient] =
    useState<ClientSearchByNameResponseInterface | null>(null)
  const [selectedAssociate, selectAssociate] = useState<
    AssociateSearchByNameResponseInterface[] | null
  >(null)
  const [associate, setAssociate] =
    useState<AssociateSearchByNameResponseInterface | null>(null)
  const [clientAccountApiError, setApiError] = useState<object | null | string>(
    null
  )
  const [isApiLoading, setApiLoading] = useState<boolean>(false)
  const [errorText, setErrorText] = useState('')

  const searchClients = useCallback(
    async () => {
      try {
        setApiLoading(true)
        // let flag = false
        //await clientSearchByNameAPI(payload)
        // .then((response) => {
        //   if (response.data.length === 0) {
        //     flag = true
        //     setErrorText('No Results Found')
        //   }
        //   setClients(response.data)
        // })
        // .catch(() => {
        //   flag = false
        //   setErrorText('Please try again')
        // })
        setApiLoading(false)
        // return flag
      } catch (error) {
        setApiLoading(false)
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    },
    []
  )

  const searchAssociates = useCallback(
    async (payload: AssociateSearchByNamePayloadInterface, token: string) => {
      try {
        setApiLoading(true)
        const response = await associateSearchByNameAPI(payload, token)
        setAssociates(response.data)
        setApiLoading(false)
      } catch (error) {
        setApiLoading(false)
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    },
    []
  )

  const value = useMemo(
    () => ({
      clients,
      selectedClient,
      setClients,
      selectClient,
      searchClients,
      associates,
      searchAssociates,
      clientAccountApiError,
      isApiLoading,
      errorText,
      associate,
      setAssociate,
      setErrorText,
      selectedAssociate,
      selectAssociate,
    }),
    [
      clientAccountApiError,
      clients,
      associates,
      associate,
      setAssociate,
      errorText,
      searchAssociates,
      isApiLoading,
      selectedAssociate,
      searchClients,
      selectedClient,
    ]
  )

  return (
    <ClientAndAccountSearchContext.Provider value={value}>
      {children}
    </ClientAndAccountSearchContext.Provider>
  )
}

export default ClientAndAccountSearchContext
