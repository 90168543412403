import styled from 'styled-components'
import { SIZES, TextSizes, TextWeight } from '../../theme/Sizes'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-content: stretch;
`
export const File = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SIZES[0]};
`
export const WDLabelClient = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[0]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const WDLabelError = styled.div`
  color: ${() => DEFAULT_COLORS.Text.Danger};
  font-size: ${TextSizes[2]};
`
export const WDLabelSemiBold = styled.div`
  font-size: ${TextSizes[2]};
  font-weight: ${TextWeight[2]};
  line-height: ${TextSizes[6]};
  font-style: normal;
  color: ${() => DEFAULT_COLORS.Text.Neutral};
`
export const UploadWrapper = styled.div`
  border: 2px solid ${() => DEFAULT_COLORS.Border.LightBlue};
  gap: ${SIZES[3]};
  box-sizing: border-box;
  background: ${() => DEFAULT_COLORS.Background.PrimaryLight};
  align-items: center;
  padding: ${SIZES[3]} 0;
  display: flex;
  &.file-uploaded {
    background-color: ${() => DEFAULT_COLORS.Background.NeutralMedium};
    border: none;
  }
`
export const InputWrapper = styled.input``
export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${SIZES[2]};
`
export const SampleFile = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: flex-start;
`
export const InlineErrorWrapper = styled.div`
  display: flex;
  flex: 0 1 25%;
  gap: 0.25rem;
  align-items: center;
  color: ${() => DEFAULT_COLORS.Text.Danger};
`
export const IconWrapper = styled.div`
  display: flex;
  flex: 0 1 75%;
  justify-content: flex-start;
  align-items: center;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: ${() => DEFAULT_COLORS.Text.Black};
  top: ${SIZES[12]};
  right: ${SIZES[5]};
  z-index: 999;
`
export const LabelWrapper = styled.label`
  padding-left: 5px;
`
