import { useEffect, useMemo, useState } from 'react'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { Textbox } from '../../components/textbox/Textbox'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import {
  AddressInformationProps,
  ShippingAddressInterface,
} from './IAdressInformation'
import {
  CreateWrapper,
  ItemWrapper,
  SelectBoxSmall,
  CollapseHeaderWrapper,
  CollapseWrapper,
  CollapseButtonWrapper,
  CheckboxWrapper,
  AddressWrapper,
  RightWrapper,
  LeftWrapper,
  DeleteButtonWrapper,
  ItemSpaeWrapper,
} from './styles'
import Image from '../../components/image/Image'
import Button from '../../components/button/Button'
import {
  WDButtonTransparent,
  WDButtonMediumPrimaryBorder,
  WDButton,
} from '../../components/ui/WDButton'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import {
  ButtonWrapper,
  ButtonWrapperRight,
  WDContactInfoWrapper,
} from '../../components/ui/WDWrapper'
import { StyledButtonWrapper } from '../emailInformation/styles'
import { countryList, states } from './CountryData'
import { DefaultProps } from '../../components/selectDropdown/ISelectDropdown'
import Checkbox from '../../components/checkbox/Checkbox'

const ShippingInformation = ({
  isUpdate,
  updateData,
  updateAddressData,
  leadInfoData,
}: AddressInformationProps) => {
  const [isShippinInformation, setIsShippingInformationOpen] = useState(true)
  const [country, setCountry] = useState<DefaultProps[]>([])
  const [mappedCountry, setMappedCountry] = useState<string>('')
  const [stateList, setStateList] = useState<DefaultProps[]>([])

  const [addressList, setAddressList] = useState<ShippingAddressInterface[]>([
    {
      addressType: '',
      isPrimary: true,
      activeFlag: true,
      city: '',
      country: 'United States of America (the)',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      state: '',
    },
  ])

  const getAddressList = (index: number, fieldName: string, value: string) => {
    if (addressList.length > 1) {
      const filteredAddress: ShippingAddressInterface[] = addressList.map(
        (address, i) => {
          if (address.addressType === value && fieldName === 'addressType') {
            return { ...address, addressType: '' }
          } else if (fieldName === 'isPrimary' && index !== i) {
            return { ...address, isPrimary: false }
          }
          return { ...address }
        }
      )
      return filteredAddress
    } else {
      return addressList
    }
  }

  const updateShippingAddress = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    const currentShippingAddress: ShippingAddressInterface[] = getAddressList(
      index,
      fieldName,
      value
    ).map((address, i) => {
      if (index === i) {
        switch (fieldName) {
          case 'isPrimary':
            return { ...address, isPrimary: value === 'true' }
          case 'addressType':
            return { ...address, addressType: value }
          case 'city':
            return { ...address, city: value }
          case 'country':
            return { ...address, country: value }
          case 'addressLine1':
            return { ...address, addressLine1: value }
          case 'addressLine2':
            return { ...address, addressLine2: value }
          case 'postalCode':
            return { ...address, postalCode: value }
          case 'state':
            return { ...address, state: value }
          default:
            return { ...address }
        }
      } else {
        return { ...address }
      }
    })
    setAddressList(currentShippingAddress)
    updateData({ addressList: currentShippingAddress }, fieldName)
  }

  const addressTypeList = useMemo(
    () => [
      { value: 'Home', label: 'Home' },
      { value: 'Work', label: 'Work' },
      { value: 'Mailing', label: 'Mailing' },
    ],
    []
  )

  const [filteredAddressTypeList, setFilteredAddressTypeList] =
    useState(addressTypeList)

  const deleteAddress = (indexNumber: number) => {
    const updatedAddressList = addressList.map((address, index) => {
      if (index === indexNumber) {
        return { ...address, activeFlag: false }
      } else {
        return { ...address }
      }
    })
    setAddressList([...updatedAddressList])
    updateData({ addressList: updatedAddressList })
  }

  const addAnotherAddress = () => {
    if (addressList.length < 3) {
      const updatedAddressList = addressList
      updatedAddressList.push({
        addressType: '',
        isPrimary: false,
        activeFlag: true,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
      })

      setAddressList([...updatedAddressList])
    }
  }

  useEffect(() => {
    if (leadInfoData.addressList.length) {
      setAddressList(
        leadInfoData.addressList.filter(
          (address) => address.activeFlag !== false
        )
      )
    }
  }, [leadInfoData.addressList])

  useEffect(() => {
    if (addressList.length) {
      setFilteredAddressTypeList(
        addressTypeList.filter(
          (address) =>
            !addressList
              .filter((address) => address.activeFlag !== false)
              .map((address) => {
                return address.addressType
              })
              .includes(address.value)
        )
      )
    }
  }, [addressList, addressTypeList])

  useEffect(() => {
    const countries = countryList.map((valr) => {
      return {
        label: valr.name,
        value: valr.code,
      }
    })
    setCountry(countries)

    setMappedCountry('US')
  }, [])

  useEffect(() => {
    if (mappedCountry !== '') {
      const filteredStates = states
        .map((state: any) => {
          return state[(mappedCountry as keyof string) || 'US']
        })[0]
        .map((state: any) => {
          return {
            label: state.name,
            value: state.name,
          }
        })
      setStateList(filteredStates)
    }
  }, [mappedCountry])
  const getCountryCode = (name: string) => {
    const cCode: any[] = countryList.filter((c) => {
      return c.name == name
    })
    return cCode[0]?.name
  }

  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper
          onClick={() => setIsShippingInformationOpen(!isShippinInformation)}
        >
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">{'Address'}</Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                onClick={() =>
                  setIsShippingInformationOpen(!isShippinInformation)
                }
              >
                <Image
                  image={
                    isShippinInformation ? Icons.ChevronUp : Icons.ChevronDown
                  }
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isShippinInformation && (
          <>
            {addressList.map((address, index) => {
              return (
                <AddressWrapper key={index}>
                  <LeftWrapper>
                    <CreateWrapper>
                      <Label>{'Address Type'}</Label>
                      <SelectDropdown
                        className="w-100"
                        borderColor={DEFAULT_COLORS.Border.Neutral}
                        padding="0rem"
                        options={
                          address.addressType
                            ? addressTypeList
                            : filteredAddressTypeList
                        }
                        height="2.62rem"
                        placeholder={'Select'}
                        value={{
                          value: address.addressType,
                          label: address.addressType,
                        }}
                        onChange={(option) =>
                          updateShippingAddress(
                            index,
                            'addressType',
                            option.value
                          )
                        }
                      />
                      <CheckboxWrapper>
                        <Checkbox
                          onChange={(checked) =>
                            updateShippingAddress(
                              index,
                              'isPrimary',
                              checked ? 'true' : 'false'
                            )
                          }
                          checked={address.isPrimary}
                          disabled={address.isPrimary}
                        />
                        <Label>{'Primary'}</Label>
                      </CheckboxWrapper>
                    </CreateWrapper>
                  </LeftWrapper>
                  <RightWrapper>
                    <ItemWrapper>
                      <CreateWrapper>
                        <Label>{'Country'}</Label>
                        <SelectBoxSmall>
                          <SelectDropdown
                            className="w-100"
                            borderColor={DEFAULT_COLORS.Border.Neutral}
                            options={country}
                            padding="0rem"
                            height="2.62rem"
                            placeholder={'Select'}
                            value={{
                              value: address?.country.length
                                ? address?.country
                                : 'US',
                              label: address?.country.length
                                ? getCountryCode(address.country)
                                : 'United States of America (the)',
                            }}
                            onChange={(option) => {
                              setMappedCountry(option.value ?? 'US'),
                                updateShippingAddress(
                                  index,
                                  'country',
                                  option.label
                                )
                            }}
                            defaultValue={{
                              value: 'US',
                              label: 'United States of America (the)',
                            }}
                          />
                        </SelectBoxSmall>
                      </CreateWrapper>
                    </ItemWrapper>
                    <ItemWrapper>
                      <CreateWrapper>
                        <Label>{'Address Line 1'}</Label>
                        <Textbox
                          width="50rem"
                          onChange={(value) =>
                            updateShippingAddress(index, 'addressLine1', value)
                          }
                          value={address.addressLine1}
                          disabled={!address.addressType}
                        />
                      </CreateWrapper>
                    </ItemWrapper>
                    <ItemWrapper>
                      <CreateWrapper>
                        <Label>{'Address Line 2'}</Label>
                        <Textbox
                          width="50rem"
                          onChange={(value) =>
                            updateShippingAddress(index, 'addressLine2', value)
                          }
                          value={address.addressLine2}
                          disabled={!address.addressType}
                        />
                      </CreateWrapper>
                    </ItemWrapper>
                    <ItemSpaeWrapper>
                      <CreateWrapper>
                        <Label>{'City'}</Label>
                        <Textbox
                          onChange={(value) =>
                            updateShippingAddress(index, 'city', value)
                          }
                          value={address.city}
                          disabled={!address.addressType}
                        />
                      </CreateWrapper>
                    </ItemSpaeWrapper>
                    <ItemSpaeWrapper>
                      <CreateWrapper>
                        <Label>{'State'}</Label>
                        <SelectDropdown
                          className="w-100"
                          borderColor={DEFAULT_COLORS.Border.Neutral}
                          options={stateList}
                          padding="0rem"
                          height="2.62rem"
                          placeholder={'Select'}
                          value={{
                            value: address.state,
                            label: address.state,
                          }}
                          onChange={(option) =>
                            updateShippingAddress(index, 'state', option.value)
                          }
                          defaultValue={stateList[0]}
                        />
                      </CreateWrapper>
                    </ItemSpaeWrapper>

                    <DeleteButtonWrapper>
                      <ItemSpaeWrapper>
                        <CreateWrapper>
                          <Label>{'Zip Code'}</Label>
                          <Textbox
                            type="text"
                            onChange={(value) =>
                              updateShippingAddress(index, 'postalCode', value)
                            }
                            value={address.postalCode}
                            disabled={!address.addressType}
                          />
                        </CreateWrapper>
                      </ItemSpaeWrapper>
                      {!address.isPrimary && (
                        <ItemWrapper margin={'1.5rem'}>
                          <StyledButtonWrapper>
                            <Button
                              type="button"
                              bgColor={DEFAULT_COLORS.Background.DangerStrong}
                              disabled={address.isPrimary}
                              onClick={() => deleteAddress(index)}
                            >
                              {'Delete'}
                            </Button>
                          </StyledButtonWrapper>
                        </ItemWrapper>
                      )}
                    </DeleteButtonWrapper>
                  </RightWrapper>
                </AddressWrapper>
              )
              // }
            })}

            <ButtonWrapper>
              <ButtonWrapperRight>
                <WDButtonMediumPrimaryBorder>
                  <Button
                    type="button"
                    onClick={() => addAnotherAddress()}
                    disabled={!(addressList.length < 3)}
                  >
                    {'Add Another Address'}
                  </Button>
                </WDButtonMediumPrimaryBorder>
                {isUpdate && (
                  <WDButton>
                    <Button type="button" onClick={updateAddressData}>
                      {'Save'}
                    </Button>
                  </WDButton>
                )}
              </ButtonWrapperRight>
            </ButtonWrapper>
          </>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default ShippingInformation
