import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { ToastProps } from './IToast'

const handleBgColor = (type: string) => {
  switch (type) {
    case 'success':
      return `background:${DEFAULT_COLORS.Background.Success}; border:1px solid ${DEFAULT_COLORS.Border.MediumGreen};`
    case 'danger':
      return `background:${DEFAULT_COLORS.Background.Fantasy}; border:1px solid ${DEFAULT_COLORS.Border.Salmon}`
    case 'warning':
      return `background:${DEFAULT_COLORS.Background.RoseWhite}; border:1px solid ${DEFAULT_COLORS.Border.FadedOrange}`
    default:
      return `background:${DEFAULT_COLORS.Background.PrimaryLight}; border:1px solid ${DEFAULT_COLORS.Border.DangerDark}`
  }
}
export const Master = styled.div<ToastProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Main = styled.div<ToastProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  animation: fadeOut ease 12s;
  padding: 0.5rem 1rem;
  width: 29.375rem;
  ${(Props: { type: string }) => handleBgColor(Props.type)};
  gap: 0.5rem;
  box-shadow: 0px 2px 6px -2px ${() => DEFAULT_COLORS.BoxShadow.Shadow};
  border-radius: 4px;
`
export const ToastWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
export const ToastIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
export const ToastTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 24rem;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0px 0.5rem;
`
export const ToastImage = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 0.9375rem;
  height: 0.9375rem;
  cursor: pointer;
`
export const ToastText = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: flex-start;
  margin-top: 0.5rem;
  word-break: break-all;
`
