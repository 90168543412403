import Lottie from 'lottie-react'
import { GraphQLSpinnerWrapper, LottieWrapper } from './styles'
import LottieLoader from '../../assets/lottiefiles/loader.json'

const GraphQLLoader = () => {
  return (
    <GraphQLSpinnerWrapper>
      <LottieWrapper>
        <Lottie animationData={LottieLoader} loop={true} />
      </LottieWrapper>
    </GraphQLSpinnerWrapper>
  )
}

export default GraphQLLoader
