import Button from '../../components/button/Button'
import Label from '../../components/label/Label'
import {
  SvgChevronLeftMedium,
  SvgChevronRightMedium,
} from '../../components/svg/SvgChevron'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { WDLabelBlue, WDLabelPrimaryText } from '../../components/ui/WDLabel'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { TextWrapper } from './styles'
import { OffsetProp } from './IPaginator'
import { ButtonContainer, Pagination } from './styles'

const Paginator = ({
  pagination,
  totalRecords,
  handlePagination,
}: OffsetProp) => {
  return totalRecords > 50 ? (
    <Pagination>
      <ButtonContainer>
        {pagination.pageIndex !== 0 && (
          <WDButtonTransparent>
            <Button
              type={'button'}
              onClick={() => handlePagination?.('prev')}
              disabled={pagination.pageIndex == 0}
              prefixedIcon={
                <SvgChevronLeftMedium
                  fillColor={DEFAULT_COLORS.Icons.Neutral}
                ></SvgChevronLeftMedium>
              }
            ></Button>
          </WDButtonTransparent>
        )}
      </ButtonContainer>

      <TextWrapper>
        <WDLabelBlue>
          <Label>
            {pagination.pageIndex !== undefined && pagination.pageIndex + 1} -{' '}
            {pagination.pageSize && totalRecords && pagination.pageSize  < +totalRecords
              ? pagination.pageSize 
              : totalRecords}{' '}
            of {totalRecords} Records
          </Label>
        </WDLabelBlue>
      </TextWrapper>
      {pagination.pageSize  < +totalRecords && (
        <ButtonContainer>
          <WDButtonTransparent>
            <Button
              type={'button'}
              prefixedIcon={
                <SvgChevronRightMedium
                  fillColor={DEFAULT_COLORS.Icons.Neutral}
                ></SvgChevronRightMedium>
              }
              onClick={() => handlePagination?.('next')}
            ></Button>
          </WDButtonTransparent>
        </ButtonContainer>
      )}
    </Pagination>
  ) : (
    <Pagination>
      <TextWrapper>
        <WDLabelPrimaryText>
          <Label>{totalRecords} Records</Label>
        </WDLabelPrimaryText>
      </TextWrapper>
    </Pagination>
  )
}

export default Paginator
