export const FormateDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return month.concat('-').concat(day).concat('-').concat(year)
}

export const FormateDateTime = (dateString: string) => {
  return new Date(dateString).toLocaleString().replace(',', '')
}

export const FullFormateDateTime = (dateString: string) => {
  return new Date(dateString)
    .toLocaleString('en-us', { hour12: false })
    .replace(',', '')
}

export const AddDays = (date1: Date, days: number) => {
  const date=new Date(date1)
  date.setDate(date.getDate() + days)
  return date
}

export const formateOriginator = (value: string) => {
  if (value.length > 2) {
    const text = value.replace(/\s{2,}/g, ' ').split(' ')
    if (text.length > 2) {
      value = text[1] + ' ' + text[2] + ' ' + text[3]
    } else if (text.length == 2) {
      value = text[1]
    }
    return value
  }
  return value
}

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '$',
}

export const UsNumberFormate = (value: any) => {
  if (typeof value !== 'number') value = parseFloat(value)
  value = value.toFixed(defaultOptions.significantDigits)

  const [currency, decimal] = value.split('.')
  return `${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    defaultOptions.thousandsSeparator
  )}${defaultOptions.decimalSeparator}${decimal}`
}
