import Lottie from 'lottie-react'
import {
  CreateEventComponent,
  CreateNoteComponent,
  CreateTaskComponent,
} from 'note-widget'
import {
  BaseSyntheticEvent,
  ChangeEvent,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Cell,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import Loader from '../../assets/lottiefiles/loader.json'
import { vars } from '../../assets/utils/CommonFunctions'
import CommonTabs from '../../composites/commonTabs/CommonTabs'
import { LottieWrapper } from '../../composites/interceptor/styles'
import NoDataFound from '../../composites/noDataFound/NoDataFound'
import { TextWrapper } from '../../composites/paginator/styles'
import { TabWrapper } from '../../global'
import {
  SubTypesDataInterface,
  TypesDataInterface,
} from '../../pages/notes/INotes'
import { TitleWrapper } from '../../pages/opportunities/styles'
import {
  leadsData,
  partiesData,
  subTypeData,
  typeData,
} from '../../reducers/leadPartySlice'
import { AuthContext } from '../../services/AuthContext'
import { formatPhoneNumber } from '../../services/Utils'
import EditActionContext from '../../services/editActionsContext/EditActionsContext'
import FilterContext, {
  initialFilterValue,
} from '../../services/filterContext/FilterContext'
import UserContext from '../../services/user/UserContext'
import appConfig from '../../settings/appConfig'
import { ContentTitleWrapper, Icons } from '../../shared/GlobalStyle'
import { DEFAULT_COLORS } from '../../theme/Colors'
import Button from '../button/Button'
import Checkbox from '../checkbox/Checkbox'
import CustomCalendar from '../customCalendar/CustomCalendar'
import { StyledInput } from '../customCalendar/styles'
import Label from '../label/Label'
import SelectDropdown from '../selectDropdown/SelectDropdown'
import { SvgApprove } from '../svg/SvgApprove'
import { SvgClose } from '../svg/SvgClose'
import { SvgDeleteIconWithoutBorder } from '../svg/SvgDeleteIconWithoutBorder'
import { SvgEdit } from '../svg/SvgEdit'
import { SvgSort } from '../svg/SvgSort'
import {
  WDButton,
  WDButtonDisabled,
  WDButtonPrimaryOutlined,
  WDButtonTransparent,
  WDButtonTransparentHeight,
  WDButtonTransparentWithoutHeight,
} from '../ui/WDButton'
import {
  StyledLabelPrimaryEllipsis,
  WDLabelBlue,
  WDLabelPrimaryWordBreak,
  WDLabelPrimaryXLarge,
} from '../ui/WDLabel'
import { ButtonWrapper, WDWrapper } from '../ui/WDWrapper'
import { DefaultFilterForColumn, MultiSelectFilter } from './Filter'
import { TableData, TableProps } from './ITable'
import './style.css'
import {
  AddButtonWrapper,
  CustomMarker,
  DateTimeWrapper,
  DisplayWrapper,
  FilterSubWrapper,
  HeaderWrapper,
  InputWrapper,
  SortWrapper,
  SpinnerTableWrapper,
  TableBody,
  TableDataCell,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  TileFilterWrapper,
  WDButtonWrapper,
  Wrapper,
} from './styles'
import { ContentInputWrapper, SplitWrapper } from './tableStyles'
import ExportCSV from '../exportCSV/ExportCSV'
import { Icon } from '../icon/Icon'

const Table = forwardRef<any, TableProps>(
  (
    {
      height,
      tableColumns,
      allDataCount,
      fetchData,
      allData = [],
      hasFooter = false,
      sortingFields,
      tableProperties,
      isLoading,
      wrapData,
      source,
      refetchInteraction,
      setThompsonSearch,
      setSummaryFilters,
      setSelectedDataList,
      filterData,
      typeArray,
      subTypeArray,
      repCodeList,
      updateStatus,
      leadPartyId,
      selectedTab,
      setSelectedTab,
    },
    ref
  ) => {
    // const [typeValue, setTypeValue] = useState<DefaultProps>(initialTypeSubType)
    // const [subTypeValue, setSubTypeValue] =
    //   useState<DefaultProps>(initialTypeSubType)
    // const [selectedRepcode, setSelectedRepcode] =
    //   useState<DefaultProps>(initialRepCode)
    // const [searchFilter, setSearchFilter] = useState<string>('')
    const { userAccessDetails, accessToken } = useContext(AuthContext)
    const {
      updateActionsFlow,
      editNotesProps,
      editEventsProps,
      editTaskProps,
      showNewEvents,
      showNewNotes,
      showTasks,
      setNewEvents,
      setShowTasks,
      setNewNotes,
      taskPropsByNote,
    } = useContext(EditActionContext)
    const tableData = useMemo(() => allData, [allData])
    const columns = useMemo(
      () =>
        tableColumns.filter((column) => {
          return (
            userAccessDetails.role === vars.adminRole ||
            column.accessor !== 'action'
          )
        }),
      [tableColumns, userAccessDetails.role]
    )
    const navigate = useNavigate()
    const scrollReference = useRef<any>(null)
    const [currentCell, setCurrentCell] = useState<any>()
    // const [filterColumnValue, setFilterColumnValue] = useState<any>()
    // const [filterSelect, setFilterSelect] = useState<any>()
    // const [filterType, setFilterType] = useState<any>()
    const wrapperRef = useRef(null)
    const { user } = useContext(UserContext)
    const leadsDetails = useSelector(leadsData)
    const partyDetails = useSelector(partiesData)
    const [selectedTranType, setSelectedTranType] = useState<number>(0)
    // const [globalFilter, setGlobalFilter] = useState<any>('')
    const typesData = useSelector(typeData)
    const subTypesData = useSelector(subTypeData)
    const [allTypes, setAllTypes] = useState<any[]>([])
    const [subTypes, setSubTypes] = useState<any[]>([])
    const {
      filterPayload,
      setFilterPayload,
      tableFilter,
      setTableFilter,
      scrollPosition,
      resetTableFilter,
    } = useContext(FilterContext)
    // const [filteredData, setFilteredData] = useState<any[]>([])
    const [exportData, setExportData] = useState<TableData[]>([])
    const csvLinkRef = useRef<HTMLDivElement>(null)
    const getTypeByTranType = (tranType: number) => {
      return typesData
        .filter((type: TypesDataInterface) => type.trantype === tranType)
        .map((type: TypesDataInterface) => {
          return { value: type.typeid, label: type.type }
        })
    }

    const getSubTypeByType = (typeId: number) => {
      return subTypesData
        .filter(
          (type: SubTypesDataInterface) =>
            type.typeid === typeId && type.trantype === selectedTranType
        )
        .map((type: SubTypesDataInterface) => {
          return { value: type.subtypeid, label: type.subtype }
        })
    }

    useEffect(() => {
      if (typesData) {
        setAllTypes(getTypeByTranType(selectedTranType))
      }
    }, [typesData, selectedTranType])

    // const filterTable = (column: string, value: any, type?: string) => {
    //   setFilterColumnValue(column)
    //   setFilterSelect(value)
    //   setFilterType(type)
    // }

    const handleInfiniteScroll = async () => {
      if (
        scrollReference?.current?.scrollTop +
          scrollReference?.current?.clientHeight >=
        scrollReference?.current?.scrollHeight - 80
      ) {
        fetchData?.(scrollReference?.current?.scrollTop)
      }
    }
    useEffect(() => {
      if (exportData.length) {
        const htmlAnchorElement = csvLinkRef.current?.children[0].children[0]
          .children[0] as HTMLAnchorElement
        htmlAnchorElement.click()
      }
    }, [exportData])
    useEffect(() => {
      if (scrollReference?.current && tableProperties?.paginatorRequired) {
        scrollReference?.current?.addEventListener(
          'scroll',
          handleInfiniteScroll
        )
        scrollPosition &&
          scrollReference?.current?.scrollTo(0, scrollPosition - 10)

        return () => {
          scrollReference?.current?.removeEventListener('scroll', () => {
            // scrollReference?.current?.scrollTo(0, 0)
            console.log('cleared')
          })
        }
      }
    }, [])

    const openTasktoConvert = () => {
      updateActionsFlow(currentCell.row.original.id, 1, undefined, 'convert')
      setShowTasks(true)
      setNewNotes(false)
    }

    // Expose the child function using useImperativeHandle
    useImperativeHandle(ref, () => ({
      openTasktoConvert,
    }))

    //filtering table using external filter
    // const filteredData = useMemo(() => {
    //   if (source != 'leadData' && selectedRepcode.value) {
    //     return allData.filter(
    //       (ele: any) => ele.advisor === selectedRepcode.value
    //     )
    //   } else {
    //     return allData
    //   }
    // }, [allData, selectedRepcode.value])

    const tableInstance = useTable(
      {
        columns,
        data: tableData,
        defaultColumn: { Filter: DefaultFilterForColumn },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useRowSelect
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      prepareRow,
      setGlobalFilter,
      state: { globalFilter },
      filteredFlatRows,
      rows, // Paginated rows
      // nextPage,
      // previousPage,
    } = tableInstance

    // const handlePagination = useCallback(
    //   (calledFrom: string) => {
    //     if (calledFrom == 'next') {
    //       // setFilterSelect(-1)
    //       // nextPage()
    //       // setPagination({
    //       //   pageIndex: pagination.pageIndex + 50,
    //       //   pageSize: pagination.pageSize + 50,
    //       // })
    //     } else {
    //       // setFilterSelect(-1)
    //       // previousPage()
    //       // setPagination({
    //       //   pageIndex: pagination.pageIndex - 50,
    //       //   pageSize: pagination.pageSize - 50,
    //       // })
    //     }
    //   },
    //   []
    // )

    const handleChange = (index: number, checked: boolean) => {
      const contactList: TableData[] = []
      if (checked) tableData[index].selected = true
      else tableData[index].selected = false
      tableData.forEach((value, i) => {
        if (value.selected) contactList.push(value)
        else contactList.splice(i, 1)
      })
      setSelectedDataList?.([...contactList])
    }
    const USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    const routeToPopUp = (cell: Cell<TableData>) => {
      if (source === 'leadData') {
        navigate('/updateContactList/' + cell.row.original.id)
      } else if (source === 'NoteAndTaskData') {
        updateActionsFlow(+cell.row.original.id, 1, undefined, 'convert')
      }
    }

    const getCrmStatus = (index: number) => {
      if (!tableData || !tableData[index] || !tableData[index].status) {
        return '--'
      }
      const leadColors = [
        { type: 'Lead', color: 'rgb(152, 255, 221)' },
        { type: 'Prospect', color: 'rgb(255, 246, 198)' },
        { type: 'Client', color: 'rgb(178, 233, 255)' },
        { type: 'Interested', color: 'rgb(236, 215, 255)' },
      ]
      const status = tableData[index].status
      const bgColor = leadColors.find((ele) => ele.type == status)?.color

      return <CustomMarker bgColor={bgColor}>{status}</CustomMarker>
    }

    const getStatus = (value: number) => {
      const leadColors = [
        { type: 0, color: 'rgb(207, 255, 222)', label: 'Open' },
        { type: 1, color: 'rgb(233, 233, 233)', label: 'Closed' },
        { type: 2, color: 'rgb(255, 214, 214)', label: 'Cancelled' },
      ]

      const status = leadColors.find((ele) => ele.type == value)

      return (
        <CustomMarker bgColor={status?.color}>{status?.label}</CustomMarker>
      )
    }

    const formatDate = (date: Date) => {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear().toString()
      return month.concat('/').concat(day).concat('/').concat(year)
    }

    const formatDateTime = (IsoDate?: string, timeZone?: string) => {
      const date = IsoDate
        ? IsoDate.endsWith('Z')
          ? new Date(IsoDate)
          : new Date(`${IsoDate}Z`)
        : new Date()
      const dateString = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear().toString()
      const hour = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return (
        <DateTimeWrapper>
          <DisplayWrapper>{`${month}/${dateString}/${year}`}</DisplayWrapper>
          {timeZone !== null ? (
            <DisplayWrapper>{`${hour}:${minutes} (${timeZone})`}</DisplayWrapper>
          ) : (
            <DisplayWrapper>{`${hour}:${minutes}`}</DisplayWrapper>
          )}
        </DateTimeWrapper>
      )
    }

    const toggleTableRowData = (cell: Cell<TableData>, a?: any) => {
      if (cell.column.Header === 'Checkbox') {
        return (
          <Checkbox
            onChange={(checked) => handleChange(cell.row.index, checked)}
          />
        )
      } else if (
        cell.column.Header === 'Action' &&
        (source === 'leadData' ||
          source === 'noteData' ||
          source === 'NoteAndTaskData')
      ) {
        //
        return (
          <WDWrapper>
            {
              <WDButtonTransparentHeight>
                <Button
                  title="Edit"
                  padding="0 4px"
                  type="button"
                  onClick={() => {
                    setCurrentCell(cell)
                    // let type = ''
                    // let tranType = 0
                    // if (source === 'NoteAndTaskData') {
                    //   type = 'note'
                    //   tranType = 1
                    // } else if (source === 'noteData') {
                    //   type = 'note'
                    //   tranType = 1
                    // }

                    setSelectedTranType(1)

                    if (source === 'leadData') {
                      if (cell.row.original.status == 'Client') {
                        window.open(
                          'https://clientmaintenance-dev.azurewebsites.net/',
                          '_blank'
                        )
                      } else {
                        navigate('/updateContactList/' + cell.row.original.id)
                      }
                    } else {
                      updateActionsFlow(
                        +cell.row.original.id,
                        1,
                        undefined,
                        'edit'
                      )
                    }

                    setThompsonSearch && setThompsonSearch(false)
                  }}
                >
                  <SvgEdit fillColor={DEFAULT_COLORS.Icons.Primary} />
                </Button>
              </WDButtonTransparentHeight>
            }
            {source === 'NoteAndTaskData' && (
              <>
                {
                  <WDButtonTransparentHeight>
                    <Button
                      title="Convert to task"
                      padding="0 0 0 14px"
                      type="button"
                      onClick={() => {
                        routeToPopUp(cell)
                        setSelectedTranType(2)
                        setThompsonSearch && setThompsonSearch(false)
                      }}
                    >
                      <SvgApprove fillColor={DEFAULT_COLORS.Icons.Primary} />
                    </Button>
                  </WDButtonTransparentHeight>
                }
              </>
            )}
          </WDWrapper>
        )
      } else if (
        (source === 'eventData' || source === 'taskData') &&
        cell.column.Header === 'Action'
      ) {
        const tranId = cell?.row?.original?.id
        return (
          <WDWrapper>
            {
              <WDButtonTransparentHeight>
                <WDButtonWrapper>
                  <Button
                    title="Edit"
                    type="button"
                    onClick={() => {
                      if (source === 'eventData') {
                        setSelectedTranType(3)
                        updateActionsFlow(
                          +cell.row.original.id,
                          3,
                          +cell.row.original?.meetingid
                        )
                      } else if (source == 'taskData') {
                        setSelectedTranType(2)
                        setCurrentCell(cell)
                        updateActionsFlow(
                          +cell.row.original.id,
                          2,
                          undefined,
                          'edit'
                        )
                      }

                      setThompsonSearch && setThompsonSearch(false)
                    }}
                  >
                    <SvgEdit fillColor={DEFAULT_COLORS.Icons.Primary} />
                  </Button>

                  {(cell.row.original.meetingstatusid === 0 ||
                    cell.row.original.status === 0) && (
                    <Button
                      title={'Cancel'}
                      type="button"
                      onClick={() => {
                        updateStatus?.('cancel', +tranId)
                        setThompsonSearch && setThompsonSearch(false)
                      }}
                    >
                      <SvgClose fillColor={DEFAULT_COLORS.Icons.Primary} />
                    </Button>
                  )}

                  {(cell.row.original.meetingstatusid === 0 ||
                    cell.row.original.status === 0) && (
                    <Button
                      title={'Close'}
                      type="button"
                      onClick={() => {
                        updateStatus?.('close', +tranId)
                        setThompsonSearch && setThompsonSearch(false)
                      }}
                    >
                      <SvgDeleteIconWithoutBorder />
                    </Button>
                  )}
                  {(cell.row.original.meetingstatusid === 1 ||
                    cell.row.original.meetingstatusid === 2 ||
                    cell.row.original.status === 1 ||
                    cell.row.original.status === 2) && (
                    <Button
                      title="Open"
                      type="button"
                      onClick={() => {
                        updateStatus?.('open', +tranId)
                        setThompsonSearch && setThompsonSearch(false)
                      }}
                    >
                      <SvgApprove fillColor={DEFAULT_COLORS.Icons.Primary} />
                    </Button>
                  )}
                </WDButtonWrapper>
              </WDButtonTransparentHeight>
            }
          </WDWrapper>
        )
      } else if (
        cell.column.Header === 'Subject' &&
        (source === 'noteProfileData' ||
          source === 'eventProfileData' ||
          source === 'taskProfileData')
      ) {
        return (
          <WDWrapper>
            <WDButtonTransparentHeight>
              <Button
                type="button"
                onClick={() => {
                  // let type = ''
                  // if (source === 'noteProfileData') {
                  //   type = 'note'
                  // } else if (source === 'eventProfileData') {
                  //   type = 'event'
                  // } else if (source === 'taskProfileData') {
                  //   type = 'task'
                  // }
                  routeToPopUp(cell)
                  setThompsonSearch && setThompsonSearch(false)
                }}
              >
                <StyledLabelPrimaryEllipsis title={cell.render('Cell')}>
                  {cell.render('Cell')}
                </StyledLabelPrimaryEllipsis>
              </Button>
            </WDButtonTransparentHeight>
          </WDWrapper>
        )
      } else if (cell.column.Header === 'Name') {
        const RouteToLeadProfile = () => {
          if (
            cell.row.original.status == 'Lead' ||
            cell.row.original.status == 'Interested' ||
            cell.row.original.status == 'Prospect'
          ) {
            const row1 = a.original.id
            if (row1 != undefined || row1 != null) {
              navigate('/Leadprofile', {
                state: {
                  leadId: row1,
                },
              })
              setThompsonSearch && setThompsonSearch(false)
            }
          } else if (cell.row.original.status == 'Client') {
            const row1 = cell.row.original.id
            if (row1 != undefined || row1 != null) {
              navigate('/Profile/' + row1, {
                state: {
                  clientId: row1,
                },
              })
              setThompsonSearch && setThompsonSearch(false)
            }
          } else {
            const row1 = a.original.id
            if (row1 != undefined || row1 != null) {
              navigate('/Leadprofile', {
                state: {
                  leadId: row1,
                },
              })
              setThompsonSearch && setThompsonSearch(false)
            }
          }
        }
        return (
          <TableDataCell
            {...cell.getCellProps()}
            key={cell.row.id + '-' + cell.column.Header}
            className="remove_box"
          >
            <SplitWrapper>
              <WDButtonTransparentWithoutHeight>
                <Button type="button" onClick={RouteToLeadProfile}>
                  <WDLabelPrimaryWordBreak>
                    {cell.render('Cell')}
                  </WDLabelPrimaryWordBreak>
                </Button>
              </WDButtonTransparentWithoutHeight>
            </SplitWrapper>
          </TableDataCell>
        )
      } else if (cell.column.Header === 'OPPORTUNITY') {
        const RouteToOpportunity = () => {
          if (leadPartyId != undefined || leadPartyId != null) {
            navigate(`/opportunities/${cell.row.original.id}`, {
              state: {
                leadPartyId,
              },
            })
            setThompsonSearch && setThompsonSearch(false)
          }
        }
        return (
          <TableDataCell
            {...cell.getCellProps()}
            key={cell.row.id + '-' + cell.column.Header}
            className="remove_box"
          >
            <SplitWrapper>
              <WDButtonTransparentWithoutHeight>
                <Button type="button" onClick={RouteToOpportunity}>
                  <WDLabelPrimaryWordBreak>
                    {cell.render('Cell')}
                  </WDLabelPrimaryWordBreak>
                </Button>
              </WDButtonTransparentWithoutHeight>
            </SplitWrapper>
          </TableDataCell>
        )
      } else if (cell.column.Header === 'Party/Lead') {
        const RouteToLeadProfile = () => {
          const id = a.original.partyid
          if (id > vars.leadIdRange) {
            navigate('/Leadprofile', {
              state: {
                leadId: id,
              },
            })
          } else {
            navigate(`/Profile/${id}`)
          }
        }
        if (cell?.value == null || cell?.value?.toString()?.trim() == '') {
          return '--'
        } else {
          return (
            <TableDataCell
              {...cell.getCellProps()}
              key={cell.row.id + '-' + cell.column.Header}
              className="remove_box"
            >
              <SplitWrapper>
                <WDButtonTransparentWithoutHeight>
                  <Button type="button" onClick={RouteToLeadProfile}>
                    <WDLabelPrimaryWordBreak>
                      {cell.render('Cell')}
                    </WDLabelPrimaryWordBreak>
                  </Button>
                </WDButtonTransparentWithoutHeight>
              </SplitWrapper>
            </TableDataCell>
          )
        }
      } else if (
        (source === 'leadData' || source == 'NotContacted') &&
        cell.column.id === 'status'
      ) {
        return getCrmStatus(cell.row.index)
      } else if (cell.column.id == 'lastContacted') {
        return cell.value == '--' ? '--' : formatDate(new Date(cell.value))
      } else if (
        cell.column.id === 'account' ||
        cell.column.id === 'accountName' ||
        cell.column.id === 'role' ||
        cell.column.id === 'accountRelationship'
      ) {
        return cell.value ? cell.value : '--'
      } else if (
        (source === 'eventData' || source === 'eventProfileData') &&
        (cell.column.Header === 'Start Time' ||
          cell.column.Header === 'End Time')
      ) {
        const timeZone: string = cell.row.original.timezone?.toString()
        return formatDateTime(cell.value, timeZone)
      } else if (
        cell.column.Header === 'Date' ||
        cell.column.Header === 'CLOSED DATE' ||
        cell.column.Header === 'Due Date'
      ) {
        const finalDate =
          cell.value &&
          cell.value?.split('T')[0].split('-')[1] +
            '-' +
            cell.value?.split('T')[0].split('-')[2] +
            '-' +
            cell.value?.split('T')[0].split('-')[0]

        return finalDate ? formatDate(new Date(finalDate)) : '--'
      } else if (
        cell.column.id === 'starttime' ||
        cell.column.id === 'endtime'
      ) {
        const timeZone: string = cell.row.original.timezone?.toString()
        return cell.value ? formatDateTime(cell.value, timeZone) : '--'
      } else if (
        (source === 'eventData' || source === 'taskData') &&
        cell.column.Header === 'Status'
      ) {
        return getStatus(cell.value)
      } else if (cell.column.id === 'repcode' || cell.column.id === 'advisor') {
        return cell.value ? cell.value : '--'
      } else if (cell.column.Header === 'Owner') {
        return cell.value ? cell.value : '--'
      } else if (
        cell.column.Header === 'STATUS' &&
        source === 'opportunities'
      ) {
        if (cell.value == 0) {
          return 'Closed Lost'
        } else if (cell.value == 1) {
          return 'Closed Won'
        } else return 'In Progress'
      } else if (
        cell.column.Header === 'AMOUNT' &&
        source === 'opportunities'
      ) {
        return cell.value ? USDollar.format(cell.value) : '--'
      } else if (
        cell.column.Header === 'LINKED CONTACTS' ||
        (cell.column.Header === 'ASSIGNED TO' && source === 'opportunities')
      ) {
        return cell.value ? cell.value : '--'
      } else if (cell.column.Header === 'NAME' && source === 'opportunities') {
        const RouteToOpportunities = () => {
          const row1 = a.original.id
          if (row1 != undefined || row1 != null) {
            navigate('/opportunities/' + row1)
          }
        }
        return (
          <TableDataCell
            {...cell.getCellProps()}
            key={cell.row.id + '-' + cell.column.Header}
            className="remove_box"
          >
            <SplitWrapper>
              <WDButtonTransparentWithoutHeight>
                <Button type="button" onClick={RouteToOpportunities}>
                  <WDLabelPrimaryWordBreak>
                    {cell.render('Cell')}
                  </WDLabelPrimaryWordBreak>
                </Button>
              </WDButtonTransparentWithoutHeight>
            </SplitWrapper>
          </TableDataCell>
        )
      } else if (cell.column.Header === 'Phone' && source === 'leadData') {
        return cell.value ? formatPhoneNumber(cell.value) : '--'
      } else if (cell.column.Header === 'Email Id' && source === 'leadData') {
        return cell.value ? cell.value : '--'
      } else if (cell.column.Header === 'Advisor' && source === 'leadData') {
        return cell.value ? cell.value : '--'
      } else if (cell.column.Header === 'Category' && source === 'leadData') {
        return cell.value ? cell.value : '--'
      } else if (cell.column.id === 'category' && source === 'leadData') {
        return cell.value ? cell.value : '--'
      } else if (cell.column.Header === 'Type' && source === 'leadData') {
        return cell.value ? cell.value : '--'
      } else if (
        (cell.column.Header === 'Account' && source === 'NoteAndTaskData') ||
        (cell.column.Header === 'Account Number' &&
          source === 'NoteAndTaskData') ||
        (cell.column.Header === 'Subject' && source === 'NoteAndTaskData') ||
        (cell.column.Header === 'Type' &&
          (source === 'NoteAndTaskData' || source === 'noteProfileData')) ||
        (cell.column.Header === 'Sub Type' &&
          (source === 'NoteAndTaskData' || source === 'noteProfileData')) ||
        (cell.column.Header === 'Party/Lead' && source === 'NoteAndTaskData') ||
        (cell.column.Header === 'Owner' &&
          (source === 'NoteAndTaskData' || source === 'noteProfileData')) ||
        (cell.column.Header === 'Note Date' && source === 'NoteAndTaskData')
      ) {
        return cell.value ? cell.value : '--'
      } else if (
        (cell.column.Header === 'Account Name' && source === 'taskData') ||
        (cell.column.Header === 'Account Number' && source === 'taskData') ||
        (cell.column.Header === 'Subject' && source === 'taskData') ||
        (cell.column.Header === 'Type' &&
          (source === 'taskData' || source === 'taskProfileData')) ||
        (cell.column.Header === 'Sub Type' &&
          (source === 'taskData' || source === 'taskProfileData')) ||
        (cell.column.Header === 'Note Date' && source === 'taskData') ||
        (cell.column.Header === 'Party/Lead' && source === 'taskData') ||
        (cell.column.Header === 'Owner' &&
          (source === 'taskData' || source === 'taskProfileData'))
      ) {
        return cell.value ? cell.value : '--'
      } else if (
        (cell.column.Header === 'Subject' && source === 'eventData') ||
        (cell.column.Header === 'Location' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'Start Time' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'Type' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'Sub Type' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'End Time' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'Party/Lead' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'Owner' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'Account Name' &&
          (source === 'eventData' || source === 'eventProfileData')) ||
        (cell.column.Header === 'LEAD/PARTY' && source === 'opportunities')
      ) {
        return cell.value ? cell.value : '--'
      } else {
        if (cell.value == null || cell.value == ' ') {
          return <span>--</span>
        }
        return cell.render('Cell')
      }
    }

    // const handleDebounce = debounce(setGlobalSearch, 500)
    const exportFile = (event?: BaseSyntheticEvent) => {
      const htmlElement = event?.target as HTMLElement
      if (htmlElement.nodeName === 'BUTTON') {
        setExportData(rows.map((row) => row.original) as TableData[])
      }
    }

    return (
      <>
        {tableProperties && (
          <>
            <ContentTitleWrapper>
              {tableProperties?.tableHeader &&
                tableProperties?.tableHeader?.length > 0 && (
                  <WDLabelPrimaryXLarge>
                    <Label>{tableProperties?.tableHeader}</Label>
                  </WDLabelPrimaryXLarge>
                )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {setSelectedTab && (
                  <CommonTabs
                    selectedTab={selectedTab ? selectedTab : ''}
                    setSelectedTab={setSelectedTab}
                  ></CommonTabs>
                )}
              </div>

              {userAccessDetails.role === vars.adminRole &&
                tableProperties.headerButtonRequired &&
                tableProperties?.buttonLabel &&
                tableProperties?.buttonLabel?.length > 0 && (
                  <>
                    <div></div>
                    <WDButton>
                      <Button
                        type="button"
                        onClick={tableProperties?.createCallback}
                      >
                        {tableProperties?.buttonLabel}
                      </Button>
                    </WDButton>
                  </>
                )}
            </ContentTitleWrapper>
            {tableProperties.tileFilterRequired && (
              <ContentTitleWrapper padding={' 0px 1rem'}>
                <AddButtonWrapper>
                  <TileFilterWrapper>
                    {tableProperties.tileFilterRequired &&
                      tableProperties.tileFilterOptions &&
                      tableProperties.tileFilterOptions.length > 0 &&
                      tableProperties.tileFilterOptions.map(
                        (ele: any, index) => (
                          <TabWrapper key={index} marginRequired={false}>
                            <button
                              onClick={() =>
                                tableProperties?.tileFilterCallback?.(ele)
                              }
                              className={
                                'nav-link' +
                                (tableProperties?.tileFilterValue.value ===
                                ele.value
                                  ? ' selected'
                                  : '')
                              }
                            >
                              <TitleWrapper>{ele?.value}</TitleWrapper>
                            </button>
                          </TabWrapper>
                        )
                      )}
                  </TileFilterWrapper>
                </AddButtonWrapper>
              </ContentTitleWrapper>
            )}
            <ContentInputWrapper>
              {/* {allDataCount && allDataCount > 0 && ( */}

              <>
                <InputWrapper>
                  {tableProperties.globalSearchRequired && (
                    <FilterSubWrapper>
                      <Label>Search</Label>
                      <StyledInput
                        autoComplete={'off'}
                        type="text"
                        value={tableFilter.searchTerm}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setTableFilter((prev) => ({
                            ...prev,
                            searchTerm: e.target.value,
                          }))
                          setTimeout(() => {
                            setFilterPayload({
                              ...filterPayload,
                              offset: 0,
                              searchString: e.target.value,
                            })
                          }, 1000)
                        }}
                        placeholder={
                          tableProperties?.tableHeader
                            ? `Search ${tableProperties?.tableHeader}...`
                            : 'Search...'
                        }
                      />
                    </FilterSubWrapper>
                  )}
                  {tableProperties?.tableHeader !== 'Opportunities' &&
                    source !== 'leadData' && (
                      <>
                        <FilterSubWrapper>
                          <Label>{'Type'}</Label>
                          <SelectDropdown
                            className="w-100"
                            borderColor={DEFAULT_COLORS.Border.Neutral}
                            padding="0rem"
                            options={[initialFilterValue, ...typeArray]}
                            width="9rem"
                            height="2rem"
                            placeholder={'Type'}
                            onChange={(option) => {
                              setTableFilter((prev) => ({
                                ...prev,
                                typeValue: option,
                              }))
                              setFilterPayload({
                                ...filterPayload,
                                offset: 0,
                                typeid: +option.value,
                              })
                            }}
                            value={tableFilter.typeValue}
                          />
                        </FilterSubWrapper>
                        <FilterSubWrapper>
                          <Label>Sub Type</Label>
                          <SelectDropdown
                            className="w-100"
                            borderColor={DEFAULT_COLORS.Border.Neutral}
                            padding="0rem"
                            options={[initialFilterValue, ...subTypeArray]}
                            width="8rem"
                            height="2rem"
                            placeholder={'Sub type'}
                            onChange={(option) => {
                              setTableFilter((prev) => ({
                                ...prev,
                                subTypeValue: option,
                              }))
                              setFilterPayload((prev) => ({
                                ...prev,
                                offset: 0,
                                subtypeid: +option.value,
                              }))
                            }}
                            value={tableFilter.subTypeValue}
                          />
                        </FilterSubWrapper>
                        {tableProperties?.tableHeader !== 'Tasks' && (
                          <FilterSubWrapper>
                            <Label>Date</Label>
                            <input
                              aria-label="Date"
                              type="date"
                              className="date-time"
                              value={
                                source === 'NoteAndTaskData'
                                  ? filterPayload?.notedate
                                    ? filterPayload?.notedate
                                        ?.toString()
                                        ?.substring(0, 10)
                                    : ''
                                  : filterPayload?.startdate
                                  ? filterPayload?.startdate
                                      ?.toString()
                                      ?.substring(0, 10)
                                  : ''
                              }
                              placeholder={`${
                                tableProperties.tableHeader ?? ''
                              } Date`}
                              // resetValue={
                              //   source === 'NoteAndTaskData'
                              //     ? filterPayload.notedate
                              //     : filterPayload.startdate
                              // }
                              onChange={(date) => {
                                if (source === 'NoteAndTaskData') {
                                  setFilterPayload((prev) => ({
                                    ...prev,
                                    offset: 0,
                                    notedate:
                                      new Date(date.target.value)
                                        .toISOString()
                                        .split('T')[0] + 'T00:00:00Z',
                                  }))
                                } else {
                                  setFilterPayload((prev) => ({
                                    ...prev,
                                    offset: 0,
                                    startdate:
                                      new Date(date.target.value)
                                        .toISOString()
                                        .split('T')[0] + 'T00:00:00Z',
                                  }))
                                }
                              }}
                            />
                          </FilterSubWrapper>
                        )}
                        {tableProperties?.tableHeader == 'Tasks' && (
                          <FilterSubWrapper>
                            <Label>Due Date</Label>
                            <input
                              aria-label="Date"
                              type="date"
                              className="date-time"
                              // name="closedate"
                              value={
                                filterPayload?.duedate
                                  ? filterPayload?.duedate
                                      ?.toString()
                                      ?.substring(0, 10)
                                  : ''
                              }
                              placeholder="Due Date"
                              // resetValue={filterPayload.duedate}
                              onChange={(date) => {
                                setFilterPayload((prev) => ({
                                  ...prev,
                                  duedate:
                                    new Date(date.target.value)
                                      .toISOString()
                                      .split('T')[0] + 'T00:00:00Z',
                                }))
                              }}
                            />
                          </FilterSubWrapper>
                        )}
                      </>
                    )}
                  {
                    <FilterSubWrapper>
                      <Label>Repcode</Label>
                      <SelectDropdown
                        className="w-100"
                        borderColor={DEFAULT_COLORS.Border.Neutral}
                        padding="0rem"
                        width="8rem"
                        options={[initialFilterValue, ...repCodeList]}
                        height="2rem"
                        placeholder={'Repcode'}
                        onChange={(option) => {
                          setTableFilter((prev) => ({
                            ...prev,
                            repcodeValue: option,
                          }))
                          setFilterPayload((prev) => ({
                            ...prev,
                            offset: 0,
                            repcode: option.value,
                          }))
                        }}
                        value={{
                          value: filterPayload.repcode,
                          label: filterPayload.repcode
                            ? filterPayload.repcode
                            : 'All',
                        }}
                      />
                    </FilterSubWrapper>
                  }
                  {
                    <ButtonWrapper>
                      {tableProperties.globalSearchRequired ? (
                        <WDButtonPrimaryOutlined>
                          <Button onClick={resetTableFilter} type="button">
                            {'Reset'}
                          </Button>
                        </WDButtonPrimaryOutlined>
                      ) : (
                        <WDButtonDisabled>
                          <Button type="button">{'Reset'}</Button>
                        </WDButtonDisabled>
                      )}
                    </ButtonWrapper>
                  }
                </InputWrapper>
              </>

              <AddButtonWrapper>
                {tableProperties.paginatorRequired ? (
                  <TextWrapper>
                    <WDLabelBlue>
                      <Label>{`Total Records: ${allDataCount ?? 0}`}</Label>
                    </WDLabelBlue>
                  </TextWrapper>
                ) : null}
                {rows.length > 0 ? (
                  <ExportCSV
                    fileName={tableProperties?.tableHeader ?? 'ExportedData'}
                    csvData={rows.map((row) => row.original) as TableData[]}
                    onClickCallBackFn={exportFile}
                    reference={csvLinkRef}
                  >
                    <Icon icon={Icons.ExportLogo} />
                  </ExportCSV>
                ) : null}
              </AddButtonWrapper>
            </ContentInputWrapper>
          </>
        )}

        <Wrapper height={height} ref={scrollReference}>
          {isLoading ? (
            <SpinnerTableWrapper>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
            </SpinnerTableWrapper>
          ) : tableData.length > 0 ? (
            <TableWrapper {...getTableProps()}>
              <TableHeader ref={wrapperRef}>
                {headerGroups.map((headerGroup, key) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()} key={key}>
                    {headerGroup.headers.map((column: any, index) => {
                      return (
                        <TableHeaderCell
                          {...column.getHeaderProps([
                            {
                              style: { width: column.width },
                            },
                          ])}
                          onClick={(event: BaseSyntheticEvent) =>
                            event.stopPropagation()
                          }
                          key={index}
                        >
                          <HeaderWrapper>
                            {column.render('Header')}
                            <SortWrapper>
                              {column.Filter === MultiSelectFilter
                                ? column.render(MultiSelectFilter, {
                                    displayedRows: filteredFlatRows,
                                    filterData,
                                    setSummaryFilters,
                                    tableData,
                                  })
                                : null}
                              {sortingFields &&
                                column.id &&
                                sortingFields.includes(
                                  column.id?.toString()
                                ) && (
                                  <WDButtonTransparent>
                                    <Button
                                      height="0px"
                                      type={'button'}
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                      )}
                                    >
                                      <SvgSort
                                        height={'18'}
                                        width={'18'}
                                        fillColor={DEFAULT_COLORS.Text.Black}
                                      />
                                    </Button>
                                  </WDButtonTransparent>
                                )}
                            </SortWrapper>
                          </HeaderWrapper>
                        </TableHeaderCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>

              <TableBody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  if (
                    row.original?.isVisible == undefined ||
                    row.original?.isVisible
                  ) {
                    prepareRow(row)
                    return (
                      <TableRow {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, i) => {
                          return (
                            <TableDataCell
                              align={
                                cell.column.id == 'amount' ? 'right' : 'left'
                              }
                              wrap={wrapData}
                              {...cell.getCellProps()}
                              key={i}
                            >
                              {toggleTableRowData(cell, row)}
                            </TableDataCell>
                          )
                        })}
                      </TableRow>
                    )
                  }
                })}
              </TableBody>

              {hasFooter ? (
                <TableFooter>
                  {footerGroups.map((group, index) => (
                    <TableRow {...group.getFooterGroupProps()} key={index}>
                      {group.headers.map((column, i) => (
                        <TableDataCell {...column.getFooterProps()} key={i}>
                          {column.render('Footer')}
                        </TableDataCell>
                      ))}
                    </TableRow>
                  ))}
                </TableFooter>
              ) : null}
            </TableWrapper>
          ) : null}
          {tableData.length == 0 && !isLoading && <NoDataFound />}
        </Wrapper>
        {showNewNotes &&
          (editNotesProps?.clients?.length ||
            editNotesProps?.accounts?.length) &&
          allTypes.length > 0 && (
            <CreateNoteComponent
              onTypeChange={(typeId: number) => {
                setSubTypes(getSubTypeByType(typeId))
              }}
              typeData={allTypes}
              subTypeData={subTypes}
              openTask={openTasktoConvert}
              user={user}
              refetchContents={refetchInteraction}
              closeNote={setNewNotes}
              combinedData={[...leadsDetails, ...partyDetails]}
              hostName={appConfig.apiEndPoint}
              accessToken={accessToken}
              noteParams={editNotesProps}
              validationRequired={vars.validationRequired}
            />
          )}
        {showTasks &&
          (editTaskProps?.clients?.length ||
            editTaskProps?.accounts?.length ||
            taskPropsByNote?.clients?.length ||
            taskPropsByNote?.accounts?.length) && (
            <CreateTaskComponent
              onTypeChange={(typeId: number) => {
                setSubTypes(getSubTypeByType(typeId))
              }}
              typeData={allTypes}
              subTypeData={subTypes}
              user={user}
              closeTask={(val: boolean) => {
                setShowTasks(val)
              }}
              combinedData={[...leadsDetails, ...partyDetails]}
              refetchContents={
                refetchInteraction
                // () =>
                // if (source === 'NoteAndTaskData') {
                //   updateNoteData?.()
                // } else {
                //   updateTaskData?.()
                // }
                // null
              }
              // updateTable={() => {
              //   if (source === 'NoteAndTaskData') {
              //     updateNoteData?.()
              //   } else {
              //     updateTaskData?.()
              //   }
              //   setShowTasks(false)
              // }}
              hostName={appConfig.apiEndPoint}
              accessToken={accessToken}
              taskParams={
                source === 'NoteAndTaskData' ? taskPropsByNote : editTaskProps
              }
              validationRequired={vars.validationRequired}
            />
          )}
        {showNewEvents && editEventsProps?.clients?.length && (
          <CreateEventComponent
            onTypeChange={(typeId: number) => {
              setSubTypes(getSubTypeByType(typeId))
            }}
            typeData={allTypes}
            subTypeData={subTypes}
            user={user}
            closeEvent={setNewEvents}
            hostName={appConfig.apiEndPoint}
            accessToken={accessToken}
            combinedData={[...leadsDetails, ...partyDetails]}
            refetchContents={refetchInteraction}
            // updateTable={() => {
            //   updateEventData?.()
            //   setNewEvents(false)
            // }}
            eventParams={editEventsProps}
            validationRequired={vars.validationRequired}
          />
        )}
      </>
    )
  }
)
Table.displayName = 'Table'
export default Table
