import styled from 'styled-components'
import { SIZES, TextSizes } from '../../theme/Sizes'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const CreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES[1]};
  width: 100%;
`
export const EmailSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES[3]};
`
export const TextBoxWrap = styled.div`
  display: block;
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
  input {
    min-width: 16rem;
    padding: 0.5rem 1rem !important;
  }
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SIZES[3]};
`
export const ItemSpaeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: ${SIZES[3]};
`
export const SelectBoxSmall = styled.div`
  min-width: ${SIZES[17]};
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
`
export const CollapseWrapper = styled.div`
  display: flex;
  border-radius: ${SIZES[1]};
  width: 100%;
  gap: ${SIZES[1]};
  cursor: pointer;
  align-items: center;
`
export const CollapseHeaderWrapper = styled.div`
  display: flex;
  flex: 0 1 90%;
  width: 100%;
`
export const CollapseButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 10%;
  justify-content: flex-end;
  width: 100%;
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: ${SIZES[1]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  padding: ${SIZES[1]};
  border: 1px solid ${() => DEFAULT_COLORS.Background.Grey};
`
export const CheckboxWrapper = styled.div`
  display: flex;
  padding: 0.5rem 0;
  gap: 0.5rem;
  align-items: center;
`
export const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Primary};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Neutral};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
  }
`
