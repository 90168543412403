import styled, { css } from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { ButtonProps } from './IButton'

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(btnProps: { justify: string }) =>
    btnProps.justify ?? `space-between`};
  gap: 0.5rem;
  background: ${(btnProps: ButtonProps) =>
    btnProps.hasBorder ? btnProps.bgColor : btnProps.borderLessBgColor};
  color: ${(btnProps: ButtonProps) =>
    btnProps.hasBorder ? btnProps.color : DEFAULT_COLORS.Text.Neutral};
  border: ${(btnProps: ButtonProps) =>
    btnProps.hasBorder ? `1px solid ${btnProps.borderColor}` : `0`};
  cursor: pointer;
  outline: none;
  padding: ${(btnProps: ButtonProps) => btnProps.padding};
  margin: ${(btnProps: ButtonProps) => btnProps.margin};
  height: ${(btnProps: ButtonProps) => btnProps.height ?? ''} !important;
  &:disabled {
    cursor: no-drop;
  }

  ${(btnProps: ButtonProps) =>
    btnProps.hover &&
    css`
      &:hover {
        background-color: ${() => btnProps.hoverBgColor};
        color: ${() => btnProps.hoverTextColor};
      }
    `};

  ${(btnProps: ButtonProps) =>
    btnProps.activeBgColor &&
    css`
      &:active {
        background-color: ${() => btnProps.activeBgColor};
      }
    `};

  ${(btnProps: ButtonProps) =>
    btnProps.focusBrColor &&
    css`
      &:focus {
        border: ${() => btnProps.focusBrColor};
      }
    `};
`
