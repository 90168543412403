import { StyledDiv } from './styles'

export const SvgDownloadFile = (props: { height?: string; width?: string }) => {
  return (
    <StyledDiv>
      <svg
        width={props.width ?? '20'}
        height={props.height ?? '20'}
        viewBox='0 0 17 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.0007 8.33398C12.8238 8.33398 12.6543 8.40422 12.5292 8.52925C12.4042 8.65427 12.334 8.82384 12.334 9.00065V11.6673C12.334 11.8441 12.2637 12.0137 12.1387 12.1387C12.0137 12.2637 11.8441 12.334 11.6673 12.334H2.33398C2.15717 12.334 1.9876 12.2637 1.86258 12.1387C1.73756 12.0137 1.66732 11.8441 1.66732 11.6673V9.00065C1.66732 8.82384 1.59708 8.65427 1.47206 8.52925C1.34703 8.40422 1.17746 8.33398 1.00065 8.33398C0.82384 8.33398 0.654271 8.40422 0.529246 8.52925C0.404222 8.65427 0.333984 8.82384 0.333984 9.00065V11.6673C0.333984 12.1978 0.544698 12.7065 0.919771 13.0815C1.29484 13.4566 1.80355 13.6673 2.33398 13.6673H11.6673C12.1978 13.6673 12.7065 13.4566 13.0815 13.0815C13.4566 12.7065 13.6673 12.1978 13.6673 11.6673V9.00065C13.6673 8.82384 13.5971 8.65427 13.4721 8.52925C13.347 8.40422 13.1775 8.33398 13.0007 8.33398ZM6.52732 9.47398C6.59072 9.53468 6.66548 9.58226 6.74732 9.61399C6.82712 9.64925 6.9134 9.66747 7.00065 9.66747C7.0879 9.66747 7.17418 9.64925 7.25398 9.61399C7.33582 9.58226 7.41058 9.53468 7.47398 9.47398L10.1407 6.80732C10.2662 6.68178 10.3367 6.51152 10.3367 6.33398C10.3367 6.15645 10.2662 5.98619 10.1407 5.86065C10.0151 5.73512 9.84485 5.66459 9.66732 5.66459C9.48978 5.66459 9.31952 5.73512 9.19398 5.86065L7.66732 7.39398V1.00065C7.66732 0.82384 7.59708 0.654271 7.47206 0.529246C7.34703 0.404222 7.17746 0.333984 7.00065 0.333984C6.82384 0.333984 6.65427 0.404222 6.52925 0.529246C6.40422 0.654271 6.33398 0.82384 6.33398 1.00065V7.39398L4.80732 5.86065C4.74516 5.79849 4.67137 5.74918 4.59015 5.71554C4.50894 5.6819 4.42189 5.66459 4.33398 5.66459C4.24608 5.66459 4.15903 5.6819 4.07782 5.71554C3.9966 5.74918 3.92281 5.79849 3.86065 5.86065C3.79849 5.92281 3.74919 5.9966 3.71554 6.07782C3.6819 6.15903 3.66459 6.24608 3.66459 6.33398C3.66459 6.42189 3.6819 6.50894 3.71554 6.59015C3.74919 6.67137 3.79849 6.74516 3.86065 6.80732L6.52732 9.47398Z'
          fill='#2563EB'
        />
      </svg>
    </StyledDiv>
  )
}
