import { StyledDiv } from './styles'

export const SvgContactCard = () => {
  return (
    <StyledDiv>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
      >
        <path
          d="M480-409.231q29.154 0 49.576-20.423 20.423-20.423 20.423-49.577 0-29.153-20.423-49.576Q509.154-549.23 480-549.23t-49.576 20.423q-20.423 20.423-20.423 49.576 0 29.154 20.423 49.577 20.422 20.423 49.576 20.423ZM330.001-261.54h299.998v-14.538q0-21.307-11.461-37.846-11.462-16.538-31.384-25.384-24.847-10.615-51.577-16.423-26.731-5.808-55.577-5.808-28.846 0-55.577 5.808-26.73 5.808-51.577 16.423-19.922 8.846-31.384 25.384-11.461 16.539-11.461 37.846v14.538Zm377.69 161.539H252.309q-30.308 0-51.308-21t-21-51.308v-615.382q0-30.308 21-51.308t51.308-21h299.229l228.461 228.461v459.229q0 30.308-21 51.308t-51.308 21Zm0-59.999q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463V-606L526-800H252.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v615.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382ZM240-160V-800v640Z"
          fill="#2563EB"
        />
      </svg>
    </StyledDiv>
  )
}
