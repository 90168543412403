import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  color: ${DEFAULT_COLORS.Text.Neutral};
  gap: 0.25rem;
  width: fit-content;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  height: 2rem;
  padding: 0.25rem;
  &:disabled {
    background-color: ${DEFAULT_COLORS.Background.NeutralDisabled};
  } 
`
export const TextWrapper = styled.div`
  width: 100%;
  height: 2rem;
  padding: 0.25rem 1rem;
  background: ${DEFAULT_COLORS.Background.NeutralWeak};
  white-space: nowrap;
`
