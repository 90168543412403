import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

interface CardProps {
  children?: React.ReactNode
}

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: center;
  border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralWeak};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  box-shadow: ${() => DEFAULT_COLORS.BoxShadow.Shadow};
  border-radius: ${SIZES[2]};
`
export const StyledCardBlue = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  border-radius: ${SIZES[1]};
  background: ${() => DEFAULT_COLORS.Background.NeutralMedium};
`
export const StyledCardBlueTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: ${SIZES[1]} ${SIZES[1]} 0 0;
  background: ${() => DEFAULT_COLORS.Background.NeutralMedium};
  padding: ${SIZES[1]} ${SIZES[3]};
  min-height: 3.125rem;
  gap: 0.5rem;
`
export const WDCard = ({ children }: CardProps) => {
  return <StyledCard>{children}</StyledCard>
}
export const WDCardBlue = ({ children }: CardProps) => {
  return <StyledCardBlue>{children}</StyledCardBlue>
}
export const WDCardBlueTop = ({ children }: CardProps) => {
  return <StyledCardBlueTop>{children}</StyledCardBlueTop>
}
