import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { SIZES} from '../../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 60%;
  justify-content: center;
  align-self: center;
  height: 90vh;
`
export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 1 100%;
  flex-flow: column nowrap;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${DEFAULT_COLORS.BoxShadow.BackgroundShadow};
  z-index: 999;
`
export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`
export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0.5rem 0.5rem;
  width: 100%;
  gap: 0.5rem;
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
  box-shadow: 0px 2px 6px 0.25px
    ${() => DEFAULT_COLORS.BoxShadow.StyledCardShadow};
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  padding: ${SIZES[3]};
  z-index: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`
export const PartyWrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: start;
  justify-content: space-between;
  gap: 0.5rem;
`
export const ClientIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
export const CrossWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const OverlayTitleWrapper = styled.div`
  background: ${DEFAULT_COLORS.Background.Neutral};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`
export const CheckboxWrapper = styled.div`
  display: flex;
  padding: 0.5rem 0;
  gap: 0.5rem;
  align-items: center;
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const CreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SIZES[1]};
  input {
    height: ${SIZES[5]} !important;
  }
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SIZES[3]};
  width: 100%;
`
export const TextBoxWrap = styled.div`
  display: block;
  input {
    width: 80px;
    height: ${SIZES[7]} !important;
  }
`
export const BtnWrapper = styled.div`
  float: right;
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const ItemsWrp = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
`
export const WrapperRow = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
`
export const SelectBoxSmall = styled.div`
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
`