export const leadColumnData = [
  {
    Header: 'Name',
    accessor: 'firstname',
  },
  {
    Header: 'CRM Status',
    accessor: 'status',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Rel Segment',
    accessor: 'category',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Email Id',
    accessor: 'email',
  },
  {
    Header: 'Rep Code',
    accessor: 'advisor',
  },
  // Temp Disable 
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  // },
]
