import { useQuery } from '@apollo/client'
import { useContext } from 'react'
import Table from '../../components/table/Table'
import { WDLabelPrimaryLarge } from '../../components/ui/WDLabel'
import { GET_BIRTHDAYS } from '../../services/graphQl/CustomerGraphQl'
import { ContentTitleWrapper } from '../../shared/GlobalStyle'
import { ItemsWrapper } from './style'
import Lottie from 'lottie-react'
import LottieLoader from '../../assets/lottiefiles/loader.json'
import UserContext from '../../services/user/UserContext'
import { LottieWrapperMedium } from '../loader/styles'

const DashboardUpcomingBirthday = () => {
  const { user } = useContext(UserContext)
  const { data: birthDayList, loading: birthdayLoading } = useQuery(
    GET_BIRTHDAYS,
    {
      variables: { adUserName: user.username },
    }
  )

  const columnData = [
    {
      Header: 'Party/Lead',
      accessor: 'name',
    },
    {
      Header: 'Birth Day',
      accessor: 'to_char',
    },
  ]

  return (
    <>
      <ContentTitleWrapper>
        <WDLabelPrimaryLarge>{'Upcoming Birthday'}</WDLabelPrimaryLarge>
      </ContentTitleWrapper>
      <ItemsWrapper>
        {birthdayLoading ? (
          <LottieWrapperMedium>
            <Lottie animationData={LottieLoader} loop={true} />
          </LottieWrapperMedium>
        ) : (
          <Table
            tableColumns={
              birthDayList &&
              birthDayList.lead_vw_upcomingbirthdaylist &&
              birthDayList.lead_vw_upcomingbirthdaylist.length > 0
                ? columnData
                : []
            }
            allData={
              birthDayList &&
              birthDayList.lead_vw_upcomingbirthdaylist &&
              birthDayList.lead_vw_upcomingbirthdaylist.length > 0
                ? birthDayList.lead_vw_upcomingbirthdaylist
                : []
            }
          />
        )}
      </ItemsWrapper>
    </>
  )
}

export default DashboardUpcomingBirthday
