/*
Component: Interactions
Created by: Anil
Created on: 04/04/2024
Desc: This component has combined data of event, tasks, notes
Those data will be arranged by date with timeline design.
*/

import moment from 'moment'
import { useEffect, useState } from 'react'
import DataCard from '../../../composites/dataCards/DataCard'
import GraphQLLoader from '../../../composites/loader/GraphQLLoader'
import NoDataFound from '../../../composites/noDataFound/NoDataFound'
import { Wrapper } from '../styles'
import { dateFormate, getTimezone } from '../../../assets/utils/CommonFunctions'

const Interactions = ({
  allData,
  interactionLoading,
  setId,
}: {
  allData: any
  interactionLoading: boolean
  callback?: () => void
  setId: (id: number, tranType: number) => void
}) => {
  //states

  const [InteractionData, setInteractionData] = useState<Map<any, any>>()
  useEffect(() => {
    if (allData) {
      // combine all data in single const. but with different key
      const interData: any = [...(allData?.note_vw_wealth_notes_client ?? [])]

      const timezone = getTimezone()

      interData.map((item: any) => {
        item.createdon = dateFormate(item.createdon.toString(), false)
        item.timezone = timezone
      })

      const combinedData = [...interData]
      const sortedData = combinedData.sort(
        (a, b) =>
          parseInt(moment(b.createdon).format('YYYYMMDDHHmmss')) -
          parseInt(moment(a.createdon).format('YYYYMMDDHHmmss'))
      )

      if (sortedData) {
        const dates: any[] = [
          ...new Set(
            sortedData.map((item) => {
              return moment(item.createdon).format('YYYYMMDD')
            })
          ),
        ]

        const finalList = dates.map((item) => {
          const d: any = []
          sortedData?.forEach((obj: any) => {
            if (parseInt(moment(obj.createdon).format('YYYYMMDD')) == item) {
              //17
              d.push(
                Object.fromEntries([
                  [
                    'id',
                    `${
                      obj.trantype == 1
                        ? 'note'
                        : obj.trantype == 2
                        ? 'task'
                        : 'event'
                    }${obj.id}`,
                  ],
                  ['dataId', obj.id],
                  ['tranType', obj.trantype],
                  [
                    'type',
                    `${
                      obj.trantype == 1
                        ? 'note'
                        : obj.trantype == 2
                        ? 'task'
                        : 'event'
                    }`,
                  ],
                  ['isVisible', false],
                  ['timezone', obj.timezone],
                  ['desc', obj.detail],
                  ['subject', obj.subject],
                  ['Owner', obj.ownername],
                  ['date', moment(obj.createdon).format('MM/DD/YYYY hh:mma')],
                ])
              )
            }
          })

          return { date: item, data: d }
        })

        const sortedFinalList = finalList.sort(
          (a, b) => parseInt(b.date) - parseInt(a.date)
        )

        const finalData: Map<any, any> = new Map()
        sortedFinalList.forEach((obj) => {
          finalData.set(obj.date, obj.data)
        })
        setInteractionData(finalData)
      }
    }
  }, [allData])

  return (
    <Wrapper>
      {interactionLoading ? (
        <GraphQLLoader />
      ) : InteractionData && InteractionData?.size ? (
        <DataCard setId={setId} OrgData={InteractionData} />
      ) : (
        !interactionLoading && <NoDataFound />
      )}
    </Wrapper>
  )
}

export default Interactions
