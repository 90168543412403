import ErrorComposite from '../../composites/errorComposite/ErrorComposite'
import { RootContainer } from './styles'

interface IErrorProps {
  error: IErrorProps1
}
interface IErrorProps1 {
  error: IErrorProps2
}
interface IErrorProps2 {
  response: { status: number }
  message?: string
}
const ErrorPage = ({ error }: IErrorProps) => {
  return (
    <>
      <RootContainer>
        <ErrorComposite
          errorCode={error?.error?.response?.status}
          errorMessage={error?.error?.message}
        />
      </RootContainer>
    </>
  )
}

export default ErrorPage
