import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { useIdleTimer } from 'react-idle-timer'
import AppRoutes from './AppRoutes'
import { loginRequest } from './settings/authConfig'
import { useContext } from 'react'
import UserContext from './services/user/UserContext'

// type AppProps = {
//   pca: IPublicClientApplication
// }

function App() {
  const { setUser } = useContext(UserContext)
  const authRequest = {
    ...loginRequest,
  }
  const { instance } = useMsal()

  const getAdIdFromEmail = (email?: string) => {
    if (email) {
      return email.split('@wedbush.com')[0]
    } else {
      return ''
    }
  }

  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      instance.setActiveAccount(account)
      setUser({
        ...account,
        name: account?.idTokenClaims?.name ? account?.idTokenClaims?.name : '',
        username: getAdIdFromEmail(account?.idTokenClaims?.Username as string),
      } as AccountInfo)
    }
  })

  const onIdle = () => {
    sessionStorage.clear()
    instance.logout()
  }

  useIdleTimer({
    onIdle,
    timeout: 1200000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <AppRoutes />
    </MsalAuthenticationTemplate>
  )
}

export default App
