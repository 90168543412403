import { DEFAULT_COLORS } from '../../theme/Colors'
import Button from '../button/Button'
import { SvgCalendar } from '../svg/SvgCalendar'
import { InputBoxProps } from './ICustomCalendar'
import { InputWrapper, StyledInput } from './styles'

const InputBox = ({
  name,
  placeholder,
  value,
  onClick,
  disabled,
}: InputBoxProps) => {
  return (
    <InputWrapper>
      <StyledInput
        autoComplete={'off'}
        readOnly
        onClick={onClick}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={() => value}
        disabled={disabled}
      />
      <Button
        bgColor={'transparent'}
        color={'transparent'}
        type="button"
        borderColor={'transparent'}
        prefixedIcon={
          <SvgCalendar fillColor={DEFAULT_COLORS.Icons.Neutral}></SvgCalendar>
        }
        onClick={onClick}
      />
    </InputWrapper>
  )
}

export default InputBox
