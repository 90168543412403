import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { SIZES } from '../../../theme/Sizes'

export const MasterWrapper = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  z-index: 99;
  overflow: auto;
  width: 100%;
  background-color: #fff;
  padding: 0px;
  box-shadow: 0px 6px 7px 2px rgb(0 0 0 / 29%);
`

export const FormContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
`

export const SearchTerm = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
  color: #000;
  gap: 0.25rem;
  position: relative;
  width: 100%;
  input {
    width: 100% !important;
    padding-right: 2rem;
    background: ${() => DEFAULT_COLORS.Background.Neutral};
    border: 1px solid
      ${(props: { error: string }) =>
        props.error
          ? DEFAULT_COLORS.Border.DangerStrong
          : DEFAULT_COLORS.Border.Iron};
    &:hover {
      border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      color: ${() => DEFAULT_COLORS.Icons.NeutralHover};
    }
    &:focus {
      border-color: ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      color: ${() => DEFAULT_COLORS.Text.Black};
    }
    /* &:active {
      border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
    } */
  }
`

export const LottieWrapperSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SIZES[1]};
  width: 3rem;
  height: 4rem;
  margin: 0 auto;
`
