import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store/store'

type InitialLeadPartyState = {
  leadContents: any[]
  partyContents: any[]
  typeData: any[]
  subTypeData: any[]
  leadPartyLoader: boolean
}
const initialState: InitialLeadPartyState = {
  leadContents: [],
  partyContents: [],
  typeData: [],
  subTypeData: [],
  leadPartyLoader: true,
}

const leadPartySlice= createSlice({
  name: 'leadParty',
  initialState,
  reducers: {
    setLeadContents: (
      state: InitialLeadPartyState,
      action: PayloadAction<any>
    ) => {
      const leadValues: any = []
      if (action.payload && action.payload.lead_leads) {
        action.payload.lead_leads.forEach((lead: any) => {
          const leadValue = {
            firstname:
              lead.type === 'Business'
                ? lead.businessname
                : lead.type === 'Trust'
                  ? lead.trustname
                  : lead.firstname +
                  ' ' +
                  (lead.middlename ? lead.middlename + ' ' : '') +
                  lead.lastname,
            status: lead.status,
            id: lead.id,
            email:
              lead.emailList &&
                Array.isArray(lead.emailList) &&
                lead.emailList.length &&
                lead.emailList[0].emailid
                ? lead.emailList[0].emailid
                : '',
            phone:
              lead.contactList &&
                Array.isArray(lead.contactList) &&
                lead.contactList.length &&
                lead.contactList[0].phonenumber
                ? lead.contactList[0].phonenumber
                : '',
            category: lead.accounttier ?? '',
            advisor:
              lead.Lead_Permissions.find((e: any) => {
                return e.type == 'Primary Owner'
              })?.repcode ?? '--', //lead.advisor,
            type: lead.type ?? '',
            dateofbirth: lead.dateofbirth,
          }
          leadValues.push(leadValue)
        })
      }

      state.leadContents = leadValues
    },
    setPartyContents: (
      state: InitialLeadPartyState,
      action: PayloadAction<any>
    ) => {
      const partyValues: any = []
      if (action.payload) {
        action.payload.advisor_vw_leadpartyvisibility.forEach((client: any) => {
          const partyValue = {
            firstname: client?.party_details?.PartyFullName ?? '',
            status: 'Client',
            email: client?.party_details?.PartyEmail ?? '',
            id: client?.party_details?.PartyId ?? 0,
            phone: client?.party_details?.PartyContact ?? '',
            category: client?.party_details?.accounttier ?? '',
            advisor: client?.repcode ?? '',
            type: client?.party_details?.PartyTypeName ?? '',
          }
          partyValues.push(partyValue)
        })
      }
      state.partyContents = partyValues
    },
    setLeadPartyLoader: (
      state: InitialLeadPartyState,
      action: PayloadAction<any>
    ) => {
      state.leadPartyLoader = action.payload
    },
    setTypeData: (
      state: InitialLeadPartyState,
      action: PayloadAction<any>
    ) => {
      state.typeData = action.payload
    },
    setSubTypeData: (
      state: InitialLeadPartyState,
      action: PayloadAction<any>
    ) => {
      state.subTypeData = action.payload
    },
  },
})

export default leadPartySlice.reducer
export const { setLeadContents, setPartyContents, setLeadPartyLoader, setTypeData, setSubTypeData } =
  leadPartySlice.actions
export const leadsData = (store: RootState) => store.leadParty.leadContents
export const partiesData = (store: RootState) => store.leadParty.partyContents
export const leadPartyLoader = (store: RootState) =>
  store.leadParty.leadPartyLoader
export const typeData = (store: RootState) =>
  store.leadParty.typeData
export const subTypeData = (store: RootState) =>
  store.leadParty.subTypeData

export const getLeadsData = (data: any) => async (dispatch: any) => {
  dispatch(setLeadContents(data))
}
export const getPartyData = (data: any) => async (dispatch: any) => {
  dispatch(setPartyContents(data))
}
export const setType = (data: any) => async (dispatch: any) => {
  dispatch(setTypeData(data))
}
export const setSubType = (data: any) => async (dispatch: any) => {
  dispatch(setSubTypeData(data))
}
