import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { SvgClose } from '../../../components/svg/SvgClose'
import {
  WDButton,
  WDButtonTransparentWithoutHeight,
} from '../../../components/ui/WDButton'
import {
  WDLabelBold,
  WDSubFormLabelHeading,
} from '../../../components/ui/WDLabel'
import ClientInfo from '../../../composites/opportunitiesDetails/ClientInfo'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { useState, useEffect, useContext } from 'react'
import {
  ContentFormSectionWrapper,
  ContentFormWrapper,
  ContentWrapper,
  FooterSectionEndWrapper,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
  OverlayWrapper,
  SelectItem,
  Wrapper,
} from './styles'
import { ClientSearchByNameResponseInterface } from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import { OptionProps } from '../../../composites/notes/INotes'
import {
  GET_LEAD_RELATIONSHIP,
  INSERT_PARTY_RELATIONSHIP,
} from '../../../services/graphQl/CustomerGraphQl'
import { getLeadRelationshipInterface } from '../../../services/graphQl/Interfaces/IContact'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CreateRelationshipValue,
  PartyDataProps,
} from '../partyRelationship/IPartyRelationship'
import { LeadInfoProps } from '../../createContactList/ICreateLead'
import SetToastContext from '../../../services/toast/SetToastContext'
import { ClientDetailsResponseInterface } from '../../../services/clientContext/IClientsContext'
import { RelationshipProps } from '../relationship/LeadRelationship'
import { vars } from '../../../assets/utils/CommonFunctions'

interface PartyRelationshipProps {
  setShowRelationship: (value: boolean) => void
  leadDetails?: LeadInfoProps
  partyDetails?: ClientDetailsResponseInterface
  updateRelData?: () => void
}

const AddMember = ({
  setShowRelationship,
  leadDetails,
  updateRelData,
}: PartyRelationshipProps) => {
  const { setToast } = useContext(SetToastContext)
  const [relationFrom, setRelationFrom] = useState<OptionProps[]>([])
  const [relationTo, setRelationTo] = useState<OptionProps[]>([])
  const [relationFromError, setRelationFromError] = useState('')
  const [relationToError, setRelationToError] = useState('')

  const [partyData, setPartyData] = useState<PartyDataProps>(
    CreateRelationshipValue
  )
  const [selectedClient, setSelectedClient] =
    useState<ClientSearchByNameResponseInterface>(
      {} as ClientSearchByNameResponseInterface
    )
  const updateSelectedClient = (val: ClientSearchByNameResponseInterface) => {
    setRelationFromError('')
    setRelationToError('')
    setSelectedClient(val)
  }

  const addNewClient = () => {
    setSelectedClient({} as ClientSearchByNameResponseInterface)
  }

  const [relationshipFrom, { data: relationFromResult }] =
    useLazyQuery<getLeadRelationshipInterface>(GET_LEAD_RELATIONSHIP)

  useEffect(() => {
    relationshipFrom()
  }, [relationshipFrom])

  useEffect(() => {
    if (relationFromResult) {
      const updatedList: OptionProps[] = []
      relationFromResult.lead_relationship.forEach((val) => {
        updatedList.push({
          label: val.relationship.toString(),
          value: val.id.toString(),
        })
      })
      setRelationFrom(updatedList)
      setRelationTo(updatedList)
    }
  }, [relationFromResult])

  const updateOnData = (dataObj: Partial<PartyDataProps>) => {
    setPartyData((prev) => {
      return { ...prev, ...dataObj }
    })
  }
  const [addNewMemberData, { data }] = useMutation(INSERT_PARTY_RELATIONSHIP)

  const addNewMemberFuc = () => {
    setRelationFromError('')
    setRelationToError('')

    let isValid = true
    if (!partyData.relationshipFrom) {
      setRelationFromError('RelationFrom is required.')
      isValid = false
    }
    if (!partyData.relationshipTo) {
      setRelationToError('RelationTo is required.')
      isValid = false
    }
    if (vars.validationRequired && !isValid) {
      setToast(false, 'Please enter mandatory detail!')
      return
    }

    const payload1 = {
      relationshipfrom: leadDetails?.id.toString() ?? '',
      relationshipfromname: leadDetails?.firstName ?? '',
      relationshipto: selectedClient?.id.toString() ?? '',
      realtionshiptoname: selectedClient?.firstname ?? '',
      isparty: leadDetails?.leadStatus == 'Client' ? true : false,
      sourceid: leadDetails?.id,
      relationshipid: +partyData.relationshipFrom,
      realtionshipname: partyData.realtionshipFName,
    }
    const payload2 = {
      relationshipfrom: selectedClient?.id.toString() ?? '',
      relationshipfromname: selectedClient?.firstname ?? '',
      relationshipto: leadDetails?.id.toString() ?? '',
      realtionshiptoname: leadDetails?.firstName ?? '',
      sourceid: leadDetails?.id,
      relationshipid: +partyData.relationshipTo,
      realtionshipname: partyData.realtionshipTName,
      isparty: selectedClient?.status == 'Client' ? true : false,
    }
    const payload: RelationshipProps[] = [payload1, payload2]
    addNewMemberData({
      variables: { payload: payload },
    })
  }

  useEffect(() => {
    if (data?.insert_lead_partyrelationship?.affected_rows > 0) {
      setToast(true, 'Relationship is created..')
      setShowRelationship(false)
      updateRelData?.()
      setSelectedClient({} as ClientSearchByNameResponseInterface)
    }
  }, [data])
  // const handleRelationFromChange = (value: string) => {
  //   const filteredRelations = relationFrom.filter(
  //     (item) => item.value !== value
  //   )
  //   setRelationTo(
  //     filteredRelations.map((item) => ({
  //       label: item.label,
  //       value: item.value,
  //     }))
  //   )
  // }

  const getFullNameForProfile = () => {
    if (leadDetails?.type === 'Business') {
      return (leadDetails && leadDetails?.businessName) ?? ''
    } else if (leadDetails?.type === 'Trust') {
      return (leadDetails && leadDetails?.trustName) ?? ''
    } else {
      const firstName = (leadDetails && leadDetails?.firstName) ?? ''
      const lastname = (leadDetails && leadDetails?.lastName) ?? ''
      return firstName + ' ' + lastname
    }
  }

  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <HeaderWrapper>
            <WDSubFormLabelHeading>
              <Label>{'Add Family Member'}</Label>
            </WDSubFormLabelHeading>
            <WDButtonTransparentWithoutHeight>
              <Button type="button" onClick={() => setShowRelationship(false)}>
                <SvgClose fillColor={DEFAULT_COLORS.Icons.Black} />
              </Button>
            </WDButtonTransparentWithoutHeight>
          </HeaderWrapper>
          <ContentWrapper>
            <ContentFormWrapper>
              <ContentFormSectionWrapper>
                <Label>{'Contact name'}</Label>
                <ClientInfo
                  header={selectedClient?.type}
                  clientName={selectedClient?.firstname}
                  clientId={selectedClient?.id}
                  onCancel={addNewClient}
                  type={selectedClient?.type ? selectedClient?.type : ''}
                  updateSelectedClient={updateSelectedClient}
                />
              </ContentFormSectionWrapper>
            </ContentFormWrapper>
            {selectedClient?.id && (
              <>
                <ContentFormWrapper align={'center'} direction={'row'}>
                  <WDLabelBold>{getFullNameForProfile()}</WDLabelBold>
                  &nbsp; is &nbsp;
                  <SelectItem error={relationFromError}>
                    <SelectDropdown
                      display="inline"
                      width="200px"
                      height={'2.5rem'}
                      borderColor={DEFAULT_COLORS.Border.Neutral}
                      options={relationFrom}
                      placeholder={'Relationship'}
                      onChange={(value: { value: string; label: string}) => {
                        updateOnData({
                          relationshipFrom: value.value,
                          realtionshipFName: value.label,
                        })
                        // handleRelationFromChange(value.value)
                      }}
                    />
                  </SelectItem>
                  &nbsp; of &nbsp;
                  <WDLabelBold>
                    {`${selectedClient?.firstname ?? '--'}`}
                  </WDLabelBold>
                  &nbsp; and,
                </ContentFormWrapper>
                <ContentFormWrapper align={'center'} direction={'row'}>
                  <WDLabelBold>
                    {`${selectedClient?.firstname ?? '--'}`}
                  </WDLabelBold>
                  &nbsp; is &nbsp;
                  <SelectItem error={relationToError}>
                    <SelectDropdown
                      display="inline"
                      height={'2.5rem'}
                      width="200px"
                      borderColor={DEFAULT_COLORS.Border.Neutral}
                      options={relationTo}
                      placeholder={'Relationship'}
                      onChange={(value) =>
                        updateOnData({
                          relationshipTo: value.value,
                          realtionshipTName: value.label,
                        })
                      }
                    />
                  </SelectItem>
                  &nbsp; of &nbsp;
                  <WDLabelBold>
                    {/* {`${leadDetails?.firstName} ${leadDetails?.lastName}`} */}
                    {getFullNameForProfile()}
                  </WDLabelBold>
                </ContentFormWrapper>
              </>
            )}
          </ContentWrapper>
          <FooterWrapper>
            <FooterSectionEndWrapper>
              {selectedClient?.id && (
                <WDButton>
                  <Button
                    onClick={addNewMemberFuc}
                    type="button"
                    bgColor={DEFAULT_COLORS.Background.DangerStrong}
                  >
                    {'Add Family Member'}
                  </Button>
                </WDButton>
              )}
            </FooterSectionEndWrapper>
          </FooterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}
export default AddMember
