import Button from '../../components/button/Button'
import Divider from '../../components/divider/Divider'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { MenuItemsProps, PlusMenuProps } from './IPlusMenu'
import { LabelWrapper, PlusMenuWrapper, Wrapper } from './styles'
const PlusMenu = ({
  plusMenuData,
  setTogglePlusMenu,
  setThompsonSearch,
  setNewNotes,
  setNewEvent,
  setShowTask,
  setShowOpportunity,
}: PlusMenuProps) => {
  const routeToNewNote = (menuName: string) => {
    if (menuName == 'Note') setNewNotes(true)
    if (menuName == 'Task') setShowTask(true)
    if (menuName == 'Event') setNewEvent(true)
    if (menuName == 'Opportunity') setShowOpportunity(true)
    if (menuName == 'Contact') {
      setThompsonSearch(true)
    }
  }
  return (
    <PlusMenuWrapper minWidth={plusMenuData.length === 3 ? '8rem' : '9rem'}>
      {plusMenuData.map((menu: MenuItemsProps, index: number) => {
        return (
          <Wrapper
            key={index}
            onClick={() => {
              setTogglePlusMenu(false)
              routeToNewNote(menu.name)
            }}
          >
            <WDButtonTransparent>
              <Button type="button">
                <LabelWrapper>{menu.name}</LabelWrapper>
              </Button>
            </WDButtonTransparent>
            {plusMenuData.length - 1 != plusMenuData.indexOf(menu) && (
              <Divider
                thickness={'1px'}
                bgColor={DEFAULT_COLORS.Border.NeutralWeak}
                horizontal={true}
              />
            )}
          </Wrapper>
        )
      })}
    </PlusMenuWrapper>
  )
}
export default PlusMenu
