import { createContext, ReactNode, useMemo, useState } from 'react'

export interface DashboardContextInterface {
  loading: boolean
  dashboardType: string
  setDashboardType: (value: string) => void
  categoryName: string
  setCategoryName: (value: string) => void
  refetchFlag: boolean
  loadingHome: boolean
  isAuthorized: boolean
  setRefetchFlag: (value: boolean) => void
  setLoadingHome: (value: boolean) => void
  setIsAuthorized: (value: boolean) => void
  refetchInteractionsFlag: boolean
  setRefetchInteractionsFlag: (value: boolean) => void
}

const DashboardContext = createContext<DashboardContextInterface>(
  {} as DashboardContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const DashboardContextProvider = ({ children }: ContextProps) => {
  const [loading] = useState(false)
  const [loadingHome, setLoadingHome] = useState<boolean>(true)
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false)
  const [categoryName, setCategoryName] = useState<string>('')
  const [dashboardType, setDashboardType] = useState('Client')
  const [refetchFlag, setRefetchFlag] = useState(false)
  const [refetchInteractionsFlag, setRefetchInteractionsFlag] = useState(false)

  const value = useMemo(
    () => ({
      loading,
      loadingHome,
      isAuthorized,
      dashboardType,
      setDashboardType,
      categoryName,
      setCategoryName,
      refetchFlag,
      setRefetchFlag,
      refetchInteractionsFlag,
      setIsAuthorized,
      setLoadingHome,
      setRefetchInteractionsFlag,
    }),
    [
      loading,
      dashboardType,
      categoryName,
      refetchFlag,
      refetchInteractionsFlag,
      loadingHome,
      isAuthorized,
    ]
  )

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardContext
