import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { CheckboxProps } from './ICheckbox'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const StyledCheckbox = styled.input<CheckboxProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${() => DEFAULT_COLORS.Border.BlueberryBlue};
  accent-color: ${() => DEFAULT_COLORS.Background.FunBlue};
  outline: none;
  &:disabled {
    background: ${() => DEFAULT_COLORS.Background.Smalt};
    border-radius: 4px;
    cursor: no-drop;
  }
  &:checked {
    accent-color: ${() => DEFAULT_COLORS.Background.FunBlue};
    border-radius: 4px;
  }
`
export const StyledLabel = styled.label<CheckboxProps>`
  &:disabled {
    color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
    cursor: no-drop;
  }
`
