const appConfig = {
  envName: 'DEV',
  appName: 'CRM Web App',
  appDomain: 'https://wealthcrmui.dev.wedbush.com/',
  apiEndPoint: 'https://dev.api.wedbush.com',
  b2cDomain: 'https://wedbushclientsdev.b2clogin.com/wedbushclientsdev.onmicrosoft.com/B2C_1A_SUSI_ADFED',
  b2cClientId: '9f15475c-eed8-4746-a32b-7cd5aa286996',
  b2cScope: 'https://wedbushclientsdev.onmicrosoft.com/3a0d6004-ab60-4056-b6eb-3d14aa33b105/api_access',
  b2cSecret: 'inu8Q~n4BwkTJrB0Y3xHI8wSxYY_WRZDCBokyc04',
  hasuraEndpoint: 'https://dev.api.wedbush.com/hasura-graphql',
}

export default appConfig


