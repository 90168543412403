import {
  CountWrapper,
  MiddleContainer,
  TabContainer,
  TitleWrapper,
} from './styles'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import Divider from '../../../components/divider/Divider'
import FinancialDetails from './FinancialDetails'
import ImportantInformationDetails from './ImportantInformationDetails'
import { LeadInfoProps } from '../../createContactList/ICreateLead'

type ProfileSubTabProps = {
  selectedTab: string
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>
  leadDetails?: LeadInfoProps
  updateData?: (fields: Partial<LeadInfoProps>) => void
  updateLeadInfoData?: (fields: Partial<LeadInfoProps>) => void
}

function ProfileSubTab({
  selectedTab,
  setSelectedTab,
  leadDetails,
  updateData,
  updateLeadInfoData,
}: ProfileSubTabProps) {
  const tabChange = (value: string) => {
    setSelectedTab(value)
  }
  return (
    <TabContainer>
      <MiddleContainer>
        <>
          <button
            onClick={() => tabChange('Financials')}
            className={
              'nav-link' + (selectedTab === 'Financials' ? ' selected' : '')
            }
          >
            <TitleWrapper>{'Financials'}</TitleWrapper>
          </button>
          <button
            onClick={() => tabChange('Important Information')}
            className={
              'nav-link' +
              (selectedTab === 'Important Information' ? ' selected' : '')
            }
          >
            <TitleWrapper>
              {'Important Information'}
              <CountWrapper></CountWrapper>
            </TitleWrapper>
          </button>
        </>
        <Divider
          horizontal={true}
          thickness={'1px'}
          bgColor={DEFAULT_COLORS.Border.NeutralWeak}
        />
      </MiddleContainer>
      {selectedTab === 'Financials' && (
        <FinancialDetails
          updateData={updateData}
          state={leadDetails}
          updateLeadInfoData={updateLeadInfoData}
        />
      )}
      {selectedTab === 'Important Information' && (
        <ImportantInformationDetails
          state={leadDetails}
          updateLeadInfoData={updateLeadInfoData}
        />
      )}
    </TabContainer>
  )
}
export default ProfileSubTab
