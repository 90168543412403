import Lottie from 'lottie-react'
import { LoaderTextWrapper, LottieWrapper, SpinnerWrapper } from './styles'
import LottieLoader from '../../assets/lottiefiles/loader.json'

interface LoaderProps {
  message?: string
  position?: string
}

const Loader = ({ message, position }: LoaderProps) => {
  return (
    <SpinnerWrapper position={position}>
      <LottieWrapper>
        <Lottie animationData={LottieLoader} loop={true} />
      </LottieWrapper>
      {message && <LoaderTextWrapper>{message}</LoaderTextWrapper>}
    </SpinnerWrapper>
  )
}

export default Loader
