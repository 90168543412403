import React, { createContext, useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { protectedResources } from '../settings/authConfig'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import appConfig from '../settings/appConfig'
export interface AuthCtxProviderInterface {
  accessToken: string
  userAccessDetails: UserAccessInterface
  client: any
  setUserAccessDetails: (userAccessDetails: UserAccessInterface) => void
}

export interface UserAccessInterface {
  repAccess?: string[] 
  accessLevels?: string
  role: string
}

export const AuthContext = createContext<AuthCtxProviderInterface>({
  accessToken: '',
  userAccessDetails: {
    repAccess: undefined,
    accessLevels: undefined,
    role: '',
  },
  setUserAccessDetails: () => null,
  client: {},
})

interface AuthCtxProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

function AuthContextProvider({ children }: AuthCtxProviderProps) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accessToken, setAccessToken] = useState<string>('')
  const [client, setClient] = useState<any>()

  const [userAccessDetails, setUserAccessDetails] = useState<{
    accessLevels?: string
    repAccess?: string[]
    role: string
  }>({
    //accessLevels: [],
    //repAccess: [],
    role: '',
  })

  const authMiddleware = (authToken: any) =>
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      if (authToken) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
      }

      return forward(operation)
    })

  const setValues = (token: string) => {
    const httpLink = new HttpLink({
      uri: appConfig.hasuraEndpoint,
    })

    const authLink = setContext((_, { headers }) => {
      const headerValue = {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
      return headerValue
    })

    const client = new ApolloClient({
      cache: new InMemoryCache({
        addTypename: false,
      }),
      link: ApolloLink.from([authMiddleware(token), authLink.concat(httpLink)]),
    })

    setClient(client)
  }
  const b2cAuth = async () => {
    if (account && inProgress === 'none' && !accessToken) {
      await instance
        .acquireTokenSilent({
          scopes: protectedResources.api.scopes,
          account: account,
        })
        .then(async (response) => {
          setAccessToken(response?.accessToken)
          setValues(response?.accessToken)
        })
    }
  }
  useEffect(() => {
    b2cAuth()
  }, [account, inProgress, instance])

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        userAccessDetails: userAccessDetails,
        client: client,
        setUserAccessDetails
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
