import React, { useContext, useEffect, useState } from 'react'
import {
  BodyWrapper,
  BtnWrapper,
  CheckboxWrapper,
  CreateWrapper,
  CrossWrapper,
  FooterWrapper,
  HeaderWrapper,
  ItemWrapper,
  MainWrapper,
  MasterWrapper,
  OverlayTitleWrapper,
  OverlayWrapper,
  PartyWrapper,
  TextBoxWrap,
  Wrapper,
  WrapperRow,
} from './style'
import { WDSubFormLabelHeading } from '../../../components/ui/WDLabel'
import Label from '../../../components/label/Label'
import Button from '../../../components/button/Button'
import {
  WDBorderButtonPrimary,
  WDButton,
  WDButtonTransparent,
} from '../../../components/ui/WDButton'
import { SvgClose } from '../../../components/svg/SvgClose'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { Textbox } from '../../../components/textbox/Textbox'
import { ThemeContext } from '../../../store/ThemeContext'
import Checkbox from '../../../components/checkbox/Checkbox'
import { EditIndividualDetailsProps } from './IFinancialDetails'
import { LeadInfoProps } from '../overviewSection/IEditDetails'

const EstimatedTaxInfo = ({
  setEstimatedTaxInfoModal,
  leadDetails,
  updateLeadInfoData,
}: EditIndividualDetailsProps) => {
  const { isDarkMode } = useContext(ThemeContext)
  const [taxInfo, setTaxInfo] = useState({
    taxyear: 0,
    estimatedtaxes: 0,
    estimatedtaxbracket: 0,
    confirmedbytaxreturn: false,
    adjustedgrossincome: 0,
  })

  useEffect(() => {
    setTaxInfo({
      taxyear: leadDetails?.taxyear ?? 0,
      estimatedtaxes: leadDetails?.estimatedtaxes ?? 0,
      estimatedtaxbracket: leadDetails?.estimatedtaxbracket ?? 0,
      confirmedbytaxreturn: leadDetails?.confirmedbytaxreturn ?? false,
      adjustedgrossincome: leadDetails?.adjustedgrossincome ?? 0,
    })
  }, [leadDetails])

  const updateTaxInfo = () => {
    updateLeadInfoData?.(
      { ...leadDetails, ...taxInfo } ?? ({} as LeadInfoProps)
    )
    setEstimatedTaxInfoModal(false)
  }


  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <MasterWrapper>
            <HeaderWrapper>
              <OverlayTitleWrapper>
                <WDSubFormLabelHeading>
                  <Label>{'UPDATE TAX INFO'}</Label>
                </WDSubFormLabelHeading>
                <CrossWrapper>
                  <WDButtonTransparent>
                    <Button
                      type="button"
                      onClick={() => setEstimatedTaxInfoModal(false)}
                    >
                      <SvgClose fillColor="#000"></SvgClose>
                    </Button>
                  </WDButtonTransparent>
                </CrossWrapper>
              </OverlayTitleWrapper>
              <PartyWrapper>
                <BodyWrapper>
                  <WrapperRow>
                    {leadDetails?.status !== 'Client' && (
                      <CreateWrapper>
                        <Label>{'Adjusted Gross Income'}</Label>
                        <TextBoxWrap>
                          <Textbox
                            width="24rem"
                            value={
                              isNaN(Number(taxInfo?.adjustedgrossincome))
                                ? 0
                                : Number(taxInfo?.adjustedgrossincome)
                            }
                            onChange={(value) => {
                              setTaxInfo({
                                ...taxInfo,
                                adjustedgrossincome: Number(value),
                              })
                            }}
                          />
                        </TextBoxWrap>
                      </CreateWrapper>
                    )}
                    <CreateWrapper>
                      <Label>{'Estimated Taxes'}</Label>
                      <TextBoxWrap>
                        <Textbox
                          width="24rem"
                          value={
                            isNaN(Number(taxInfo?.estimatedtaxes))
                              ? 0
                              : Number(taxInfo?.estimatedtaxes)
                          }
                          onChange={(value) => {
                            setTaxInfo({
                              ...taxInfo,
                              estimatedtaxes: Number(value),
                            })
                          }}
                        />
                      </TextBoxWrap>
                    </CreateWrapper>
                  </WrapperRow>
                  <ItemWrapper>
                    <CheckboxWrapper>
                      <Checkbox
                        onChange={(checked) => {
                          setTaxInfo({
                            ...taxInfo,
                            confirmedbytaxreturn: checked,
                          })
                        }}
                        checked={taxInfo.confirmedbytaxreturn}
                      ></Checkbox>
                      <Label>{'Confirmed by tax return?'}</Label>
                    </CheckboxWrapper>
                  </ItemWrapper>
                  <WrapperRow>
                    <CreateWrapper>
                      <Label>Tax Year</Label>
                      <TextBoxWrap>
                        <Textbox
                          width="24rem"
                          value={
                            isNaN(Number(taxInfo?.taxyear))
                              ? 0
                              : Number(taxInfo?.taxyear)
                          }
                          onChange={(value) => {
                            setTaxInfo({
                              ...taxInfo,
                              taxyear: Number(value),
                            })
                          }}
                        />
                      </TextBoxWrap>
                    </CreateWrapper>
                    {leadDetails?.status !== 'Client' && (
                      <CreateWrapper>
                        <Label>{'Tax Bracket'}</Label>
                        <TextBoxWrap>
                          <Textbox
                            width="24rem"
                            value={
                              isNaN(Number(taxInfo?.estimatedtaxbracket))
                                ? 0
                                : Number(taxInfo?.estimatedtaxbracket)
                            }
                            onChange={(value) => {
                              setTaxInfo({
                                ...taxInfo,
                                estimatedtaxbracket: Number(value),
                              })
                            }}
                          />
                        </TextBoxWrap>
                      </CreateWrapper>
                    )}
                  </WrapperRow>
                </BodyWrapper>
              </PartyWrapper>
              <FooterWrapper>
                <BtnWrapper>
                  <WDBorderButtonPrimary>
                    <Button
                      type="button"
                      onClick={() => setEstimatedTaxInfoModal(false)}
                      color={
                        isDarkMode
                          ? DEFAULT_COLORS.Text.UserDark
                          : DEFAULT_COLORS.Text.Primary
                      }
                    >
                      {'Cancel'}
                    </Button>
                  </WDBorderButtonPrimary>
                  <WDButton>
                    <Button type="button" onClick={updateTaxInfo}>
                      {'Save'}
                    </Button>
                  </WDButton>
                </BtnWrapper>
              </FooterWrapper>
            </HeaderWrapper>
          </MasterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}

export default EstimatedTaxInfo
