import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment-timezone'
import { SetStateAction, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { vars } from '../../assets/utils/CommonFunctions'
import Button from '../../components/button/Button'
import Label from '../../components/label/Label'
import { OptionProps } from '../../components/selectDropdown/ISelectDropdown'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import TextEditor from '../../components/textEditor/TextEditor'
import { Textbox } from '../../components/textbox/Textbox'
import { WDBorderButtonPrimary, WDButton } from '../../components/ui/WDButton'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { BtnWrapper } from '../../global'
import { ClientSearchByNameResponseInterface } from '../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import {
  INSERT_OPPORTUNITIES_DATA,
  INSERT_OPPORTUNITIES_HISTORY_DATA,
  INSERT_OPPORTUNITIES_PERMISSIONS,
} from '../../services/graphQl/CustomerGraphQl'
import { GET_PERMISSIONS_BY_LEAD } from '../../services/graphQl/LeadsGraphQL'
import ToastContext from '../../services/toast/ToastContext'
import UserContext from '../../services/user/UserContext'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { TextBoxWrap } from '../addressInformation/styles'
import Loader from '../loader/Loader'
import { SearchWrapper } from '../navbar/styles'
import { PermissionPayloadInterface } from '../permissionAndRoles/IPermissions'
import ClientInfo from './ClientInfo'
import {
  ContentWrapper,
  ErrorMessage,
  FormWrapper,
  Header,
  LeftSubWrapper,
  OverlayWrapper,
  RightSubWrapper,
  Wrapper,
} from './styles'

interface ModalProps {
  setAddOpportunitiesModal: (value: boolean) => void
  clientParams?: ClientSearchByNameResponseInterface
  leadPartyId?: number
}
interface PermissionsResponseInterface {
  id: number
  type: string
  repcode: string
}

const AddOpportunitiesModal = ({
  setAddOpportunitiesModal,
  clientParams,
  leadPartyId,
}: ModalProps) => {
  const [selectedClient, setSelectedClient] =
    useState<ClientSearchByNameResponseInterface>(
      {} as ClientSearchByNameResponseInterface
    )
  const { user } = useContext(UserContext)
  const [name, setName] = useState('')
  const [flag, setFlag] = useState(false)
  const [id, setId] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState(0)
  const navigate = useNavigate()
  const [nameError, setNameError] = useState('')
  const [linkedContactError, setLinkedContactError] = useState('')
  const [getPermissionsByLead] = useLazyQuery<PermissionsResponseInterface>(
    GET_PERMISSIONS_BY_LEAD
  )
  const [loader, setLoader] = useState(false)
  const { setToast } = useContext(ToastContext)
  const [primaryOwner, setPrimaryOwner] = useState<any>('')
  const typeData = [
    { value: '0', label: 'New Business' },
    { value: '1', label: 'Existing Business' },
  ]

  useEffect(() => {
    clientParams?.id && setSelectedClient(clientParams)
  }, [clientParams])

  useEffect(() => {
    if (selectedClient?.id) {
      setLinkedContactError(selectedClient.id > 0 ? '' : 'Contact is required.')
      getPermissionsByLead({ variables: { id: selectedClient.id } }).then(
        (resp: any) => {
          if (resp?.data?.lead_permissions) {
            const codes: any[] = []
            resp?.data?.lead_permissions.map((permission: any) => {
              if (permission.type !== 'All') {
                codes.push({
                  repcode: permission.repcode,
                  type: permission.type,
                })
              }
            })
          }
        }
      )
    }
  }, [selectedClient])

  const addNewClient = () => {
    setSelectedClient({} as ClientSearchByNameResponseInterface)
  }

  const updateSelectedClient = (val: ClientSearchByNameResponseInterface) => {
    if (val.id >= vars.leadIdRange) {
      setType(0)
    } else {
      setType(1)
    }
    setPrimaryOwner(val.advisor)
    setSelectedClient(val)
  }

  const [addNewOpportunityData, { data }] = useMutation(
    INSERT_OPPORTUNITIES_DATA
  )
  const [addNewHistoryData, { data: historyData }] = useMutation(
    INSERT_OPPORTUNITIES_HISTORY_DATA
  )
  const timeZone = () => {
    const zone = moment.tz.guess()
    const abbreviation = moment.tz(zone).zoneAbbr()
    return abbreviation
  }
  const [addOppPermissions] = useMutation(INSERT_OPPORTUNITIES_PERMISSIONS)

  const handleNameChange = (value: SetStateAction<string>) => {
    setName(value)
    setNameError(value ? '' : 'Name is required.')
  }
  const validateOpportunities = () => {
    setNameError('')
    setLinkedContactError('')

    // Perform validation
    let isValid = true
    if (!name) {
      setNameError('Name is required.')
      isValid = false
    }
    if (!selectedClient?.id) {
      setLinkedContactError('Contact is required.')
      isValid = false
    }

    return isValid
  }

  const addNewOpportunityFuc = () => {
    // If validation fails, stop submission
    if (vars.validationRequired && !validateOpportunities()) {
      setToast(false, 'Please enter mandatory detail!')
      return
    }
    setLoader(true)
    addNewOpportunityData({
      variables: {
        name: name,
        contactid: selectedClient?.id ? +selectedClient?.id : 0,
        contactname: selectedClient?.firstname ? selectedClient?.firstname : '',
        repcode: selectedClient?.advisor ?? '',
        description: description ?? '',
        type: type ? +type : 0,
        createddate: new Date(),
        createdby: user.username,
        stage: 2,
        timezone: timeZone(),
      },
    })
      .then((res: any) => {
        const affectedRows =
          res.data.insert_opportunities_opportunities.affected_rows
        if (affectedRows > 0) {
          const oppId =
            res.data.insert_opportunities_opportunities.returning[0].id

          const oppPermissionsPayload: any = []

          oppPermissionsPayload.push({
            opportunityid: oppId,
            type: 'Primary Owner',
            createdby: user.username,
            createdon: new Date(),
            updatedby: user.username,
            updatedon: new Date(),
            repcode: primaryOwner,
          })
          const permissions: PermissionPayloadInterface = oppPermissionsPayload
          addOppPermissions({ variables: { payload: permissions } })
            .then((res) => {
              const affectedRows =
                res.data.insert_opportunities_permissions.affected_rows
              if (affectedRows > 0) {
                setToast(true, 'Opportunity created successfully!')
                setAddOpportunitiesModal(false)
                setLoader(false)
                navigate(`/opportunities/${oppId}`, {
                  state: { leadPartyId },
                })
              } else {
                setLoader(false)
                setToast(false, 'Something went wrong!')
              }
            })
            .catch(() => {
              setLoader(false)
              setToast(false, 'Something went wrong!')
            })
        }
      })
      .catch(() => {
        setLoader(false)
        setToast(false, 'Something went wrong!')
      })
  }

  useEffect(() => {
    if (flag) {
      addNewHistoryData({
        variables: {
          opportunityid: id ? +id : 0,
          description: 'Added new opportunity',
          updateddate: new Date(),
        },
      })
    }
  }, [flag])

  useEffect(() => {
    if (historyData?.insert_opportunities_history?.affected_rows > 0) {
      setAddOpportunitiesModal(false)
      navigate('/opportunities')
      setFlag(false)
    }
  }, [historyData])

  useEffect(() => {
    if (data?.insert_opportunities_opportunities?.affected_rows > 0) {
      setFlag(true)
      setId(data?.insert_opportunities_opportunities?.returning[0].id)
    }
  }, [data])

  const onChangeSelectData = (fieldName: string, selectedOption: any) => {
    setType(selectedOption?.value)
  }
  const defaultTypeOption = typeData.find(
    (option: OptionProps) => +option.value == +type
  )

  return (
    <OverlayWrapper>
      {loader && <Loader position="absolute" />}
      <Wrapper>
        <Header>
          <WDSubFormLabelHeading>
            <Label>
              <Label>{'Add Opportunity'}</Label>
            </Label>
          </WDSubFormLabelHeading>
          <BtnWrapper padding={0}>
            <WDBorderButtonPrimary>
              <Button
                type="button"
                onClick={() => setAddOpportunitiesModal(false)}
                color={DEFAULT_COLORS.Text.Primary}
              >
                {'Cancel'}
              </Button>
            </WDBorderButtonPrimary>
            <WDButton>
              <Button type="button" onClick={addNewOpportunityFuc}>
                {'Create'}
              </Button>
            </WDButton>
          </BtnWrapper>
        </Header>
        <ContentWrapper>
          <FormWrapper>
            <LeftSubWrapper>
              <Label>{'Name'}</Label>
              <TextBoxWrap error={nameError}>
                <Textbox
                  value={name}
                  onChange={(value) => handleNameChange(value)}
                />
              </TextBoxWrap>
              {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
            </LeftSubWrapper>
            <LeftSubWrapper>
              <Label>{'Linked Contacts'}</Label>
              <SearchWrapper error={linkedContactError}>
                <ClientInfo
                  header={selectedClient?.type}
                  clientName={selectedClient?.firstname}
                  clientId={selectedClient?.id}
                  repCode={selectedClient?.advisor}
                  onCancel={addNewClient}
                  type={selectedClient?.type ? selectedClient?.type : ''}
                  updateSelectedClient={updateSelectedClient}
                />
              </SearchWrapper>
              {linkedContactError && (
                <ErrorMessage>{linkedContactError}</ErrorMessage>
              )}
            </LeftSubWrapper>
            <LeftSubWrapper>
              <Label>{'Type'}</Label>
              <SelectDropdown
                className="w-100"
                borderColor={DEFAULT_COLORS.Border.Neutral}
                padding="0"
                options={typeData}
                height="2.62rem"
                placeholder={'Select'}
                value={defaultTypeOption}
                onChange={(selectedOption) =>
                  onChangeSelectData('type', selectedOption)
                }
              />
            </LeftSubWrapper>
            {/* <PermissionAndRoles
              theme="white"
              permissionError={false}
              isAdditionalRepRequired={false}
              updateData={(data) => {
                setPrimaryOwner(data)
              }}
            /> */}
          </FormWrapper>
          <FormWrapper>
            <RightSubWrapper flex={'none'}>
              <Label>{'Description'}</Label>
              <TextBoxWrap>
                <TextEditor
                  height={'200px'}
                  value={description}
                  onChange={(value) => setDescription(JSON.stringify(value))}
                />
              </TextBoxWrap>
            </RightSubWrapper>
          </FormWrapper>
        </ContentWrapper>
      </Wrapper>
    </OverlayWrapper>
  )
}

export default AddOpportunitiesModal
