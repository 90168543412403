import { AccountInfo } from '@azure/msal-browser'
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
export interface UserContextInterface {
  userAccess?: UserAccessInterface
  user: AccountInfo
  setUser: (user: AccountInfo) => void
  setUserAccess?: (userAccess: UserAccessInterface) => void
}
import { useAccount, useMsal } from '@azure/msal-react'
import appConfig from '../../settings/appConfig'
import { UserAccessInterface } from '../clientContext/IClientsContext'
const UserContext = createContext<UserContextInterface>(
  {} as UserContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const UserContextProvider = ({ children }: ContextProps) => {
  const getUserFromSession = () => {
    const loggedInUser = sessionStorage.getItem('user')
    if (loggedInUser) {
      return JSON.parse(loggedInUser) as AccountInfo
    } else {
      return {} as AccountInfo
    }
  }
  const { accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [user, setLoggedInUser] = useState<AccountInfo>(getUserFromSession())
  const [userAccess, setUserAccess] = useState<UserAccessInterface>()

  const setUser = useCallback((userLogged: AccountInfo) => {
    setLoggedInUser(
      (userDetails) =>
        ({
          ...userDetails,
          username: userLogged.username,
          name: userLogged.name,
        } as AccountInfo)
    )
  }, [])

  const setUserAccessFn = useCallback(
    (userAccessParam: UserAccessInterface) => {
      setUserAccess(
        (userAccess) =>
          ({
            ...userAccess,
            repAccess: userAccessParam.repAccess,
            accessLevels: userAccessParam.accessLevels,
          } as UserAccessInterface)
      )
    },
    []
  )

  useEffect(() => {
    if (appConfig.envName !== 'PROD') {
      // Set user if not available
      if (user.username) {
        sessionStorage.setItem('user', JSON.stringify(user))
      } else {
        setUser({
          username: (account?.idTokenClaims?.Username as string)?.split(
            '@'
          )[0],
          name: account?.name,
        } as AccountInfo)
      }
    }
  }, [setUser, user])
  useEffect(() => {
    if (appConfig.envName == 'PROD') {
      const accountName = (account?.idTokenClaims?.Username as string)?.split(
        '@'
      )[0]
      setUser({
        username: accountName,
        name: account?.name,
      } as AccountInfo)
    }
  }, [account?.idTokenClaims?.Username, account?.name, setUser])

  const value = useMemo(
    () => ({
      user,
      setUser,
      userAccess,
      setUserAccessFn,
    }),
    [setUser, user]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContext
