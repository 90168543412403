import styled from 'styled-components'
import { SIZES } from '../../theme/Sizes'
import { DEFAULT_COLORS } from '../../theme/Colors'

export const FormContainer = styled.div`
  display: flex;
  flex: 1 0 calc(50% - ${SIZES[3]});
  flex-direction: column;
  gap: ${SIZES[1]};
  width: 100%;
`
export const GridItemContainer = styled.div`
  display: flex;
  flex: 1 0 calc(25% - ${SIZES[3]});
  flex-direction: column;
  gap: ${SIZES[1]};
  width: 100%;
`
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES[1]};
  width: 100%;
`

export const TextBoxWrap = styled.div`
  display: block;
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
  input {
    min-width: 16rem;
    padding: 0.5rem 1rem !important;
  }
`
export const ContactDetailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SIZES[3]};
`
export const SelectBoxSmall = styled.div`
  /* min-width: 17rem; */
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
`
export const CollapseWrapper = styled.div`
  display: flex;
  border-radius: ${SIZES[1]};
  width: 100%;
  gap: ${SIZES[1]};
  cursor: pointer;
  align-items: center;
`
export const CollapseHeaderWrapper = styled.div`
  display: flex;
  flex: 0 1 90%;
  width: 100%;
`
export const CollapseButtonWrapper = styled.div`
  display: flex;
  flex: 0 1 10%;
  justify-content: flex-end;
  width: 100%;
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: ${SIZES[1]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  padding: ${SIZES[1]};
  border: 1px solid ${() => DEFAULT_COLORS.Background.Grey};
`
export const CalendarWrap = styled.div`
  display: flex;
  border: 1px solid
    ${(props: { error: string }) =>
      props.error ? DEFAULT_COLORS.Border.DangerStrong : 'transparent'};
  input {
    width: 100%;
    height: 2.75rem;
    font-size: 14px;
  }
  input.date-time {
    padding: 0.65rem 1rem;
    flex: 0 1 100%;
    width: ${(Props: { width: string }) => Props.width};
    background: ${() => DEFAULT_COLORS.Background.Neutral};
    border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
    &:hover {
      border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
      color: ${() => DEFAULT_COLORS.Icons.NeutralHover};
    }
    /* &:active {
    border: 2px solid ${() => DEFAULT_COLORS.Border.PrimaryMedium};
  } */
    &:disabled {
      border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
      color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
      cursor: no-drop;
    }
    &:invalid {
      border: 1px solid ${() => DEFAULT_COLORS.Border.Iron};
    }
    &:focus {
      box-shadow: inset 0px 0px 0px 1px
        ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      border-color: ${() => DEFAULT_COLORS.Border.PrimaryMedium};
      color: ${() => DEFAULT_COLORS.Text.Black};
    }
    &:required:valid {
      border: 2px solid ${() => DEFAULT_COLORS.Border.DangerDark};
      background-color: ${() => DEFAULT_COLORS.Background.Fantasy};
    }
    box-sizing: border-box;
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    font-style: normal;
    min-height: 2.62rem;
    height: 2.62rem;
    font-weight: 400;
    font-size: 1rem;
    color: ${() => DEFAULT_COLORS.Text.Neutral};
    outline: none;
  }
`
