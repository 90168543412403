import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { CreateNoteComponent } from 'note-widget'
//import { CreateNoteComponent } from '../../noteWidget'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { vars } from '../../assets/utils/CommonFunctions'
import {
  AttachDocument,
  EXTENSIONS,
} from '../../components/attachDocument/AttachDocument'
import Button from '../../components/button/Button'
import Label from '../../components/label/Label'
import { FileDownloadResponseInterface } from '../../components/previewFile/IPreviewFile'
import PreviewFile from '../../components/previewFile/PreviewFile'
import { DocumentContainer } from '../../components/previewFile/styles'
import { OptionProps } from '../../components/selectDropdown/ISelectDropdown'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import TextEditor from '../../components/textEditor/TextEditor'
import { Textbox } from '../../components/textbox/Textbox'
import { WDBorderButtonPrimary, WDButton } from '../../components/ui/WDButton'
import { WDLabelBold } from '../../components/ui/WDLabel'
import ClientInfo from '../../composites/opportunitiesDetails/ClientInfo'
import { leadsData, subTypeData, typeData } from '../../reducers/leadPartySlice'
import { AuthContext } from '../../services/AuthContext'
import {
  AssociateSearchByNameResponseInterface,
  ClientSearchByNameResponseInterface,
} from '../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import { fileUploadRequest } from '../../services/fileAttachment/FileTransferApi'
import {
  GET_EDIT_OPPORTUNITIES_DATA,
  GET_NOTES_BY_OPP,
  INSERT_OPPORTUNITIES_HISTORY_DATA,
  UPDATE_OPPORTUNITIES_DATA,
} from '../../services/graphQl/CustomerGraphQl'
import { GetSavedFiles } from '../../services/graphQl/LeadsGraphQL'
import UserContext from '../../services/user/UserContext'
import appConfig from '../../settings/appConfig'
import { useAppSelector } from '../../store/store'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { TextBoxWrap } from '../partyProfile/styles'
import {
  OpportunitiesListProps,
  UploadFilePayloadInterface,
} from './IOpportunitiesEditDetails'
import OpportunitiesHistory from './OpportunitiesHistory'
import OpportunitiesNotes from './OpportunitiesNotes'
import { AssignedToSearch } from './assignedToSearch/AssignedToSearch'
import {
  BtnWrapper,
  CreateWrapper,
  DetailsWrp,
  FooterWrapper,
  ItemWrapper,
  ItemsSubWrp,
  ItemsWrp,
  ListPermission,
  ODFormWrapper,
  ODLeftMainWrapper,
  ODRightMainCardWrapper,
  ODRightMainWrapper,
  ODWrapper,
  RightTitleWrp,
  SearchWrapper,
} from './styles'
import ClientAndAccountSearchContext from '../../services/clientAndAccountSearch/ClientAndAccountSearchContext'
import Loader from '../../composites/loader/Loader'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { SubTypesDataInterface, TypesDataInterface } from '../notes/INotes'
import {
  FormateDate,
  FormateDateTime,
  FullFormateDateTime,
} from '../../components/customCalendar/Util'

const stageData = [
  { value: '0', label: 'Closed Lost' },
  { value: '1', label: 'Closed Won' },
  { value: '2', label: 'In Progress' },
]
const OppTypeData = [
  { value: '0', label: 'New Business' },
  { value: '1', label: 'Existing Business' },
]

const OpportunitiesEditDetails = () => {
  const navigate = useNavigate()
  const { opportunityId } = useParams()
  const { state } = useLocation()
  const { userAccessDetails } = useContext(AuthContext)
  const { refetchFlag, setRefetchFlag } = useContext(DashboardContext)
  const [duplicateFile, setDuplicateFile] = useState(false)
  const [allFiles, setAllFiles] = useState<FileDownloadResponseInterface[]>([])
  const [showNewNotes, setNewNotes] = useState<boolean>(false)
  const [opportunitiesList, setOpportunitiesList] =
    useState<OpportunitiesListProps>()
  const { associate, setAssociate } = useContext(ClientAndAccountSearchContext)
  const { accessToken } = useContext(AuthContext)
  const combinedData = useAppSelector(leadsData)
  const [loader, setLoader] = useState(false)
  const { user } = useContext(UserContext)
  const formatUSD = (value: string | number | undefined) => {
    if (!value) return value
    const amount = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    return '$' + amount
  }
  const [getSavedFiles] = useLazyQuery<any>(GetSavedFiles)
  const { data: opportunitiesData } = useQuery(GET_EDIT_OPPORTUNITIES_DATA, {
    variables: { id: opportunityId },
    fetchPolicy: 'no-cache',
  })

  const [updateData] = useMutation(UPDATE_OPPORTUNITIES_DATA)

  const [addNewHistoryData] = useMutation(INSERT_OPPORTUNITIES_HISTORY_DATA)
  const typesData = useSelector(typeData)
  const subTypesData = useSelector(subTypeData)
  const [allTypes, setAllTypes] = useState<any[]>([])
  const [subTypes, setSubTypes] = useState<any[]>([])

  const getTypeByTranType = (tranType: number) => {
    return typesData
      .filter((type: TypesDataInterface) => type.trantype === tranType)
      .map((type: TypesDataInterface) => {
        return { value: type.typeid, label: type.type }
      })
  }

  const getSubTypeByType = (typeId: number) => {
    return subTypesData
      .filter(
        (type: SubTypesDataInterface) =>
          type.typeid === typeId && type.trantype === 1
      )
      .map((type: SubTypesDataInterface) => {
        return { value: type.subtypeid, label: type.subtype }
      })
  }
  useEffect(() => {
    if (typesData) setAllTypes(getTypeByTranType(1))
  }, [typesData])

  // interface PermissionsResponseInterface {
  //   id: number
  //   type: string
  //   repcode: string
  // }

  // const [getPermissionsByOpp] = useLazyQuery<PermissionsResponseInterface>(
  //   GET_OPPORTUNITIES_PERMISSIONS
  // )

  const {
    data: notesData,
    loading: loadingNotes,
    refetch: refetchNoteData,
  } = useQuery(GET_NOTES_BY_OPP, {
    variables: {
      limit: 5,
      offset: 0,
      userName: user.username,
      oppid: parseInt(opportunityId ?? '0'),
    },
    fetchPolicy: 'no-cache',
  })

  const uploadDocument = useCallback(
    (payload: UploadFilePayloadInterface) => {
      const combineFiles = allFiles.filter((file) => !file.id)
      if (combineFiles?.length > 0) {
        combineFiles.forEach((file) => {
          if (!file.isUploaded) {
            file.isCallingAPI = true
            const formData = new FormData()
            console.log(file.file, 'file')
            formData.append('FormFile', file.file)
            formData.append('FolderName', payload.FolderName)
            formData.append('UploadTranType', payload.UploadTranType)
            formData.append('UserBy', payload?.PartyId?.toString())
            formData.append('TranId', payload?.TranId?.toString())

            fileUploadRequest(formData)
              .then((res) => {
                if (res.data.id) {
                  // setToast(true, 'File uploaded successfully!')
                  console.log('File Uploaded')
                } else {
                  // setToast(
                  //   false,
                  //   'Something went wrong. Please upload the file again.'
                  // )
                  console.log('Something went wrong')
                }
              })
              .catch(() => {
                // setToast(
                //   false,
                //   'Something went wrong. Please upload the file again.'
                // )
                console.log('Something went wrong')
              })
          }
        })
      }
    },
    [allFiles]
  )

  const updateOpportunities = () => {
    setLoader(true)
    const opportunitiesPayload = {
      ...opportunitiesList,
      assignedtoid: associate?.adAccount,
      assignedtoname: associate?.employeeName,
      updatedby: user.username,
      updateddate: new Date().toISOString(),
    }

    if (opportunitiesList?.id) {
      updateData({
        variables: {
          opportunitiesList: opportunitiesPayload, // Use the updated list
          id: opportunitiesList?.id,
        },
      })
        .then(() => {
          const info = {
            FolderName: vars.OppFolderName,
            UploadTranType: '4',
            PartyId: user.username,
            TranId: opportunitiesList?.id,
          }
          uploadDocument(info)
          const opportunitiesHistoryPayload = {
            opportunityid: opportunitiesList?.id,
            description: 'Updated opportunity',
            updateddate: new Date(),
          }

          addNewHistoryData({
            variables: opportunitiesHistoryPayload,
          })
            .then(() => {
              setLoader(false)
              setRefetchFlag(!refetchFlag)
              if (state?.leadPartyId > 0) {
                if (state.leadPartyId > vars.leadIdRange) {
                  navigate('/Leadprofile', {
                    state: {
                      leadId: state.leadPartyId,
                    },
                  })
                } else {
                  navigate(`/Profile/${state.leadPartyId}`)
                }
              } else {
                navigate('/opportunities')
              }
            })
            .catch(() => {
              setLoader(false)
            })
        })
        .catch(() => {
          setLoader(false)
        })
    }
  }

  // useEffect(() => {
  //   getPermissionsByOpp({
  //     variables: {
  //       id: opportunityId,
  //     },
  //   }).then((resp: any) => {
  //     if (resp?.data?.opportunities_permissions) {
  //       setPermissionData(resp?.data?.opportunities_permissions)
  //     }
  //   })

  // }, [])

  // useEffect(() => {
  //   if (
  //     opportunitiesUpdateData?.update_opportunities_opportunities
  //       ?.affected_rows > 0
  //   ) {
  //     setFlag(true)
  //     setId(
  //       opportunitiesUpdateData?.update_opportunities_opportunities
  //         ?.returning[0].id
  //     )
  //   }
  // }, [
  //   opportunitiesUpdateData?.update_opportunities_opportunities?.affected_rows,
  // ])

  useEffect(() => {
    getSavedFiles({
      variables: {
        tranId: opportunityId,
        tranType: 4,
      },
      fetchPolicy: 'no-cache',
    }).then((res) => {
      const fileResponse = res.data.note_trandocuments.map((file: any) => {
        return {
          id: file.id,
          tranId: file.tranid,
          tranType: file.trantype,
          name: file.filename,
          fileExtension:
            file.filename.split('.')[file.filename.split('.').length - 1],
          folderPath: file.filepath,
          createdBy: file.createdby,
          createdOn: file.createdon,
          lastModified: '',
          isCallingAPI: false,
          isUploaded: file.activeflag === 1 ? true : false,
        }
      })
      setAllFiles(fileResponse)
    })
    if (
      opportunitiesData?.opportunities_opportunities &&
      Array.isArray(opportunitiesData?.opportunities_opportunities)
    ) {
      setOpportunitiesList(opportunitiesData?.opportunities_opportunities[0])
      if (opportunitiesData?.opportunities_opportunities[0]?.assignedtoid) {
        setAssociate({
          adAccount:
            opportunitiesData?.opportunities_opportunities[0]?.assignedtoid,
          employeeName:
            opportunitiesData?.opportunities_opportunities[0]?.assignedtoname,
        } as AssociateSearchByNameResponseInterface)
      } else {
        setAssociate({
          adAccount: user?.username ?? '',
          employeeName: user?.name ?? '',
        } as AssociateSearchByNameResponseInterface)
      }
    }
  }, [
    opportunitiesData?.opportunities_opportunities,
    opportunityId,
    user?.name,
    user?.username,
  ])

  const addNewClient = () => {
    setOpportunitiesList((prevState: any) => ({
      ...prevState,
      contactid: null,
      contactname: '',
    }))
  }

  const updateSelectedClient = (val: ClientSearchByNameResponseInterface) => {
    setOpportunitiesList((prevState: any) => {
      return {
        ...prevState,
        contactname: val.firstname,
        contactid: val.id,
      }
    })
  }

  const onChangeData = (fieldName: string, value: any) => {
    console.log(fieldName, value, 'field')
    setOpportunitiesList((prevOpportunitiesList: any) => ({
      ...prevOpportunitiesList,
      [fieldName]:
        fieldName === 'amount' ||
        fieldName === 'projectedrevenue' ||
        fieldName === 'actualrevenue'
          ? parseFloat(value)
          : value,
    }))
  }

  const onChangeSelectData = (
    fieldName: string,
    selectedOption: OptionProps | null
  ) => {
    setOpportunitiesList((prevOpportunitiesList: any) => ({
      ...prevOpportunitiesList,
      [fieldName]: selectedOption?.value,
    }))
  }
  const defaultTypeOption = OppTypeData.find(
    (option: OptionProps) => +option.value == opportunitiesList?.type
  )

  const defaultStageOption = stageData.find(
    (option: OptionProps) => +option.value == opportunitiesList?.stage
  )

  const dateFormate = (inputDateString: any) => {
    // If inputDateString can be null or undefined, provide a default value or handle accordingly
    inputDateString = inputDateString || '' // Default value is an empty string

    const inputDate = new Date(inputDateString)

    // Check if the date is valid before formatting
    if (!isNaN(inputDate.getTime())) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Use 12-hour clock format
      }
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
        inputDate
      )
      return formattedDate
    } else {
      return '-'
    }
  }

  // useEffect(() => {
  //   if (flag) {
  //     addNewHistoryData({
  //       variables: {
  //         opportunityid: id ? +id : 0,
  //         description: 'Updated opportunity',
  //         updateddate: new Date(),
  //       },
  //     })
  //   }
  // }, [flag, id])

  // useEffect(() => {
  //   if (
  //     historyData?.insert_opportunities_history?.affected_rows > 0 &&
  //     state &&
  //     state?.partyId
  //   ) {
  //     navigate('/Leadprofile', {
  //       state: {
  //         leadId: state.partyId ?? '',
  //       },
  //     })
  //     setFlag(false)
  //   }
  // }, [historyData])

  // useEffect(() => {
  //   if (historyData?.insert_opportunities_history?.affected_rows > 0) {
  //     navigate('/opportunities')
  //     setFlag(false)
  //   }
  // }, [historyData])

  // const getFiles = (files: File[]) => {
  //   let updatedFile: FileDownloadResponseInterface[] = []
  //   const alreadyUploadedFileNames: string[] = allFiles.map((file) =>
  //     file.name.includes('.' + file.fileExtension)
  //       ? file.name
  //       : file.name + '.' + file.fileExtension
  //   )
  //   const attachedFiles = updatedFile?.map((file) =>
  //     file.name.includes('.' + file.fileExtension)
  //       ? file.name
  //       : file.name + '.' + file.fileExtension
  //   )

  //   const mainFiles = [...alreadyUploadedFileNames, ...attachedFiles]

  //   const filteredFiles = files.filter((file) => {
  //     setDuplicateFile(mainFiles.includes(file.name))
  //     return (
  //       !mainFiles.includes(file.name) &&
  //       EXTENSIONS.includes(
  //         file.name.split('.')[file.name.split('.').length - 1]
  //       )
  //     )
  //   })
  //   filteredFiles.forEach((file) => {
  //     const allowedFiles = {
  //       file: file,
  //       createdOn: new Date().toDateString(),
  //       downloadUrl: '',
  //       fileExtension: file.name.split('.')[file.name.split('.').length - 1],
  //       folderPath: '',
  //       createdBy: user?.name ?? '',
  //       lastModified: new Date().toDateString(),
  //       name: file.name,
  //       isUploaded: false,
  //       tranId: 0,
  //       tranType: 4,
  //     }
  //     updatedFile.push(allowedFiles)
  //   })
  //   console.log([...allFiles, ...updatedFile], 'yesthis')
  //   setAllFiles([...allFiles, ...updatedFile])
  //   updatedFile = []
  // }

  const getFiles = (files: File[]) => {
    let updatedFile: FileDownloadResponseInterface[] = []
    const alreadyUploadedFileNames: string[] = allFiles.map((file) =>
      file.name.includes('.' + file.fileExtension)
        ? file.name
        : file.name + '.' + file.fileExtension
    )
    const attachedFiles = updatedFile?.map((file) =>
      file.name.includes('.' + file.fileExtension)
        ? file.name
        : file.name + '.' + file.fileExtension
    )

    const mainFiles = [...alreadyUploadedFileNames, ...attachedFiles]

    const filteredFiles = files.filter((file) => {
      setDuplicateFile(mainFiles.includes(file.name))
      return (
        !mainFiles.includes(file.name) &&
        EXTENSIONS.includes(
          file.name.split('.')[file.name.split('.').length - 1]
        )
      )
    })
    filteredFiles.forEach((file) => {
      const allowedFiles = {
        file: file,
        createdOn: new Date().toDateString(),
        fileExtension: file.name.split('.')[file.name.split('.').length - 1],
        createdBy: user?.name ?? '',
        name: file.name,
        isUploaded: false,
        tranId: parseInt(opportunitiesList?.id ?? '0') ?? 0,
        tranType: 4,
      }
      updatedFile.push(allowedFiles)
    })

    setAllFiles([...allFiles, ...updatedFile])
    updatedFile = []
  }
  // console.log(opp)
  return (
    <>
      <ODWrapper>
        {loader && <Loader position="absolute" />}
        <ODLeftMainWrapper>
          <WDLabelBold>
            <Label>{'Opportunity Details'}</Label>
          </WDLabelBold>
          <DetailsWrp>
            <CreateWrapper>
              <Label>{'Name'}</Label>
              <TextBoxWrap>
                <Textbox
                  name="name"
                  value={opportunitiesList?.name ? opportunitiesList?.name : ''}
                  onChange={(value) => {
                    onChangeData('name', value)
                  }}
                />
              </TextBoxWrap>
            </CreateWrapper>

            <CreateWrapper>
              <Label>{'Product'}</Label>
              <TextBoxWrap>
                <Textbox
                  name="products"
                  value={
                    opportunitiesList?.products
                      ? opportunitiesList?.products
                      : ''
                  }
                  onChange={(value) => {
                    onChangeData('products', value)
                  }}
                />
              </TextBoxWrap>
            </CreateWrapper>
            <ItemsWrp>
              <CreateWrapper>
                <Label>{'Next Steps'}</Label>
                <TextBoxWrap>
                  <Textbox
                    name="nextsteps"
                    value={
                      opportunitiesList?.nextsteps
                        ? opportunitiesList?.nextsteps
                        : ''
                    }
                    onChange={(value) => {
                      onChangeData('nextsteps', value)
                    }}
                  />
                </TextBoxWrap>
              </CreateWrapper>
              <CreateWrapper>
                <Label>{'Probable Close Date'}</Label>
                <input
                  aria-label="Date"
                  type="date"
                  className="date-time"
                  name="closedate"
                  value={
                    opportunitiesList?.closedate
                      ? opportunitiesList?.closedate
                          ?.toString()
                          ?.substring(0, 10)
                      : ''
                  }
                  onChange={(value) => {
                    if (String(value.target.value).length === 10) {
                      // 10 is the length of the date format YYYY-MM-DD
                      const formattedDate = new Date(
                        value.target.value
                      ).toISOString()
                      onChangeData('closedate', formattedDate)
                    } else if (value.target.value === '') {
                      const formattedDate = new Date(
                        value.target.value
                      ).toISOString()
                      onChangeData('closedate', formattedDate)
                    }
                  }}
                />
              </CreateWrapper>
            </ItemsWrp>
            <ItemsWrp>
              <CreateWrapper>
                <Label>{'Stage'}</Label>
                <SelectDropdown
                  className="w-100"
                  borderColor={DEFAULT_COLORS.Border.Neutral}
                  padding="0rem"
                  options={stageData}
                  height="2.62rem"
                  name="stage"
                  placeholder={'Select'}
                  value={defaultStageOption}
                  onChange={(selectedOption) =>
                    onChangeSelectData('stage', selectedOption)
                  }
                />
              </CreateWrapper>
              <CreateWrapper>
                <Label>{'Type'}</Label>
                <SelectDropdown
                  className="w-100"
                  borderColor={DEFAULT_COLORS.Border.Neutral}
                  padding="0rem"
                  options={OppTypeData}
                  height="2.62rem"
                  placeholder={'Select'}
                  name="type"
                  value={defaultTypeOption}
                  onChange={(selectedOption) =>
                    onChangeSelectData('type', selectedOption)
                  }
                />
              </CreateWrapper>
            </ItemsWrp>
            <ItemsWrp>
              <CreateWrapper>
                <Label>{'Assigned To'}</Label>
                <AssignedToSearch />
              </CreateWrapper>
              <CreateWrapper>
                <Label>{'Source'}</Label>
                <TextBoxWrap>
                  <Textbox
                    name="source"
                    value={
                      opportunitiesList?.source ? opportunitiesList?.source : ''
                    }
                    onChange={(value) => {
                      onChangeData('source', value)
                    }}
                  />
                </TextBoxWrap>
              </CreateWrapper>
            </ItemsWrp>
            <ItemsWrp>
              <ItemsSubWrp>
                <ItemWrapper>
                  <CreateWrapper>
                    <Label>{'Amount'}</Label>
                    <TextBoxWrap>
                      <Textbox
                        name="amount"
                        value={
                          opportunitiesList?.amount
                            ? `${formatUSD(opportunitiesList?.amount)}`
                            : opportunitiesList?.amount
                        }
                        onChange={(value) => {
                          const numericValue = value.replace(/[^0-9.]/g, '')
                          onChangeData('amount', +numericValue)
                        }}
                      />
                    </TextBoxWrap>
                  </CreateWrapper>
                </ItemWrapper>
                <ItemWrapper>
                  <CreateWrapper>
                    <Label>{'Probability %'}</Label>
                    <TextBoxWrap>
                      <Textbox
                        name="probablity"
                        value={
                          opportunitiesList?.probablity
                            ? opportunitiesList?.probablity
                            : ''
                        }
                        max={3}
                        onChange={(value) => {
                          const numericValue = value.replace(/[^0-9.]/g, '')
                          onChangeData('probablity', numericValue)
                        }}
                      />
                    </TextBoxWrap>
                  </CreateWrapper>
                </ItemWrapper>
              </ItemsSubWrp>
              <ItemsSubWrp>
                <ItemWrapper>
                  <CreateWrapper>
                    <Label>{'Projected Revenue'}</Label>
                    <TextBoxWrap>
                      <Textbox
                        name="projectedrevenue"
                        value={
                          opportunitiesList?.projectedrevenue
                            ? `${formatUSD(
                                +opportunitiesList?.projectedrevenue
                              )}`
                            : ''
                        }
                        onChange={(value) => {
                          const numericValue = value.replace(/[^0-9.]/g, '')
                          onChangeData('projectedrevenue', +numericValue)
                        }}
                      />
                    </TextBoxWrap>
                  </CreateWrapper>
                </ItemWrapper>
                <ItemWrapper>
                  <CreateWrapper>
                    <Label>{'Actual Revenue'}</Label>
                    <TextBoxWrap>
                      <Textbox
                        name="actualrevenue"
                        value={
                          opportunitiesList?.actualrevenue
                            ? `${formatUSD(opportunitiesList?.actualrevenue)}`
                            : ''
                        }
                        onChange={(value) => {
                          const numericValue = value.replace(/[^0-9.]/g, '')
                          onChangeData('actualrevenue', +numericValue)
                        }}
                      />
                    </TextBoxWrap>
                  </CreateWrapper>
                </ItemWrapper>
              </ItemsSubWrp>
            </ItemsWrp>
            <CreateWrapper>
              <Label>{'Description'}</Label>
              <TextBoxWrap>
                <TextEditor
                  height={'250px'}
                  value={
                    opportunitiesList?.description
                      ? opportunitiesList?.description
                      : ''
                  }
                  onChange={(value) => {
                    onChangeData('description', JSON.stringify(value))
                  }}
                />
              </TextBoxWrap>
            </CreateWrapper>
            {userAccessDetails.role === vars.adminRole && (
              <AttachDocument
                uploadedFilesCallback={getFiles}
                duplicateFile={duplicateFile}
              ></AttachDocument>
            )}
            {allFiles?.length > 0 && (
              <DocumentContainer>
                <PreviewFile
                  fromView={false}
                  allFiles={allFiles}
                  setAllFiles={setAllFiles}
                />
              </DocumentContainer>
            )}
          </DetailsWrp>
          <FooterWrapper>
            <BtnWrapper>
              <WDBorderButtonPrimary>
                <Button
                  type="button"
                  onClick={() => navigate('/opportunities')}
                  color={DEFAULT_COLORS.Text.Primary}
                >
                  {'Cancel'}
                </Button>
              </WDBorderButtonPrimary>
              {userAccessDetails.role === vars.adminRole && (
                <WDButton>
                  <Button type="button" onClick={updateOpportunities}>
                    {'Save'}
                  </Button>
                </WDButton>
              )}
            </BtnWrapper>
          </FooterWrapper>
        </ODLeftMainWrapper>
        <ODRightMainWrapper>
          <ODRightMainCardWrapper>
            <WDLabelBold>
              <Label>{'Linked Contacts'}</Label>
            </WDLabelBold>
            <ODFormWrapper>
              <SearchWrapper>
                <ClientInfo
                  clientName={opportunitiesList?.contactname}
                  clientId={opportunitiesList?.contactid}
                  repCode={opportunitiesList?.repcode}
                  onCancel={addNewClient}
                  updateSelectedClient={updateSelectedClient}
                />
              </SearchWrapper>
            </ODFormWrapper>
          </ODRightMainCardWrapper>
          <ODRightMainCardWrapper>
            <RightTitleWrp>
              <WDLabelBold>
                <Label>{'Note'}</Label>
              </WDLabelBold>
              {userAccessDetails.role === vars.adminRole && (
                <WDButton>
                  <Button type="button" onClick={() => setNewNotes(true)}>
                    {'Create Note'}
                  </Button>
                </WDButton>
              )}
            </RightTitleWrp>
            <OpportunitiesNotes
              notesData={notesData}
              loadingNotes={loadingNotes}
            />
          </ODRightMainCardWrapper>
          {/* {permissionData && permissionData.length > 0 && (
            <ODRightMainCardWrapper>
              <WDLabelBold>
                <Label>{'Opportunity Permissions'}</Label>
              </WDLabelBold>
              <ItemWrapper>
                <SectionWrapper>
                  <ItemSubWrp>
                    <Table>
                      <Tr>
                        <Th width="50px">{'Sr.No'}</Th>
                        <Th width="120px">{'Type'}</Th>
                        <Th width="auto">{'Code'}</Th>
                      </Tr>
                      <>
                        {permissionData?.map((tr: any, i: number) => {
                          return (
                            <Tr key={i + 1}>
                              <Td>{i + 1}</Td>
                              <Td>{tr.type}</Td>
                              <Td>{tr.repcode}</Td>
                            </Tr>
                          )
                        })}
                      </>
                    </Table>
                  </ItemSubWrp>
                </SectionWrapper>
              </ItemWrapper>
            </ODRightMainCardWrapper>
          )} */}
          <ODRightMainCardWrapper>
            <WDLabelBold>
              <Label>{'Details'}</Label>
            </WDLabelBold>
            <ListPermission>
              <table>
                <thead>
                  <tr>
                    <th>{'Created On'}</th>
                    <td>{dateFormate(opportunitiesList?.createddate)}</td>
                  </tr>
                  <tr>
                    <th>{'Last Updated'}</th>
                    <td>{dateFormate(opportunitiesList?.updateddate)}</td>
                  </tr>
                </thead>
              </table>
            </ListPermission>
          </ODRightMainCardWrapper>
          <OpportunitiesHistory />
        </ODRightMainWrapper>
      </ODWrapper>
      {showNewNotes && (
        <CreateNoteComponent
          onTypeChange={(typeId: number) => {
            setSubTypes(getSubTypeByType(typeId))
          }}
          typeData={allTypes}
          subTypeData={subTypes}
          user={user}
          closeNote={setNewNotes}
          refetchContents={refetchNoteData}
          hostName={appConfig.apiEndPoint}
          accessToken={accessToken}
          clientParams={{
            clientId: opportunitiesList?.contactid ?? 0,
            clientName: opportunitiesList?.contactname ?? '',
            repcode: opportunitiesList?.repcode ?? '',
          }}
          combinedData={combinedData}
          opportunityData={{
            id: opportunitiesList?.id ? +opportunitiesList?.id : 0,
            name: opportunitiesList?.name ? opportunitiesList?.name : '',
          }}
          validationRequired={vars.validationRequired}
        />
      )}
    </>
  )
}

export default OpportunitiesEditDetails
