import { SvgNoData } from '../../components/svg/SvgNoData'
import {
  WDLabelTableDataStatus,
  WDLabelWeakMedium,
} from '../../components/ui/WDLabel'
import { NoDataSubWrapper, NoDataWrapper } from './styles'

const NoDataFound = () => {
  return (
    <NoDataWrapper>
      <SvgNoData />
      <WDLabelTableDataStatus>{'No Results Yet'}</WDLabelTableDataStatus>
      <NoDataSubWrapper>
        <WDLabelWeakMedium>
          {'Try adjusting your search or filters to find what'}
        </WDLabelWeakMedium>
        <WDLabelWeakMedium>{'you’re looking for.'}</WDLabelWeakMedium>
      </NoDataSubWrapper>
    </NoDataWrapper>
  )
}

export default NoDataFound
