import styled, { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'styled-normalize'
import { SIZES } from './theme/Sizes'

const BodyStyles = css`
  body {
    display: flex;
    flex: 1;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'SourceSansPro-Regular', 'Open Sans', sans-serif;
  }
  #root {
    display: flex;
    flex: 1;
  }
`

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${BodyStyles}
`

export const Spacer = styled.div`
  ${(props: { height: string; width: string }) => {
    return `height: ${props.height};
    width: ${props.height};`
  }}
`
export const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
export const BtnWrapper = styled.div`
  ${(props: { padding?: number; justifyContent?: string; bgcolor?: string }) => {
    return `
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: ${props.padding ?? SIZES[2]};
  justify-content: ${props.justifyContent ?? 'normal'};
  background-color: ${props.bgcolor ?? 'transparent'};
  `
  }}
`

export const DataListHeading = styled.div`
  font-weight: bold;
`

export const DataListDesc = styled.div`
  
`
export const TabWrapper = styled.div`
  margin: ${(props: { isMarginRequired: boolean }) =>
    props.isMarginRequired ? '1rem' : '0'};
  button {
    color: rgb(37, 99, 235);
    background: #fff;
    border: 1px solid rgb(227 227 227);
    outline: none;
    margin-left: -1px;
    padding: 0.4rem 1rem;
    cursor: pointer;
    &.selected {
      color: rgb(255 255 255);
      background: rgb(37, 99, 235);
      border: 1px solid rgb(37, 99, 235);
    }
  }
`
