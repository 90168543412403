import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import {
  WDBorderButtonPrimary,
  WDButtonPrimaryOutlined,
} from '../../../components/ui/WDButton'
import { ThemeContext } from '../../../store/ThemeContext'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { LeftUserProfile } from '../styles'
import { useContext, useEffect, useState } from 'react'
import {
  BtnWrapper,
  ContactDetailsTable,
  HeadingTextWrapper,
  ProfileHeadingWrapper,
} from './styles'
import EditDetails from './EditDetails'
import { LeadInfoProps } from '../../createContactList/ICreateLead'
import AdditionalAddressDetails from './AdditionalAddressDetails'
import { formatPhoneNumber } from '../../../services/Utils'
import { vars } from '../../../assets/utils/CommonFunctions'
import { AuthContext } from '../../../services/AuthContext'

type LeadContactCardProps = {
  contacts: LeadInfoProps
  updateData?: (fields: Partial<LeadInfoProps>) => void
}

export const DefaultAddressList = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
}

export const LeadContactCard = ({
  contacts,
  updateData,
}: LeadContactCardProps) => {
  const [showAdditionalContact, setShowAdditionalContact] = useState(false)
  const { isDarkMode } = useContext(ThemeContext)
  const { userAccessDetails } = useContext(AuthContext)
  const [showEditAddress, setShowEditAddress] = useState(false)
  const [addressList, setAddressList] = useState<Array<string | number>>([])
  const [contactId, setContactId] = useState<number>(0)

  const displayState = (state: string, postalCode: string) => {
    if (state && postalCode) {
      return `${state} - ${postalCode}`
    } else if (state && !postalCode) {
      return state
    } else if (!state && postalCode) {
      return postalCode
    } else {
      return ''
    }
  }

  useEffect(() => {
    const { addressLine1, addressLine2, city, state, country, postalCode } =
      contacts?.addressList?.find((address) => address.isPrimary) ??
      DefaultAddressList
    setAddressList(
      [
        addressLine1,
        addressLine2,
        city,
        displayState(state, postalCode.toString()),
        country,
      ].filter((address) => address && address !== '')
    )

    if (contacts && contacts && contacts?.id) {
      setContactId(contacts?.id)
    }
  }, [contacts])

  const showAdditionalDetails = () => {
    setShowAdditionalContact(true)
  }

  const closeAdditionalDetails = () => {
    setShowAdditionalContact(false)
  }

  return (
    <LeftUserProfile>
      <ProfileHeadingWrapper>
        <HeadingTextWrapper>
          <Label>{'Contact Card'}</Label>
        </HeadingTextWrapper>
        <BtnWrapper>
          {userAccessDetails.role === vars.adminRole &&
            contacts &&
            (contacts?.phoneList?.length > 0 ||
              contacts?.emailList?.length > 0 ||
              contacts?.addressList?.length > 0) && (
              <WDButtonPrimaryOutlined>
                <Button type="button" onClick={showAdditionalDetails}>
                  {'All Details'}
                </Button>
              </WDButtonPrimaryOutlined>
            )}
          {userAccessDetails.role === vars.adminRole &&
            contacts &&
            contacts?.status !== 'Client' && (
              <WDBorderButtonPrimary>
                <Button
                  type="button"
                  onClick={() => {
                    setShowEditAddress(!showEditAddress)
                  }}
                  color={
                    isDarkMode
                      ? DEFAULT_COLORS.Text.UserDark
                      : DEFAULT_COLORS.Text.Primary
                  }
                >
                  {'Edit'}
                </Button>
              </WDBorderButtonPrimary>
            )}
        </BtnWrapper>
      </ProfileHeadingWrapper>
      <ContactDetailsTable>
        <tbody>
          <tr>
            <td style={{ paddingRight: '10px' }}>{'Phone'}</td>
            <td>:</td>
            <td>
              {contacts?.phoneList?.find((contact) => contact.isPrimary)
                ?.phoneNo
                ? formatPhoneNumber(
                    contacts?.phoneList?.find((contact) => contact.isPrimary)
                      ?.phoneNo
                  )
                : 'NA'}
            </td>
          </tr>

          {/* Email Address */}
          <tr>
            <td style={{ paddingRight: '10px' }}>{'Email'}</td>
            <td>:</td>
            <td>
              {contacts?.emailList?.find((email) => email.isPrimary)?.email ??
                'NA'}
            </td>
          </tr>

          {/* Address */}
          <tr>
            <td style={{ paddingRight: '10px' }}>{'Address'}</td>
            <td>:</td>
            <td>
              {addressList.length === 0 ||
              addressList.every((address) => address === '') ? (
                'NA'
              ) : (
                <>
                  {addressList.map((address, addressIndex) => (
                    <tr key={addressIndex}>
                      <td>
                        {address === '' ? (
                          ''
                        ) : addressIndex !== addressList.length - 1 ? (
                          <>{`${address}, `}</>
                        ) : (
                          address
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </ContactDetailsTable>
      {showEditAddress && (
        <EditDetails
          contactId={contactId}
          setShowEditAddress={setShowEditAddress}
          update={updateData}
          leadDetails={contacts}
        />
      )}
      {showAdditionalContact && (
        <AdditionalAddressDetails
          contacts={contacts}
          closeAdditionalDetails={closeAdditionalDetails}
        />
      )}
    </LeftUserProfile>
  )
}
