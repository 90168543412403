import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../theme/Sizes'

interface CardProps {
  width?: string
  children?: React.ReactNode
  activeButton?: number
  buttonStatus?: string | number
  type?: 'Lead' | 'Client' | 'ProfileTask'
}

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
    white-space: nowrap;
  }
`
export const StyledButtonSmallHeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Primary};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Neutral};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[4]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonSmallHeightDisable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.NeutralStrong};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[4]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: transparent;
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${SIZES[0]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledPageButtonWhite = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} 0.6rem;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledPageButtonPrimary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Primary};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} 0.6rem;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`

export const StyledButtonDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${() => DEFAULT_COLORS.Background.Iron};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonOutLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Neutral};

  border: 1px solid ${() => DEFAULT_COLORS.Border.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonOutLineSmallHeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Neutral};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[4]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};
  cursor: pointer;
  button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    width: ${(props: { width: string }) => { return props.width ?? '100%' }};
    height: ${SIZES[6]};
    font-size: 14px;
    line-height: ${SIZES[3]};
  }
`
export const StyledButtonTransparentWithGap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};
  cursor: pointer;
  button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: ${SIZES[6]};
    font-size: 14px;
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonTransparentSubMenu = styled.div`
  display: flex;

  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};

  cursor: pointer;
  button {
    border: none;

    background: none;
    display: flex;
    width: 100%;
    height: ${SIZES[4]};
    font-size: 14px;
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[1]};
  }
`
export const StyledButtonTransparentWithoutHeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};

  cursor: pointer;
  button {
    border: none;

    background: none;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonTransparentWithoutHeightNormal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};

  cursor: pointer;
  button {
    border: none;

    background: none;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonTransparentSmallHeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};

  cursor: pointer;
  button {
    border: none;

    background: none;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonTransparentSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};

  cursor: pointer;
  button {
    border: none;

    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    font-size: 14px;
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[3]};
  }
`

export const StyledButtonAccent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  border: 1px solid ${() => DEFAULT_COLORS.Border.FunBlue};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.Elevation};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledBorderButtonPrimary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid ${() => DEFAULT_COLORS.Background.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledBorderButtonPrimarySmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  border: 1px solid ${() => DEFAULT_COLORS.Background.Primary};
  cursor: pointer;
  width: fit-content;
  height: 1.5rem;

  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Primary};
    display: flex;
    padding: ${SIZES[0]} ${SIZES[1]};
    align-items: center;
    justify-content: center;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[5]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledBorderButtonPrimaryBorderStrong = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  border: 1px solid ${() => DEFAULT_COLORS.Background.PrimaryBorderStrong};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonOutLineLarge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Neutral};

  border: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} ${SIZES[5]};
    font-size: ${SIZES[3]};
    line-height: ${SIZES[4]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props: { type: string }) =>
    props.type == 'Lead'
      ? DEFAULT_COLORS.Background.Success
      : DEFAULT_COLORS.Background.PrimaryHover};
  cursor: pointer;
  height: ${(props: { type: string }) =>
    props.type == 'ProfileTask' ? SIZES[4] : SIZES[5]};
  button {
    border: none;
    background: none;
    color: ${(props: { type: string }) =>
    props.type == 'Lead'
      ? DEFAULT_COLORS.Text.Success
      : DEFAULT_COLORS.Text.PrimaryStrong};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonBackgroundActive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.NeutralWeak};

  cursor: pointer;
  height: ${SIZES[6]};
  width: 100%;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.Neutral};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[2]};
    font-size: ${TextSizes[3]};
    line-height: ${SIZES[4]};
    Icon {
      color: ${() => DEFAULT_COLORS.Icons.Primary};
    }
  }
  &:hover {
    background: ${() => DEFAULT_COLORS.Background.PrimaryActive};
    button {
      color: ${() => DEFAULT_COLORS.Text.Primary};
    }
  }
`
export const StyledButtonBackgroundSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.PrimaryActive};

  cursor: pointer;
  height: ${SIZES[6]};
  width: 100%;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.Primary};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[2]};
    font-size: ${TextSizes[3]};
    line-height: ${SIZES[4]};
    Icon {
      color: ${() => DEFAULT_COLORS.Icons.Primary};
    }
  }
`
export const StyledButtonBackgroundSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.PrimaryHover};
  cursor: pointer;
  height: ${SIZES[3]};
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.PrimaryStrong};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonBgWarningSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.RoseWhite};
  height: ${SIZES[3]};
  button {
    border: none;
    color: ${() => DEFAULT_COLORS.Text.Warning};
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonBgNeutralStrong = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.NeutralStrong};
  height: ${SIZES[3]};
  button {
    border: none;
    color: ${() => DEFAULT_COLORS.Text.Neutral};
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonPrimaryOutlined = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${DEFAULT_COLORS.Text.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[2]};
    padding: ${SIZES[0]} ${SIZES[3]};
  }
`
export const StyledButtonPrimaryText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${DEFAULT_COLORS.Background.Primary};

  border: 1px solid ${DEFAULT_COLORS.Border.Primary};
  cursor: pointer;
  button {
    border: ${DEFAULT_COLORS.Border.Primary};
    background: none;
    color: ${DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonGrayTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${DEFAULT_COLORS.Text.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} 0;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[3]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonGray = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  border: 1px solid ${() => DEFAULT_COLORS.Border.Neutral};
  button {
    border: none;
    background: transparent;
    color: ${DEFAULT_COLORS.Text.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[3]};
    font-weight: ${TextWeight[1]};
    &:focus {
      color: ${DEFAULT_COLORS.Border.BlackDark};
      background-color: ${DEFAULT_COLORS.Background.Platinum};
      background-image: none;
      border-color: ${DEFAULT_COLORS.Border.SilverChalice};
    }
  }
`
export const StyledPageButtonPrimaryWithExtraPadding = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${SIZES[4]};
    font-weight: ${TextWeight[1]};
    background: ${DEFAULT_COLORS.Background.Primary};
  }
  button:disabled {
    border: none;
    background: none;
    color: ${DEFAULT_COLORS.Text.NeutralDisabled};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[3]};
    line-height: ${SIZES[4]};
    font-weight: ${TextWeight[1]};
    background: ${DEFAULT_COLORS.Background.NeutralStrong};
    border: 1px solid ${DEFAULT_COLORS.Border.NeutralWeak};
  }
`
export const StyledButtonNeutralMed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${DEFAULT_COLORS.Background.NeutralMedium};

  cursor: pointer;
  button {
    border: ${DEFAULT_COLORS.Border.NeutralMedium};
    background: none;
    color: ${DEFAULT_COLORS.Text.NeutralInverted};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`

export const StyledButtonBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  border: 1px solid ${DEFAULT_COLORS.Border.Primary};
  cursor: pointer;
  width: 25%;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: ${DEFAULT_COLORS.Border.Primary};
    background: none;
    color: ${DEFAULT_COLORS.Background.Primary};
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[0]};
    font-family: 'SourceSansPro-SemiBold';
  }
`
export const WDButton = ({ children }: CardProps) => {
  return <StyledButton>{children}</StyledButton>
}

export const WDButtonIcon = ({ children }: CardProps) => {
  return <StyledButtonIcon>{children}</StyledButtonIcon>
}

export const WDPageButtonWhite = ({ children }: CardProps) => {
  return <StyledPageButtonWhite>{children}</StyledPageButtonWhite>
}

export const WDPageButtonPrimary = ({ children }: CardProps) => {
  return <StyledPageButtonPrimary>{children}</StyledPageButtonPrimary>
}

export const WDButtonAccent = ({ children }: CardProps) => {
  return <StyledButtonAccent>{children}</StyledButtonAccent>
}

export const WDBorderButtonPrimary = ({ children }: CardProps) => {
  return <StyledBorderButtonPrimary>{children}</StyledBorderButtonPrimary>
}
export const WDBorderButtonPrimarySmall = ({ children }: CardProps) => {
  return (
    <StyledBorderButtonPrimarySmall>{children}</StyledBorderButtonPrimarySmall>
  )
}
export const WDBorderButtonPrimaryStrong = ({ children }: CardProps) => {
  return (
    <StyledBorderButtonPrimaryBorderStrong>
      {children}
    </StyledBorderButtonPrimaryBorderStrong>
  )
}

export const WDButtonDisabled = ({ children }: CardProps) => {
  return <StyledButtonDisabled>{children}</StyledButtonDisabled>
}

export const WDButtonOutLine = ({ children }: CardProps) => {
  return <StyledButtonOutLine>{children}</StyledButtonOutLine>
}
export const WDButtonOutLineSmallHeight = ({ children }: CardProps) => {
  return (
    <StyledButtonOutLineSmallHeight>{children}</StyledButtonOutLineSmallHeight>
  )
}

export const WDButtonTransparent = ({ children, width }: CardProps) => {
  return <StyledButtonTransparent width={width}>{children}</StyledButtonTransparent>
}
export const WDButtonTransparentWithGap = ({ children }: CardProps) => {
  return (
    <StyledButtonTransparentWithGap>{children}</StyledButtonTransparentWithGap>
  )
}

export const WDButtonTransparentSubMenu = ({ children }: CardProps) => {
  return (
    <StyledButtonTransparentSubMenu>{children}</StyledButtonTransparentSubMenu>
  )
}
export const WDButtonTransparentWithoutHeight = ({ children }: CardProps) => {
  return (
    <StyledButtonTransparentWithoutHeight>
      {children}
    </StyledButtonTransparentWithoutHeight>
  )
}

export const WDButtonTransparentWithoutHeightNormal = ({
  children,
}: CardProps) => {
  return (
    <StyledButtonTransparentWithoutHeightNormal>
      {children}
    </StyledButtonTransparentWithoutHeightNormal>
  )
}

export const WDButtonTransparentSmall = ({ children }: CardProps) => {
  return <StyledButtonTransparentSmall>{children}</StyledButtonTransparentSmall>
}
export const WDButtonTransparentHeight = ({ children }: CardProps) => {
  return (
    <StyledButtonTransparentSmallHeight>
      {children}
    </StyledButtonTransparentSmallHeight>
  )
}
export const WDButtonOutLineLarge = ({ children }: CardProps) => {
  return <StyledButtonOutLineLarge>{children}</StyledButtonOutLineLarge>
}

export const WDButtonBackground = ({ children, type }: CardProps) => {
  return <StyledButtonBackground type={type}>{children}</StyledButtonBackground>
}
export const WDButtonBackgroundActive = ({ children }: CardProps) => {
  return <StyledButtonBackgroundActive>{children}</StyledButtonBackgroundActive>
}
export const WDButtonBackgroundSelected = ({ children }: CardProps) => {
  return (
    <StyledButtonBackgroundSelected>{children}</StyledButtonBackgroundSelected>
  )
}
export const WDButtonBackgroundSmall = ({ children }: CardProps) => {
  return <StyledButtonBackgroundSmall>{children}</StyledButtonBackgroundSmall>
}
export const WDButtonBgWarningSmall = ({ children }: CardProps) => {
  return <StyledButtonBgWarningSmall>{children}</StyledButtonBgWarningSmall>
}
export const WDButtonBgNeutralStrong = ({ children }: CardProps) => {
  return <StyledButtonBgNeutralStrong>{children}</StyledButtonBgNeutralStrong>
}
export const WDButtonPrimaryOutlined = ({ children }: CardProps) => {
  return <StyledButtonPrimaryOutlined>{children}</StyledButtonPrimaryOutlined>
}
export const WDButtonPrimaryText = ({ children }: CardProps) => {
  return <StyledButtonPrimaryText>{children}</StyledButtonPrimaryText>
}
export const WDButtonGrayTransparent = ({ children }: CardProps) => {
  return <StyledButtonGrayTransparent>{children}</StyledButtonGrayTransparent>
}
export const WDButtonSmallHeight = ({ children }: CardProps) => {
  return <StyledButtonSmallHeight>{children}</StyledButtonSmallHeight>
}
export const WDButtonSmallHeightDisable = ({ children }: CardProps) => {
  return (
    <StyledButtonSmallHeightDisable>{children}</StyledButtonSmallHeightDisable>
  )
}
export const WDButtonNeutralMed = ({ children }: CardProps) => {
  return <StyledButtonNeutralMed>{children}</StyledButtonNeutralMed>
}
export const WDPageButtonPrimaryWithExtraPadding = ({
  children,
}: CardProps) => {
  return (
    <StyledPageButtonPrimaryWithExtraPadding>
      {children}
    </StyledPageButtonPrimaryWithExtraPadding>
  )
}

export const WDButtonMediumPrimaryBorder = ({ children }: CardProps) => {
  return <StyledButtonBorder>{children}</StyledButtonBorder>
}
