export const addressValidationKeys = [
  'addressType',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'postalCode',
]

export const phoneValidationKeys = ['phoneType', 'phoneNo']
export const emailValidationKeys = ['emailType', 'email']

export const individualValidationKeys = [
  // 'addressList',
  // 'phoneList',
  // 'emailList',
  // 'honorific',
  'firstName',
  'lastName',
  // 'preferedPronouce',
  // 'jobTitle',
  // 'account',
  // 'accountTier',
  // 'gender',
  // 'leadSource',
  // 'martialStatus',
  // 'dateOfBirth',
  // 'interestHobbies',
  // 'householdMembers',
  // 'totalAssets',
  // 'aumSize',
  // 'financialInterests',
  // 'advisoryFirm',
  // 'investmentExperience',
  // 'investmentObjectives',
  // 'netWorth',
  // 'riskTolerance',
  // 'timeHorizon',
]

export const businessValidationKeys = [
  'businessName',
  // 'taxIdNumber',
  // 'leadSource',
  // 'referredby',
  // 'clientSince',
  // 'totalAssets',
  // 'aumSize',
  // 'financialInterests',
  // 'advisoryFirm',
  // 'investmentExperience',
  // 'investmentObjectives',
  // 'netWorth',
  // 'riskTolerance',
  // 'timeHorizon',
]

export const trustValidationKeys = [
 
  'trustName',
  // 'taxIdNumber',
  // 'leadSource',
  // 'referredby',
  // 'clientSince',
  // 'totalAssets',
  // 'aumSize',
  // 'financialInterests',
  // 'advisoryFirm',
  // 'investmentExperience',
  // 'investmentObjectives',
  // 'netWorth',
  // 'riskTolerance',
  // 'timeHorizon',
]

// export const newRecruitValidationKeys = [
//   'addressList',
//   'phoneList',
//   'emailList',
//   'honorific',
//   'firstName',
//   'lastName',
//   'preferedPronouce',
//   'jobTitle',
//   'account',
//   'leadSource',
//   'totalAssets',
//   'aumSize',
//   'financialInterests',
//   'advisoryFirm',
//   'investmentExperience',
//   'investmentObjectives',
//   'netWorth',
//   'riskTolerance',
//   'timeHorizon',
// ]

// export const individualContactValidationKeys = [
//   'honorific',
//   'firstName',
//   'lastName',
//   'preferedPronouce',
  
//   'jobTitle',
//   'account',
//   'accountTier',
//   'gender',
//   'leadSource',
//   'martialStatus',
//   'dateOfBirth',
//   'interestHobbies',
//   'householdMembers',
  
// ]

// export const businessContactValidationKeys = [
//   'businessName',
//   'taxIdNumber',
//   'leadSource',
//   'referredby',
//   'clientSince',
// ]

// export const trustContactValidationKeys = [
//   'trustName',
//   'taxIdNumber',
//   'leadSource',
//   'referredby',
//   'clientSince',
//   'totalAssets',
// ]

// export const newRecruitContactValidationKeys = [
//   'honorific',
//   'firstName',
//   'lastName',
//   'preferedPronouce',
  
//   'jobTitle',
//   'account',
//   'leadSource',
// ]

// export const clientValidationKeys = [
//   'accountTier',
//   'gender',
//   'leadSource',
//   'referredby',
//   'interestHobbies',
//   'householdMembers',
//   'clientSince',
// ]
