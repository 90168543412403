import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { uniqueArrayOfObjects, vars } from '../../assets/utils/CommonFunctions'
import Table from '../../components/table/Table'
import Loader from '../../composites/loader/Loader'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import FilterContext from '../../services/filterContext/FilterContext'
import {
  GET_All_OPPORTUNITIES_BY_BOOK_COUNT,
  GET_All_OPPORTUNITIES_LIST,
  GET_All_OPPORTUNITIES_LIST_COUNT,
  GET_OPPORTUNITIES_LIST_BY_BOOK,
  GET_OPPORTUNITIES_REPCODE_LIST,
} from '../../services/graphQl/CustomerGraphQl'
import UserContext from '../../services/user/UserContext'
import AddOpportunitiesModal from './AddOpportunitiesModal'
import { OpportunitiesDetailsColumn } from './OpportunitiesDetailsData'

const OpportunitiesDetails = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string
  setSelectedTab: (value: string) => void
}) => {
  const [addOpportunitiesModal, setAddOpportunitiesModal] = useState(false)
  const [summaryFilters, setSummaryFilters] = useState<
    { id: string; value: string | string[] | undefined | null }[]
  >([])
  const { refetchFlag } = useContext(DashboardContext)
  const [stageFilter, setStageFilter] = useState({ id: '2', value: 'Active' })
  const { user } = useContext(UserContext)
  const {
    setOpportunitiesPayload,
    filterPayload,
    setFilterPayload,
    resetTableFilter,
    setScrollPosition,
  } = useContext(FilterContext)
  const [repCodeData, setRepCodeData] = useState<any[]>([])
  const [opportunitiesContents, setOpportunitiesContents] = useState<any[]>([])
  const [opportunitiesSize, setOpportunitiesSize] = useState<number>(0)

  const [getRepCodeList, { data: repCodeList }] = useLazyQuery(
    GET_OPPORTUNITIES_REPCODE_LIST
  )

  const getOpportunityQueries = () => {
    if (selectedTab == 'Created By') {
      return [GET_All_OPPORTUNITIES_LIST, GET_All_OPPORTUNITIES_LIST_COUNT]
    } else {
      return [
        GET_OPPORTUNITIES_LIST_BY_BOOK,
        GET_All_OPPORTUNITIES_BY_BOOK_COUNT,
      ]
    }
  }

  const [
    listData,
    { data: opportunitiesData, loading: loadingOpportunitiesData },
  ] = useLazyQuery(getOpportunityQueries()[0], {
    variables: {
      payload: setOpportunitiesPayload(+stageFilter.id, selectedTab),
      limit: vars.InitRecordCount,
      offset: filterPayload.offset,
    },
    fetchPolicy: 'no-cache',
  })
  const [
    fetchOpportunitiesCount,
    { data: opportunitiesCount, loading: loadingOpportunitiesCount },
  ] = useLazyQuery<any>(getOpportunityQueries()[1])

  const fetchData = (scrollHeight: number) => {
    if (
      !loadingOpportunitiesData &&
      !loadingOpportunitiesCount &&
      filterPayload.offset + vars.InitRecordCount < opportunitiesSize &&
      opportunitiesSize > vars.InitRecordCount
    ) {
      setScrollPosition(scrollHeight)
      setFilterPayload((prev) => ({
        ...prev,
        offset: filterPayload.offset + vars.InitRecordCount,
      }))
    }
  }

  useEffect(() => {
    //rep code
    setOpportunitiesContents([])
    if (
      opportunitiesData &&
      opportunitiesData?.opportunities_opportunities &&
      opportunitiesData?.opportunities_opportunities.length > 0
    ) {
      const opportunitiesList =
        opportunitiesData.opportunities_opportunities.map((item: any) => ({
          ...item,
          partyid: item?.contactid,
        }))
      filterPayload.offset > 0
        ? setOpportunitiesContents((prev) => [prev, ...opportunitiesList])
        : setOpportunitiesContents(opportunitiesList)
    }
    if (
      opportunitiesData &&
      opportunitiesData?.opportunities_vw_opportunities &&
      opportunitiesData?.opportunities_vw_opportunities.length > 0
    ) {
      const opportunitiesList =
        opportunitiesData.opportunities_vw_opportunities.map((item: any) => ({
          ...item,
          partyid: item?.contactid,
        }))
      filterPayload.offset > 0
        ? setOpportunitiesContents((prev) => [...prev, opportunitiesList])
        : setOpportunitiesContents(opportunitiesList)
    }
    if (
      opportunitiesCount &&
      opportunitiesCount?.opportunities_opportunities_aggregate
    ) {
      setOpportunitiesSize(
        opportunitiesCount?.opportunities_opportunities_aggregate?.aggregate
          ?.count
      )
    }
    if (
      opportunitiesCount &&
      opportunitiesCount?.opportunities_vw_opportunities_aggregate
    ) {
      setOpportunitiesSize(
        opportunitiesCount?.opportunities_vw_opportunities_aggregate?.aggregate
          ?.count
      )
    }
  }, [opportunitiesCount, opportunitiesData])

  useEffect(() => {
    resetTableFilter()
  }, [selectedTab])

  useEffect(() => {
    fetchOpportunitiesCount({
      variables: {
        payload: setOpportunitiesPayload(+stageFilter.id, selectedTab),
      },
    })
    listData()
  }, [refetchFlag, stageFilter.id, selectedTab])

  useEffect(() => {
    if (
      repCodeList &&
      repCodeList?.opportunities_vw_opportunities &&
      repCodeList.opportunities_opportunities
    ) {
      const temp = [
        ...repCodeList.opportunities_vw_opportunities,
        ...repCodeList.opportunities_opportunities,
      ]

      setRepCodeData(
        uniqueArrayOfObjects(temp)
          ?.filter((rep: any) => rep.repcode)
          ?.map((ele: any) => {
            return {
              label: ele.repcode,
              value: ele.repcode,
            }
          })
      )
    }
  }, [repCodeList])

  useEffect(() => {
    getRepCodeList({
      variables: {
        adId: user.username,
      },
    })
  }, [getRepCodeList, user.username])

  return (
    <>
      {opportunitiesContents.length > 0 && loadingOpportunitiesData && (
        <Loader position="absolute" />
      )}
      <Table
        source="opportunities"
        tableProperties={{
          tableHeader: 'Opportunities',
          globalSearchRequired: true,
          tileFilterRequired: true,
          tileFilterOptions: [
            { id: '2', value: 'Active' },
            { id: '1', value: 'Closed Won' },
            { id: '0', value: 'Closed Lost' },
          ],
          tileFilterValue: stageFilter,
          tileFilterCallback: setStageFilter,
          paginatorRequired: true,
          headerButtonRequired: true,
          buttonLabel: 'New Opportunity',
          createCallback: () => setAddOpportunitiesModal(true),
        }}
        key={opportunitiesContents?.length}
        fetchData={fetchData}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tableColumns={
          (opportunitiesData &&
            opportunitiesData?.opportunities_opportunities &&
            opportunitiesData?.opportunities_opportunities.length > 0) ||
          (opportunitiesData &&
            opportunitiesData?.opportunities_vw_opportunities &&
            opportunitiesData?.opportunities_vw_opportunities.length > 0)
            ? OpportunitiesDetailsColumn
            : []
        }
        allDataCount={opportunitiesSize}
        allData={opportunitiesContents ?? []}
        isLoading={
          opportunitiesContents.length == 0 && loadingOpportunitiesData
        }
        filterData={summaryFilters}
        setSummaryFilters={setSummaryFilters}
        repCodeList={repCodeData ?? []}
        sortingFields={[
          'name',
          'contactname',
          'assignedtoname',
          'nextsteps',
          'stage',
          'source',
          'probablity',
          'updateddate',
          'amount',
          'repcode',
          'closedate',
        ]}
      />
      {addOpportunitiesModal && (
        <AddOpportunitiesModal
          setAddOpportunitiesModal={setAddOpportunitiesModal}
        />
      )}
    </>
  )
}

export default OpportunitiesDetails
