import { useEffect, useState } from 'react'
import { TabWrapper } from '../../global'
import {
  leadPartyLoader,
  leadsData,
  partiesData,
} from '../../reducers/leadPartySlice'
import { ContentItemWrapper, Wrapper } from '../../shared/GlobalStyle'
import { useAppSelector } from '../../store/store'
import LeadParty from './LeadParty'
import NewRecruit from './NewRecruit'
import PersonalContact from './PersonalContact'
import { TitleWrapper } from './ProfileTab/styles'

const Lead = () => {
  const [selectedTab, setSelectedTab] = useState('Lead/Party')
  const leadsDetails = useAppSelector(leadsData)
  const partyDetails = useAppSelector(partiesData)
  const [leadPartyData, setLeadPartyData] = useState<any[]>([])
  const [newRecruitData, setNewRecruitData] = useState<any[]>([])
  const [personalContactData, setPersonalContactData] = useState<any[]>([])
  const [filterRepCodeData, setFilterRepCodeData] = useState<any[]>([])
  const leadPartyLoading = useAppSelector(leadPartyLoader)

  useEffect(() => {
    const allDetails = [...leadsDetails, ...partyDetails].filter(
      (dataList) => dataList.firstname?.toString().trim() !== ''
    )

    const LeadPartyDetails = allDetails.filter(
      (dataList) =>
        dataList.type !== 'New Recruit' && dataList.type !== 'Personal Contact'
    )

    const NewRecruitDetails = allDetails.filter(
      (dataList) => dataList.type === 'New Recruit'
    )
    const PersonalContactDetails = allDetails.filter(
      (dataList) => dataList.type === 'Personal Contact'
    )
    setNewRecruitData(NewRecruitDetails)
    setPersonalContactData(PersonalContactDetails)
    setLeadPartyData(LeadPartyDetails)
  }, [leadsDetails, partyDetails])

  useEffect(() => {
    const filterRepCode: any[] = []

    //rep code
    if (leadPartyData.length > 0) {
      // filterRepCode.push({ value: -1, label: 'All' })
      leadPartyData.map((item) => {
        if (item.advisor.toString().trim() != '' && item.advisor != '--')
          filterRepCode.push({ value: item.advisor, label: item.advisor })
      })
    }
    //make unique list
    setFilterRepCodeData(
      [...new Set(filterRepCode.map((item) => item.value))].map((value) =>
        filterRepCode.find((item) => item.value === value)
      )
    )
    //type

    //sub type
  }, [leadPartyData])

  const tabChange = (value: string) => {
    setSelectedTab(value)
  }
  return (
    <Wrapper>
      <TabWrapper>
        <button
          onClick={() => tabChange('Lead/Party')}
          className={
            'nav-link' + (selectedTab === 'Lead/Party' ? ' selected' : '')
          }
        >
          <TitleWrapper>{'Lead/Party'}</TitleWrapper>
        </button>
        <button
          onClick={() => tabChange('New Recruit')}
          className={
            'nav-link' + (selectedTab === 'New Recruit' ? ' selected' : '')
          }
        >
          <TitleWrapper>{'New Recruit'}</TitleWrapper>
        </button>
        <button
          onClick={() => tabChange('Personal Contact')}
          className={
            'nav-link' + (selectedTab === 'Personal Contact' ? ' selected' : '')
          }
        >
          <TitleWrapper>{'Personal Contact'}</TitleWrapper>
        </button>
      </TabWrapper>
      <ContentItemWrapper>
        {selectedTab === 'Lead/Party' && (
          <LeadParty
            loadingData={leadPartyLoading}
            RepCodeData={filterRepCodeData}
            leadPartyData={leadPartyData}
          />
        )}
        {selectedTab === 'New Recruit' && (
          <NewRecruit
            newRecruitData={newRecruitData}
            loadingData={leadPartyLoading}
            RepCodeData={filterRepCodeData}
          />
        )}
        {selectedTab === 'Personal Contact' && (
          <PersonalContact
            personalContactData={personalContactData}
            loadingData={leadPartyLoading}
            RepCodeData={filterRepCodeData}
          />
        )}
      </ContentItemWrapper>
    </Wrapper>
  )
}

export default Lead
