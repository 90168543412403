import './style.css'
import Label from '../../components/label/Label'
import { useEffect, useRef, useState } from 'react'
import { NoteMainWrapper, NoteWrapper } from './styles'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import Button from '../../components/button/Button'
import { WDSubFormLabelHeading } from '../../components/ui/WDLabel'
import { ContactInfoProps } from './INotes'
import {
  CollapseButtonWrapper,
  CollapseHeaderWrapper,
  CollapseWrapper,
} from '../individualInformation/styles'
import Image from '../../components/image/Image'
import { CollaspPanelWrapper, Icons } from '../../shared/GlobalStyle'
import { WDContactInfoWrapper } from '../../components/ui/WDWrapper'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { DeltaStatic } from 'quill'
import { Footer } from '../../pages/partyProfile/ProfileTab/styles'

const Notes = ({ updateData, leadInfoData }: ContactInfoProps) => {
  const [isNoteOpen, setIsNoteOpen] = useState(true)
  const [delta, setDelta] = useState<DeltaStatic>()
  const editorRef = useRef<ReactQuill | null>(null)

  const allColors = [
    '#000000',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#444444',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466',
  ]

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [
        {
          color: allColors,
        },
      ],
      [
        {
          background: allColors,
        },
      ],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'background',
    'align',
    'size',
    'font',
  ]

  function JSONChecker(text: string) {
    if (typeof text !== 'string') {
      return false
    }
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    const newContent: DeltaStatic =
      leadInfoData?.notes && JSONChecker(leadInfoData?.notes ?? '')
        ? JSON.parse(leadInfoData?.notes)
        : ''
    newContent && setDelta(newContent)
  }, [leadInfoData])

  const handleProcedureContentChange = () => {
    if (editorRef.current) {
      const newContent = editorRef.current.getEditor().getContents()
      setDelta(newContent)
      updateData({ notes: JSON.stringify(newContent) ?? '' })
    }
  }

  return (
    <CollaspPanelWrapper>
      <WDContactInfoWrapper>
        <CollapseWrapper onClick={() => setIsNoteOpen(!isNoteOpen)}>
          <CollapseHeaderWrapper>
            <WDSubFormLabelHeading>
              <Label fontFamily="SourceSansPro-SemiBold">{'Notes'}</Label>
            </WDSubFormLabelHeading>
          </CollapseHeaderWrapper>
          <CollapseButtonWrapper>
            <WDButtonTransparent>
              <Button type="button" onClick={() => setIsNoteOpen(!isNoteOpen)}>
                <Image
                  image={isNoteOpen ? Icons.ChevronUp : Icons.ChevronDown}
                  alt="Chevron Down"
                />
              </Button>
            </WDButtonTransparent>
          </CollapseButtonWrapper>
        </CollapseWrapper>
        {isNoteOpen && (
          <NoteMainWrapper>
            <NoteWrapper className={'create-note'}>
              <ReactQuill
                theme="snow"
                placeholder="Take a note"
                ref={editorRef}
                value={delta}
                modules={modules}
                formats={formats}
                onChange={handleProcedureContentChange}
              />
              <Footer>{'The input must not exceed 8000 characters.'}</Footer>
            </NoteWrapper>
          </NoteMainWrapper>
        )}
      </WDContactInfoWrapper>
    </CollaspPanelWrapper>
  )
}

export default Notes
