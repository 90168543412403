import { useLazyQuery, useMutation } from '@apollo/client'
import { useContext, useState, useEffect } from 'react'
import { OptionProps } from '../../../composites/notes/INotes'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { SvgClose } from '../../../components/svg/SvgClose'
import {
  WDButton,
  WDButtonTransparentWithoutHeight,
} from '../../../components/ui/WDButton'
import {
  WDLabelBold,
  WDSubFormLabelHeading,
} from '../../../components/ui/WDLabel'
import ClientInfo from '../../../composites/opportunitiesDetails/ClientInfo'
import { ClientSearchByNameResponseInterface } from '../../../services/clientAndAccountSearch/IClientAndAccountSearchContext'
import {
  DELETE_PARTY_RELATIONSHIP,
  GET_LEAD_RELATIONSHIP,
  UPDATE_PARTY_RELATIONSHIP,
} from '../../../services/graphQl/CustomerGraphQl'
import { getLeadRelationshipInterface } from '../../../services/graphQl/Interfaces/IContact'
import SetToastContext from '../../../services/toast/SetToastContext'
import { DEFAULT_COLORS } from '../../../theme/Colors'
import { SIZES } from '../../../theme/Sizes'
import { LeadInfoProps } from '../overviewSection/IEditDetails'
import {
  ContentFormSectionWrapper,
  ContentFormWrapper,
  ContentWrapper,
  FooterSectionEndWrapper,
  FooterSectionWrapper,
  FooterWrapper,
  HeaderWrapper,
  MainWrapper,
  OverlayWrapper,
  StyledButtonWrapper,
  Wrapper,
} from './styles'
import { ClientDetailsResponseInterface } from '../../../services/clientContext/IClientsContext'
import { RelationshipProps } from '../relationship/LeadRelationship'
import { vars } from '../../../assets/utils/CommonFunctions'

interface PartyRelationshipProps {
  closeUpdateMemberPopup: () => void
  leadDetails?: LeadInfoProps
  partyDetails?: ClientDetailsResponseInterface
  relationShipFrom?: RelationshipProps
  relationShipTo?: RelationshipProps
  type: string
}

export const initialRelationshipValues: RelationshipProps = {
  id: 0,
  relationshipfrom: '',
  relationshipto: '',
  sourceid: 0,
  relationshipid: 0,
  realtionshipname: '',
  relationshipfromname: '',
  realtionshiptoname: '',
  isparty: false,
}

const UpdateMember = ({
  closeUpdateMemberPopup,
  leadDetails,
  relationShipFrom,
  relationShipTo,
  type,
}: PartyRelationshipProps) => {
  const { setToast } = useContext(SetToastContext)
  const [relationFrom, setRelationFrom] = useState<OptionProps[]>([])
  const [relationTo, setRelationTo] = useState<OptionProps[]>([])
  const [displayedRelationShipFrom, setDisplayedRelationShipFrom] =
    useState<RelationshipProps>(initialRelationshipValues)
  const [displayedRelationShipTo, setDisplayedRelationShipTo] =
    useState<RelationshipProps>(initialRelationshipValues)
  const [contactNameError, setContactNameError] = useState('')

  const [selectedClient, setSelectedClient] = useState<
    ClientSearchByNameResponseInterface[]
  >([])

  const updateSelectedClient = (val: ClientSearchByNameResponseInterface) => {
    setContactNameError('')
    setSelectedClient((prevState) => {
      return [...prevState, val]
    })
  }

  const addNewClient = () => {
    setSelectedClient([])
  }

  const [relationshipFrom, { data: relationFromResult }] =
    useLazyQuery<getLeadRelationshipInterface>(GET_LEAD_RELATIONSHIP)

  useEffect(() => {
    relationShipFrom && setDisplayedRelationShipFrom(relationShipFrom)
    relationShipTo && setDisplayedRelationShipTo(relationShipTo)
    setSelectedClient([
      {
        id: Number(relationShipFrom?.relationshipto) ?? 0,
        firstname: relationShipTo?.relationshipfromname ?? '',
        type: type,
        lastname: '',
        familyname: '',
        accounttier: '',
        advisor: '',
        account: '',
        middlename: '',
      },
    ])
  }, [relationShipFrom, relationShipTo, type])

  useEffect(() => {
    relationshipFrom()
  }, [relationshipFrom])

  useEffect(() => {
    if (relationFromResult) {
      const updatedList: OptionProps[] = []
      relationFromResult.lead_relationship.forEach((val) => {
        updatedList.push({
          label: val.relationship.toString(),
          value: val.id.toString(),
        })
      })
      setRelationFrom(updatedList)
      setRelationTo(updatedList)
    }
  }, [relationFromResult])

  const updateOnData = (dataObj: OptionProps, relation: string) => {
    if (relation === 'relationShipFrom') {
      setDisplayedRelationShipFrom({
        ...(displayedRelationShipFrom ?? initialRelationshipValues),
        relationshipid: Number(dataObj.label),
        realtionshipname: dataObj.value,
      })
    } else {
      setDisplayedRelationShipTo({
        ...(displayedRelationShipTo ?? initialRelationshipValues),
        relationshipid: Number(dataObj.label),
        realtionshipname: dataObj.value,
      })
    }
  }

  const [UpdateMemberData] = useMutation(UPDATE_PARTY_RELATIONSHIP)
  const [DeleteMemberData] = useMutation(DELETE_PARTY_RELATIONSHIP)

  const updateMemberFuc = () => {
    let isValid = true
    if (!selectedClient.length) {
      setContactNameError('Contact name is required.')
      isValid = false
    }
    if (vars.validationRequired && !isValid) {
      setToast(false, 'Please enter mandatory detail!')
      return
    }
    const payload1 = {
      id: displayedRelationShipFrom?.id ?? 0,
      relationshipfrom: displayedRelationShipFrom.relationshipfrom ?? '',
      relationshipfromname:
        displayedRelationShipFrom.relationshipfromname ?? '',
      relationshipto: selectedClient[0]?.id.toString() ?? '',
      relationshiptoname: selectedClient[0]?.firstname ?? '',
      sourceid: displayedRelationShipFrom.sourceid ?? 0,
      relationshipid: displayedRelationShipFrom?.relationshipid ?? 0,
      realtionshipname: displayedRelationShipFrom?.realtionshipname ?? '',
      isparty: displayedRelationShipFrom.isparty,
    }
    const payload2 = {
      id: displayedRelationShipTo.id ?? 0,
      relationshipfrom: selectedClient[0]?.id.toString() ?? '',
      relationshipto: displayedRelationShipTo.relationshipto ?? '',
      relationshipfromname: selectedClient[0]?.firstname ?? '',
      relationshiptoname: displayedRelationShipTo.realtionshiptoname ?? '',
      sourceid: displayedRelationShipTo.sourceid ?? 0,
      relationshipid: displayedRelationShipTo?.relationshipid ?? 0,
      realtionshipname: displayedRelationShipTo?.realtionshipname ?? '',
      isparty: displayedRelationShipTo.isparty,
    }
    const payloads: RelationshipProps[] = [payload1, payload2]

    const updatedDependent = payloads.map((payload) => {
      return UpdateMemberData({
        variables: payload,
      })
    })

    Promise.all(updatedDependent)
      .then((allResponse) => {
        if (
          allResponse.every(
            (res) => res.data.update_lead_partyrelationship.affected_rows
          )
        ) {
          setToast(true, 'Dependent Details Updated Successfully!')
          closeUpdateMemberPopup()
          setSelectedClient([])
        } else {
          closeUpdateMemberPopup()
          setToast(false, 'Something went wrong!')
        }
      })
      .catch(() => {
        closeUpdateMemberPopup()
        setToast(false, 'Something went wrong!')
      })
  }
  const deleteDependent = () => {
    const deleteDependentData = [relationShipFrom, relationShipTo].map(
      (payload) => {
        return DeleteMemberData({
          variables: {
            id: payload?.id,
          },
        })
      }
    )
    Promise.all(deleteDependentData)
      .then(() => {
        setToast(true, 'Dependent Details Deleted Successfully!')
        closeUpdateMemberPopup()
        setSelectedClient([])
      })
      .catch(() => {
        closeUpdateMemberPopup()
        setToast(false, 'Something went wrong!')
      })
  }
  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <HeaderWrapper>
            <WDSubFormLabelHeading>
              <Label>{'Update Family Member'}</Label>
            </WDSubFormLabelHeading>
            <WDButtonTransparentWithoutHeight>
              <Button type="button" onClick={() => closeUpdateMemberPopup()}>
                <SvgClose fillColor={DEFAULT_COLORS.Icons.Black} />
              </Button>
            </WDButtonTransparentWithoutHeight>
          </HeaderWrapper>
          <ContentWrapper>
            <ContentFormWrapper>
              <ContentFormSectionWrapper>
                <Label>Contact name</Label>
                <ClientInfo
                  errorMsg={contactNameError}
                  header={selectedClient[0]?.type}
                  clientName={selectedClient[0]?.firstname}
                  clientId={selectedClient[0]?.id}
                  onCancel={addNewClient}
                  type={selectedClient[0]?.type ? selectedClient[0]?.type : ''}
                  updateSelectedClient={updateSelectedClient}
                />
              </ContentFormSectionWrapper>
              {displayedRelationShipFrom ? (
                <>
                  <ContentFormWrapper align={'center'} direction={'row'}>
                    <WDLabelBold>{`${leadDetails?.firstName} ${leadDetails?.lastName}`}</WDLabelBold>{' '}
                    &nbsp; is &nbsp;
                    <SelectDropdown
                      display="inline"
                      width="200px"
                      height={'2.5rem'}
                      borderRadius={SIZES[1]}
                      borderColor={DEFAULT_COLORS.Border.Neutral}
                      value={{
                        label:
                          displayedRelationShipFrom?.realtionshipname ?? '',
                        value:
                          displayedRelationShipFrom?.relationshipid.toString() ??
                          '',
                      }}
                      options={relationFrom}
                      placeholder={'Relationship'}
                      onChange={(value) =>
                        updateOnData(
                          {
                            label: value.value,
                            value: value.label,
                          },
                          'relationShipFrom'
                        )
                      }
                    />
                    &nbsp; of &nbsp;
                    <WDLabelBold>
                      {`${selectedClient[0]?.firstname ?? '--'}`}
                    </WDLabelBold>
                    &nbsp; and,
                  </ContentFormWrapper>
                  <ContentFormWrapper align={'center'} direction={'row'}>
                    <WDLabelBold>
                      {`${selectedClient[0]?.firstname ?? '--'}`}
                    </WDLabelBold>
                    &nbsp; is &nbsp;
                    <SelectDropdown
                      height={'2.5rem'}
                      width="200px"
                      borderRadius={SIZES[1]}
                      borderColor={DEFAULT_COLORS.Border.Neutral}
                      options={relationTo}
                      value={{
                        label: displayedRelationShipTo?.realtionshipname ?? '',
                        value:
                          displayedRelationShipTo?.relationshipid.toString() ??
                          '',
                      }}
                      placeholder={'Relationship'}
                      onChange={(value) =>
                        updateOnData(
                          {
                            label: value.value,
                            value: value.label,
                          },
                          'relationShipTo'
                        )
                      }
                    />{' '}
                    &nbsp; of &nbsp;
                    <WDLabelBold>{`${leadDetails?.firstName} ${leadDetails?.lastName}`}</WDLabelBold>
                  </ContentFormWrapper>
                </>
              ) : null}
            </ContentFormWrapper>
          </ContentWrapper>
          <FooterWrapper>
            <FooterSectionWrapper>
              <StyledButtonWrapper>
                <Button
                  type="button"
                  bgColor={DEFAULT_COLORS.Background.DangerStrong}
                  onClick={deleteDependent}
                >
                  Remove From Family
                </Button>
              </StyledButtonWrapper>
            </FooterSectionWrapper>
            <FooterSectionEndWrapper>
              {/* <WDBorderButtonPrimary>
                <Button type="button" onClick={() => closeUpdateMemberPopup()}>
                  Cancel
                </Button>
              </WDBorderButtonPrimary> */}
              <WDButton>
                <Button
                  onClick={() => updateMemberFuc()}
                  type="button"
                  bgColor={DEFAULT_COLORS.Background.DangerStrong}
                >
                  Update Family Member
                </Button>
              </WDButton>
            </FooterSectionEndWrapper>
          </FooterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}
export default UpdateMember
