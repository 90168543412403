import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'
interface WrapperInterface {
  source: string
}

export const Wrapper = styled.div<WrapperInterface>`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  width: ${(props: WrapperInterface) =>
    props.source == 'stageTwoInfo' ? '100%' : '95%'};
  gap: 1rem;
  justify-content: center;
`
export const HeaderWrapper = styled.div<WrapperInterface>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: ${SIZES[5]};
  z-index: 1;
  font-size: 14px;
  color: #3a3a3a;
`
export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: self-start;
  justify-content: space-between;
  position: sticky;
  padding: 0.5rem;
  top: 0;
  z-index: 10;
  background: ${() => DEFAULT_COLORS.Background.NeutralWeak};
  box-shadow: ${DEFAULT_COLORS.BoxShadow.Shadow};
`

export const CategoryWrapper = styled.div`
  display: flex;
  padding: 1rem;
  border-top: 0;
  border-left: 1px solid rgb(234 234 234);
  border-bottom: 1px solid rgb(234 234 234);
  border-right: 1px solid rgb(234 234 234);
  &:first-child {
    border-top: 1px solid rgb(234 234 234);
  }
  cursor: pointer;
  &:hover {
    background: #8080800f;
  }
`
export const ContentFormSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
