import { createContext, Dispatch, ReactNode, useMemo, useState } from 'react'

export interface ToastContextInterface {
  toastOpen: boolean | null
  toastMessage: string
  isLoading: boolean
  isMessageLoaderLoading: boolean
  loadingMessage: string | undefined
  setLoader: Dispatch<React.SetStateAction<boolean>>
  setMessageLoader: Dispatch<React.SetStateAction<boolean>>
  setLoadingMessage: Dispatch<React.SetStateAction<string | undefined>>
  setToastOpen: Dispatch<React.SetStateAction<boolean | null>>
  setToastMessage: Dispatch<React.SetStateAction<string>>
  setToast: (isOpen: boolean | null, message: string) => void
  setLoaderWithMessage: (isLoading: boolean, message?: string) => void
}

const ToastContext = createContext<ToastContextInterface>(
  {} as ToastContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const ToastContextProvider = ({ children }: ContextProps) => {
  const [toastOpen, setToastOpen] = useState<boolean | null>(null)
  const [toastMessage, setToastMessage] = useState<string>('')
  const [isLoading, setLoader] = useState(false)
  const [isMessageLoaderLoading, setMessageLoader] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>('')

  const setToast = (isOpen: boolean | null, message: string) => {
    setToastOpen(isOpen)
    setToastMessage(message)
  }

  const setLoaderWithMessage = (
    isLoading: boolean,
    message: string | undefined
  ) => {
    setMessageLoader(isLoading)
    setLoadingMessage(message)
  }

  const values = useMemo(
    () => ({
      toastOpen,
      isLoading,
      isMessageLoaderLoading,
      toastMessage,
      loadingMessage,
      setToast,
      setLoader,
      setMessageLoader,
      setToastOpen,
      setToastMessage,
      setLoadingMessage,
      setLoaderWithMessage,
    }),
    [isLoading, isMessageLoaderLoading, loadingMessage, toastMessage, toastOpen]
  )

  return (
    <ToastContext.Provider value={values}>{children}</ToastContext.Provider>
  )
}

export default ToastContext
