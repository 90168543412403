
export const leadColumnData = [
  {
    Header: 'Party/Lead',
    accessor: 'partyname',
  },
  {
    Header: 'Type',
    accessor: 'typedescription',
    // Filter: MultiSelectFilter,
    // filter: 'textArrayFilter',
  },
  {
    Header: 'Sub Type',
    accessor: 'subtypedescription',
    // Filter: MultiSelectFilter,
    // filter: 'textArrayFilter',
  },
  {
    Header: 'Location',
    accessor: 'location',
      },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
 
  {
    Header: 'Start Date-Time',
    accessor: 'starttime',
  },
  {
    Header: 'End Date-Time',
    accessor: 'endtime',
  },
  {

    Header: 'Status',
    accessor: 'meetingstatusid',
  },
  {
    Header: 'Owner',
    accessor: 'ownername',
  },
  {
    Header: 'REP CODE',
    accessor: 'repcode',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]

