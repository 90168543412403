import { useState, useEffect } from 'react'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { WDButtonTransparent } from '../../../components/ui/WDButton'
import { WDSubFormLabelHeading } from '../../../components/ui/WDLabel'
import { LeadInfoProps } from './IEditDetails'
import {
  HeaderWrapper,
  Wrapper,
  MasterWrapper,
  OverlayTitleWrapper,
  CrossWrapper,
  ColumnWrapper,
  AddressCardWrapper,
  AddressContentWrapper,
  AddressLineWrapper,
} from './styles'
import { ShippingAddressInterface } from '../../../composites/addressInformation/IAdressInformation'
import { PhoneInterface } from '../../../composites/phoneInformation/IPhoneInformation'
import { EmailInformationInterface } from '../../../composites/emailInformation/IEmailInformation'
import { MainWrapper, OverlayWrapper } from '../styles'
import { SvgClose } from '../../../components/svg/SvgClose'
import { WDContactInfoWrapper } from '../../../components/ui/WDWrapper'
import {
  CollapseWrapper,
  CollapseHeaderWrapper,
  CollapseButtonWrapper,
} from '../../../composites/individualInformation/styles'
import { CollaspPanelWrapper, Icons } from '../../../shared/GlobalStyle'
import Image from '../../../components/image/Image'
import { formatPhoneNumber } from '../../../services/Utils'
export const AdditionalAddressDetails = ({
  contacts,
  closeAdditionalDetails,
}: {
  contacts: LeadInfoProps
  closeAdditionalDetails: () => void
}) => {
  const [isPhoneInformationOpen, setPhoneInformation] = useState(true)
  const [isEmailInformationOpen, setEmailInformation] = useState(true)
  const [isAddressInformationOpen, setAddressInformation] = useState(true)
  const [addressList, setAddressList] = useState<any[]>([])
  const [emailList, setEmailList] = useState<EmailInformationInterface[]>([])
  const [phoneList, setPhoneList] = useState<PhoneInterface[]>([])

  const displayState = (state: string, postalCode: string) => {
    if (state && postalCode) {
      return `${state} - ${postalCode}`
    } else if (state && !postalCode) {
      return state
    } else if (!state && postalCode) {
      return postalCode
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (contacts) {
      const allContacts = contacts?.addressList
        .filter((address) => address.activeFlag !== false)
        .map((obj: ShippingAddressInterface) => {
          return {
            [obj.isPrimary ? `${obj.addressType} (Primary)` : obj.addressType]:
              [
                obj.addressLine1,
                obj.addressLine2,
                obj.city,
                displayState(obj.state, obj.postalCode),
                obj.country,
              ].filter((address) => address !== ''),
          }
        })
      setAddressList(allContacts.reverse())
      setEmailList(contacts?.emailList)
      setPhoneList(contacts?.phoneList)
      console.log('contacts?.emailList', contacts?.emailList)
    }
  }, [contacts])

  return (
    <OverlayWrapper>
      <MainWrapper>
        <Wrapper>
          <MasterWrapper>
            <HeaderWrapper>
              <OverlayTitleWrapper>
                <WDSubFormLabelHeading>
                  <Label>{'Additional Contact Information'}</Label>
                </WDSubFormLabelHeading>
                <CrossWrapper>
                  <WDButtonTransparent>
                    <Button
                      type="button"
                      onClick={() => closeAdditionalDetails()}
                    >
                      <SvgClose fillColor="#000"></SvgClose>
                    </Button>
                  </WDButtonTransparent>
                </CrossWrapper>
              </OverlayTitleWrapper>
              {phoneList.length > 0 && (
                <CollaspPanelWrapper>
                  <WDContactInfoWrapper>
                    <CollapseWrapper
                      onClick={() =>
                        setPhoneInformation(!isPhoneInformationOpen)
                      }
                    >
                      <CollapseHeaderWrapper>
                        <WDSubFormLabelHeading>
                          <Label fontFamily="SourceSansPro-SemiBold">
                            {'Phone'}
                          </Label>
                        </WDSubFormLabelHeading>
                      </CollapseHeaderWrapper>
                      <CollapseButtonWrapper>
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            onClick={() =>
                              setPhoneInformation(!isPhoneInformationOpen)
                            }
                          >
                            <Image
                              image={
                                isAddressInformationOpen
                                  ? Icons.ChevronUp
                                  : Icons.ChevronDown
                              }
                              alt="Chevron Down"
                            />
                          </Button>
                        </WDButtonTransparent>
                      </CollapseButtonWrapper>
                    </CollapseWrapper>
                    {isPhoneInformationOpen && (
                      <ColumnWrapper>
                        {phoneList.map((phone, index) => {
                          if (phone.activeFlag !== false) {
                            return (
                              <AddressCardWrapper key={index}>
                                <Label fontFamily="SourceSansPro-SemiBold">
                                  {phone.isPrimary
                                    ? `${phone.phoneType} (Primary)`
                                    : phone.phoneType}
                                </Label>
                                <AddressContentWrapper>
                                  {formatPhoneNumber(phone.phoneNo)}
                                </AddressContentWrapper>
                              </AddressCardWrapper>
                            )
                          }
                        })}
                      </ColumnWrapper>
                    )}
                  </WDContactInfoWrapper>
                </CollaspPanelWrapper>
              )}
              {emailList.length > 0 && (
                <CollaspPanelWrapper>
                  <WDContactInfoWrapper>
                    <CollapseWrapper
                      onClick={() =>
                        setEmailInformation(!isEmailInformationOpen)
                      }
                    >
                      <CollapseHeaderWrapper>
                        <WDSubFormLabelHeading>
                          <Label fontFamily="SourceSansPro-SemiBold">
                            {'Email'}
                          </Label>
                        </WDSubFormLabelHeading>
                      </CollapseHeaderWrapper>
                      <CollapseButtonWrapper>
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            onClick={() =>
                              setEmailInformation(!isEmailInformationOpen)
                            }
                          >
                            <Image
                              image={
                                isAddressInformationOpen
                                  ? Icons.ChevronUp
                                  : Icons.ChevronDown
                              }
                              alt="Chevron Down"
                            />
                          </Button>
                        </WDButtonTransparent>
                      </CollapseButtonWrapper>
                    </CollapseWrapper>
                    {isEmailInformationOpen && (
                      <ColumnWrapper>
                        {emailList.map((email, index) => {
                          if (email.activeFlag !== false) {
                            return (
                              <AddressCardWrapper key={index}>
                                <Label fontFamily="SourceSansPro-SemiBold">
                                  {email.isPrimary
                                    ? `${email.emailType} (Primary)`
                                    : email.emailType}
                                </Label>
                                <AddressContentWrapper>
                                  {email.email}
                                </AddressContentWrapper>
                              </AddressCardWrapper>
                            )
                          }
                        })}
                      </ColumnWrapper>
                    )}
                  </WDContactInfoWrapper>
                </CollaspPanelWrapper>
              )}
              {addressList.length > 0 && (
                <CollaspPanelWrapper>
                  <WDContactInfoWrapper>
                    <CollapseWrapper
                      onClick={() =>
                        setAddressInformation(!isAddressInformationOpen)
                      }
                    >
                      <CollapseHeaderWrapper>
                        <WDSubFormLabelHeading>
                          <Label fontFamily="SourceSansPro-SemiBold">
                            {'Address'}
                          </Label>
                        </WDSubFormLabelHeading>
                      </CollapseHeaderWrapper>
                      <CollapseButtonWrapper>
                        <WDButtonTransparent>
                          <Button
                            type="button"
                            onClick={() =>
                              setAddressInformation(!isAddressInformationOpen)
                            }
                          >
                            <Image
                              image={
                                isAddressInformationOpen
                                  ? Icons.ChevronUp
                                  : Icons.ChevronDown
                              }
                              alt="Chevron Down"
                            />
                          </Button>
                        </WDButtonTransparent>
                      </CollapseButtonWrapper>
                    </CollapseWrapper>
                    {isAddressInformationOpen && (
                      <ColumnWrapper>
                        {addressList.map((addressDetail, index) => {
                          return (
                            <AddressCardWrapper key={index}>
                              <Label fontFamily="SourceSansPro-SemiBold">
                                {Object.keys(addressDetail)[0]}
                              </Label>
                              <AddressContentWrapper>
                                {addressDetail[
                                  Object.keys(addressDetail)[0]
                                ].map((address: any, addressIndex: number) =>
                                  addressIndex !==
                                  addressDetail[Object.keys(addressDetail)[0]]
                                    .length -
                                    1 ? (
                                    <AddressLineWrapper
                                      key={addressIndex}
                                    >{`${address}, `}</AddressLineWrapper>
                                  ) : (
                                    <AddressLineWrapper key={addressIndex}>
                                      {address}
                                    </AddressLineWrapper>
                                  )
                                )}
                              </AddressContentWrapper>
                            </AddressCardWrapper>
                          )
                        })}
                      </ColumnWrapper>
                    )}
                  </WDContactInfoWrapper>
                </CollaspPanelWrapper>
              )}
            </HeaderWrapper>
          </MasterWrapper>
        </Wrapper>
      </MainWrapper>
    </OverlayWrapper>
  )
}

export default AdditionalAddressDetails
