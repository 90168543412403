import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES } from '../../theme/Sizes'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 0.5rem;
  height: auto;
`
export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`
export const TaskWrapper = styled.div`
  width: 50%;
  padding: ${SIZES[1]};
  gap: ${SIZES[3]};
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border: 1px solid ${() => DEFAULT_COLORS.Background.Grey};
  z-index: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${DEFAULT_COLORS.Background.Neutral};
  }
  ::-webkit-scrollbar-thumb {
    background: ${DEFAULT_COLORS.Background.PaleCerulean};
    border-radius: 4px;
  }
`
