import { gql } from '@apollo/client'

// Insert queries

// export const CREATE_LEAD_PERMISSION = gql`
//   mutation createLeadPermission($payload: [lead_permissions_insert_input!]!) {
//     insert_lead_permissions(objects: $payload) {
//       affected_rows
//       returning {
//         id
//       }
//     }
//   }
// `
// // Update queries

// export const UPDATE_LEAD_EMAIL = gql`
//   mutation updateLeadEmail($payload: [lead_emails_insert_input!]!) {
//     insert_lead_emails(
//       objects: $payload
//       on_conflict: {
//         constraint: emails_pkey
//         update_columns: [isprimary, type, emailid, activeflag]
//       }
//     ) {
//       affected_rows
//       returning {
//         id
//       }
//     }
//   }
// `

// Get queries
// export const GET_LEADS = gql`
//   query GetLeadDetails {
//     lead_leads(
//       distinct_on: id
//       order_by: [{ id: desc }, { createdon: desc }]
//       where: { _and: [{ partyid: { _is_null: true } }] }
//     ) {
//       id
//       firstname
//       middlename
//       lastname
//       type
//       account
//       accounttier
//       advisor
//       emailList {
//         id
//         leadid
//         isprimary
//         type
//         emailid
//       }
//       contactList {
//         id
//         leadid
//         isprimary
//         type
//         phonenumber
//         extension
//         speeddial
//       }
//     }
//   }
// `
// Get queries

export const GET_PERMISSIONS_BY_ID = gql`
  query GetLeadPermissions($leadid: bigint!) {
    lead_permissions(where: { leadid: { _eq: $leadid } }) {
        id
        type
        repcode
        status
    }
  }
`
